let icon_white = 'white';
let icon_green = 'success';
let icon_blue = 'primary';
let icon_red = 'danger';
let icon_warning = 'warning';

export const ConnectorState = {
    0: {text: "initing", color: icon_warning, icon: icon_white},
    1: {text: "free", color: icon_green, actived: false, icon: icon_green, showInfo: false, buttonDis:true},
    2: {text: "free_permiss", color: icon_blue, actived: true, icon: icon_green, showInfo: false, buttonDis:true},
    3: {text: "connected", color: icon_green, actived: false, icon: icon_blue, showInfo: false, buttonDis:false},
    4: {text: "connected_permiss", color: icon_blue, actived: true, icon: icon_blue, showInfo: false, buttonDis:true},
    5: {text: "loading", color: "charging", actived: true, icon: icon_blue, showInfo: true, buttonDis:false},
    6: {text: "loading_limited", color: icon_blue, actived: true, icon: icon_blue, showInfo: true, buttonDis:false},
    7: {text: "loading_paused", color: icon_blue, actived: true, icon: icon_blue, showInfo: true, buttonDis:false},
    8: {text: "loading_finished", color: icon_warning, actived: true, icon: icon_blue, showInfo: true, buttonDis:false},
    9: {text: "error_hardware", color: icon_red, actived: false, icon: icon_red, showInfo: false, buttonDis:true},
    10: {text: icon_red, color: icon_red, actived: false, icon: icon_red, showInfo: false, buttonDis:true},
    11: {text: icon_red, color: icon_red, actived: false, icon: icon_red, showInfo: false, buttonDis:true},
    12: {text: "error_diode", color: icon_red, actived: false, icon: icon_red, showInfo: false, buttonDis:true},
    13: {text: "error_land", color: icon_red, actived: false, icon: icon_red, showInfo: false, buttonDis:true},
    14: {text: "switched_on", color: icon_green, actived: true, icon: icon_green, showInfo: false, buttonDis:false},
    30: {text: "switched_on", color: icon_green, actived: true, icon: icon_green, showInfo: false, buttonDis:false},
    31: {text: "switched_off", color: icon_red, actived: false, icon: icon_red, showInfo: false, buttonDis:false},
    32: {text: "inoperative", color: icon_red, actived: false, icon: icon_red, showInfo: false, buttonDis:true},
}

export const SelectorPosition = {
    0: {text: "No limit", color: "warning", actived: false, icon: icon_white, showInfo: false, buttonDis:true},
    1: {text: "10 A", color: "success", actived: false, icon: icon_green, showInfo: false, buttonDis:true},
    2: {text: "13 A", color: "primary", actived: true, icon: icon_green, showInfo: false, buttonDis:true},
    3: {text: "15 A", color: "success", actived: false, icon: icon_blue, showInfo: false, buttonDis:false},
    4: {text: "18 A", color: "primary", actived: true, icon: icon_blue, showInfo: false, buttonDis:true},
    5: {text: "20 A", color: "primary", actived: true, icon: icon_blue, showInfo: true, buttonDis:false},
    6: {text: "23 A", color: "primary", actived: true, icon: icon_blue, showInfo: true, buttonDis:false},
    7: {text: "25 A", color: "primary", actived: true, icon: icon_blue, showInfo: true, buttonDis:false},
    8: {text: "28 A", color: "warning", actived: true, icon: icon_blue, showInfo: true, buttonDis:false},
    9: {text: "30 A", color: "error", actived: false, icon: icon_red, showInfo: false, buttonDis:true},
    10: {text: "35 A", color: "error", actived: false, icon: icon_red, showInfo: false, buttonDis:true},
    11: {text: "40 A", color: "error", actived: false, icon: icon_red, showInfo: false, buttonDis:true},
    12: {text: "45 A", color: "error", actived: false, icon: icon_red, showInfo: false, buttonDis:true},
    13: {text: "50 A", color: "error", actived: false, icon: icon_red, showInfo: false, buttonDis:true},
    14: {text: "55 A", color: "success", actived: true, icon: icon_green, showInfo: false, buttonDis:false},
    15: {text: "55 A", color: "success", actived: true, icon: icon_green, showInfo: false, buttonDis:false},
    16: {text: "63 A", color: "error", actived: false, icon: icon_red, showInfo: false, buttonDis:false},
}
