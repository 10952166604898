<!-- <div tabindex="-1" role="dialog" aria-hidden="true"> -->
  <!-- <div role="document"> -->

    <div class="card  mt-0 mb-0 " style="height: 110%;">
      <div class="card-header">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="text-primary">{{"subgroups" | translate}}</h5>

          <button class="button-plus" (click)="close()"  ><i class="fas fa-times"></i></button>
        </div>
      </div>
      <div class="card-body ">
        <div class="d-flex justify-content-between align-items-center ">
          <div class="card-body row p-1">
            <div class="row d-flex align-items-center mt-2">
              <div class="col d-flex justify-content-start pl-6">
                <label for="" class="align-middle">{{"nameInstallation" | translate}}: </label>
              </div>
              <div class="col">
                <input type="text" class="input" [(ngModel)]="group.name">
              </div>
            </div>
            <div class="row d-flex align-items-center mt-2">
              <div class="col d-flex justify-content-start pl-6">
                <label> {{"chargerDescription" | translate}}: </label>
              </div>
              <div class="col">
                <input type="text" class="input" [(ngModel)]="group.description">
              </div>
            </div>
            <!-- <div class="row d-flex align-items-center mt-2">
              <div class="col d-flex justify-content-start pl-6">
                <label for="">Potencia: </label>
              </div>
              <div class="col">
                <input type="text" class="input">
              </div>
            </div>
            <div class="row d-flex align-items-center mt-2">
              <div class="col d-flex justify-content-start pl-6">
                <label for="">Potencia: </label>
              </div>
              <div class="col">
                <input type="text" class="input">
              </div>
            </div>
            <div class="row d-flex align-items-center mt-2">
              <div class="col d-flex justify-content-start pl-6">
                <label for="">SPL: </label>
              </div>
              <div class="col">
                <div class="example-section">
                  <mat-checkbox class="example-margin" ></mat-checkbox>
                </div>
              </div>
            </div>
          </div> -->

        </div>
      </div>

      <div class="d-flex justify-content-center align-items-center">
        <button class="button-save m-3" (click)="save()" >{{"save"| translate}}</button>
      </div>
    </div>
<!-- </div> -->
