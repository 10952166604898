import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  device='';

  @Input()
  allSettings=false;

  successMessage: boolean = false;
  error: string = '';
  modalLoad: NgbModalRef;

  lastType='';

  constructor(public _dService: DeviceService, private _aRoute: ActivatedRoute, private _modal: ModalService, private _translate: TranslateService,) { }

  ngOnInit(): void {
    this._aRoute.parent?.parent?.params
    .subscribe((params: Params) => this.device = params['serial']);
  }

  reset(type){
    if(type=="hard")
      var text = this._translate.instant('resetSure');
    else
      var text = this._translate.instant('restoreSure');
    if (confirm(text)) {
      this.modalLoad=this._modal.open(LoadingPopupComponent);

      this.lastType = type;

      this._dService.reset(this.device, type)
        .subscribe(data=>{
          console.log(data);
          this.modalLoad.close();
          this.successMessage=true;
          setTimeout(() => {
            this.successMessage=false;
          }, 10000);
        }, err=>{
          console.error(err)
          this.error=err.error.message;
          setTimeout(() => {
            this.error='';
          }, 10000);
          this.modalLoad.close();
        })
    }
  }
}
