import { NestedTreeControl } from '@angular/cdk/tree';
// import { Component, OnInit, OnChanges } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute, Params, Router, NavigationStart, ActivatedRouteSnapshot, ParamMap } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalSelectSerialComponent } from 'src/app/components/ui/modal-select-serial/modal-select-serial.component';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { UserService } from 'src/app/services/user/user.service';

import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import calculoZoom from 'src/app/pages/dashboard/calculoDistancias';
import { forkJoin } from 'rxjs';




export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

interface TreeNode {
  name: string;
  alias?: string
  isExpanded?: boolean;
  children?: TreeNode[];
  devices?: any[];
  subgroups?: any[]
}

interface Devices {
  serial: string,
  role: string,
  name?: string,
  alive?: number
}


@Component({
  //moduleId: module.id,
  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']

})

export class SidebarComponent implements OnInit {
  userName: string = '';
  userRole: string = '';
  userEmail: string = '';
  devices: any[] = [];

  filteredSerial = '';

  group: string | null;
  topPosition: string = 'calc(100% - 75px)';
  sidebarHeight: string = 'calc(100vh - 64px)'; // Altura inicial
  sidebarTop: string = '64px'; // Posición inicial desde la parte superior
  devicesHeight: string = '56vh';

  top : string = '';


  modalDiagnosis: NgbModalRef;

  data: any[] = []
  modoDashboard: string = '';
  view : 'devices' | 'groups' = 'groups';


  navStart: Observable<NavigationStart>;
  updateSidebar

  paramInfoGroup: string | null | undefined= null; // Parámetro de ruta 'infoGroup'
  paramGroup: string | null | undefined = null; // Parámetro de ruta 'newGroup'
  paramSplGroup : string | null | undefined = null; // Parámetro de ruta 'slpGroup'
  paramViewGroup : string | null | undefined = null  // Parámetro de ruta 'viewGroup'
  paramDevice : string | null | undefined = null;
  selectedNode: any = null;
  expandedNode: any = null;

  expandedParentNode: TreeNode | null = null;
  expandedChildNode: TreeNode | null = null;
  expandedGrandchildNode: TreeNode | null = null;

  constructor(private _devices: DeviceService,
    private _router: Router,
    private _modal: ModalService,
    private _user: UserService,
    private rutaActiva: ActivatedRoute,
    private location: Location,
    private _dashboard: DashboardService) {

    // Create a new Observable that publishes only the NavigationStart event
    this.navStart = _router.events.pipe(
      filter(evt => evt instanceof NavigationStart)
    ) as Observable<NavigationStart>;
  }


  ngOnInit() {

    this._dashboard.getModoDashboard().subscribe(data => {
      this.modoDashboard = data
    });

    this._dashboard.getViewDashboard().subscribe( data => {
      this.view = data
    })

    this._dashboard.getUpdateSidebar().subscribe( data => {
      this.updateSidebar = data;
      if( this.updateSidebar == 1){
        this.expandedParentNode = null;
        this.expandedChildNode= null;
        this.expandedGrandchildNode = null;
        this.paramInfoGroup = null; // Parámetro de ruta 'infoGroup'
        this.paramGroup = null; // Parámetro de ruta 'newGroup'
        this.paramSplGroup = null; // Parámetro de ruta 'slpGroup'
        this.paramViewGroup = null  // Parámetro de ruta 'viewGroup'
        this.paramDevice = null
        this.selectedNode = null

        this.getGroups();
      }
    })


    // Obtener la URL completa incluyendo query parameters
    const currentUrl = this.location.path();
    this.extractParamsFromUrl(currentUrl)
    this.selectedNode = null


    this.navStart.subscribe(evt => {
      this.selectedNode = null;
      this.paramGroup=null , this.paramInfoGroup=null, this.paramSplGroup=null, this.paramViewGroup=null;  this.paramDevice=null;
      this.extractParamsFromUrl(evt.url)
    });

    this._user.getUserInfo().subscribe((userInfo) => {
      this.userEmail = userInfo.email || '';
      this.userName = userInfo.name || '';
      this.userName += ' ' + userInfo.last_name || ''
      this.userRole = userInfo.role || '';
      this.getAllDevices();
    });
    this.getGroups()

  }

  getAllDevices(){
    this._devices?.getDevicesByUser().subscribe((devices) => {
      this.devices = devices;
    });
  }

  getGroups() {
    this.data = [];
    this._devices.getGroups('onlyParents').subscribe(groupsParents => {

      if (groupsParents && groupsParents.length > 0) {
        const requests = groupsParents.map(parent =>
          this._devices.getGroup(parent.alias)        );

        forkJoin(requests).subscribe(responses => {
          this.data = responses;
          this.data.sort((a, b) => new Date(a.date_build).getTime() - new Date(b.date_build).getTime());
          this._dashboard.resetUpdateSidebar();
        });
      } else {
        this._dashboard.resetUpdateSidebar();
      }
    });
  }


  extractParamsFromUrl(url: string) {
    const regex = /(group|newgroup|splconfig|viewgroup|device)\/([^\/]+)/;
    const match = url.match(regex);

    if (match) {
      const parameter = match[2];
      switch (match[1]) {
        case 'group':
          this.paramInfoGroup = parameter;
          break;
        case 'newgroup':
          this.paramGroup = parameter;
          break;
        case 'splconfig':
          this.paramSplGroup = parameter;
          break;
        case 'viewgroup':
          this.paramViewGroup = parameter;
          break;
        case 'device':
            this.paramDevice = parameter;
          break;
      }
    }
  }


  clickDiagnosis() {
    this.modalDiagnosis = this._modal.open(ModalSelectSerialComponent);
    this.modalDiagnosis.componentInstance.devices = this.devices;

    this.modalDiagnosis.result.then((result) => {
      if (result) {
        this._router.navigate(['/dashboard/diagnosis', result]);
      }
    });
  }

  clickDevice(device) {
    this._devices?.setUserDevice(this.userEmail);
    this.selectedNode = null;
    setTimeout(() => {
      this._router.navigate(['/dashboard/device', device]);
    },500);
  }

  deleteUserDevice() {
    //var self = this;
    //self._devices.setUserDevice(null);
  }

  addGroup() {
    this._router.navigate(['/dashboard/newgroup']);
  }


  toggleNode(node: TreeNode, devices: any[]) {
    this._dashboard.resetBreadCrumbs();
    this._dashboard.setBreadCrumbs({ name: node.name, alias: node.alias == undefined ? "" : node.alias  });

    this.selectedNode = node.alias ;

    // Si el nodo ya está expandido, lo cerramos
    if (this.expandedParentNode === node) {
      node.isExpanded = false;
      this.expandedParentNode = null;
    } else {
      // Cerrar el nodo previamente expandido en el nivel principal
      if (this.expandedParentNode) {
        this.expandedParentNode.isExpanded = false;
      }
      node.isExpanded = true;
      this.expandedParentNode = node;
    }

    this.navigate(node.alias ?? '', devices);
  }


  clickGroup(parentNode: TreeNode, child: TreeNode) {
    this.selectedNode = child.alias;

  // Gestionar breadcrumbs
  const breadcrumbs = [
    { name: 'home', alias: 'dashboard' },
    { name: parentNode.name, alias: parentNode.alias ?? '' },
    { name: child.name, alias: child.alias ?? '' }
  ];
  this._dashboard.replaceBreadCrumbs(breadcrumbs);

  // Expandir/contraer subgrupo
  if (this.expandedChildNode === child) {
    child.isExpanded = false;
    this.expandedChildNode = null;
  } else {
    if (parentNode.subgroups?.length) {
      parentNode.subgroups.forEach(subgroup => {
        if (subgroup !== child && subgroup.isExpanded) {
          subgroup.isExpanded = false;
        }
      });
    }
    child.isExpanded = true;
    this.expandedChildNode = child;

    this._devices.getGroup(child.alias ?? '').subscribe(data => {
      child.children = data.subgroups || [];
      child.devices = data.devices || [];
      this.navigate(child.alias ?? '', child.devices);
    });
    }
  }

  clickGrandchild(grandchild: TreeNode) {
    this.selectedNode = grandchild.alias

    // Gestionar breadcrumbs - REEMPLAZAR los breadcrumbs con el nuevo nieto
    const breadcrumbs = [
      { name: 'home', alias: 'dashboard' },
      { name: this.expandedParentNode?.name ?? '', alias: this.expandedParentNode?.alias ?? '' },
      { name: this.expandedChildNode?.name ?? '', alias: this.expandedChildNode?.alias ?? '' },
      { name: grandchild.name, alias: grandchild.alias ?? '' }
    ];
    this._dashboard.replaceBreadCrumbs(breadcrumbs);

    // Expandir/contraer nieto
    if (this.expandedGrandchildNode === grandchild) {
      grandchild.isExpanded = false;
      this.expandedGrandchildNode = null;
    } else {
      // Cerrar el nieto previamente expandido
      if (this.expandedGrandchildNode) {
        this.expandedGrandchildNode.isExpanded = false;
      }
      grandchild.isExpanded = true;
      this.expandedGrandchildNode = grandchild;

      // Obtener subgrupos y dispositivos del nieto seleccionado
      this._devices.getGroup(grandchild.alias ?? '').subscribe(data => {
        grandchild.children = data.subgroups || [];
        grandchild.devices = data.devices || [];
        this.navigate(grandchild.alias ?? '', grandchild.devices);
      });
    }
  }



  navigate(param , devices ){

    if (this.modoDashboard === 'list' || this.modoDashboard != 'map' ) {
      if( this.paramInfoGroup != null){
        this._router.navigate([`/dashboard/group/${param}`]);
      }
      if( this.paramViewGroup != null){
        this._router.navigate([`/dashboard/viewgroup/${param}`]);
      }
      if( this.paramGroup != null){
        this._router.navigate([`/dashboard/newgroup/${param}`]);
      }
      if( this.paramSplGroup != null){
        this._router.navigate([`/dashboard/splconfig/${param}`]);
      }
      if( this.paramInfoGroup == null && this.paramViewGroup == null && this.paramGroup == null && this.paramSplGroup == null ){
        this._router.navigate([`/dashboard/group/${param}`]);
      }
    } else {
      this.loadGeolocationAndNavigate(devices, param);
    }


  }
  loadGeolocationAndNavigate(devices: any[] | undefined, alias: string | undefined) {
    if (!devices || devices.length === 0) return;

    const observables = devices.map(device =>
      this._devices.getModuleInfo(device.serial, 'clock', 'cfg')
    );

    forkJoin(observables).subscribe(responses => {
      const geolocation = responses.map((data: any) => {
        const geoloc = JSON.parse(data.cfg.geoloc);
        return {
          position: {
            lat: parseFloat(geoloc.coords[0]),
            lng: parseFloat(geoloc.coords[1])
          }
        };
      });

      if (geolocation.length > 0) {
        this._dashboard.setLocation(geolocation[0].position);
        this._dashboard.setZoom(calculoZoom(geolocation));
      }
    });
  }

  changeView(){
    this.view === 'devices' ? this.view = 'groups' : this.view = 'devices';
    this._dashboard.setViewDashboard(this.view)
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    // Calcula siha llegado al final de la página
    const windowHeight = window.innerHeight;
    const pageHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.scrollY + windowHeight;


    const scrollTop = window.scrollY || document.documentElement.scrollTop;

    if (scrollTop > 0) {
      this.sidebarHeight = '100vh'; // Cambiar a altura completa
      this.sidebarTop = '0'; // Cambiar para que empiece desde la parte superior
      this.devicesHeight = '62vh'
    } else {
      this.sidebarHeight = 'calc(100vh - 64px)'; // Altura inicial sin scroll
      this.sidebarTop = '64px'; // Posición inicial
      this.devicesHeight = '56vh'
    }

    if (scrollPosition >= pageHeight) {
      this.topPosition = 'calc(100% - 82px)';
      this.sidebarHeight = 'calc(100vh - 40px)'; // Altura inicial sin scroll
      this.devicesHeight = '58vh'
    }
    else {
      this.topPosition = 'calc(100% - 52px)';
    }
  }

}
