import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MatTableDataSource } from "@angular/material/table";
import { DeviceService } from 'src/app/services/devices/device.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/modals/confirmation-modal/confirmation-modal.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';

interface Module {
  name: string;
  topic_name: string;
  active: boolean;
  link: string;
  dateEnd: string;
  show: boolean;
}

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})

export class SubscriptionsComponent implements OnInit {
  serial: string = '';
  user: string = '';
  dateEndWarranty:any = null;

  modalConfirm: NgbModalRef;

  socket;

  modules: Module[] = [
    {name: 'modulator', topic_name: "modulator", active: true, dateEnd: '', link: ""/*"https://buy.stripe.com/test_7sIg2Fcs69rg2jedQR"*/, show: true},
    {name: 'multidevice', topic_name: "spl", active: true, dateEnd: '', link: ""/* "https://buy.stripe.com/test_3csaIlfEi0UKe1W28a"*/, show: true},
    {name: 'solar', topic_name: "solar", active: false, dateEnd: '', link: 'https://buy.stripe.com/test_14kbMp3VAeLAaPK7sA', show: true},
    {name: 'OCPP', topic_name: "ocpp", active: false, dateEnd: '', link: 'https://buy.stripe.com/test_5kAeYB2Rw6f48HC007', show: true},
    {name: 'MQTT', topic_name: "mqtt_user", active: false, dateEnd: '', link: "https://buy.stripe.com/test_00g6s54ZEeLAga4dQW", show: true},
    {name: 'scheduler', topic_name: "schedman", active: false, dateEnd: '', link: "https://buy.stripe.com/test_9AQbMpcs68ncaPK149", show: true},
    {name: 'RFID', topic_name: "rfid", active: false, dateEnd: '', link: "https://buy.stripe.com/test_5kAcQtgIm1YOe1W4gk", show: true},
    {name: 'Modbus', topic_name: "modbus", active: false, dateEnd: '', link: "https://buy.stripe.com/test_00gbMp0Jo9rg9LG28b", show: true},
    {name: 'Extensión de garantía', topic_name: "warranty_ext", active: false, dateEnd: '', link: "https://buy.stripe.com/test_eVa17L2Rw7j8e1WcMX", show: false}
  ];

  dataSource = new MatTableDataSource<Module>();

  constructor(private _device: DeviceService, private _aRoute: ActivatedRoute, private _modal: ModalService, private _translate: TranslateService, private _ws: WebsocketService) {}

  ngOnInit(): void {
    this.user = this._device.getUserActive();
    this._aRoute.parent?.params
    .subscribe((params: Params) => {
      this.serial = params['serial'];
      this.loadData();
      this.connectWS();
    });


    this.updateTableData();
  }

  loadData(){
    this._device.getModulesByDevice(this.serial)
      .subscribe(data => {
        this.modules.forEach(module => {
          var m = data.find(m => m.topic_name === module.topic_name)

          if(m) {
            module.active = true;
            if(m.date_end_subscription)
              module.dateEnd = new Date(m.date_end_subscription).toLocaleDateString();
            else
              module.dateEnd = '';
          }
          else {
            module.active = false;
          }
        });
        this.updateTableData();
      })

    this._device.activeDeviceInfo$.subscribe((info) => {
      if(info){
        var firstDeviceOwner = info.user_build;
        if(firstDeviceOwner){
          this._device.getLinkedUsersToCharger(this.serial).subscribe((data) => {
            // comprueba si el usuario actual es el propietario del dispositivo y la fecha de vinculación
            let user = data.find(user => user.user_email === firstDeviceOwner && user.user_device_role === 'owner');
            if(user){
              var firstLinkedDate = new Date(user.user_device_date_create);
              this.dateEndWarranty = new Date(firstLinkedDate.setFullYear(firstLinkedDate.getFullYear() + 3));
            }
          });
        }
      }
    });
  }

  unsubscribe(module: Module) {
    this.modalConfirm = this._modal.open(ConfirmationModalComponent);
    this.modalConfirm.componentInstance.header = this._translate.instant('cancelSubscription');
    this.modalConfirm.componentInstance.body = this._translate.instant('sureCancelSubscription');
    this.modalConfirm.componentInstance.emitService.subscribe( (valueRecieved) => {
        if(valueRecieved){
          this._device.deleteModule(this.serial, module.topic_name, null)
          .subscribe(data=>{
            module.active = false;
            module.dateEnd = '';
            this.modalConfirm.close();
            if(module.topic_name === 'warranty_ext'){
              module.show = false;
              this.updateTableData();
            }
          }, err => {
            alert(err);
          });
        }
      }
    );
  }

  subscribe(module: Module) {
    window.open(module.link+"?prefilled_email="+this.user+"&client_reference_id="+this.serial, '_blank');
  }


  connectWS() {
    this.socket = this._ws.connect(this.serial)
      .subscribe(
        msg => {
          let message = msg;
          switch (message.module) {
            case "subscription":
              if(message.data.stat){
                console.log(message.data.stat);
                let module = this.modules.find(m => m.topic_name === message.data.stat.module);
                if(module){
                  if(message.data.stat.event == "created" || message.data.stat.event == "renewed"){
                    module.active = true;
                    module.dateEnd = new Date(message.data.stat.date_expiration).toLocaleDateString();
                  }
                  else{
                    module.active = false;
                    module.dateEnd = '';
                  }
                }
                this.updateTableData();
              }
              break;
          }
        },
        err => {
          console.log(err);
        }
      )
  }

  checkWarrantyDate(){
    if(!this.dateEndWarranty)
      return false;
    
    var warrantyDate = new Date(this.dateEndWarranty);
    var currentDate = new Date();

    if (warrantyDate > currentDate) {
      var oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(currentDate.getFullYear() + 1);

      return warrantyDate <= oneYearFromNow;
    }

  return false;
  }

  checkWarrantyActive(){
    return this.modules.find(module => module.topic_name === 'warranty_ext' && module.active);
  }

  subscribeWarranty(){
    var module = this.modules.find(module => module.topic_name === 'warranty_ext');
    if(module)
      this.subscribe(module);
  }

  updateTableData(){
    this.dataSource.data = this.modules.filter(module => module.show || module.active);
  }
}
