import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Chart } from 'chart.js';
import { DeviceService } from 'src/app/services/devices/device.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Colors } from 'src/assets/js/colors';
import moment, { Moment } from 'moment';

@Component({
  selector: 'app-card-analytics',
  templateUrl: './card-analytics.component.html',
  styleUrls: ['./card-analytics.component.scss'],
  // animations: [
  //   trigger('fadeInLeft', [transition('* => *', useAnimation(fadeInRight, {
  //     // Set the duration to 5seconds and delay to 2seconds
  //     params: { timing: 0.2, delay: 0 }
  //   }))])
  // ]
})
export class CardAnalyticsComponent implements OnInit {

  canvas: any;
  ctx: any;
  chart: any;

  dateSearch = new Date();
  totalEvse = 0;
  totalHome = 0;

  device = '';
  view = 'consumptions';

  selectedConnector;

  historicsInfo: any;
  historics: any = {};

  @Input()
  dataAnalytics: any;

  @Input()
  connectors: any;

  @Input()
  serial : any;

  constructor(private _device: DeviceService, private _aRoute: ActivatedRoute, private _utils: UtilsService) {
    this.view = 'consumptions';
  }

  ngOnInit(): void {
    this.device = this._aRoute.snapshot.params.serial;
    if (this.device === undefined )
      this.device = this.serial;


    setTimeout(() => {
      this.loadGraphHistorics();
    }, 100);

    this.selectedConnector = this.connectors[0].name;

  }

  changeDate(change: number) {
    this.totalEvse = 0;
    this.totalHome = 0;
    var date: Date = moment(this.dateSearch).toDate()
    if(change > 0){
      date = moment(this.dateSearch).add(change, 'days').toDate()
    }else if(change < 0){
      date = moment(this.dateSearch).subtract((change*-1), 'days').toDate()
    }
    this.dateSearch = date;
    this._device.getHistoricGraphView(
      this.device,
      this._utils.getFormatedLocalDate(date, 'YYYY-MM-DD'),
      this._utils.getFormatedLocalDate(date, 'YYYY-MM-DD')
    )
      .subscribe(data => {
        //console.log("changeDate",data);
        let filledData: any[] = [];
        for(let i=0; i<24; i++){
          let findHour = data.data.find(d=>Number.parseInt(this._utils.getFormatedLocalDate(d.hour, 'HH'))==i)
          findHour?filledData.push(findHour):filledData.push({});
        }
        this.dataAnalytics = filledData;
        this.updateGraph();
      });
  }

  subtractDates(date1, date2) {
    var difference_ms = new Date(date1).getTime() - new Date(date2).getTime();
    //take out milliseconds
    difference_ms = difference_ms / 1000;
    var seconds = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    var minutes = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    var hours = Math.floor(difference_ms % 24);
    if (hours != 0)
      return hours + 'h ' + minutes.toString() + 'm ' + seconds.toString() + 's';
    else return minutes.toString() + 'm ' + seconds.toString() + 's';
  }

  updateGraph(){
    var dataEvse = {
      label: 'Evse',
      data: [0],
      backgroundColor: [
        Colors.secondary
      ]
    };
    var dataHome = {
      label: 'Home',
      data: [0],
      backgroundColor: [
        Colors.primary
      ]
    };

    this.dataAnalytics.forEach((hour, index) => {
      let evsePower = hour.elements?.reduce((acc, element) => { return acc +  Number.parseInt(element.active)}, 0);

      if (index == 0) {
        dataEvse.data[0] = (evsePower);
        dataHome.data[0] = (parseInt(hour.homeEnergy));
      } else {
        dataEvse.data.push(evsePower);
        dataEvse.backgroundColor.push(Colors.secondary);
        dataHome.backgroundColor.push(Colors.primary);
        dataHome.data.push(parseInt(hour.homeEnergy));
      }

      if(evsePower)
        this.totalEvse += evsePower;
      if(hour.homeEnergy)
        this.totalHome += parseInt(hour.homeEnergy);
    });

    this.chart.data.datasets[0] = dataEvse;
    this.chart.data.datasets[1] = dataHome;

    this.chart.update();
  }

  loadGraphHistorics() {
    this.totalEvse = 0;
    this.totalHome = 0;
    var dataEvse = {
      label: 'Evse',
      data: [0],
      backgroundColor: [
        Colors.secondary
      ]
    };
    var dataHome = {
      label: 'Home',
      data: [0],
      backgroundColor: [
        Colors.primary
      ]
    };

    this.dataAnalytics.forEach((hour, index) => {
      let evsePower = hour.elements?.reduce((acc, element) => { return acc +  Number.parseInt(element.active)}, 0);

      if (index == 0) {
        dataEvse.data[0] = (evsePower);
        dataHome.data[0] = (parseInt(hour.homeEnergy));
      } else {
        dataEvse.data.push(evsePower);
        dataEvse.backgroundColor.push(Colors.secondary);
        dataHome.backgroundColor.push(Colors.primary);
        dataHome.data.push(parseInt(hour.homeEnergy));
      }

      if(evsePower)
        this.totalEvse += evsePower;
      if(hour.homeEnergy)
        this.totalHome += hour.homeEnergy;
    });

    var labels = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
    this.canvas = document.getElementById("chart-card-historics");
    this.ctx = this.canvas.getContext("2d");
    this.chart = new Chart(this.ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [dataEvse, dataHome]
      },
      options: {
        scales: {
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

}
