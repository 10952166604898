export interface Theme {
    name: string;
    properties: any;
}

export const light: Theme = {
    name: "light",
    properties: {
        "--background-main": "none",
        "--background-wrapper": "linear-gradient(131deg, rgba(0,119,139,1) 1%, rgba(0,44,51,1) 100%)"
        //   "--foreground-default": "#08090A",
        //   "--foreground-secondary": "#41474D",
        //   "--foreground-tertiary": "#797C80",
        //   "--foreground-quaternary": "#F4FAFF",
        //   "--foreground-light": "#41474D",

        //   "--background-dark": "#F4FAFF",
        //   "--background-secondary": "#A3B9CC",
        //   "--background-tertiary": "#5C7D99",
        //   "--background-light": "none",

        //   "--primary-default": "#5DFDCB",
        //   "--primary-dark": "#24B286",
        //   "--primary-light": "#B2FFE7",

        //   "--error-default": "#EF3E36",
        //   "--error-dark": "#800600",
        //   "--error-light": "#FFCECC",

        //   "--background-tertiary-shadow": "0 1px 3px 0 rgba(92, 125, 153, 0.5)"
    }
};

export const dark: Theme = {
    name: "dark",
    properties: {
        "--background-main": "linear-gradient(131deg, rgba(0,119,139,1) 1%, rgba(0,44,51,1) 100%)",
        "--background-wrapper": "none"
        //   "--foreground-default": "#5C7D99",
        //   "--foreground-secondary": "#A3B9CC",
        //   "--foreground-tertiary": "#F4FAFF",
        //   "--foreground-quaternary": "#E5E5E5",
        //   "--foreground-light": "#FFFFFF",

        //   "--background-default": "#797C80",
        //   "--background-secondary": "#41474D",
        //   "--background-tertiary": "#08090A",
        //   "--background-light": "linear-gradient(131deg, rgba(0,119,139,1) 1%, rgba(0,44,51,1) 100%)",

        //   "--primary-default": "#5DFDCB",
        //   "--primary-dark": "#24B286",
        //   "--primary-light": "#B2FFE7",

        //   "--error-default": "#EF3E36",
        //   "--error-dark": "#800600",
        //   "--error-light": "#FFCECC",

        //   "--background-tertiary-shadow": "0 1px 3px 0 rgba(8, 9, 10, 0.5)"
    }
};