import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { ModalConfirmComponent } from 'src/app/components/ui/modal-confirm/modal-confirm.component';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss'],
})
export class AddDeviceComponent implements OnInit {
  user: string = '';
  disabledBtn = true;
  response = {
    success: false,
    error: false,
  };
  error404 = false
  error500 = false

  body = {
    serial: '',
    name: '',
  };

  buttonOpenConfirm: any;
  buttonCloseConfirm: any;
  loadingModal: NgbModalRef;
  modalSuccess: NgbModalRef;
  devices
  file: File | null = null;
  data: any[] = [];
  messageOK: any[] = [];
  messageError: any[] = [];
  showResult = false;

  constructor(private _device: DeviceService, private _modal: ModalService, private _translate: TranslateService) {}

  ngOnInit(): void {
    this.buttonOpenConfirm = document.getElementById('toggle-name-modal');
    this.buttonCloseConfirm = document.getElementById('dismiss-confirm-modal');
  }

  save() {
    this.buttonOpenConfirm?.click();
  }

  assign() {

    this.buttonCloseConfirm?.click();
    this.loadingModal = this._modal.open(LoadingPopupComponent);

    this.error404 = false
    this.error500 = false;

    this._device.getDeviceInfo(this.body.serial).subscribe( (data) => {
        this._device.assignDevices(this.body.serial, this.user, 'all').subscribe( (data) => {
          console.log(data);

            this.loadingModal.close();
            this.modalSuccess=this._modal.open(ModalConfirmComponent);
            this.modalSuccess.componentInstance.title = this._translate.instant("device_assigned_successfully");

          },
          (err) => {
            this.response.error = true;
            if (err.status === 404) {
              this.loadingModal.close();
              this.error404 = true
              this.resetForm()

            }else if(err.status === 500){
                this.loadingModal.close();
                this.error500 = true
                this.resetForm()
            }
          }
        );
      },
      (err) => {
        this.response.error = true;
        if (err.status === 404) {
          this.error404 = true
          this.loadingModal.close();
          this.resetForm()
        }
      }
    );
  }



  resetForm(){
    this.user = '';
    this.body.serial = '';

  }

   // Capturar el archivo
   onFileChange(event: any) {
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) return;
    this.file = target.files[0];
    this.upload()
  }

  upload() {
    if (!this.file) {
      console.error("No file selected");
      return;
    }

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0]; // Seleciona la primera hoja (si hubiera más de una)
      const worksheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Leer como array
      console.log("Datos en bruto:", jsonData);

      // Validar que jsonData tiene contenido
      if (!jsonData || jsonData.length === 0 || !Array.isArray(jsonData[0])) {
        console.error("El archivo no tiene datos o el formato es incorrecto.");
        return;
      }

      let columnIndex = -1;
      let hasHeader = false;

      // Comprueba si en la cabecera está "devices" en la primera fila
      if (jsonData[0].some(cell => typeof cell === "string" && cell.toLowerCase() === "devices")) {
        columnIndex = jsonData[0].findIndex(header =>
          typeof header === "string" && header.toLowerCase() === "devices"
        );
        hasHeader = true;
      }

      // Extraer los datos según si hay cabecera o no
      if (hasHeader && columnIndex !== -1) {
        // Si hay cabecera, extraer solo la columna "devices"
        this.data = jsonData.slice(1).map(row => row[columnIndex]).filter(value => value);
      } else {
        // Si no hay cabecera, tomar todos los valores de la primera columna
        this.data = jsonData.map(row => row[0]).filter(value => value);
      }
    };

    reader.readAsArrayBuffer(this.file);
  }


  send() {
    this.loadingModal = this._modal.open(LoadingPopupComponent);
    this.messageOK = [];
    this.messageError = [];
    let totalRequests = this.data.length;
    let completedRequests = 0;

    this.data.forEach(serial => {
      console.log('Enviando serial:', serial);

      this._device.assignDevices(serial, this.user, 'all').subscribe(
        (data) => {
          this.messageOK.push({ serial: serial, status: 'ok' });
        },
        (error) => {
          this.messageError.push({ serial: serial, status: 'error', message: error.message });
          setTimeout(() => {
            this.loadingModal.close();
            this.showResult = true;
          }, 1500);
        },
        () => {
          setTimeout(() => {
            this.loadingModal.close();
            this.showResult = true;
          }, 1500);
        }
      );
    });
  }

  resetData(){
    this.data = [];
    this.messageError = [];
    this.messageOK = [];

  }


}
