import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DeviceService } from 'src/app/services/devices/device.service';
import { environment } from 'src/environments/environment';

declare var io: any;

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit, OnDestroy {

  serial: string = '';
  logs: any[] = [];
  logsString: any[] = [];
  fullLogs: any[] = [];

  archiveLogs: any[] = [];

  filter: string = '';
  filters = ['','','','','',''];

  logsStrings = {
    0: new Array<any>(),
    1: new Array<any>(),
    2: new Array<any>(),
    3: new Array<any>(),
    4: new Array<any>(),
    5: new Array<any>()
  }

  autoScroll=true;
  big_dash=false;

  stopped=false;
  socket;


  constructor(private _device: DeviceService, private _auth: AuthService) { }

  ngOnInit(): void {
    this.socket = io(environment.logsServer, {
      reconnectionDelayMax: 10000,
      query:{
        token: this._auth.userToken
      }
    });

    this.socket.on('log', (message) => {
      //console.log(message);
      var cleanMessage = this.cleanLog(message);

      this.fullLogs.push({
        color: cleanMessage.color,
        log: cleanMessage.message
      });
      if(this.fullLogs.length>150){
        this.fullLogs.splice(0, this.fullLogs.length-300);
      }
      this.archiveLogs.push({
        color: cleanMessage.color,
        log: cleanMessage.message
      });

      if(this.filter!=''){
        if(cleanMessage.message.toLowerCase().includes(this.filter.toLowerCase())){
          this.logsString.push({
            color: cleanMessage.color,
            log: cleanMessage.message
          });
        }
      } else {
        this.logsString=this.fullLogs;
      }
      if(this.big_dash){
        this.filters.forEach((f, i)=>{
          if(f!=''){
            if(cleanMessage.message.toLowerCase().includes(f.toLowerCase())){
              this.logsStrings[i].push({
                color: cleanMessage.color,
                log: cleanMessage.message
              });
              if(this.logsStrings[i].length>20){
                this.logsStrings[i].splice(0,this.logsStrings[i].length-20);
              }
            }
          } else {
            this.logsStrings[i]=this.fullLogs;
          }
        })
      }

      var objDivs = document.getElementsByClassName("container-logs-smaller");
      var objDiv = document.getElementById("container-logs");
      var self=this;
      if (this.autoScroll){
        if(this.big_dash){
          Array.prototype.forEach.call(objDivs, function(o) {
            o.scrollTop = o.scrollHeight;
          });
        } else {
          objDiv ? objDiv.scrollTop = objDiv.scrollHeight : null;
        }
      }
      Array.prototype.forEach.call(objDivs, function(o) {
        o.addEventListener("wheel", () => self.autoScroll=false );
        o.addEventListener("dblclick", () => self.autoScroll=true );
      });
      objDiv ? objDiv.addEventListener("wheel", () => self.autoScroll=false ) : null;
      objDiv ? objDiv.addEventListener("dblclick", () => self.autoScroll=true ) : null;
    })
  }

  ngOnDestroy() {
    this.stop();

  }

  filterLogs(){
    if(this.filter!=''){
      this.logsString = this.fullLogs.filter(log=>log.log.toLowerCase().includes(this.filter.toLowerCase()));
    } else {
      this.logsString = this.fullLogs;
    }

    if(this.big_dash){
      this.filters.forEach((f, i)=>{
        if(f!=''){
          this.logsStrings[i] = this.fullLogs.filter(log=>log.log.toLowerCase().includes(f.toLowerCase()));
          console.log(this.logsStrings);
        } else {
          this.logsStrings[i] = this.fullLogs;
        }
      })
    }
  }

  downloadFile(){
    let string='';
    this.archiveLogs.forEach(l=>{
      string += l.log + '\n'
    });
    var blob = new Blob([string], { type: 'text/plain' });
    var anchor = document.createElement('a');

    anchor.download = this.serial + "_log.txt";
    anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
    anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
    anchor.click();
  }

  cleanLog(log: string) {
    let aux = log.indexOf('[0;');
    if(aux==-1)
      return{
        message: log.substring(0, log.length-5),
        color: 'white'
      }

    let colorCode = log.substring(aux + 3, aux + 5);
    let logMessage = log.substring(aux + 6, log.length-5);

    let color = 'white';
    switch (colorCode) {
      case '31':
        color = 'red';
        break;
      case '32':
        color = 'green';
        break;
      case '33':
        color = 'yellow';
        break;
    }

    return {
      message: logMessage,
      color
    };
  }

  getLogsBySerial() {
    console.log("getLogsBySerial", this.serial);
    this._device.getDeviceInfo(this.serial)
      .subscribe(data=>{
        console.log(data);
        this._device.setUserDevice(data.user_build);
        this._device.setModuleCmd(this.serial,
          {"uri" : environment.remoteLoggerDevices+"/logger/"+this.serial},
          'wslog', 'start-log',
          )
            .subscribe(data=>{
            console.log(data);
            this.logs = [];
            this.socket.emit('change-serial', this.serial);
            if(this.socket&&this.stopped)
            this.socket.connect();
            }, err=>{
              this.logs = [];
              this.socket.emit('change-serial', this.serial);
              if(this.socket&&this.stopped)
              this.socket.connect();
            });
      })

  }

  cleanLogs(){
    this.logs=[];
    this.logsString=[];
  }

  stop(){
    this.socket.disconnect();

    this._device.setModuleCmd(this.serial,
      {"uri" : ""},
      'wslog', 'stop-log'
      )
        .subscribe(data=>{
          console.log(data);
          this.logs = [];
          this.socket.emit('change-serial', this.serial);
          if(this.socket&&this.stopped)
          this.socket.connect();
        });

    this.stopped=true;
  }

}
