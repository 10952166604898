

export function TestSpl(data, serial, testList){

  var taskCheck;

  // Comprueba que el valor de splMeter corresponda con un meter lógico.
  data.cfg.splMeter < 0 || data.cfg.splMeter > 6 ? taskCheck = false  : taskCheck= true;
  testList.push( {serial: serial, module: 'SPL Manager', test: 'El meter principal tiene un valor lógico', config : JSON.stringify(data.cfg,null,2), result:  taskCheck })

  // Comprueba que el valor del splMeteAux tenga un valor válido.
  data.cfg.splMeterAux == 1 || data.cfg.splMeterAux == 3 || data.cfg.splMeterAux == 4 || data.cfg.splMeterAux == 255 || data.cfg.splMeterAux == null ?  taskCheck = true : taskCheck  = false;
  testList.push( {serial: serial, module: 'SPL Manager', test: 'El meter auxiliar tiene un valor lógico', config : JSON.stringify(data.cfg,null,2), result:  taskCheck})

    // Para verificar que no están los dos meter como TCM100
  data.cfg.splMeterAux == 1 && data.cfg.splMeter == 1 ? taskCheck = false : taskCheck= true;
  testList.push(  {serial: serial, module: 'SPL Manager', test: 'Los dos meters no están como TMC100', config : JSON.stringify(data.cfg,null,2), result:  taskCheck })


  // Comprueba que si está habilitado el inversor, también lo esté en la parte auxiliar ( o que no esté el auxiliar como inversor y el principal sí.)
  data.cfg.splMeter == 4 && data.cfg.splMeterAux != 4 ? taskCheck = false : taskCheck = true;
  testList.push( {serial: serial, module: 'SPL Manager', test: 'Si el meter principal está con inversor, el auxiliar también', config : JSON.stringify(data.cfg,null,2), result:  taskCheck})


}
