import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-mainMeter',
  templateUrl: './modal-mainMeter.component.html',
  styleUrls: ['./modal-mainMeter.component.scss']
})
export class ModalMainMeterComponent implements OnInit {

    @Input()
    type: number;

    @Input()
    shellyMainIp: string;
    @Input()
    shellyMainModel: number;

    @Input()
    inverterModel: number;
    @Input()
    inverterIp: string;
    @Input()
    inverterPort: number;
    @Input()
    inverterEnabledBatt: number;
    @Input()
    extraUnitIds : (number | null)[];

    @Input()
    p1WifiMainIp: string;

    @Input()
    versionCompatible: boolean;


    shellyMainModels = [
        {label: 'ShellyEM', value: 0},
        {label: 'Shelly3EM', value: 1},
        {label: 'ShellyPRO3EM', value: 3}
    ]

    inverterModels = [
        {label: 'INGETEAM - INGECON SUN STORAGE 1PLAY TLM', value: 1},
        {label: 'INGETEAM - INGECON SUN STORAGE 1PLAY TLM (EV)', value: 2},
        {label: 'HUAWEI - SUN2000-(2KTL-6KTL)-L1', value: 3},
        /*{label: 'FRONIUS - PRIMO', value: 4},
        {label: 'APSYSTEMS - QS1', value: 6},
        {label: 'Custom', value: 5}*/
        {label: 'HUAWEI - SUN2000-(3KTL-10KTL)-M1', value: 11},
    ]

    unitIds : {placeHolder: number, name: string}[] = [];

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit(): void {

      if( this.type == 3){
        if (this.shellyMainIp === undefined) this.shellyMainIp = '0.0.0.0';
        if (this.shellyMainModel === undefined) this.shellyMainModel = 0;
      }
      if( this.type == 6){
        if (this.p1WifiMainIp === undefined ) this.p1WifiMainIp = '0.0.0.0';
      }
      this.changeModel();

    }

    close(){
        var result:any = {};
        console.log('shelly', this.shellyMainIp);

        switch (this.type) {
          case 3:
            result.ip = this.shellyMainIp;
            result.model = this.shellyMainModel;
            result.zone = "main";
            break;
          case 4:
            result.model = this.inverterModel;
            result.inversorIP = this.inverterIp;
            result.inversorPort = this.inverterPort;
            result.battery = this.inverterEnabledBatt;
            break;
          case 6:
            result.ip = this.p1WifiMainIp;
            result.model = 2;
            result.zone = "main";
            break;
          default:
            break;
        }
        this.activeModal.close(result);
      }

      changeModel(){
        this.unitIds = [];
        if ( this.inverterModel == 1 || this.inverterModel == 2  ||  this.inverterModel == 3 ||  this.inverterModel == 6 || this.inverterModel == 7 || this.inverterModel == 9 || this.inverterModel == 11){
          this.unitIds.push({ placeHolder :  1, name : 'inverter'});
        }

        if ( this.inverterModel == 4 ){
          this.unitIds.push({ placeHolder :  0, name : 'inverter'});

        }
        if ( this.inverterModel == 5 ||  this.inverterModel == 10){
          this.unitIds.push({ placeHolder:  1, name: 'inverter'});
          this.unitIds.push({ placeHolder:  240, name: 'wattmeter'});
        }
        if ( this.inverterModel == 8){
          this.unitIds.push({ placeHolder:  3, name: 'inverter'});

        }
        if ( this.inverterModel == 12 || this.inverterModel == 13){
          this.unitIds.push({ placeHolder:  2, name: 'inverterMaster'});
          this.unitIds.push({ placeHolder:  1, name: 'inverterSlave'});
        }
        if ( this.inverterModel == 14 || this.inverterModel == 15){
          this.unitIds.push({ placeHolder:  247, name: 'inverter'});
        }
      }
}
