/*MODULES*/
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';

/*COMPONENTS*/
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { FileUploadExportComponent } from './components/file-upload-export/file-upload-export.component';
import { CrudModalComponent } from './components/modals/crud-modal/crud-modal.component';

/*MATERIAL*/
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CustomDateAdapter } from 'src/app/custom-date.adapter';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

/*TRANSLATE*/
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    ConfirmationModalComponent,
    FileUploadExportComponent,
    CrudModalComponent,
  ],
  imports: [
    MatDatepickerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    CommonModule,
    FormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    FileUploadExportComponent,
    MatDatepickerModule,
    ReactiveFormsModule,
    CrudModalComponent,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    CommonModule,
    FormsModule,
  ],
  providers: [
    {
      useClass: CustomDateAdapter,
      provide: [],
      deps: [MAT_DATE_LOCALE],
    },
    { provide: LOCALE_ID, useValue: 'es' },
  ],
  bootstrap: [],
})
export class SharedModule {}
