<div class="modal-header">
    <div class="d-flex w-100">
        <h2 class="text-primary modal-title"></h2>
        <h2 class="modal-title text-primary text-center w-100">{{title}}</h2>
    </div>

</div>
<div class="modal-body m-3">
    <div class="m-auto text-center">
        <i *ngIf="title != msgError" class="fas fa-check-circle" style="font-size: 50px;"></i>
        <i *ngIf="title == msgError" class="fas fa-exclamation-triangle" style="font-size: 50px;"></i>
        <p class="mb-0 mt-3">{{description| translate}}</p>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="close()">Ok</button>
</div>
