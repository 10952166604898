import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-check',
  templateUrl: './modal-check.component.html',
  styleUrls: ['./modal-check.component.scss']
})
export class ModalCheckComponent {

  @Input()
  data: any;

  // resultado
  dataJSON: any;
  moreInfo = false

  constructor(private activeModal: NgbActiveModal, private _router: Router) { }

  ngOnInit(): void {

  }

  close(){
    this.activeModal.close();
  }


  showJSON(item){
    !this.moreInfo ? this.moreInfo = true : this.moreInfo = false;
    this.dataJSON = item;
  }

}
