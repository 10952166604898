import { Component, ElementRef, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { ModalConfirmComponent } from 'src/app/components/ui/modal-confirm/modal-confirm.component';
import { ModalService } from 'src/app/services/modal/modal.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  styleUrls: ['./file-upload-export.component.scss'],
  templateUrl: './file-upload-export.component.html',
  selector: 'app-file-upload-export',
})
export class FileUploadExportComponent implements OnInit {
  @Output() event: EventEmitter<any> = new EventEmitter();
  @ViewChild('input') fileInput: ElementRef;
  @Input() data: any;


  modalConfirm: NgbModalRef;
  modalError: NgbModalRef;
  shortLink: string = '';
  mode: string = '';
  fileExist: boolean = false;
  errorDate: boolean = false;
  noFile: boolean = true;
  file: File;
  objToPrint: any = {
    idTag:'',
    idTagInfo: [{
      expiryDate: '',
      parentIdTag: '',
      status: ''
    }]
  };
  constructor(private _modal: ModalService, @Inject(LOCALE_ID) public locale: string, private _translate: TranslateService) {}

  ngOnInit(): void {
    this.mode = this.data[0].mode
  }


  /*MODAL*/
  onChange(event) {
    this.file = event.target.files[0];
    if (this.file != undefined) {
      this.noFile = !this.noFile;
    }
  }
  confirm() {

    if (this.file != undefined) {
      this.modalConfirm = this._modal.open(ConfirmationModalComponent);
      this.modalConfirm.componentInstance.header = this._translate.instant('deleteImportRfidFile');
      this.modalConfirm.componentInstance.body = this._translate.instant('areYouSureContinue');
      this.modalConfirm.componentInstance.emitService.subscribe( (valueRecieved) => {
          if(valueRecieved){
            this.onUpload();
          }
        }
      );
    }else{
      this.noFile = true
    }
  }
  close() {
    this.modalConfirm.close();
  }

  /*FILES*/
  async onUpload() {

    const workbook = XLSX.read(await this.file.arrayBuffer());
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    let raw_dat = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    let result = this.filterDuplicatedItems(raw_dat);
    let idTagRpt = this.checkIdTag(raw_dat)

    if (idTagRpt < 1) {
      if (result.duplicateRows.length < 1) {
        if (this.checkInvalidDates(result.uniqueRows) > 0) {
          this.modalNotification('something_failed','fas fa-exclamation-triangle','uploadRfidCardError');
        } else {
          try {
            let loading: boolean = true;
            const raw_data = this.getStatusCodeString(raw_dat);
            this.event.emit({ raw_data, loading });
            this.fileInput.nativeElement.value = null;
            this.noFile = true;
            this.file = new File([], '', undefined);
            this.close();
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        let list = this.getListOfDuplicates(result.duplicateRows);
        this.errorDate = true;
        this.modalError = this._modal.open(ModalConfirmComponent);
        this.modalError.componentInstance.title = this._translate.instant('something_failed');
        this.modalError.componentInstance.icon = 'fas fa-exclamation-triangle';
        this.modalError.componentInstance.description = this._translate.instant('duplicatedCards') + ': ' + list;
        this.noFile = true;
        this.close();
        this.fileInput.nativeElement.value = null;
        this.file = new File([], '', undefined);
      }
    } else {
      this.modalNotification('something_failed','fas fa-exclamation-triangle','emptyIdTag');
    }
  }
  downloadFiles(extension: string){
    let cards = this.data[0].data;
    let incorrectDates = this.countBadDates(cards);
    if(incorrectDates > 0){
      this.modalNotification('something_failed', 'fas fa-exclamation-triangle',  'exportRfidCardError');
    }else{
      this.objToPrint = [];
      this.objToPrint = cards.map((element) => ({
        idTag: this.cleanIdTags(element.idTag),
        expiryDate:(element.idTagInfo.expiryDate != '')?formatDate(element.idTagInfo.expiryDate, 'YYYY-MM-ddTHH:mm', this.locale): '',
        parentIdTag:element.idTagInfo.parentIdTag,
        status: element.idTagInfo.status
      }));
      const worksheet = XLSX.utils.json_to_sheet(this.objToPrint);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Historics');
      XLSX.utils.sheet_add_aoa( worksheet, [['Card', 'Expiry date', 'Group','Status']], { origin: 'A1' } );
      XLSX.writeFile(workbook, `rfid_cards_list.${extension}`, { compression: true });
    }
  }


  /*PRIVATE*/
  isValidDate(dateString) {
    return dateString === '' || dateString === undefined|| moment(dateString, 'YYYY-MM-DDTHH:mm', true).isValid();
  }
  checkInvalidDates(dataObject){
    const datesArray: string[] = dataObject.slice(1).map((item: any) => item[1]);
    let falseCount: number = 0;
    datesArray.forEach((item) => {
      if (!this.isValidDate(item)) {
        falseCount++;
      }
    });
    return falseCount;
  }
  cleanIdTags(data: string) {
    return data = data.replace(/\n/g, '');
  }
  getStatusCodeString(array: any): void {
    const statusMap: { [key: number]: string } = {
        0: 'Accepted',
        1: 'Rejected',
        2: 'Expired',
        3: 'Invalid',
        4: 'In transaction',
        5: 'No credit',
        // 6: 'Blocked',
        // 7: 'ConcurrentTx',
        // 8: 'NotDefined'
    };

    const newArray = array.map(innerArray => {
      return innerArray.map(element => {
          if (typeof element === 'number') {
              return statusMap[element] || element;
          }
          return element;
      });
  });

  return newArray;
}

  countBadDates(data: any[]): number {
    let count = 0;
    for (const obj of data) {
        const expiryDate = obj.idTagInfo.expiryDate.trim();
        if (expiryDate !== '') {
            const isValidFormat = moment(expiryDate, 'YYYY-MM-DDTHH:mm', true).isValid();
            if (!isValidFormat) {
                count++;
            }
        }
    }
    return count;
  }
  filterDuplicatedItems(data: any): { uniqueRows: any[][]; duplicateRows: any[][] } {
    var uniqueRows: any[][] = [];
    var duplicateRows: any[][] = [];
    var seenValues: { [key: string]: boolean } = {};
    for (var i = 0; i < data.length; i++) {
      var row = data[i];
      var value = String(row[0]);
      if (row.length > 0 && !seenValues[value]) {
        uniqueRows.push(row);
        seenValues[value] = true;
      } else if (row.length > 0) {
        duplicateRows.push(row);
      }
    }
    return { uniqueRows: uniqueRows, duplicateRows: duplicateRows };
  }
  modalNotification(title: string, icon: string, description: string){
    this.errorDate = true;
    this.modalError = this._modal.open(ModalConfirmComponent);
    this.modalError.componentInstance.title = this._translate.instant(title);
    this.modalError.componentInstance.icon = icon;
    this.modalError.componentInstance.description = this._translate.instant(description);
    this.noFile = true;
    this.close();
    this.fileInput.nativeElement.value = null;
    this.file = new File([], '', undefined);
  }
  getListOfDuplicates(obj: Array<Array<any>>): string {
    const positions: string[] = [];
    obj.forEach(row => {
      if (row[0] !== null) {
        positions.push(row[0]);
      }
    });
    return positions.join(', ');
  }
  checkIdTag(obj: any[]): number {
    let count = 0;
    for (let i = 0; i < obj.length - 1; i++) {
      const array = obj[i];
      if (array.length === 0 || array[0] === null || array[0] === undefined || array[0] === '') {
        count++;
      }
    }
    return count;
  }
}
