<footer class="footer m-0 p-2 bg-primary">
  <div class="container-fluid">
    <div class="row text-muted">
      <div class="col-8 ">
        <ul class="list-inline" >
          <li class="list-inline-item">
            <a class="text-white" [href]="'footerPrivacyLink' | translate" target="_blank"><!-- Aviso legal y política de privacidad --> {{"legalNoticePrivacyPolicy" | translate}} </a>
          </li>
          <li class="list-inline-item ms-2">
            <a class="text-white" [href]="'footerCookiesPrivacyLink' | translate" target="_blank"><!-- Política de cookies --> {{"CookiesPolicy" | translate}} </a>
          </li>
          <li class="list-inline-item ms-2">
            <a class="text-white" [href]="'footerEthicsAndConductLink' | translate" target="_blank"><!-- Código ético y de conducta --> {{"CodeEthicsConduct" | translate}} </a>
          </li>
          <li class="list-inline-item ms-2">
            <a class="text-white" [href]="'footerSupportLink' | translate" target="_blank"><!-- Soporte técnico --> {{"TechnicalSupport" | translate}} </a>
          </li>
        </ul>
      </div>
      <div class="col-4 text-right">
        <p class="mb-0 text-white">
          Orbis © 2024 - v{{ version }}
        </p>
      </div>
    </div>
  </div>
</footer>
