export class Cfg{
    date_build= null;
    limitPower= 0
    notifCycle= 0
    selectorPosition= 0
    splSlaveFailPower= 0
    timestamp= null;
    updFlags= 0
    verbosity= 0


    constructor(values: Object = {}) {
        Object.assign(this, values);
        
    }

    update(newObj){
        Object.entries(newObj).forEach(([key, value])=>{
            this[key] = value;
        });
    }
}