import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CustomModalComponent } from 'src/app/components/ui/custom-modal/custom-modal.component';
import { ModalService } from 'src/app/services/modal/modal.service';
import { UserService } from 'src/app/services/user/user.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  editActivated: boolean = false;
  userInfo = {
    email: '',
    name: '',
    last_name: '',
    language: '',
    tester_requested: 0
  };
  userRole: string = '';

  unsubscribeModal: NgbModalRef;

  dashboard = false;


  constructor(private _uService: UserService, private _modal: ModalService, private _auth: AuthService, private _toastr: ToastrService, private _translate: TranslateService) { }

  ngOnInit(): void {
    this.userRole = sessionStorage.getItem('role') || '';

    this.getUserInfo();
    localStorage.getItem('dashboardOpt') == 'mapa' ? this.dashboard = false : this.dashboard = true;

  }

  refreshUserInfo(){
    this.getUserInfo();
  }

  showUnsubscribeConfirmModal(){
    this.unsubscribeModal = this._modal.open(CustomModalComponent);
    this.unsubscribeModal.componentInstance.title = this._translate.instant("betatesterDowngrade");
    this.unsubscribeModal.componentInstance.description = this._translate.instant("unsubscribe_Tester");
    this.unsubscribeModal.componentInstance.customButtonText = this._translate.instant("unsubscribe");
    this.unsubscribeModal.componentInstance.customButtonClass = 'btn btn-danger';
    this.unsubscribeModal.componentInstance.customOkButtonClass = 'hide';

    this.unsubscribeModal.componentInstance.customFunction = () => this.unsubscribe();
  }

  closeUnsubscribeConfirmModal(){
    this.unsubscribeModal.close()
  }

  getUserInfo(){
    this._uService.getUserInfo()
      .subscribe(data=>{
        this.userInfo=data;
        this.userRole = data.role;
      })
  }

  editProfile(){
    //TODO: eliminar cuenta (esto pensar como hacerlo, haría falta también un email de confirmación o algo.)
    this._uService.updateUser(this.userInfo.name, this.userInfo.last_name).then(()=>{
      this._auth.updateProfile(this.userInfo.name+this.userInfo.last_name);
      this.editActivated=false;
      this._toastr.success(this._translate.instant("profileUpdateSuccess"),this._translate.instant("profileUpdateSuccessTitle"));
    });
  }

  resetPassword(){
    if(window.confirm(this._translate.instant("profilePasswordResetQuestion"))){
      this._auth.resetPassword(this.userInfo.email)
      this._toastr.success(this._translate.instant("recoverEmail_sent"), this._translate.instant("recoverEmail_title"));
    }
  }

  deleteAccount(){
    if(window.confirm(this._translate.instant("editProfileDeleteAccountConfirmationQuestion"))){
      //this._auth.deleteAccount()
      window.alert(this._translate.instant("not_available_function"));
    }
  }

  askDeveloperMode(){
    if(window.confirm(this._translate.instant("profileDeveloperEnableQuestion"))){
      this._uService.askDeveloperMode().subscribe(data=>{
        this._toastr.success(this._translate.instant("getInTouchShortly"), this._translate.instant("requestSent"));
      })
    }
  }

  unsubscribe(){
    this._uService.unsubscribeAsTester(this.userInfo?.email)
      .toPromise()
      .then(data => {
        // Cambiamos el role de forma global si ha ido bien
        this.userRole = 'user';
        sessionStorage.setItem('role', 'user');
      })
      .finally(() => {
        this.closeUnsubscribeConfirmModal();
        this.getUserInfo();
      })
    }

    requestTester(){
      this._uService.requestTester(this.userInfo?.email, this.userInfo?.language)
        .toPromise()
        .then(data => {
          console.log(data);
        })
        .finally(() => {
          this.getUserInfo();
        })
    }


    changeOption(opcion: string){
      if (opcion === 'lista'){
        this.dashboard = true
      }else {
        this.dashboard = false
      }
      localStorage.setItem("dashboardOpt",  `${opcion}`);

    }
}
