import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { SseService } from 'src/app/services/sse/sse.service';
import { ConnectorState } from 'src/assets/js/constants';
import { Colors } from 'src/assets/js/colors';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card-state',
  templateUrl: './card-state.component.html',
  styleUrls: ['./card-state.component.scss']
})
export class CardStateComponent implements OnInit {

  canvasPie: any;
  ctxPie: any;
  chartPie: any;

  @Input()
  infoModulator: any;
  @Input()
  connectors: any;
  @Input()
  connectorsState: any = {};
  allStates = ConnectorState;

  constructor(private _sse: SseService, private _translate: TranslateService) { }

  ngOnInit(): void {
    this.loadGraphPie();

    this._sse.getEvents()
    .subscribe(data => {
      let connectorData = JSON.parse(data.data);
      console.log(connectorData);
      this.connectorsState[connectorData.element].state=connectorData.data.stat.state;
    });
  }

  loadGraphPie(){
    this.canvasPie = document.getElementById("chartjs-card-pie");
    this.ctxPie = this.canvasPie.getContext("2d");
    this.chartPie = new Chart(this.ctxPie, {
      type: 'pie',
      data: {
        labels: ["Connected", "Disconnected", "Other"],
        datasets: [{
          data: this.infoModulator ?
                                    this.infoModulator?.stat.fvPower!=null ?
                                    this.infoModulator?.stat.instPower<0 ?
                                    [
                                      this.infoModulator?.stat.instPower,
                                      this.infoModulator?.stat.totalPower,
                                      //this.infoModulator?.stat.fvPower - this.infoModulator?.stat.instPower,
                                      this.infoModulator?.stat.limitPower - (-this.infoModulator?.stat.instPower+this.infoModulator?.stat.totalPower)
                                    ] :
                                    [
                                      //this.infoModulator?.stat.fvPower,
                                      this.infoModulator?.stat.totalPower-this.infoModulator?.stat.instPower,
                                      this.infoModulator?.stat.instPower,
                                      this.infoModulator?.stat.limitPower - this.infoModulator?.stat.totalPower
                                    ]
                                    :
                                    [
                                      0,
                                      this.infoModulator?.stat.totalPower,
                                      this.infoModulator?.stat.limitPower - this.infoModulator?.stat.totalPower
                                    ]
                                  : [],
          backgroundColor: this.infoModulator?.stat.fvPower!=null ?
            [
              this.infoModulator?.stat.instPower>0 ? Colors.yellow : Colors.accent_blue,
              this.infoModulator?.stat.instPower>0 ? Colors.accent_blue : Colors.yellow,
              Colors.grey
            ]
            :
            [
              Colors.grey,
              Colors.accent_blue,
              Colors.grey
            ]
            ,
          borderColor: "transparent"
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        rotation: this.infoModulator?.stat.instPower<0 ?
                  this.infoModulator?.stat.instPower/this.infoModulator?.stat.limitPower * Math.PI * 2 - Math.PI/2
                  : -Math.PI/2,
        cutoutPercentage: 75
      }
    });

    this.canvasPie = document.getElementById("chartjs-card-pie-inside");
    this.ctxPie = this.canvasPie.getContext("2d");
    this.chartPie = new Chart(this.ctxPie, {
      type: 'pie',
      data: {
        labels: ["Connected", "Disconnected", "Other"],
        datasets: [{
          data: this.infoModulator?[this.infoModulator.stat.evsePower, this.infoModulator.stat.homePower, this.infoModulator.stat.limitPower-this.infoModulator.stat.evsePower-this.infoModulator.stat.homePower]:[],
          backgroundColor: [
            "#8be6c0",
            "#5ecc98",
            "#E8EAED"
          ],
          borderColor: "transparent"
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        cutoutPercentage: 75
      }
    });

  }

  getTranslatedText(translationKey: string) {
    return this._translate.instant(translationKey);
  }

}
