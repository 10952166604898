import { Injectable } from '@angular/core';
import { webSocket } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  myWebSocket: any;

  constructor(private _auth: AuthService) { }

  public connect(serial): any {
    if(this.myWebSocket)
      this.myWebSocket.complete();

    this.myWebSocket = webSocket(environment.socketServer + '/' + serial + '?token=' + this._auth.userToken);
    return this.myWebSocket;
  }

  public connectByUrl(url): any {
    if(this.myWebSocket)
      this.myWebSocket.complete();

    this.myWebSocket = webSocket(url);
    return this.myWebSocket;
  }

  sendMessage(msg: any) {
    this.myWebSocket.next(msg);
  }

  close() {
    this.myWebSocket.complete();
  }

}
