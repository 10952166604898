<div style="height: fit-content ; min-height: 850px; width: 100%;  ">


  <div class="d-flex justify-content-center " *ngIf="loading">
    <div class="spinner-border  " role="status" style="height: 100px; width: 100px;margin-top: 10%; ">
      <span class="visually-hidden"></span>
    </div>
  </div>

  <div *ngIf="!loading">


    <div class="card mt-4 ms-3 me-3">
      <div class="card-header">
        <h5 class="text-primary"> {{"group"|translate}}
        </h5>
      </div>
      <div class="card-body row p-1 pl-5">
        <form>
          <div class="form-group row d-flex">
            <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
              <label class="col-form-label">{{"nameInstallation" | translate}}:</label>
            </div>
            <div class="col-xl-4 col-sm-5">
              <div class="d-flex">
                <input type="text" class="form-control form-control-group" name="nameInstallation"
                  [(ngModel)]="dataGroup.name" placeholder="{{'nameInstallation' | translate}}">
              </div>
            </div>
          </div>
          <div class="form-group row d-flex">
            <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
              <label class="col-form-label">{{"chargerDescription" | translate}}:</label>
            </div>
            <div class="col-xl-4 col-sm-5">
              <div class="d-flex">
                <input type="text" class="form-control form-control-group" name="description"
                  [(ngModel)]="dataGroup.description" placeholder="{{'chargerDescription' | translate}}">
              </div>
            </div>
          </div>
        </form>

      </div>
      <div class="card-footer pt-0 pe-0 ">
        <div class="form-group row">
          <div>
            <button class="btn btn-primary mr-2"
              [ngClass]="{ 'disabled-button' : dataGroup.name == '' || dataGroup.name == null  } "
              [disabled]="dataGroup.name == ''|| dataGroup.name == null " (click)="saveGroup()">
              {{group ? ("updateGroup" | translate ) :( "createGroup" | translate) }}
            </button>
            <button class="btn btn-primary mr-4" style="background-color: #dc3545;"
              [ngClass]="{ 'disabled-button' : dataGroup.alias == '' || dataGroup.alias == null  } "
              [disabled]="dataGroup.alias == ''|| dataGroup.alias == null " (click)="deleteGroup(dataGroup.alias)">
              {{"delete" | translate }}
            </button>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Modal" *ngIf="userRole == 'admin'">
              {{'Vincular dispositivos' |translate}}
            </button>
          </div>
        </div>
      </div>

    </div>


    <div class="card m-3 ">
      <div class="card-header">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="instalation">{{ "subgroups" | translate}}</h5>
        </div>
      </div>
      <div class="card-body ">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="instalation ">{{ "group" | translate}}</h5>
          <h5 class="description">{{ "chargerDescription" | translate }}</h5>
          <!-- <button class="button-plus" [disabled]="disableButtons" (click)="addGroup()"> -->
          <button class="button-plus" [disabled]="disableButtons" data-bs-toggle="modal"
            data-bs-target="#addSubgroupModal">
            <i class="fa fa-plus-circle" [ngClass]="disableButtons ? 'disabled-button' : ''"></i>
          </button>
        </div>
        <div class="d-flex  align-items-center">
          <div class="divider ml-2"></div>
        </div>

        <div class="d-flex justify-content-between align-items-center " *ngFor="let subgrupos of dataGroup.subgroups">
          <div class="instalation"> {{subgrupos.name}} </div>
          <div class="description"> {{subgrupos.description }}</div>
          <button class="button-edit" (click)="deleteSubgroup(dataGroup.alias, subgrupos.alias)"><i
              class="fa fa-trash text-danger"></i>
          </button>
        </div>
      </div>
    </div>


    <div class="d-flex ps-3 pe-3 pt-0 pb-0">

      <div class="card w-50 mr-3">
        <div class="card-header">
          <h5 class="text-primary">{{"addUser" | translate}}</h5>
        </div>
        <div class="card-body row p-1 pr-5">
          <div class="row d-flex align-items-center mt-2">
            <div class="col d-flex justify-content-start pl-6">
              <label class="align-middle">{{"user" | translate}}: </label>
            </div>
            <div class="col-5">
              <input type="text" class="form-control form-control-group" name="user" [(ngModel)]="userToAdd" placeholder="{{'user' | translate}}">
            </div>
            <div class="col-3">
              <select class="form-select form-select-group" [(ngModel)]="roleToAdd">
                <option *ngFor="let role of roles" [ngValue]="role.value">
                  {{ role.viewValue }}
                </option>
              </select>
            </div>
          </div>
          <div class="row d-flex align-items-center mt-2">
            <div class="col d-flex justify-content-start pl-6">
              <label for="">{{"groupInstalacion" | translate}}: </label>
            </div>
            <div class="col-8">
              <select class="form-select form-select-group" [(ngModel)]="groupToAdd">
                <option *ngFor="let grupo of listGroups; let i = index" [ngValue]="grupo.alias">
                  {{ grupo.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="card-footer pt-0 pe-0 ">
          <div class="form-group row">
            <div>
              <button type="submit" class="btn btn-primary" [ngClass]="{'disabled-button': disableButtons}"
                [disabled]="disableButtons" (click)="asignarUser()">{{"add" | translate}}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card w-50 ml-3">
        <div class="card-header">
          <h5 class="text-primary">{{"Añadir dispositivo" | translate}}</h5>
        </div>
        <div class="card-body row p-1 pr-5">
          <div class="row d-flex align-items-center mt-2">
            <div class="col d-flex justify-content-start pl-6">
              <label class="align-middle">{{"serial_number" | translate}}: </label>
            </div>
            <div class="col">
              <input type="text" class="form-control form-control-group" [(ngModel)]="serialGroup">
            </div>
          </div>
          <div class="row d-flex align-items-center mt-2">
            <div class="col d-flex justify-content-start pl-6">
              <label for="">{{"groupInstalacion" | translate}}: </label>
            </div>
            <div class="col">
              <select class="form-select form-select-group" [(ngModel)]="subgroup">
                <option *ngFor="let grupo of listGroups; let i = index" [ngValue]="grupo.alias">
                  {{ grupo.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="card-footer pt-0 pe-0 ">
          <div class="form-group row">
            <div>
              <button type="submit" class="btn btn-primary" [ngClass]="{'disabled-button': disableButtons}"
                [disabled]="disableButtons" (click)="asignarGruop()">{{"add" | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- </div> -->
    <div class="card-body p-0">
      <div class="card mt-0 ms-3 me-3">
        <div class="card-header">
          <h5 class="text-primary">{{"devicesInGroup" | translate}}</h5>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-around align-items-center">
            <h5 class="instalation" style="width: 20%; text-align: center;">{{"unlinkDevice" | translate}} </h5>
            <h5 class="instalation" style="width: 30%; text-align: center;" (click)="sortName()" >{{"name" | translate}}
              <span  class="sort-icon">{{ sortField === 'name' ? (sortDirection === 'asc' ? '▲' : '▼') : '' }}</span>
            </h5>
            <h5 class="instalation" style="width: 30%; text-align: center;" (click)="sortSerial()" > {{"serial" | translate}}
              <span  class="sort-icon">{{ sortField === 'serial' ? (sortDirection === 'asc' ? '▲' : '▼') : '' }}</span>
            </h5>
            <h5 class="instalation" style="width: 20%; text-align: center;">{{"permissions" | translate }}</h5>
          </div>
          <div class="divider ml-2"></div>
          <div class="device-row d-flex justify-content-around align-items-center" *ngFor="let device of devicesInGruoup">
            <button class="button-edit" style="width: 20%;" (click)="removeDevice(device.serial, dataGroup.alias )"> <i
                class="fas fa-unlink"></i></button>
            <div class="instalation" style="width: 30%; text-align: center;"> {{device.name}} </div>
            <div class="instalation" style="width: 30%; text-align: center;"> {{device.serial}} </div>
            <div class="instalation" style="width: 20%;text-align: center;" (click)="goToSettings(device.serial, 'linkage')" >
              <span *ngIf="device.role== 'all'"> <i class="far fa-edit"></i> </span>
              <span *ngIf="device.role == 'owner'"> <i class="fas fa-user-shield"></i> </span>
              <span *ngIf="device.role == 'write'"> <i class="fas fa-pen"></i> </span>
              <span *ngIf="device.role == 'read'"> <i class="fas fa-book-open"></i> </span>
            </div>
          </div>
        </div>
        <span class="ms-3" >Dispositivos en el grupo: {{devicesInGruoup.length}}</span>
      </div>
    </div>

    <div class="card-body p-0" *ngIf="usersInGroup.length > 0">
      <div class="card mt-0 ms-3 me-3">
        <div class="card-header">
          <h5 class="text-primary">{{"Usuarios vinculados" | translate}}</h5>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-around align-items-center">
            <h5 class="instalation" style="width: 20%; text-align: center;">{{"unlinkUser" | translate}} </h5>
            <h5 class="instalation" style="width: 30%; text-align: center;" (click)="sortEmail()" >{{"email" | translate}}
              <span  class="sort-icon">{{ sortFieldUsers === 'email' ? (sortDirectionUsers === 'asc' ? '▲' : '▼') : '' }}</span>
            </h5>
            <h5 class="instalation" style="width: 30%; text-align: center;" (click)="sortUsername()" > {{"name" | translate}}
              <span  class="sort-icon">{{ sortFieldUsers === 'username' ? (sortDirectionUsers === 'asc' ? '▲' : '▼') : '' }}</span>
            </h5>
            <h5 class="instalation" style="width: 20%; text-align: center;">{{"permissions" | translate }}</h5>
            <h5 class="instalation" style="width: 20%; text-align: center;">{{"linkDevices" | translate }}</h5>
          </div>
          <div class="divider ml-2"></div>
          <div class="device-row d-flex justify-content-around align-items-center" *ngFor="let user of usersInGroup">
            <button class="button-edit" [disabled]="user.role == 'owner'" [ngClass]="{'disabled-button' : user.role == 'owner'} "
            style="width: 20%;" (click)="removeUser(user.email, dataGroup.alias )"> 
              <i class="fas fa-unlink"></i>
            </button>
            <div class="instalation" style="width: 30%; text-align: center;"> {{user.email}} </div>
            <div class="instalation" style="width: 30%; text-align: center;"> {{user.name}} {{user.lastname}} </div>
            <div class="instalation" style="width: 20%;text-align: center;">
              <span *ngIf="user.role== 'all'"> {{"all" | translate}} </span>
              <span *ngIf="user.role == 'owner'"> {{"owner" | translate}} </span>
              <span *ngIf="user.role == 'write'"> {{"write" | translate}} </span>
              <span *ngIf="user.role == 'read'"> {{"read" | translate}} </span>
            </div>
            <button class="button-edit" [disabled]="user.role == 'owner'" [ngClass]="{'disabled-button' : user.role == 'owner'} "
              style="width: 20%;" (click)="openModalRoleUser(user.email)"> 
                <i class="fas fa-link"></i>
            </button>
          </div>
        </div>
        <span class="ms-3" >Usuarios vinculados al grupo: {{usersInGroup.length}}</span>
      </div>
    </div>

  </div>
</div>



<!-- Modal add subgroup -->

<div class="modal" tabindex="-1" id="addSubgroupModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"> {{"subgroups" | translate}} </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">


        <div class="d-flex justify-content-between align-items-center ">
          <div class="card-body row p-1">
            <div class="row d-flex align-items-center mt-2">
              <div class="col d-flex justify-content-start pl-6">
                <label for="" class="align-middle">{{"nameInstallation" | translate}}: </label>
              </div>
              <div class="col">
                <input type="text" class="form-control form-control-group" [(ngModel)]="newSubgroup.name">
              </div>
            </div>
            <div class="row d-flex align-items-center mt-2">
              <div class="col d-flex justify-content-start pl-6">
                <label> {{"chargerDescription" | translate}}: </label>
              </div>
              <div class="col">
                <input type="text" class="form-control form-control-group" [(ngModel)]="newSubgroup.description">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
        <button type="button" class="btn btn-primary"  data-bs-dismiss="modal" (click)="saveSubgroup()">{{"overwrite" | translate}}</button>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="Modal" tabindex="-1" aria-labelledby="ModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Vincular listado de dispositivos</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-group row">
          <div class="col-12 text-right">
            <input class="form-control" type="file" (change)="onFileSelected($event)"  #input accept=".xls, .xlsx, .csv">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-sm-4 text-sm-right">{{'Usuario al que vincular' | translate}}:</label>
          <div class="col-sm-7">
            <input type="email" class="form-control" placeholder="email" autocomplete="one-time-code" [(ngModel)]="userToLink" name="userToLink">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-sm-4 text-sm-right">{{"role" | translate}}:</label>
          <div class="col-sm-7">
            <select class="form-select form-select-group" [(ngModel)]="selectedUserToLinkRole" name="selectedUserToLinkRole">
              <option *ngFor="let role of roles" [ngValue]="role.value">{{role.viewValue | translate}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{'cancel'|translate}}</button>
        <button type="button" class="btn btn-primary" *ngIf="isFileLoaded" (click)="confirmAndExecute()"  data-bs-dismiss="modal">{{'save'|translate}}</button>
      </div>
    </div>
  </div>
</div>
