<div style="height: fit-content ; min-height: 850px; width: 100%;"   >
  <div class="p-0 ">
    <div class="d-flex justify-content-between align-items-center ms-3 me-3 pt-0"  *ngIf="selectedSetting==='list'">
      <nav aria-label="breadcrumb" style="margin-top: 16px;" >
        <ol class="breadcrumb" style="background-color: #00778B; width: 40px; height: 40px;">
          <li class="breadcrumb-item" (click)="home() ">
            <div class="breadcrumb-item text-white " style="padding: 10px;">
              <i class="fas fa-home"></i>
            </div>
          </li>
        </ol>
      </nav>

      <div class="" role="group" aria-label="Button group with nested dropdown" >
        <button id="btnGroupDrop1" type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" style="width: 40px; height: 40px;"
          aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-globe"></i>
        </button>
        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
          <a class="dropdown-item" href="#">Viaris</a>
          <a class="dropdown-item" href="#">Xeo Lum</a>
          <a class="dropdown-item" href="#">others</a>
        </div>
      </div>

      <div class=" ">

        <button  class="btn btn-secondary " (click)="selectedSetting= 'map'; changeOption() " style="height: 40px; width: 40px;">
          <i class="fas fa-map-marked-alt" style="height: 20px;"></i></button>
        <!-- <button *ngIf="selectedSetting === 'map' " class="btn btn-secondary" style="height: 40px; width: 40px;"
          (click)="selectedSetting= 'list'; changeOption() "><i class="fas fa-list"></i></button> -->
      </div>
    </div>

    <div *ngIf="showList" class="me-3 ms-3">
      <div class="spinner-border text-primary spinner" role="status" *ngIf="loading" style="margin-top: 20%;">
        <span class="visually-hidden"></span>
      </div>

      <div *ngIf="!loading">
        <div *ngIf="view=='devices'">

          <h4 class="text-primary mb-0">{{"devices" | translate | uppercase}}</h4>
          <table id="datatables-dashboard-projects" class="table table-striped my-0"
            *ngIf="devices !== undefined && devices.length > 0">
            <thead>
              <tr>
                <th>{{"serial_number" | translate}}</th>
                <th> {{"name" | translate}}</th>
                <th> {{ "alias" | translate}}</th>
                <th> {{ "user" | translate}}</th>
                <th> {{"modelo" | translate }}</th>
                <th class="d-none d-xl-table-cell">{{"deviceDateBuild" | translate}}</th>
                <th>{{"state" | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let device of devices;  index as i">
                <td><a class="link-dark" (click)="clickDevice(device.serial)">{{device.serial}} <span
                      *ngIf="device.alias">({{device.alias}})</span></a></td>
                <td>{{ device.name}}</td>
                <td>{{ device.alias | uppercase}}</td>
                <td> {{ owners[i].user_build }} </td>
                <td>{{ device.sub_type | uppercase}}</td>
                <td class="d-none d-xl-table-cell">{{device.date_build | date:'short'}}</td>
                <td *ngIf="device.alive == 1 "><span class="badge badge-success">Online</span></td>
                <td *ngIf="device.alive == 0 "><span class="badge badge-danger">Offline</span></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="view=='groups'" class="container">
          <div *ngIf="data.length === 0">
            <div class="d-flex justify-content-center">
              <h2>{{"noGroups" | translate}} </h2>
            </div>
          </div>
          <div class="row mb-3 ml-1 mr-1" *ngFor="let mainGroup of data; let i =index;">

            <div class="card   mt-3 pb-4 p-0 mb-2 w-100 w-lg-5  ">

              <div class="d-flex justify-content-between align-items-center  card-header bg-primary">
                <div class="fs-4  text-white">
                  {{ mainGroup.name}} &nbsp;
                  <!-- <span *ngIf="mainGroup.role == 'all'"> <i class="far fa-edit"></i> </span>
                  <span *ngIf="mainGroup.role == 'owner'"> <i class="fas fa-user-shield"></i> </span>
                  <span *ngIf="mainGroup.role == 'write'"> <i class="fas fa-pen"></i> </span>
                  <span *ngIf="mainGroup.role == 'read'"> <i class="fas fa-book-open"></i> </span> -->
                </div>

                <div class="d-flex justify-content-between align-items-center  ">
                  <button class="button-main-group text-white" (click)="infoGroup(mainGroup)">
                    <i class="far fa-eye "></i>
                  </button>
                  <button class="button-main-group text-white" (click)="viewGroup(mainGroup)">
                    <i class="far fa-chart-bar"></i>
                  </button>
                  <div class=" button-main-group  text-white" (click)="goToSPL(mainGroup)" ><i>SPL</i></div>

                  <!-- <button (click)="configSPL()" class="button-main-group">
                                <div class="icon-config-SPL"> </div>
                              </button> -->
                  <button class="button-main-group text-white" (click)="detailGroup(mainGroup)"> <i class="fa fa-gear "></i>
                  </button>
                </div>
              </div>


              <div class="d-flex  align-items-center pl-4 pr-3 mt-2" *ngIf="data[i].subgroups.length != 0">
                <div style="flex: 1">{{"groups" | translate}} </div>
                <div class="divider ml-2"></div>
              </div>

              <div class="accordion accordion-flush p-4" id="accordion" *ngIf="data[i].subgroups.length != 0">
                <div *ngFor="let subgrupo of data[i].subgroups; let i = index">
                  <button class="item d-flex align-items-center ms-2 me-2"
                    [ngClass]="{ 'par': i % 2 == 0, 'impar': i % 2 != 0 }" type="button" data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#collapse' + subgrupo.alias" aria-expanded="false"
                    [attr.aria-controls]="'collapse' + subgrupo.alias" (click)="getLinkedDevices(subgrupo.alias)">
                    <span style="width: 35%;" class="d-flex align-items-center ps-4 fw-bold">{{ subgrupo.name }}</span>
                    <span style="width: 55%;" class="d-flex align-items-center">{{ subgrupo.description }}</span>
                    <span style="width: 10%;" class="d-flex align-items-center">
                    </span>
                  </button>
                  <div [id]="'collapse' + subgrupo.alias" class="accordion-collapse collapse m-4 ps-3 pe-3">
                    <div class="accordion-body devices">
                      <h5 *ngIf="linkedDevices.length==0" style="text-align: center;"> {{"noDevices"|translate }} </h5>
                      <div *ngFor="let device of linkedDevices; let x = index">
                        <div [ngClass]="{
                                                    'par': (i % 2 == 0 && x % 2 != 0) || (i % 2 != 0 && x % 2 == 0),
                                                    'impar': (i % 2 == 0 && x % 2 == 0) || (i % 2 != 0 && x % 2 != 0)
                                                    }" class="item card">
                          {{ device.serial }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pl-4 pr-3 mt-2">
                <div class="d-flex  align-items-center">
                  <div style="flex: 1">{{"devices" | translate }} </div>
                  <div class="divider ml-2"></div>
                </div>
                <div *ngIf="data[i].devices.length === 0">
                  <h5 style="text-align: center;">{{"noDevices"|translate }} </h5>
                </div>
                <div class="pl-4 pr-4 pt-2 ">
                  <table class="tabla text-muted fst-italic">
                    <tbody>
                      <tr *ngFor="let device of  data[i].devices; let x =index"
                        [ngClass]="{'f-par': x % 2 !== 0, 'f-impar': x % 2 === 0}" (click)="goToDevice(device.serial)" >
                        <td > {{device.serial}}</td>
                        <td > {{device.deviceInfo.name}}</td>
                        <td >
                          <span *ngIf="device.role == 'all'"> <i class="far fa-edit"></i> </span>
                          <span *ngIf="device.role == 'owner'"> <i class="fas fa-user-shield"></i> </span>
                          <span *ngIf="device.role == 'write'"> <i class="fas fa-pen"></i> </span>
                          <span *ngIf="device.role == 'read'"> <i class="fas fa-book-open"></i> </span>
                        </td>
                        <td style="width: 10%;">
                          <span class="badge badge-success" style="width: fit-content;" *ngIf="device.deviceInfo.alive ==1"> <i class="fas fa-wifi"></i>
                          </span>
                          <span class="badge badge-danger" style="width: fit-content;" *ngIf="device.deviceInfo.alive ==0"> <i class="fas fa-wifi"></i>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row m-0 p-0" *ngIf="!showList">
      <div class="col  m-0 p-0" *ngIf="info" style="background-color: '#F4F7Fc';">

        <div class="d-flex justify-content-center " *ngIf="loadingInfo">
          <div class="spinner-border  " role="status" style="height: 100px; width: 100px;margin-top: 50%; ">
            <span class="visually-hidden"></span>
          </div>
        </div>

        <div *ngIf="!loadingInfo" [ngClass]="animacion == 1 ? 'animacion1' : 'animacion2'" class="animacion">

          <div class="card col-12  sombreado ">

            <div class="row">
              <div class="col-4 p-0 m-0">
                <img *ngIf="serial.includes('EVVC3')" height="125px" src="../../../assets/img/orbis/VIARIS_UNI.png">
                <img *ngIf="serial.includes('EVVC4')" height="125px" src="../../../assets/img/orbis/VIARIS_COMBI+.png">
                <img *ngIf="serial.includes('EVVC5')" height="125px" src="../../../assets/img/orbis/VIARIS_CITY+.png">
              </div>
              <div class="col-8  pt-4">
                <div class="d-flex  justify-content-between align-items-center">
                  <h3 class="p-0 m-0"> {{locations[indice].title }}</h3>
                  <i class="fas fa-arrow-right  pe-1" style="cursor: pointer;" (click)="changeAnimation()"></i>
                </div>
                <div class="row">
                  <div class="col-6">
                    <h4> {{locations[indice].sub_type | uppercase }} </h4>
                    <h4> {{locations[indice].firmware_version_name }} </h4>
                  </div>
                  <div class="col-6 d-flex justify-content-end align-items-center ">
                    <div class="d-flex justify-content-end align-items-center " *ngIf="locations[indice].alive == 1 ">
                      <span class="badge badge-success"> {{"connected" | translate}}</span>
                    </div>
                    <div class="d-flex justify-content-end align-items-center " *ngIf="locations[indice].alive == 0 ">
                      <span class="badge badge-danger"> {{"disconnected" | translate}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <!-- Inst. Power -->
              <div class="card pt-2 pb-2 ms-3 sombreado mt-4">
                <div class="card-body text-center p-3">
                  <div class="row  ">
                    <div class="col-5 ">
                      <div class="avatar ">
                        <div class="avatar-title rounded-circle bg-primary ">
                          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor"
                              d="M8.28,5.45L6.5,4.55L7.76,2H16.23L17.5,4.55L15.72,5.44L15,4H9L8.28,5.45M18.62,8H14.09L13.3,5H10.7L9.91,8H5.38L4.1,10.55L5.89,11.44L6.62,10H17.38L18.1,11.45L19.89,10.56L18.62,8M17.77,22H15.7L15.46,21.1L12,15.9L8.53,21.1L8.3,22H6.23L9.12,11H11.19L10.83,12.35L12,14.1L13.16,12.35L12.81,11H14.88L17.77,22M11.4,15L10.5,13.65L9.32,18.13L11.4,15M14.68,18.12L13.5,13.64L12.6,15L14.68,18.12Z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-7 text-center" style="display: flex; justify-content: center; align-items: center;"> -->
                    <div class="col-7 p-0 m-0  d-flex justify-content-center align-items-center ">
                      <h4 class="p-0 m-0"> {{totalPower}} <span>w</span></h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <!-- Inst. Home -->
              <div class="card pt-2 pb-2 me-3 sombreado mt-4">
                <div class="card-body text-center p-3">
                  <div class="row  ">
                    <div class="col-5 ">
                      <div class="avatar ">
                        <div class="avatar-title rounded-circle bg-primary-light">
                          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="col-7 p-0 m-0  d-flex justify-content-center align-items-center ">
                      <h4 class="p-0 m-0 ">{{homePower}} <span>w</span></h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <!-- Inst. EV -->
              <div class="card pt-2 pb-2 ms-3 sombreado">
                <div class="card-body text-center p-3">
                  <div class="row  ">
                    <div class="col-5 ">
                      <div class="avatar ">
                        <div class="avatar-title rounded-circle  bg-blue-accent ">
                          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor"
                              d="M16,6L19,10H21C22.11,10 23,10.89 23,12V15H21A3,3 0 0,1 18,18A3,3 0 0,1 15,15H9A3,3 0 0,1 6,18A3,3 0 0,1 3,15H1V12C1,10.89 1.89,10 3,10L6,6H16M10.5,7.5H6.75L4.86,10H10.5V7.5M12,7.5V10H17.14L15.25,7.5H12M6,13.5A1.5,1.5 0 0,0 4.5,15A1.5,1.5 0 0,0 6,16.5A1.5,1.5 0 0,0 7.5,15A1.5,1.5 0 0,0 6,13.5M18,13.5A1.5,1.5 0 0,0 16.5,15A1.5,1.5 0 0,0 18,16.5A1.5,1.5 0 0,0 19.5,15A1.5,1.5 0 0,0 18,13.5Z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="col-7 p-0 m-0  d-flex justify-content-center align-items-center ">
                      <h4 class="p-0 m-0  ">{{evsePower}} <span>w</span></h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <!-- Inst. Solar -->
              <div class="card pt-2 pb-2 me-3 sombreado" *ngIf="solarInfo.cfg.enabled">
                <div class="card-body text-center p-3">
                  <div class="row  ">
                    <div class="col-5 ">
                      <div class="avatar ">
                        <div class="avatar-title rounded-circle bg-yellow text-primary">
                          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor"
                              d="M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,2L14.39,5.42C13.65,5.15 12.84,5 12,5C11.16,5 10.35,5.15 9.61,5.42L12,2M3.34,7L7.5,6.65C6.9,7.16 6.36,7.78 5.94,8.5C5.5,9.24 5.25,10 5.11,10.79L3.34,7M3.36,17L5.12,13.23C5.26,14 5.53,14.78 5.95,15.5C6.37,16.24 6.91,16.86 7.5,17.37L3.36,17M20.65,7L18.88,10.79C18.74,10 18.47,9.23 18.05,8.5C17.63,7.78 17.1,7.15 16.5,6.64L20.65,7M20.64,17L16.5,17.36C17.09,16.85 17.62,16.22 18.04,15.5C18.46,14.77 18.73,14 18.87,13.21L20.64,17M12,22L9.59,18.56C10.33,18.83 11.14,19 12,19C12.82,19 13.63,18.83 14.37,18.56L12,22Z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="col-7 p-0 m-0  d-flex justify-content-center align-items-center ">
                      <h4 class="p-0 m-0  ">{{fvPower}} <span>w</span></h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <!-- Battery Power -->
              <div class="card pt-2 pb-2 ms-3 sombreado mb-3" *ngIf="solarInfo.cfg.battery">
                <div class="card-body text-center p-3">
                  <div class="row  ">
                    <div class="col-5 d-flex  align-items-center ">
                      <div class="avatar " *ngIf="batteryCharging">
                        <div class="avatar-title rounded-circle ng-tns-c200-0 bg-green text-primary ">
                          <svg style="width:24px;height:24px" viewBox="0 0 512 512">
                            <path fill="currentColor"
                              d="M80 96c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32l96 0c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32h16c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64l16 0zm304 96c0-8.8-7.2-16-16-16s-16 7.2-16 16v32H320c-8.8 0-16 7.2-16 16s7.2 16 16 16h32v32c0 8.8 7.2 16 16 16s16-7.2 16-16V256h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H384V192zM80 240c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H96c-8.8 0-16 7.2-16 16z" />
                          </svg>
                        </div>
                      </div>
                      <div class="avatar " *ngIf="!batteryCharging">
                        <div class="avatar-title rounded-circle ng-tns-c200-0 bg-orange text-primary ">
                          <svg style="width:24px;height:24px" viewBox="0 0 512 512">
                            <path fill="currentColor"
                              d="M80 96c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32l96 0c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32h16c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64l16 0zm304 96c0-8.8-7.2-16-16-16s-16 7.2-16 16v32H320c-8.8 0-16 7.2-16 16s7.2 16 16 16h32v32c0 8.8 7.2 16 16 16s16-7.2 16-16V256h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H384V192zM80 240c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H96c-8.8 0-16 7.2-16 16z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="col-7 text-center">
                      <h4 class="p-0 m-0 ">{{battEnergy}} <span>w</span></h4>
                      <h4 class="p-0 m-0">{{battPercent}} <span>%</span></h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6"></div>
          </div>
          <div class="row">
            <!-- Swhitch -->
            <div class="col-12" *ngFor="let conn of connectors; index as i">
              <div class="card  ms-3 me-3 sombreado">
                <div class="card-body p-3">
                  <div class="row">
                    <!-- <div class="col"> -->
                    <div class="col-7 ">
                      <h4 class="text-primary"> {{connectors[i].data.type.startsWith('mennekes') ? ("type" |
                        translate)+'-2' : connectors[i].data.type | titlecase}}</h4>
                      <span
                        class="badge badge-pill bg-success mw-100">{{connectorsState[i]}}-{{getTranslatedText(allStates[connectorsState[i]]?.text)}}
                      </span>
                    </div>
                    <div class="col-5 d-flex justify-content-end align-items-center ">
                      <div class="d-flex justify-content-end align-items-center  ">
                        <label class="switch-sm mb-0  text-right">
                          <input type="checkbox" [(ngModel)]="permission[i]" disabled>
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="display: flex; height: 125px; align-items: flex-end; justify-content: center;">
            <button class="col-7 text-center btn btn-primary " (click)="goToDevice(locations[indice].title)">
              {{"moreInfo" | translate}}</button>
            <button class="col-1  btn btn-primary ms-2 p-1 " (click)="goToDiagnosis(locations[indice].title)"> <i
                class="fas fa-stethoscope text-white"></i> </button>
            <button class="col-1  btn btn-primary ms-1 p-1" (click)="goToSettings(locations[indice].title)"> <i
                class="fas fa-cog fa-fw"></i> </button>
          </div>
        </div>
      </div>

      <!-- Mapa -->
      <div class="spinner-border text-primary spinner" role="status" *ngIf="loading" style="margin-top: 20%;">
        <span class="visually-hidden"></span>
      </div>
      <div [class.col-md-9]="info" [class.col-md-12]="!info" *ngIf="!loading" class="m-0 p-0">
        <div class="" style="min-height: 100%;">
          <div id="map"></div>
        </div>

        <button id="list" class="botonLista"(click)="this.selectedSetting = 'list'; changeOption()" >
          <svg xmlns="http://www.w3.org/2000/svg" style="height: 20px; width: 20px;" viewBox="0 0 512 512">
            <path
              d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z" />
          </svg>
        </button>

        <button id="home" class="botonLista" (click)="home()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M575.8 255.5c0 18-15 32.1-32 32.1l-32 0 .7 160.2c0 2.7-.2 5.4-.5 8.1l0 16.2c0 22.1-17.9 40-40 40l-16 0c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1L416 512l-24 0c-22.1 0-40-17.9-40-40l0-24 0-64c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32 14.3-32 32l0 64 0 24c0 22.1-17.9 40-40 40l-24 0-31.9 0c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2l-16 0c-22.1 0-40-17.9-40-40l0-112c0-.9 0-1.9 .1-2.8l0-69.7-32 0c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/>
          </svg>
        </button>


        <input type="text " id="pac-input" class="controls" placeholder="{{ 'search' | translate }}">
      </div>
    </div>
  </div>

</div>
