

<main class="main h-100 w-100">
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                <div class="d-table-cell align-middle" *ngIf="showLogin">

                    <div class="text-center mt-4">
                        <h1 class="h2 text-white">{{"welcome" | translate}}</h1>

                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="m-sm-4">
                                <div class="text-center">
                                    <img src="/assets/img/orbis/orbisIcon.png" alt="Linda Miller" class="img-fluid" width="100" height="100" />
                                </div>
                                <form>
                                    <div class="form-group">
                                        <label for="email">{{"email" | translate}}</label>
                                        <input class="form-control form-control-lg" [(ngModel)]="username" type="email" data-cy="loginEmail"
                                            name="email" id="email" [placeholder]="'email' | translate" />
                                    </div>
                                    <div class="form-group">
                                        <label for="password">{{"password" | translate}}</label>
                                        <input class="form-control form-control-lg" [(ngModel)]="password" type="password" data-cy="loginPassword"
                                            name="password" id="password" [placeholder]="'password' | translate" (keyup.enter)="login()"/>
                                        <div class="col-sm-12 mb-2">
                                          <re-captcha #captchaRef="reCaptcha"
                                          siteKey="6LdmZkckAAAAAM8ZmRJ-Gt8CfhPX3GTKPc_HkDen"
                                          size="invisible"
                                          (resolved)="$event && checkRecaptcha($event)"></re-captcha>
                                        </div>
                                        <small>
                                            <a routerLink="/forgot">{{"forgotPass" | translate}}</a>
                                        </small>
                                        <br>
                                        <small>
                                            <a routerLink="/register">{{"register" | translate}}</a>
                                        </small>
                                    </div>
                                    <!-- <div>
                                        <div class="custom-control custom-checkbox align-items-center">
                                            <input id="customControlInline" type="checkbox" class="custom-control-input" value="remember-me"
                                                name="remember-me" checked>
                                            <label class="custom-control-label text-small" for="customControlInline">Remember me next time</label>
                                        </div>
                                    </div> -->
                                    <div class="text-center mt-3" data-cy="loginSubmit" >
                                        <a  *ngIf="prod"  class="btn btn-lg btn-primary" (click)="captchaRef.execute();">{{"login" | translate}}</a>
                                        <a  *ngIf="!prod"  class="btn btn-lg btn-primary" (click)="login();">{{"login" | translate}}</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
