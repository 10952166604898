<div>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">{{"geoLocationTabTitle" | translate}}</h5>
    </div>
    <div class="card-body">
      <form >
        <div class="form-group row d-flex align-items-center">
          <div class="col-auto">
            <label class="col-form-label"> {{"address" | translate}}:</label>
          </div>
          <div class="col-10">
            <input id="pac-input" class="controls" type="text" placeholder="Search Box" [(ngModel)]="address" name="address" />
          </div>
        </div>
      </form>
      <div id="map"></div>
    </div>

    <div class="card-footer pt-0">
      <div class="form-group row">
        <div>
          <button type="submit" class="btn btn-primary" (click)="save()" [disabled]="change">{{"overwrite" |
            translate}}</button>
          <button type="submit" class="btn btn-light ml-2" (click)="restoreData()">{{"restore" | translate}}</button>
        </div>
      </div>
      <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error!=''">
        <div class="alert-message">
          {{ error }}
        </div>
      </div>
      <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage">
        <div class="alert-message">
          {{"successMessage" | translate}}
        </div>
      </div>
    </div>

  </div>
</div>
