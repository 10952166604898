<div class="modal-header">
  <div class="d-flex w-100">

      <h2 class="text-primary modal-title"></h2>
      <h2 class="modal-title text-primary text-center w-100">{{header | translate}}</h2>
  </div>

</div>
<div class="modal-body m-3">
  <div class="m-auto text-center">
      <h3 class="mb-0 mt-3">{{body | translate}}</h3>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="close()">{{"cancel" | translate}}</button>
  <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="continue()">{{"next" | translate}}</button>
</div>
