<div [ngClass]="{'col-12 col-xl-6' : !allSettings}">
    <div class="card">
        <div class="card-header">
            <h5 class="card-title">{{"password" | translate}}</h5>
            <h6 class="card-subtitle text-muted">{{"password_text" | translate}}</h6>
        </div>
        <div class="card-body">
            <form>
              <div class="d-flex w-80 m-auto button-group-container justify-content-center mt-3">
                <div class="col-4 text-center p-1 button-group"
                  [ngClass]="{'bg-primary text-white' : body.apEnabled}" (click)="body.apEnabled= true; changesMade= this._device.checkChangesSettings(this.originalData, this.body)  "  >
                  {{"active" | translate}}
                </div>
                <div class="col-4 text-center p-1 button-group"
                  [ngClass]="{'bg-primary text-white' : !body.apEnabled}" (click)="body.apEnabled=false; changesMade=  this._device.checkChangesSettings(this.originalData, this.body)"  >
                  OFF
                </div>
              </div>
              <div class="d-flex  text-muted mt-3" *ngIf="!body.apEnabled" >
                {{ "for_security" | translate}}
              </div>
              <div >
                <div class="form-group row mt-5">
                    <label class="col-form-label col-sm-4 text-sm-right">{{"password_pass" |
                        translate}}</label>
                    <div class="col-sm-8">
                        <input type="email" class="form-control" name="apPasswd" [(ngModel)]="body.apPasswd"
                            [placeholder]="'password' | translate"
                            (keyup)="changesMade=_device.checkChangesSettings(originalData,body)">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-sm-4 text-sm-right">{{"password_new" |
                        translate}}</label>
                    <div class="col-sm-8">
                        <input type="password" class="form-control" name="apPassNew" [(ngModel)]="body.apPassNew"
                            [placeholder]="'user' | translate"
                            (keyup)="changesMade=_device.checkChangesSettings(originalData,body)">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-sm-4 text-sm-right">{{"password_repeat" |
                        translate}}</label>
                    <div class="col-sm-8">
                        <input type="password" class="form-control" name="apPassNew2" [(ngModel)]="body.apPassNew2"
                            [placeholder]="'user' | translate"
                            (keyup)="changesMade=_device.checkChangesSettings(originalData,body)">
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10 ml-sm-auto">
                        <button type="submit" class="btn btn-primary" (click)="save()"
                            [disabled]="!changesMade">{{"overwrite" | translate}}</button>
                        <button type="submit" class="btn btn-light ml-2" [disabled]="!changesMade">{{"restore"
                            | translate}}</button>
                    </div>

                </div>
              </div>
            </form>
            <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error!=''">
                <div class="alert-message">
                    {{ error }}
                </div>
            </div>
            <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage">
                <div class="alert-message">
                    {{"updatedSuccesfully" | translate}}
                </div>
            </div>
        </div>
    </div>
</div>
