<div class="content  m-0 p-0 "  >
  <div class="header d-flex justify-content-between mb-1 pr-1" style="background-color: '#006272';">

    <div class="header-title ps-5 pt-4 " >

    <h1 class="text-white" [routerLink]="['diagnosis']">
      {{serial}}
    </h1>
    <h3 class="text-white"  [routerLink]="['diagnosis']">
      {{objectDevice?.info?.name}}
    </h3>
    <h5 class="text-white"  [routerLink]="['diagnosis']">
      {{objectDevice?.info?.firmware_version_name}}
    </h5>
  </div>
  <div>
    <div class="float-left d-flex">
      <a class="nav-link" (click)="goToLink(serial)" *ngIf="userRole=='admin'">
        <i class="fas fa-dice-d20 text-white"></i>
      </a>
      <a class="nav-link text-white" [routerLink]="['/dashboard/device', serial]" [ngClass]="{'btn btn-light text-dark': (this._router.url.includes('device')&&!this._router.url.includes('settings')&&!this._router.url.includes('modules')&&!this._router.url.includes('diagnosis')&&!this._router.url.includes('spl')&&!this._router.url.includes('subscriptions'))}">
        <i class="fas fa-eye"></i>
      </a>
      <a class="nav-link text-white" [routerLink]="['diagnosis']" [ngClass]="{'btn btn-light text-dark': (this._router.url.includes('diagnosis')&&!this._router.url.includes('settings')&&!this._router.url.includes('modules'))}">
        <i class="fas fa-stethoscope"></i>
      </a>
      <a class="nav-link text-white" [routerLink]="['spl']" *ngIf="splMode != 0" [ngClass]="{'btn btn-light text-dark': (this._router.url.includes('spl')&&!this._router.url.includes('settings')&&!this._router.url.includes('modules'))}">
        <i class="fas fa-sitemap"></i>
      </a>
    <ul class="p-0 m-0">
      <li class="position-relative" style="list-style-type: none;">
        <div class="float-left d-flex ml-3">
          <a class="nav-link position-relative text-white" [ngClass]="{'btn btn-light text-dark': this._router.url.includes('settings')}" routerLink="settings">
            <i class="align-middle cog-device-set fas fa-cog"></i>
          </a>
          <a class="nav-link position-relative text-white" [ngClass]="{'btn btn-light text-dark': this._router.url.includes('subscriptions')}" routerLink="subscriptions" *ngIf="userRole=='admin' && deviceSubtype=='exo'">
            <i class="align-middle fas fa-cart-plus"></i>
          </a>
          <a class="nav-link position-relative text-white" [ngClass]="{'btn btn-light text-dark': this._router.url.includes('modules')}" routerLink="modules" *ngIf="userRole=='admin'">
            <i class="align-middle fas fa-cogs"></i>
          </a>
        </div>
        <!-- <a class="nav-link dropdown-toggle position-relative" id="userDropdown" data-toggle="dropdown">
                  <i class="align-middle cog-device-set fas fa-cog text-white ml-3"></i>
              </a> -->
        <div class="dropdown-menu dropdown-menu-right dropdown-menu-settings-device" style="top: 50px !important;">
          <a class="dropdown-item" routerLink="settings/connectivity"><i
              class="align-middle mr-1 fas fa-fw fa-broadcast-tower"></i> {{"connectivity" | translate}}</a>
          <a class="dropdown-item" routerLink="settings/password" href="#"><i
              class="align-middle mr-1 fas fa-fw fa-key"></i> {{"password" | translate}}</a>
          <a class="dropdown-item" routerLink="settings/wifi" href="#"><i
              class="align-middle mr-1 fas fa-fw fa-wifi"></i>
            {{"wifi" | translate}}</a>
          <a class="dropdown-item" routerLink="settings/power" href="#"><i
              class="align-middle mr-1 fas fa-fw fa-bolt"></i> {{"power" | translate}}</a>
          <a class="dropdown-item" routerLink="settings" routerLink="settings"><i
              class="align-middle mr-1 fas fa-fw fa-clock"></i> {{"datetime" | translate}}</a>
          <a class="dropdown-item" href="#"><i class="align-middle mr-1 fas fa-fw fa-calendar-alt"></i> {{"hourScheduler" |
            translate}}</a>
          <a class="dropdown-item" href="#"><i class="align-middle mr-1 fas fa-fw fa-hand-pointer"></i> {{"touchRfid" |
            translate}}</a>
          <a class="dropdown-item" routerLink="settings/ocpp"><i class="align-middle mr-1 fas fa-fw fa-cogs"></i>
            {{"ocpp"
            | translate}}</a>
          <a class="dropdown-item" href="#"><i class="align-middle mr-1 fas fa-fw fa-cloud-download-alt"></i>
            {{"fw_update" | translate}}</a>
        </div>
      </li>
    </ul>
  </div>
  </div>
</div>
<div class="pt-4 m-0" >
  <router-outlet></router-outlet>

</div>
