  <div class="modal-header">
      <div class="d-flex w-100">
          <h2 class="modal-title text-primary text-center w-100">{{title}}</h2>
      </div>

  </div>
  <div class="modal-body m-3">
      <p class="mb-0">{{description}}</p>
      <p class="mb-0" style="color: orange;">{{sendResult}}</p>
  </div>
  <div class="modal-footer">
    <button  [disabled]="checkingVerification" type="button" class="btn btn-success" (click)="reCheckUserValidation()">
      <span>Comprobar</span>
    </button>
    <button  [disabled]="resendingVerification" type="button" class="btn btn-warning" (click)="resendVerification()">
      <span >Reenviar</span>
    </button>
    <button  [disabled]="resendingVerification" type="button" class="btn btn-danger" (click)="logout()">
      <span >Cerrar sesión</span>
    </button>
  </div>

