<div class="header d-flex justify-content-between">
    <h1 class="header-title">
      {{"historics" | translate}}
    </h1>
</div>
<div class="col-12 col-xl-12">
    <div class="card">
        <div class="card-header">
            <button class="btn btn-primary float-right" (click)="big_dash=!big_dash">
                <i class="fas fa-plus"></i>
            </button>
            <button class="btn btn-primary float-right mr-2" (click)="downloadFile()">
                <i class="fas fa-file-download"></i>
            </button>
            <div class="w-75">
                <h5 class="card-title">{{"searchBy" | translate}}</h5>
                <h6 class="card-subtitle text-muted"></h6>
            </div>
        </div>
        <div class="col-xl-10 col-sm-10 col-md-8 m-auto p-4">
            <form id="formElem" class="form form-group">
                <div class="form-group col-xl-6 m-auto">
                    <label for="">{{"serialNumber" | translate}}</label>
                    <input id="select-serial" [(ngModel)]="serial" type="text" class="form-control" name="serial">
                </div>
                <div class="position-relative">
                    <div class="d-flex justify-content-center mt-3">
                        <button class="btn btn-primary" type="button" (click)="getLogsBySerial()">Get logs</button>
                    </div>
                    <div class="position-absolute d-flex" style="top: 0; right: 0;">
                        <input id="filter-logs" [(ngModel)]="filter" type="text" placeholder="Filter..." class="form-control mr-2" name="filter">
                        <button class="btn btn-light" type="button" (click)="cleanLogs()">
                          {{"clean" | translate}}
                        </button>
                    </div>
                    <div class="position-absolute" style="top: 0; left: 0;">
                        <button class="btn btn-danger" type="button" (click)="stop()">
                            <i class="fas fa-stop"></i>
                        </button>
                    </div>
                </div>
            </form>
            <div class="container-logs bg-dark" id="container-logs">
                <div *ngFor="let log of logsString">
                    <!-- <td>{{log.date | date : 'short'}}</td> -->
                    <td class="text-{{log.color}}" *ngIf="filter==''||log.log.toUpperCase().indexOf(filter.toUpperCase())!=-1">{{log.log}}</td>
                </div>
            </div>
            <div class="mt-3 d-none" role="status" id="loading-request">
                <span class="text-bold">{{"loading" | translate}}...</span>
            </div>
        </div>
    </div>
</div>

<div class="w-100 h-100 container_logs_big bg-dark" *ngIf="big_dash">
    <div class="d-flex w-100 h-50">
        <div class="position-absolute btn-close">
            <button class="btn btn-light" (click)="big_dash=false"><i class="fas fa-times"></i></button>
        </div>
        <div class="container-logos">
            <input [(ngModel)]="filters[0]" type="text" [placeholder]="'filter' | translate" class="form-control mr-2 position-absolute filter-small-window" name="filter" (change)="filterLogs()">
            <div class="container-logs-smaller bg-dark position-relative" id="container-logs">
                <div *ngFor="let log of logsStrings[0]">
                    <!-- <td>{{log.date | date : 'short'}}</td> -->
                    <td class="text-{{log.color}}">{{log.log}}</td>
                </div>
            </div>
        </div>

        <div class="container-logos">
            <input [(ngModel)]="filters[1]" type="text" [placeholder]="'filter' | translate" class="form-control mr-2 position-absolute filter-small-window" name="filter" (change)="filterLogs()">
            <div class="container-logs-smaller bg-dark position-relative" id="container-logs">
                <div *ngFor="let log of logsStrings[1]">
                    <!-- <td>{{log.date | date : 'short'}}</td> -->
                    <td class="text-{{log.color}}">{{log.log}}</td>
                </div>
            </div>
        </div>
        <div class="container-logos">
            <input [(ngModel)]="filters[2]" type="text" [placeholder]="'filter' | translate" class="form-control mr-2 position-absolute filter-small-window" name="filter" (change)="filterLogs()">
            <div class="container-logs-smaller bg-dark position-relative" id="container-logs">
                <div *ngFor="let log of logsStrings[2]">
                    <!-- <td>{{log.date | date : 'short'}}</td> -->
                    <td class="text-{{log.color}}">{{log.log}}</td>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex w-100 h-50">
        <div class="container-logos">
            <input [(ngModel)]="filters[3]" type="text" [placeholder]="'filter' | translate" class="form-control mr-2 position-absolute filter-small-window" name="filter" (change)="filterLogs()">
            <div class="container-logs-smaller bg-dark position-relative" id="container-logs">
                <div *ngFor="let log of logsStrings[3]">
                    <!-- <td>{{log.date | date : 'short'}}</td> -->
                    <td class="text-{{log.color}}">{{log.log}}</td>
                </div>
            </div>
        </div>
        <div class="container-logos">
            <input [(ngModel)]="filters[4]" type="text" [placeholder]="'filter' | translate" class="form-control mr-2 position-absolute filter-small-window" name="filter" (change)="filterLogs()">
            <div class="container-logs-smaller bg-dark position-relative" id="container-logs">
                <div *ngFor="let log of logsStrings[4]">
                    <!-- <td>{{log.date | date : 'short'}}</td> -->
                    <td class="text-{{log.color}}">{{log.log}}</td>
                </div>
            </div>
        </div>
        <div class="container-logos">
            <input [(ngModel)]="filters[5]" type="text" [placeholder]="'filter' | translate" class="form-control mr-2 position-absolute filter-small-window" name="filter" (change)="filterLogs()">
            <div class="container-logs-smaller bg-dark position-relative" id="container-logs">
                <div *ngFor="let log of logsStrings[5]">
                    <!-- <td>{{log.date | date : 'short'}}</td> -->
                    <td class="text-{{log.color}}">{{log.log}}</td>
                </div>
            </div>
        </div>
    </div>
</div>
