import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterLogsP'
})
export class FilterLogsPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if(!args){
      return [];
    }
    if(args[0]!=""){
      return value.filter(item => item.serial.toUpperCase().indexOf(args[0].toUpperCase()) !== -1);
    }
    else{
      return value;
    }
  }

}
