

<main class="main h-100 w-100">
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                <div class="d-table-cell align-middle">

                    <div class="text-center mt-4">
                        <h1 class="h2">{{"welcome" | translate}}</h1>
                        <p class="lead">
                          {{"notFilled" | translate}}
                        </p>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="m-sm-4">
                                <div class="text-center">
                                    <img src="/assets/img/orbis/orbis-logo.jpg" alt="Linda Miller" class="img-fluid" width="132" height="132" />
                                </div>
                                <form>
                                    <div class="d-flex">
                                        <div class="form-group mr-1">
                                            <label>{{"name" | translate}}</label>
                                            <input class="form-control form-control-lg" [(ngModel)]="userInfo.name" type="text" name="name"/>
                                        </div>
                                        <div class="form-group ml-1">
                                            <label>{{"surname" | translate}}</label>
                                            <input class="form-control form-control-lg" [(ngModel)]="userInfo.last_name" type="text" name="last_name" />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label>{{"email" | translate}}</label>
                                        <input class="form-control form-control-lg" [(ngModel)]="userInfo.email" type="email" name="email"/>
                                    </div>
                                    <!--
                                      <div class="form-group">
                                          <label>Alias</label>
                                          <input class="form-control form-control-lg" [(ngModel)]="userInfo.alias" type="text" name="alias"/>
                                      </div>
                                    -->
                                    <div class="form-group">
                                        <label>{{"password" | translate}}</label>
                                        <input class="form-control form-control-lg" [(ngModel)]="userInfo.password" type="password" name="password"/>
                                    </div>
                                    <div class="col-sm-12 mb-2">
                                      <re-captcha #captchaRef="reCaptcha"
                                      siteKey="6LdmZkckAAAAAM8ZmRJ-Gt8CfhPX3GTKPc_HkDen"
                                      size="invisible"
                                      (resolved)="$event && checkRecaptcha($event)"></re-captcha>
                                    </div>
                                    <div class="form-group">
                                        <label>{{"confirmPassword" | translate}}</label>
                                        <input class="form-control form-control-lg" [(ngModel)]="rePass" type="password" name="rePass"/>
                                        <small>
                                            <a routerLink="/login">{{"registered" | translate}}</a>
                                        </small>
                                    </div>
                                    <div>
                                            <label *ngIf="errorPasswords" class="text-small text-danger">{{"notSamePass" | translate}}</label>
                                    </div>
                                    <div class="text-center mt-3">
                                        <a class="btn btn-lg btn-primary" (click)="captchaRef.execute();">{{"login" | translate}}</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
