import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-select-serial',
  templateUrl: './modal-select-serial.component.html',
  styleUrls: ['./modal-select-serial.component.scss']
})
export class ModalSelectSerialComponent implements OnInit {

  @Input()
  devices: any[];

  device;
  otherDevice;
  yourDevice;


  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  passBack() {
    if (this.otherDevice && this.otherDevice != '')
      this.device = this.otherDevice;

    if (this.yourDevice && this.yourDevice != '')
      this.device = this.yourDevice;

    console.log(this.device)

    this.activeModal.close(this.device);
  }

  close(){
    this.activeModal.close();
  }

}
