import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-fwupdate',
  templateUrl: './fwupdate.component.html',
  styleUrls: ['./fwupdate.component.scss']
})
export class FwupdateComponent implements OnInit {

  latestVersion: any;

  availableNewVersion = false;

  device: any;
  serial: string = '';
  
  error: string = '';
  successMessage: boolean = false;

  originalData: any;
  changesMade=false;
  modalLoad: NgbModalRef;

  @Input()
  allSettings = false;

  constructor(public _device: DeviceService, private _aRoute: ActivatedRoute, private _modal: ModalService) { }

  ngOnInit(): void {

    this._aRoute.parent?.parent?.params
      .subscribe((params: Params) => this.serial = params['serial']);

    this._device.getDeviceInfo(this.serial)
      .subscribe(data => {
        console.log(data);
        this.device = data;
        this._device.getLatestVersionDevice(this.serial)
          .subscribe(data => {
            console.log(data);
            this.latestVersion = data;
            if(this.latestVersion.name != this.device.firmware_version_name){
                this.availableNewVersion=true;
              }
          })
      })
  }

  update() {
    this.modalLoad=this._modal.open(LoadingPopupComponent);

    this._device.updateFw(this.serial, this.latestVersion.url)
      .subscribe(data=>{
        console.log(data);
        this.modalLoad.close();
        this.successMessage=true;
        setTimeout(() => {
          this.successMessage=false;
        }, 10000);
      }, err=>{
        console.error(err)
        this.error=err.error.message;
        setTimeout(() => {
          this.error='';
        }, 10000);
        this.modalLoad.close();
      })
  }

}
