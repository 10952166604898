import { Component, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-mqtt',
  templateUrl: './mqtt.component.html',
  styleUrls: ['./mqtt.component.scss']
})
export class MqttComponent {

  @Input() allSettings=false;

  mqtt ={
    mqttUrl: '',
    mqttPort: '',
    mqttUser: '',
    mqttPass:'',
    mqttClientId: '',
    qos: 0,
    pingInterval: '',
    keepAlive: 0

  };

  modalLoad: NgbModalRef;
  device: string = '';
  originalData: any;
  changesMade=false;
  successMessage: boolean = false;
  error: string = '';


  constructor(public _device: DeviceService, private _modal: ModalService, private _aRoute: ActivatedRoute) { }



  ngOnInit(): void {
    this._aRoute.parent?.parent?.params
    .subscribe((params: Params) => this.device = params['serial']);

    this._device.getModuleInfo(this.device, 'mqtt_user')
      .subscribe(data=>{
        console.log(data);
        this.mqtt=data.cfg;
        console.log('mqtt_user ',this.mqtt)
        this.originalData={...data.cfg};
      })

  }


  save(){
    this.modalLoad=this._modal.open(LoadingPopupComponent);

    this._device.setModuleCfg(this.device, this.mqtt, 'mqtt_user')
      .subscribe(data=>{
        console.log(data);
        this.modalLoad.close();
        this.successMessage=true;
        setTimeout(() => {
          this.successMessage=false;
        }, 10000);
      }, err=>{
        console.error(err)
        this.error=err.error.message;
        setTimeout(() => {
          this.error='';
        }, 10000);
        this.modalLoad.close();
      })
  }

  change(){
    this.changesMade=this._device.checkChangesSettings(this.originalData,this.mqtt)
    console.log('changeMade ', this.changesMade)

  }

  restore(){

    // this.changesMade  = false
    this.mqtt = this.originalData;
    this.changesMade=this._device.checkChangesSettings(this.originalData,this.mqtt)
  }

}
