//@ts-nocheck
import { Component, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-testers',
  templateUrl: './testers.component.html',
  styleUrls: ['./testers.component.scss']
})
export class TestersComponent {

  constructor(private _uService : UserService) { }

  testers = [
  ];

  selectedTesters = [];

  searchText = '';

  displayedColumns: string[] = ['checkbox', 'name', 'email', 'role', 'actions'];
  dataSource = new MatTableDataSource<any>(this.testers);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  ngOnInit(){
    // implementa aquí la lógica para obtener los testers
    this.getTesters();
    this.dataSource.paginator = this.paginator;
  }

  getTesters(){
    this._uService.getTesters()
      .subscribe(data => {
        this.testers = data;
        this.dataSource.data = this.testers;
        this.dataSource.paginator = this.paginator;
      });
  }

  filterTesters(event: any) {
    const filterValue = event.target.value;

    switch (filterValue) {
      case 'all':
        // Mostrar todos los testers.
        this.testers = [...this.testers];
        break;

      case 'tester_requested':
        // Mostrar solo los testers que ya han sido invitados.
        this.testers = this.testers.filter(tester => tester.tester_requested !== 0);
        break;

      case 'not_invited':
        // Mostrar solo los testers que no han sido invitados.
        this.testers = this.testers.filter(tester => tester.tester_requested === 0);
        break;

      default:
        // Maneja cualquier otro caso inesperado.
        console.error("Unexpected filter value:", filterValue);
        this.testers = [...this.testers];
        break;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  selectTester(tester: any) {
    const index = this.selectedTesters.indexOf(tester);
    if (index > -1) {
        this.selectedTesters.splice(index, 1);
    } else {
        this.selectedTesters.push(tester);
    }
  }

  isAllSelected() {
    const numSelected = this.selectedTesters.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.clearSelected() :
        this.dataSource.data.forEach(row => this.selectedTesters.push(row));
  }

  clearSelected() {
      this.selectedTesters = [];
  }

  async sendInvitations() {
    if(confirm("¿Estás seguro de quere reenviar las invitaciones? Se les enviará un email con un nuevo link para acceder al canal de Telegram.")){
      this._uService.resendTelegramInviteLink(this.selectedTesters)
      .subscribe(data => {
        this.getTesters();
      })
    }
  }

  confirmTester(tester: any){
    if(confirm("¿Estás seguro de confirmar a éste usuario como tester? Se le enviará un email con una invitación para acceder al canal de Telegram y acceder a las apps como betatester.")){
      this._uService.confirmTesters([tester])
      .subscribe(data => {
        this.getTesters();
      })
    }
  }

  cancelTester(tester: any){
    if(confirm("¿Estás seguro de querer eliminar a éste usuario como tester? Se le enviará un email y se le sacará del canal de Telegram")){
      this._uService.downgradeTesters([tester])
      .subscribe(data => {
        this.getTesters();
      })
    }
  }

  cancelSelectedTesters() {
    if(confirm("¿Estás seguro de querer eliminar a éstos usuarios como tester? Se les enviará un email y se les sacará del canal de Telegram")){
      this._uService.downgradeTesters(this.selectedTesters)
      .subscribe(data => {
        this.getTesters();
      })
    }
    this.selectedTesters = [];
  }

  confirmSelectedTesters() {
    if(confirm("¿Estás seguro de confirmar a éstos usuarios como testers? Se les enviará un email con una invitación para acceder al canal de Telegram y acceder a las apps como betatesters.")){
      this._uService.confirmTesters(this.selectedTesters)
      .subscribe(data => {
        this.getTesters();
      })
    }
  }

  sendTelegramInvitation(tester: any){
    // Éste método reenvía el email de invitación al canal de Telegram
    if(confirm("¿Estás seguro de quere reenviar una invitación? Se les enviará un email con un nuevo link para acceder al canal de Telegram.")){
      this._uService.resendTelegramInviteLink([tester])
      .subscribe(data => {
        this.getTesters();
      })
    }
  }
}
