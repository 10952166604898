<div class="all-Width">
  <navbar-cmp></navbar-cmp>

  <div class="d-flex contenido">
    <div class="side">
      <sidebar-cmp></sidebar-cmp>
    </div>
    <main class="dashboard">
        <router-outlet></router-outlet>
    </main>
  </div>

  <app-footer></app-footer>
</div>
