<div style="height: fit-content ; min-height: 850px; width: 100%;">


  <div class="ps-5" style="background-color: #006272; min-height: 100px;">

    <nav aria-label="breadcrumb" class="ps-1">
      <ol class="breadcrumb" style="width: fit-content;">
        <li *ngFor="let item of breadCrumbs; let i = index" class="breadcrumb-item" style="padding: 10px;"
            [class.active]="i === breadCrumbs.length - 1">

          <!-- Si no es el último elemento en la ruta -->
          <div *ngIf="i !== breadCrumbs.length - 1" style="cursor: pointer;" (click)="updateBreadCrums(item.alias, i)">
            <!-- Si el nombre es 'home' muestra el ícono, de lo contrario muestra el nombre -->
            <ng-container *ngIf="item.name === 'home'; else normalBreadcrumb">
              <i class="fas fa-home text-white" (click)="home()"></i>
            </ng-container>
            <ng-template #normalBreadcrumb>
              <div class="text-white">{{ item.name }}</div>
            </ng-template>
          </div>

          <!-- Si es el último elemento en la ruta -->
          <span *ngIf="i === breadCrumbs.length - 1" class="text-white" (click)="item.name === 'home' ? home() : null">
            <i *ngIf="item.name === 'home'" class="fas fa-home"></i>
            <span *ngIf="item.name !== 'home'">{{ item.name }}</span>
          </span>
        </li>
      </ol>
    </nav>




    <div  class="m-1  d-flex justify-content-between align-items-center text-white" >
      <div class="text-white fs-4">
        {{ data?.name}}&nbsp;

        <span *ngIf="data?.role == 'all'" >    <i class="far fa-edit"></i>     </span>
        <span *ngIf="data?.role == 'owner'" >  <i class="fas fa-user-shield"></i>    </span>
        <span *ngIf="data?.role == 'write'" >  <i class="fas fa-pen"></i>   </span>
        <span *ngIf="data?.role == 'read'" >  <i class="fas fa-book-open"></i>  </span>
      </div>
      <div class="d-flex justify-content-between align-items-center pe-3 ">
        <button class="button-main-group text-white" (click)="goToInfoGroup(data.alias)">
          <i class="far fa-eye"></i>
        </button>
        <button class="button-main-group text-white" (click)="goToSPL(data.alias)" ><i>SPL</i></button>

        <button class="button-main-group text-white " (click)="goToDetailGroup(data.alias)"  > <i class="fa fa-gear"></i>
        </button>
        <button class="button-main-group text-white" data-toggle="modal" data-target="#centeredModalHistorics">
          <i class="fas fa-file-download"></i>
        </button>
      </div>
    </div>

  </div>



  <div class="d-flex justify-content-center " *ngIf="loading">
    <div class="spinner-border  " role="status" style="height: 100px; width: 100px; margin: 100px;">
      <span class="visually-hidden"></span>
    </div>
  </div>

  <div class="" *ngIf="!loading">

    <div class="card mt-4 ms-3 me-3">
      <div class="pl-4 pr-4 pt-1 pb-0 mt-2">
        <div class="d-flex  align-items-center" *ngIf="data.subgroups.length != 0">
          <div style="flex: 1"> {{ "subgroups" | translate  }}</div>
          <div class="divider ml-2"></div>
        </div>


        <div class="" *ngFor="let subgrupo of data.subgroups; let i = index">
          <h2 class="">
            <button class="item  d-flex  align-items-center ms-2 me-2"
              type="button" data-bs-toggle="collapse"
              [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
              <span style="width: 35%;" class="  d-flex  align-items-center ps-4 ">
                {{subgrupo?.name }}
              </span>


              <span style="width: 55%;" class="  d-flex  align-items-center">{{ subgrupo.description }}</span>
              <span style="width: 10%;" class=" d-flex  align-items-center">
                <button class="button-main-group text-primary" (click)="infoGroup(subgrupo)">
                  <i class="far fa-eye"></i>
                </button>
                <button class="button-main-group text-primary" (click)="viewGroup(subgrupo)">
                  <i class="far fa-chart-bar"></i>
                </button>

                <button class="button-main-group text-primary" (click)="detailGroup(subgrupo)">
                  <i class="fa fa-gear"></i>
                </button>
              </span>
            </button>
          </h2>
        </div>

      </div>

      <div class="pl-4 pr-3 mt-2">
        <div class="d-flex  align-items-center">
          <div style="flex: 1">{{"devices" | translate}}</div>
          <div class="divider ml-2"></div>
        </div>

        <div class="pl-4  pt-2 ">
          <table class="table table-striped ">
            <tbody>
              <tr *ngFor="let device of data.devices; let x =index">
                <td>
                  <span class="fw-semibold" style="cursor: pointer;" (click)="goToDevice(device.serial)">
                    {{device.serial}}
                  </span>

                </td>
                <!-- <td class="carga ms-0 p-0"  style="width: fit-content;"> -->
                <td>

                  <span *ngIf="device.role == 'all'"> <i class="far fa-edit"></i> </span>
                  <span *ngIf="device.role == 'owner'"> <i class="fas fa-user-shield"></i> </span>
                  <span *ngIf="device.role == 'write'"> <i class="fas fa-pen"></i> </span>
                  <span *ngIf="device.role == 'read'"> <i class="fas fa-book-open"></i> </span>
                </td>
                <!-- <td class="me-0  " style="width: fit-content;"> -->
                <td>

                  <span class="badge rounded-pill bg-info text-white f-4">
                    {{"lastCharge" | translate}}:
                  </span>
                </td>
                <!-- <td class="carga ms-0 "> -->
                <td>

                  <!-- {{"user"| translate}}: <span class="text-dark"> -->
                  <i class="fas fa-user"></i> <span class="text-dark">
                    {{ device?.historic?.[0]?.user ? device?.historic?.[0]?.user : '-' }}
                  </span>
                </td>
                <!-- <td class="carga ms-0 p-0"> -->
                <td>

                  <!-- {{"date"| translate}}: <span class="text-dark"> -->
                  <i class="fas fa-calendar"></i> <span class="text-dark">
                    {{ device?.historic?.[0]?.timestamp ?( device?.historic?.[0]?.timestamp | date: 'dd/MM/yy' ):
                    '--/--/--' }}
                  </span>
                </td>
                <!-- <td class="carga ms-0 p-0">{{"duration"|translate}}: -->
                <!-- <td class="carga ms-0 p-0"> -->
                <td>

                  <i class="fas fa-clock"></i>
                  <span class="text-dark">
                    {{ subtractDates( device?.historic?.[0]?.end_datetime, device?.historic?.[0]?.start_datetime ) }}
                  </span>
                </td>
                <td>
                  <i class="fas fa-bolt"></i>
                  <span class="text-dark">
                    {{ device?.historic?.[0]?.active ? device?.historic?.[0]?.active : 0}} Wh
                  </span>
                </td>
                <td>
                  <span class="carga ms-0 p-0">
                    {{"total"|translate}}:
                  </span>
                  <span class="p-2 text-right text-warning  fs-6"> {{ device?.total / 1000 }} Kwh </span>
                </td>
                <td>
                  <button class="button-main-group" (click)="getHistoricGraph(device.serial)" data-toggle="modal"
                  data-target="#centeredModalSecondary"><i class="far fa-chart-bar"></i></button>
                  <button class="button-main-group" (click)="getHistoricsDevice(device.serial)" data-toggle="modal"
                  data-target="#centeredModalPrimary"><i class="fas fa-history"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>

      <div class="d-flex   ">

        <div class="card w-50 ms-3 me-3">
          <div class="card-header text-center" >
            <h4 class="text-primary ">{{ "summaryConsumptions" | translate }} </h4>
          </div>

          <div class="card-body row m-2">
            <table class="table table-striped ">
              <tbody>
                <!-- <tr style="border-bottom-width: 2px; border-color: #00778B; "  > -->
                <tr *ngFor="let device of data.devices; let x =index">
                  <td class="ps-4"> {{ device.serial }} </td>
                  <td class="text-right pe-4"> {{device.total/1000 | number}} Kwh</td>
                </tr>
                <tr style="border-bottom-width: 2px; border-color: #00778B; "> </tr>
                <td class=" ps-5 fs-4"> {{"totalGroupConsumption" | translate }} {{ data.name }} </td>
                <td class="text-right pe-4">{{total / 1000 }} Kwh</td>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card w-50 ms-3 me-3">
          <div class="card-header text-center" >
            <h4 class="text-primary">{{"lastRecharges"|translate}}</h4>
          </div>
          <div class="card-body row m-2">
              <table class="table table-striped ">
                <tbody>
                  <tr *ngFor="let charge of lastFiveCharges; let i = index">
                    <td class="ps-4">{{charge.serial}}</td>
                    <td class="ps-5"> {{ charge.user }} </td>
                    <td> {{ charge.timestamp | date: 'dd/MM/yy' }}</td>
                    <td class="text-right pe-4"> {{ subtractDates( charge.end_datetime, charge.start_datetime ) }}</td>
                    <td class="text-right pe-4"> {{charge.active }} Wh </td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
      </div>
  </div>
</div>


  <!--  Modals -->

<div class="modal" id="centeredModalPrimary" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog-centered modal-card-devices m-auto" role="document">
    <div class="w-100 h-100">
      <div class="modal-header" style="background: transparent !important; border-bottom: none;">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetModals()">
          <span class="text-white" style="font-size: 35px;" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-content" [@zoomIn]="zoomIn" *ngIf="showModalHistorics">
        <app-card-historic [dataHistoric]="dataHistoric" [connectors]="connectors"></app-card-historic>
      </div>
    </div>
  </div>
</div>


<div class="modal" id="centeredModalSecondary" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog-centered modal-card-devices m-auto" role="document">
    <div class="w-100 h-100">
      <div class="modal-header" style="background: transparent !important; border-bottom: none;">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"   (click)="resetModals()">
          <span class="text-white" style="font-size: 35px;" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-content" [@zoomIn]="zoomIn" *ngIf="showModalGraphics">
        <app-card-analytics [dataAnalytics]="dataAnalytics" [connectors]="connectors" [serial]="device"  ></app-card-analytics>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="centeredModalHistorics" tabindex="-1" role="dialog" aria-hidden="true" >
  <div class="modal-dialog-centered modal-card-devices m-auto" role="document">
    <div class="w-100 h-100 modal-content" >
      <div class="modal-header">
        <h2 class="modal-title">{{"historics" | translate}} </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
          <span class="" style="font-size: 35px;" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-6 d-flex justify-content-center">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [(ngModel)]="selectAll"
                (change)="toggleAllDevices()" id="checkAll">
              <label class="form-check-label text-dark" for="checkAll"> {{"all" | translate  }} {{"devices"| translate }} </label>
              <div *ngFor="let device of selectedDevices; let i = index">
                <input class="form-check-input" type="checkbox" [(ngModel)]="device.check" id="check{{i}}">
                <label class="form-check-label" for="check{{i}}">
                  <span> {{ device.serial }}</span>
                </label>
              </div>
            </div>

          </div>
          <div class="col-6 d-flex justify-content-center align-items-center">
            <div class="row">

              <div class="form-check mb-2" >
                <input class="form-check-input" type="radio" [(ngModel)]="formato" name="fileFormat" value="csv"
                  id="csv">
                <label class="form-check-label" for="csv">
                  .csv
                </label>
              </div>
              <div class="form-check mt-2">
                <input class="form-check-input" type="radio" [(ngModel)]="formato" name="fileFormat" value="xls"
                  id="xls">
                <label class="form-check-label" for="xls">
                  .xls
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-primary " (click)="donwloadHistorics(selectedDevices, formato)" data-dismiss="modal" aria-label="Close" >
          {{"download" | translate}}</button>
      </div>
    </div>
  </div>
</div>
