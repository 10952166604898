import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-pat-token',
  templateUrl: './pat-token.component.html',
  styleUrls: ['./pat-token.component.scss']
})
export class PatTokenComponent implements OnInit {

  patToken = {
    token: '',
    uuid: '',
    loaded: false
  }

  constructor(private _auth: AuthService, private _user: UserService, private _toastr: ToastrService) { }

  ngOnInit() {
    this._auth.currentUser.subscribe((user) => {
      if (user) {
        this.patToken.uuid = user.uid;
        this.getUserPatToken();
      }
    })
  }

  deleteToken(){
    if(window.confirm('¿Estás seguro de querer eliminar el token? No podrás volver a utilizar el mismo token.')){
      //Delete token
      this._user.deleteUserPATToken(this.patToken.token).subscribe((res) => {
        if(res){
          this._toastr.success('Token eliminado correctamente');
          this.patToken.token = '';
          this.getUserPatToken();
        }
      });
    }
  }

  createToken(){
    this._user.generateToken().subscribe((res) => {
      console.log(res);
      this.patToken.token = res.token;
      this._toastr.success("Ya puedes utilizar la API con éstas credenciales", "Token generado");
    })
  }

  getUserPatToken(){
    this.patToken.loaded = false;
    this._user.getUserPATToken().subscribe((res) => {
      this.patToken.loaded = true;
      if(res.length > 0 && res[0].token){
        //Sólo debería haber 1
        this.patToken.token = res[0].token;
      }
    })
  }

}
