import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  ngOnInit() {
    //console.log(document.getElementById('script-main'));
    document.getElementById('script-main')?.remove();
    var newScript = document.createElement('script');
    newScript.setAttribute("id", "script-main");
    newScript.setAttribute("type", "text/javascript");
    newScript.onload = function () {
      //alert("Script loaded and ready");
    };
    newScript.src = "./assets/js/app.js";
    document.getElementsByTagName('head')[0].appendChild(newScript);
  }
}
