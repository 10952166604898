

export function TestScheduler(data, serial, testList,  state){

  var mennekes1;
  var mennekes2;
  var taskCheck;
  var state1;
  var state2;

  state1 = state[0].data.stat.state;
  if (state.length > 1) {
    state2 = state[1].data.stat.state;
  }

  mennekes1 = JSON.parse( data[0].data.cfg.tasks);
  testChargeRelated(mennekes1, '1');

  if (data.length >1 ){
    mennekes2 = JSON.parse(data[1].data.cfg.tasks);
    testChargeRelated(mennekes2, '2');
    testTramos();
  }

  // Verificar que no tiene tramos de potencia diferentes en ambos conectores.
  // Hay que comprobar los objetos de tipo 1 que son los que corresponde a la programación de potencia
  function testTramos(){
    var tramos1: any[] = []
    var tramos2: any[] = []

    mennekes1.forEach(element => {
      if (element.type == 1) tramos1.push(element);
    });
    mennekes2.forEach(element => {
      if (element.type == 1) tramos2.push(element);
    });
    if ( JSON.stringify(tramos1) === JSON.stringify(tramos2 )) {
      taskCheck = true;
    }else {
        taskCheck = false
    }
    var text1 = 'Salida1: '+ JSON.stringify(tramos1);
    var text2 = '\nSalida2: '+ JSON.stringify(tramos2);
    testList.push({ serial: serial, module: 'Scheduler Manager', test: `Los tramos de potencia son iguales en ambos conectores ` , config: text1+text2, result: taskCheck })
  }


  function testChargeRelated (salida, name){
  // Comprobar que no tiene tramos de potencia con el flag chargeRelated=1 si no está cargando.
  for (let x = 0; x < salida.length; x++) {
    if (salida[x].chargeRelated == 1 && (state1 != 5 || state1 != 6 || state1 != 7)) {
      taskCheck = false;
      break;
    } else {
      taskCheck = true
    }
  }
  testList.push({ serial: serial, module: 'Scheduler Manager', test: `No tiene un tramo de potencia con el valor chargeRelated = 1 en la salida ${name} ` , config: JSON.stringify(salida, null, 2), result: taskCheck })
  }




}
