import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { ModalAddGroupComponent } from 'src/app/components/ui/modal-add-group/modal-add-group.component';
import { ModalConfirmComponent } from 'src/app/components/ui/modal-confirm/modal-confirm.component';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/modals/confirmation-modal/confirmation-modal.component';
import { DashboardService } from '../../services/dashboard/dashboard.service';



@Component({
  selector: 'app-new-group',
  templateUrl: './new-group.component.html',
  styleUrls: ['./new-group.component.scss']
})
export class NewGroupComponent  implements OnInit{



  group : string = ""
  dataGroup: any = {}
  listGroups: any[] = []
  serialGroup : string = ""
  serialUser : string = ""
  user : any;
  devicesInGruoup : any[] = [];
  subgroup : any =''
  check = {
    group : false,
    usuario : false,
    update : false
  }
  newSubgroup  = { name : '', description : '', alias : null}
  loading = false;
  breadCrumbs : any;



  constructor(private _router: Router,  private _modal: ModalService, private _devices: DeviceService, private route: ActivatedRoute, private _dashboard: DashboardService) {}

  modalAdd: NgbModalRef;
  modal : NgbModalRef;


  inputNombre = true;
  disableButtons = true;


  ngOnInit() {
    this._dashboard.getBreadCrumbs().subscribe(
      data => this.breadCrumbs = data
    )
    this.loading = true
    this.readData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  readData(){
    this.route.params.subscribe(params => {

      this.group = params['group'] || null;

      if (this.group == null) this.inputNombre=false;

      if (this.group != null){

        this.disableButtons = false
        this.subgroup = this.group

        this._devices.getGroup(this.group)
          .subscribe( data => {
            this.dataGroup = data;
            const exists = this.breadCrumbs.some(breadcrumb => breadcrumb.alias === this.dataGroup.alias);
            if (!exists) {
              this._dashboard.setBreadCrumbs({name: this.dataGroup.name, alias: this.dataGroup.alias })
            }
            this.devicesInGruoup = data.devices;
            this.listGroups.push({alias: this.dataGroup.alias, name: this.dataGroup.name})
          this.dataGroup.subgroups.forEach(element => {
            this.listGroups.push( {alias: element.alias, name: element.name })
          });
        })
      }
    });

  }


  addGroup(){
    this._dashboard.setUpdateSidebar();
    this.modalAdd = this._modal.open(ModalAddGroupComponent)
    if (this.group != null){
      this.modalAdd.componentInstance.parentGroup =  this.group
    }
        // Comprobar cuando el modal se cierra
        this.modalAdd.closed.subscribe(() => {
          this.modal=this._modal.open(LoadingPopupComponent);
          setTimeout(() => {
            this.modal.close()
            this.readData();
            this._dashboard.setUpdateSidebar();
          }, 1000);
      });

  }

  asignarGruop(){
    this.modal=this._modal.open(LoadingPopupComponent);
    this._devices.addDevices(this.subgroup,  { "devices":[this.serialGroup]  } )
      .subscribe ( data => {
        setTimeout(() => {
          this.modal.close()
          this.readData();
          this.check.group = true
        }, 1000);
        setTimeout(() => {
          this.check.group = false
        }, 3000);
      }, err => {
        console.error(err)
        this.modal.close()
      }
    )
  }


  asignarUser(){
    this.modal=this._modal.open(LoadingPopupComponent);

    this._devices.assignDevices(this.serialUser, this.user, 'read')
    .subscribe( data => {
      setTimeout(() => {
        this.modal.close()
        this.readData();
        this.check.usuario = true
      }, 1000);
      setTimeout(() => {
        this.modal.close()
        this.check.usuario = false
      }, 3000);
    })

  }

  deleteGroup(mainGroup){
    this._dashboard.setUpdateSidebar();

    this.modal = this._modal.open(LoadingPopupComponent)
    if( confirm( '¿Estas seguro de querer eliminar el grupo?') ){
      this._devices.deleteGroup( mainGroup)
      .subscribe ( () => {
        setTimeout(() => {
          this.modal.close()
          this._dashboard.setUpdateSidebar();
          this._router.navigate(['/dashboard']);
        }, 1200);
      })
    }
  }

  deleteSubgroup(group, subgroup){
    this.modal = this._modal.open(LoadingPopupComponent)
    if( confirm( '¿Estas seguro de querer eliminar el subgrupo?') ){
      this._devices.removeSubgroup(group, subgroup)
      .subscribe(  ()=> {
        this._devices.deleteGroup( subgroup)
        .subscribe ( ( ) => {
          this._dashboard.setUpdateSidebar();
        })
        setTimeout(() => {
          this.modal.close()
          this.readData();
        }, 1200);
      })
    }
  }

  cancel(){
    this._router.navigate(['/dashboard']);
  }

  saveGroup(){

    this._dashboard.setUpdateSidebar();

    var body = {
      name: this.dataGroup.name,
      description: this.dataGroup.description
      // devices: null
      }
    this.modal=this._modal.open(LoadingPopupComponent);

    if (this.group == null && this.dataGroup.name !=""){
      this._devices.createGroup(body)
        .subscribe( (data) => {
          this._dashboard.setUpdateSidebar();
          this.group = data.group_alias;
          this._router.navigate([`/dashboard/newgroup/${this.group}`]);
          this.disableButtons = !this.disableButtons
          setTimeout(() => {
            this.modal.close()
            this.check.update = true
          }, 3000);
          setTimeout(() => {
            this.check.update = false
          }, 3000);
        }, err => {
          console.log(err);
          this.modal.close();
        });

    };

    if ( this.group != null && (this.dataGroup.name != "" || this.dataGroup.description !="") ){
      this._devices.updateGroup(this.dataGroup.alias, body)
        .subscribe( (data ) => {
          setTimeout(() => {
            this.modal.close()
            this.check.update = true
          }, 1000);
          setTimeout(() => {
            this.check.update = false
          }, 3000);

        }, err => {
          console.log(err);
          this.modal.close();
        }
      )
    }
  }


  removeDevice(serial, aliasGroup) {

    if (confirm('¿Estas seguro de querer desvincular el dispositivo del grupo?')) {

      this._devices.removeDevice(aliasGroup, serial)
        .subscribe((data) => {
          this.readData();
        })
    }
  }

  viewGroup(group : string){
    this._router.navigate(['/dashboard/viewgroup', group]);
  }

  goToSPL(group : string){
    this._router.navigate(['/dashboard/splconfig', group]);
  }

  detailGroup(alias){
    this._router.navigate(['/dashboard/newgroup',alias]);
  }

  infoGroup(alias){
    this._router.navigate(['/dashboard/group',alias]);
  }

  saveSubgroup(){
    this._devices.createGroup( this.newSubgroup )
     .subscribe ( (data) => {
      this.newSubgroup.alias = data.group_alias;
      this._devices.addSubGroups(this.group , {subgroup: this.newSubgroup.alias  })
        .subscribe( data => {
          this._dashboard.setUpdateSidebar();
          this.readData();
          this.newSubgroup  = { name : '', description : '', alias : null}
        })
     })
  }

  updateBreadCrums(alias: string, index: number): void {
    const currentBreadCrumbs = this.breadCrumbs;
    const updatedBreadCrumbs = currentBreadCrumbs.slice(0, index +1);
    this._dashboard.replaceBreadCrumbs(updatedBreadCrumbs )
    if (index === 0) {
      this._dashboard.setModoDashboard('list')
      this._router.navigate(['dashboard']);
    } else {
      this._router.navigate(['dashboard/newgroup', alias]);
    }
  }

  home(){
    this._dashboard.setUpdateSidebar();
  }

}
