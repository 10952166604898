<div>
    <div class="card">
        <div class="card-header">
            <h5 class="card-title">{{"powerScheduler" | translate}}</h5>
            <h6 class="card-subtitle text-muted"></h6>
        </div>
        <div class="card-body">
            <div class="container">
                <div class="d-flex justify-content-between w-100">
                    <div>
                        <p class="text-grey">* {{"taskMustBeAllDayLong" | translate}}</p>
                    </div>
                    <div>
                        <a class="btn btn-outline" (click)="selectedDays='weekday'" [ngClass]="{'bg-primary text-white': selectedDays=='weekday'}">{{"weekday" | translate}}</a>
                        <a class="btn btn-outline" (click)="selectedDays='weekend'" [ngClass]="{'bg-primary text-white': selectedDays=='weekend'}"> {{"weekend" | translate}}</a>
                    </div>
                </div>

                    <!-- <h5 *ngIf="!tasks[conn.name]||tasks[conn.name][selectedDays].length==0">No hay tareas</h5> -->
                    <div class="w-100">
                        <!-- <h4 *ngIf="conn.name=='mennekes'">T</h4> -->

                        <table class="table table-responsive w-100">
                            <thead>
                                <tr class="w-100">
                                    <!-- <th class="col-3">Estado</th> -->
                                    <th class="col-3">{{"maxPower" | translate}} (W)</th>
                                    <th class="col-3">{{"initTime" | translate}}</th>
                                    <th class="col-2">{{"endTime" | translate}}</th>
                                    <th class="col-2" *ngIf="solarCfg?.enabled">{{"solar" | translate}}</th>
                                    <th class="col-2"></th>
                                </tr>
                            </thead>


                            <tbody *ngIf="powerTasks[selectedDays]!=undefined && powerTasks[selectedDays].length>0">
                                <tr *ngFor="let task of powerTasks[selectedDays], let i = index" class="tr_sched">
                                  <!-- TODO: Mostrar el valor correcto en los inputs y en el color del icono de la hojita (svg), también en el modal que abre con las 3 opciones -->
                                    <td><input type="number" class="form-control" name="task.maxPower" [(ngModel)]="task.maxPower" (change)="checkPriority(task)" placeholder="W"></td>
                                    <td>{{_utils.getDateFromMinutes(task.initTime.timeList[0].hourMin)}}</td>
                                    <td>{{_utils.getDateFromMinutes(task.initTime.timeList[0].hourMin+task.initTime.timeList[0].duration)}}</td>
                                    <td *ngIf="solarCfg.enabled">
                                    <div *ngIf="task.solarPriority==0" (click)="openModalPriority(task)">
                                        <i class="fas fa-solar-panel text-success" style="font-size: 18px;"></i>
                                    </div>
                                    <div *ngIf="task.solarPriority==2" (click)="openModalPriority(task)">
                                        <svg style="width:24px;height:24px" class="text-success" viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M17,8C8,10 5.9,16.17 3.82,21.34L5.71,22L6.66,19.7C7.14,19.87 7.64,20 8,20C19,20 22,3 22,3C21,5 14,5.25 9,6.25C4,7.25 2,11.5 2,13.5C2,15.5 3.75,17.25 3.75,17.25C7,8 17,8 17,8Z" />
                                        </svg>
                                    </div>
                                    <div *ngIf="task.solarPriority==1" (click)="openModalPriority(task)">
                                        <svg style="width:24px;height:24px" class="text-grey" viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M17,8C8,10 5.9,16.17 3.82,21.34L5.71,22L6.66,19.7C7.14,19.87 7.64,20 8,20C19,20 22,3 22,3C21,5 14,5.25 9,6.25C4,7.25 2,11.5 2,13.5C2,15.5 3.75,17.25 3.75,17.25C7,8 17,8 17,8Z" />
                                        </svg>
                                    </div>
                                    </td>
                                    <td class="d-flex">
                                        <button class="btn btn-primary btn-primary-hard" (click)="openEditTask(task)">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                        <button class="btn btn-danger btn-danger-hard ml-2" (click)="deleteTask(task.id)">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>

                            <tr>
                                <button class="btn btn-outline-primary" (click)="openAddTask()">
                                    <i class="fas fa-plus"></i>
                                </button>
                            </tr>
                        </table>
                    </div>
                    <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error!=''">
                        <div class="alert-message">
                          {{ "taskMustBeAllDayLong" | translate }}
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage">
                        <div class="alert-message">
                            {{"updatedSuccesfully" | translate}}
                        </div>
                    </div>

                    <button class="btn btn-primary" (click)="save()">
                      {{"overwrite" | translate}}
                    </button>
            </div>
        </div>
    </div>
</div>
