import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  userName: string = '';
  userRole: string = '';
  userEmail: string = '';
  userSubscriptions: any[] = [];

  constructor(private _user: UserService){}

  ngOnInit() {
    //console.log(document.getElementById('script-main'));
    document.getElementById('script-main')?.remove();
    var newScript = document.createElement('script');
    newScript.setAttribute("id", "script-main");
    newScript.setAttribute("type", "text/javascript");
    newScript.onload = function () {
      //alert("Script loaded and ready");
    };
    newScript.src = "./assets/js/app.js";
    document.getElementsByTagName('head')[0].appendChild(newScript);

    this._user.getUserInfo().subscribe((userInfo) => {
      this._user.setUserInfo(userInfo);
      this.userEmail = userInfo.email || '';
      this.userName = userInfo.name || '';
      this.userName += ' ' + userInfo.last_name || '';
      this.userRole = userInfo.role || '';
      if (typeof userInfo.subscriptions === "string")
        this.userSubscriptions = JSON.parse(userInfo.subscriptions);
      this.userSubscriptions = userInfo.subscriptions || [];
    });
  }
}
