import { CustomModalComponent } from 'src/app/components/ui/custom-modal/custom-modal.component';
import { UserService } from 'src/app/services/user/user.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ErrorModalComponent } from 'src/app/components/ui/error-modal/error-modal.component';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  userInfo={
    name: '',
    email: '',
    password: '',
    last_name: '',
    alias: ''
  }

  modalLoad: NgbModalRef;
  errorModal: NgbModalRef;
  customModal: NgbModalRef;

  rePass='';
  error=false;
  errorPasswords=false;

  token: string|undefined;

  captchaRef = undefined;

  constructor(private _auth: AuthService,
              private _router: Router,
              private _modal: NgbModal,
              private _translate: TranslateService,
              private _userService: UserService){
                this.token = undefined;
              }

  async checkRecaptcha(event){
    this.token = event;
    if(this.token){
      this.register();
    }
  }

  register(){
    if(this.rePass!=this.userInfo.password){
      this.errorPasswords=true;
      return;
    }
    this.modalLoad=this._modal.open(LoadingPopupComponent);

    this._auth.register(this.userInfo)
    .then(async user=>{
      //El alias se envía como null, ya que da problemas si utiliza el mismo.
      await this._userService.updateUser(this.userInfo.name, this.userInfo.last_name, null, navigator.language);
      let _displayName = this.userInfo.name + " " + this.userInfo.last_name;
      this._auth.updateProfile(_displayName);
      //Hacer un resend validation para que le llegue un email de validación.
      this._auth.verifyEmail();
      this.modalLoad.close();
      this.customModal=this._modal.open(CustomModalComponent);
      this.customModal.componentInstance.title='';
      this.customModal.componentInstance.description=this._translate.instant("register_success");
      this.customModal.closed.subscribe(()=>{
        this._router.navigate(['/login']);
      })
    }, err=>{
      this.modalLoad.close();
      this.errorModal=this._modal.open(ErrorModalComponent);
      this.errorModal.componentInstance.description=err.message;
    })

  }

}
