<div class="w-100 p-3" id="card-state">
    <div class="card-header">
      <div class="card-actions float-right">
        <i class="fas fa-circle text-primary fa-fw"></i> {{"connected" | translate}}

        <!-- <a href="#" class="mr-1">
                  <i class="align-middle" data-feather="refresh-cw"></i>
                </a>
                <div class="d-inline-block dropdown show">
                  <a href="#" data-toggle="dropdown" data-display="static">
                    <i class="align-middle" data-feather="more-vertical"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </div>
                </div> -->
      </div>
      <h5 class="card-title mb-0">{{"state" | translate}}</h5>
    </div>
    <div class="card-body" *ngIf="false">
    </div>
    <div class="card-body py-3">
      <div class="d-flex">
        <div class="col-xl-4 col-12">
          <div class="sm-card m-1" *ngFor="let connector of connectors">
            <h4 class="text-primary">{{connector.name | titlecase}}</h4>
            <span
              class="badge badge-pill badge-{{allStates[connectorsState[connector.name]?.state]?.icon}} mw-100">
              {{getTranslatedText(allStates[connectorsState[connector.name]?.state]?.text)}}</span>
          </div>
        </div>
        <div class="col-xl-4 col-12 container-rings-device">
          <div class="container-rings-device">
            <div class="chart chart-xs">
              <canvas class="ring-outside" id="chartjs-card-pie"></canvas>
            </div>
            <div class="chart chart-xs">
              <canvas class="ring-inside" weight="0.5" id="chartjs-card-pie-inside"></canvas>
            </div>
          </div>

      </div>
      <div class="col-xl-4">
        <div>
          <h6 class="text-light">
            {{"homePower" | translate}}
          </h6>
          <h4 class="text-right">{{infoModulator?.stat.homePower}}W</h4>
        </div>
        <div>
          <h6 class="text-light">
            {{"chargerPower" | translate}}
          </h6>
          <h4 class="text-right">{{infoModulator?.stat.evsePower}}W</h4>
        </div>
        <div>
          <h6 class="text-light">
            {{"total" | translate}}
          </h6>
          <h4 class="text-right">{{infoModulator?.stat.limitPower}}W</h4>
        </div>
      </div>

    </div>
  </div>
