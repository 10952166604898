import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeviceService } from 'src/app/services/devices/device.service';
import { Chart } from 'chart.js';
import { Colors } from 'src/assets/js/colors';
import { ConnectorState, SelectorPosition } from 'src/assets/js/constants';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { ModalService } from 'src/app/services/modal/modal.service';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import { infoModulator } from 'src/app/models/Modulator/infoModulator';
import { AlertsDiagnosisComponent } from 'src/app/components/ui/alerts-diagnosis/alerts-diagnosis.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { compare } from 'compare-versions';


@Component({
  selector: 'app-diagnosis-home',
  templateUrl: './diagnosis-home.component.html',
  styleUrls: ['./diagnosis-home.component.scss']
})
export class DiagnosisHomeComponent implements OnInit, OnDestroy {

  indexAux = 0;
  transl: string = ''
  subscriptionRouter: any;

  socket;
  stopped = false;

  rt: any;

  solarInfo: any;
  historicData: any;
  infoModulator: any;
  dataMennekes: any[] = [];
  sysData: any;
  sysCfg: any;
  splData: any;
  num_phases = 3;

  loading = true;

  device = '';

  error = false;

  canvasPowers: any;
  ctxPowers: any;
  chartPowers: any;

  modalAlerts: NgbModalRef;

  NOW = Date.now();
  lastReport = Date.now() - 10000000;
  dateDisplay = 'short';
  deviceConnected: boolean;

  userRole;

  yourDevice = '';
  otherDevice = '';

  connectors: any[] = [];
  connectorsState: any = {};
  allStates = ConnectorState;
  connectorsType: any[] = [];

  alerts: string[] = [];

  selectorPosition = SelectorPosition;

  isTMC100 = {
    solar:false,
    inst:false,
    home:false,
    car:false,
    totCurrent:false
  }

  rtLoaded = false;
  interval: any;
  intervalRT: any;

  mobileView = false;
  notReporting = true;

  tempMessages = [
    {code: 0, label: 'InRange', vbl: 'tempInRangeDesc'},
    {code: 1, label: 'AboveLimit', vbl: 'tempAboveLimitDesc'},
    {code: 2, label: 'TimeExceeded', vbl: 'tempTimeExceededDesc'},
    {code: 3, label: 'NotValid', vbl: 'tempNotValidDesc'},
  ];

  version : boolean;

  constructor(public _device: DeviceService, private _ws: WebsocketService, private _aux: UtilsService,
    private _aRoute: ActivatedRoute, private _router: Router, private _modal: ModalService, private _utils: UtilsService, private _translate: TranslateService
  ) {
    if (window.innerWidth < 576) {
      this.mobileView = true;
    }
  }

  ngOnInit(): void {
    var time = this._device.isFirstLoad ? 500 : 100;
    this.infoModulator=new infoModulator();

    this._aRoute.parent?.params.subscribe((params: Params) => {
      this.device = params['serial'];
    });

    this.connectWS();

    this._device.getDeviceInfo(this.device)
      .subscribe(data => {
        console.log(data);
        this.version = compare(data.firmware_version_name, '7.2.0', '>=');
      })

    this._device.getElemDevice(this.device)
      .subscribe( data => {
        // Para ordenar la array de los conectores por si se hubieran programado en el orden equivocado
        this.connectorsType = data.sort((a, b) => a.data.id - b.data.id);
      })

    if (this.device && this.device != '')
      setTimeout(() => {
        this.load();
      }, time);

    this.subscriptionRouter=this._router.events.subscribe((val) => {
      if ((val instanceof NavigationEnd) && (this.device != this._aRoute.snapshot.params.serial)) {
        this.device = this._aRoute.snapshot.params.serial;
        this.initialiseState();
        this.connectWS();
        setTimeout(() => {
          this.load();
        }, time);
      }
    });
  }

  connectWS() {
    this.socket = this._ws.connect(this.device)
      .subscribe(
        msg => {
          let message = msg;
          console.log('%cMessage WS: ', 'background: yellow; color: black', message)
          this.lastReport = Date.now();
          this.rtLoaded = true;
          this.deviceConnected = true;
          this.notReporting = false;
          switch (message.module) {
            case "modulator":
              if (!message.element) {
                if(message.data.stat)
                 this.infoModulator.stat = message.data.stat;
                if(message.data.cfg)
                 this.infoModulator.cfg = message.data.cfg;
                if (this.infoModulator) {
                  Object.entries(message.data.stat).forEach((entry:any) => {
                    // if(entry[0]=="instPowerByPhase")
                    //   this._utils.update(this.infoModulator.stat.instPowerByPhase,JSON.parse(this.infoModulator?.stat?.instPowerByPhase));
                    // else if(entry[0]=="fvPowerByPhase")
                    //   this._utils.update(this.infoModulator.stat.instPowerByPhase,JSON.parse(this.infoModulator?.stat?.instPowerByPhase));
                    // else this.infoModulator.stat[entry[0]] = entry[1];
                  })
                  this.infoModulator.stat.timestamp = new Date();
                }
                console.log('%cinfoModulator: ', 'background: brown; color: white', this.infoModulator)
                // this.addData(this.chartPowers, new Date());
              }

              if (message.element) {
                this.connectors.forEach((conn, index) => {
                  if (conn.name == message.element) {
                    Object.entries(message.data.stat).forEach(entry => {
                      this.connectors[index].stat[entry[0]] = entry[1];
                    });
                  }
                });
                // this.addData(this.chartPowers, "" + new Date());
                console.log('%cConnectors: ', 'background: orange; color: white', this.connectors)
              }

              break;
            case "evsm":
              if (message.element) {
                this.connectorsState[message.element].state = message.data.stat.state;
                if (message.data.stat.state == 5) {
                  this._device.getElementModule(this.device, "modulator")
                    .subscribe(data => {
                      console.log('%cTime load', 'background: #222; color: #bada55', data)
                      this.connectors.forEach(el => {
                        let finded = data.find(con => con.name == el.name);
                        console.log("updated " + message.element);
                        el.stat.durationCharge = this._aux.subtractDates(new Date(), finded.data.stat.startTimestamp);
                        el.stat.startTimestamp = finded.data.stat.startTimestamp;
                      });
                    })
                }
                console.log('%cConnectors: ', 'background: orange; color: white', this.connectorsState)
              }

              break;
            case "mennekes":
              if (message.element) {
                var connector = this.connectors.find(conn => conn.name == message.element);
                connector.mennekes = message.data;
              }

              break;
          }

        },
        err => {
          console.log(err);
          this._modal.showWarn(this._translate.instant("deviceDisconnectedFromServer"), this._translate.instant("connectionCouldNotBeStablished"));
          this.deviceConnected = false;
          this.notReporting = true;
        },
        () => console.log('complete')
      )
  }

  load() {

    this.interval = setInterval(() => {
      this.NOW = Date.now();
      let differenceSinceLastUpdateModulator = Math.round((Date.now() - new Date(this.infoModulator?.stat?.timestamp).valueOf()) / 1000);
      if (differenceSinceLastUpdateModulator > 30) {
        this.dateDisplay = 'short';
        // if (differenceSinceLastUpdate % 10 == 0 && this.rtLoaded) {
        //   this.addData(this.chartPowers, "" + new Date());
        // }
      } else {
        this.dateDisplay = 'seconds';
      }
      let differenceSinceLastReport = Math.round((Date.now() - this.lastReport) / 1000);
      if (differenceSinceLastReport > 10) {
        this.notReporting = true;
      }

      this.connectors.forEach(conn => {
        // if (this.connectorsState[conn.name]?.state == 5)
      if ([5, 6, 7].includes(this.connectorsState[conn.name]?.state))
          conn.stat.durationCharge = this._aux.subtractDates2(new Date(), conn.stat.startTimestamp);
      })
      if (this.deviceConnected)
        this.addData(this.chartPowers, "" + new Date());
    }, 2000)


    let context = this;

    this.startRT(this);
    this.intervalRT = setInterval(() => {
      this.startRT(context);
    }, 120000)

    this._device.getModuleInfo(this.device, 'modulator').subscribe(data => {
        this.infoModulator = data;
        try {
          this.infoModulator.stat.homeEnergyByPhase = JSON.parse(this.infoModulator?.stat?.homeEnergyByPhase);
          this.infoModulator.stat.limitPowerByPhase = JSON.parse(this.infoModulator?.stat?.limitPowerByPhase);
          this.infoModulator.stat.instPowerByPhase = JSON.parse(this.infoModulator?.stat?.instPowerByPhase);
          this.infoModulator.stat.totalEnergyByPhase = JSON.parse(this.infoModulator?.stat?.totalEnergyByPhase);
          this.infoModulator.stat.totalPowerByPhase = JSON.parse(this.infoModulator?.stat?.totalPowerByPhase);
          this.infoModulator.stat.totalCurrent = JSON.parse(this.infoModulator?.stat?.totalCurrent);
          this.infoModulator.stat.fvPowerByPhase = JSON.parse(this.infoModulator?.stat?.fvPowerByPhase);
        } catch (e) { }

        console.log(data);
        this.loading = false;

      }, (err) => {
        this.error = true;
        this.loading = false;
      })

    this._device.getElementModule(this.device, "mennekes").subscribe(data => {
        console.log(data)
        this.dataMennekes = data;
      })

    this._device.getModuleInfo(this.device, "spl").subscribe(data => {
        console.log(data);
        this.splData = data;
      })

    this._device.getModuleInfo(this.device, "sys").subscribe(data => {
        console.log(data);
        this.sysData = data.stat;
        this.sysCfg = data.cfg;
        this.num_phases = data.stat.fwv_pot.startsWith('3') ? data.cfg.phase!=1 ? 3 : 1: 1;
      })

    this._device.getModuleInfo(this.device, "solar").subscribe(data => {
        console.log(data);
        this.solarInfo = data;
        if(typeof this.solarInfo?.cfg?.inversor === "string")
          this.solarInfo.cfg.inversor = JSON.parse(this.solarInfo.cfg.inversor);

        if(data.cfg.enabled==1){
          switch (data.cfg.type){
            case 10:
            case 30:
              this.isTMC100.totCurrent=true;
              break;
            case 11:
            case 31:
              this.isTMC100.home=true;
              break;
            case 12:
            case 32:
              this.isTMC100.solar=true;
              break;
          }
        }

      })

    this._device.getElementModule(this.device, "modulator").subscribe(data => {
        console.log(data)
        this.connectors = data;
        this.connectors.forEach(el => {
          let a = data.find(con => con.name == el.name);
          el.stat = a.data.stat;
          el.stat.aPow = JSON.parse(el.stat?.aPow);
          el.stat.rPow = JSON.parse(el.stat?.rPow);
          el.stat.current = JSON.parse(el.stat?.current);
          el.stat.pfactor = JSON.parse(el.stat?.pfactor);
          el.stat.durationCharge = this._aux.subtractDates(new Date(), el.stat.startTimestamp);
        });
        setTimeout(() => {
          this.loadGraphs();
        }, 500);
      })

      this._device.getElementModule(this.device, "reqman").subscribe(data => {
        console.log('%cReqman: ', 'background: green; color: white', data);

        if (data) {
          this.connectors?.forEach(conn => {
            let conData = data.find(con => con.name == conn.name);
            if (conData && conData.data && conData.data.stat) {
              conn.stat.user = conData.data.stat.user;
              conn.stat.source = conData.data.stat.source;
            }
          });
        }
      }, error => {
        console.error('Error fetching data:', error);
      });


    this._device.getElementModule(this.device, "evsm").subscribe(data => {
        console.log(data);
        data.forEach(conn => this.connectorsState[conn.name] = conn.data.stat)
        this.connectors.forEach(el => {
          let a = data.find(con => con.name == el.name);
          el.data.stat = a.data?.stat;
        });
        if (!data[1])
          this.connectors.splice(1, 1);
        console.log(this.connectorsState)
      })
    //new Date().getFullYear()-1+'-'+(new Date().getMonth()+1).toString()+'-'+new Date().getDate()
    this._device.getModuleInfo(this.device, "modulator", "data", { filter: 'month', dateMin: '2021-03-03' }).subscribe(data => {
        console.log(data);
        this.historicData = data;
      })

    this._device.getElementModule(this.device, "mennekes").subscribe(data => {
      if (data) {
        this.connectors?.forEach(conn => {
          let conData = data.find(con => con.name == conn.name);
          if (conData && conData.data !== undefined) {
            conn.mennekes = conData.data;
          }
        });
      }
    });



      //this.modalSuccess.componentInstance.title = this._translate.instant("device_assigned_successfully");

    setTimeout(() => {
      if(this.solarInfo?.cfg?.inversor?.enabled && this.infoModulator.stat.ctxDetected == 0){
        this.transl = this._translate.instant("diag_alert_0");
        this.alerts.push(this.transl);
      }
      else{
        if(this.infoModulator.stat.ctxDetected == 0){
          if(this.solarInfo?.cfg.enabled == 1){
            this.transl = this._translate.instant("diag_alert_1");
            this.alerts.push(this.transl);
          }
          if(this.splData.cfg.splOpMode == 2 && this.splData.cfg.splMode != 2){
            this.transl = this._translate.instant("diag_alert_2");
            this.alerts.push(this.transl);
          }
        }
        if(this.infoModulator.stat.mbusDetected == 0){
          if(this.solarInfo?.cfg.enabled == 1){
            this.transl = this._translate.instant("diag_alert_3");
            this.alerts.push(this.transl);
          }
          if(this.splData.cfg.splOpMode == 1 && this.splData.cfg.splMode == 1){
            this.transl = this._translate.instant("diag_alert_4");
            this.alerts.push(this.transl);
          }
        }
      }
      if(!this.solarInfo?.cfg?.enabled && this.splData.cfg.splMode == 0 && this.infoModulator.stat.ctxDetected == 0 && this.version){
        this.transl = this._translate.instant("diag_alert_5");
        this.alerts.push(this.transl);
      }
      if(!this.solarInfo?.cfg?.enabled && this.splData.cfg.splMode == 0 && this.infoModulator.stat.mbusDetected == 0 && !this.version){
        this.transl = this._translate.instant("diag_alert_5");
        this.alerts.push(this.transl);
      }
      if((this.solarInfo?.cfg?.inversor?.enabled && this.splData.cfg.splMeter != 4) || (this.splData.cfg.splMeter  == 1 && this.solarInfo?.cfg?.enabled )){
        this.transl = this._translate.instant("diag_alert_6");
        this.alerts.push(this.transl);
      }

    }, 1000);
  }

  startRT(context) {
    context._device.setRT(this.device, { period: 1, timeout: 120, status: true })
      .subscribe(data => {
        console.log(data);
        if (!this.deviceConnected)
          this._modal.showSuccess(this._translate.instant("deviceConnectedToServer"), '');
        this.deviceConnected = true;
      }, (err) => {
        if (this.deviceConnected || this.deviceConnected == undefined)
          this._modal.showWarn(this._translate.instant("deviceDisconnectedFromServer"), this._translate.instant("connectionCouldNotBeStablished"));
      })
  }

  ngOnDestroy() {
    //this._device.setUserDevice(null);

    this.subscriptionRouter.unsubscribe();
    this._device.setRT(this.device, { period: 1, timeout: 120, status: false })
      .subscribe(data => {
        console.log(data)
      })

    clearInterval(this.interval);
    clearInterval(this.intervalRT);

    this._ws.close();
  }

  loadGraphs() {
    this.loadPowerChart();
  }

  loadPowerChart() {
    let dataLimit: any = {
      label: "Limit power",
      data: [this.infoModulator?.stat?.limitPower,
            this.infoModulator?.stat?.limitPower],
      borderDash: [5],
      borderWidth: 1,
      borderColor: Colors.dark_red,
      backgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointBackgroundColor: 'transparent',
      labels: [Date.now(), Date.now()]
    };
    let dataTotal: any = {
      label: "TOTAL",
      data: [this.infoModulator?.stat?.totalPower, this.infoModulator?.stat?.totalPower],
      borderColor: Colors.primary,
      borderWidth: 1.5,
      backgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointBackgroundColor: 'transparent',
      labels: [Date.now(), Date.now()]
    };
    let conn1 = this.connectors[0];
    let dataConn;
    dataConn = {
      label: conn1.name,
      data: [conn1.stat.aPow[0] + conn1.stat.aPow[1] + conn1.stat.aPow[2], conn1.stat.aPow[0] + conn1.stat.aPow[1] + conn1.stat.aPow[2]],
      borderColor: Colors.primary_light,
      backgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointBackgroundColor: 'transparent',
      labels: [Date.now(), Date.now()]
    };
    let conn2;
    let dataConn2: any;
    if (this.connectors[1]) {
      conn2 = this.connectors[1];
      dataConn2 = {
        label: conn2.name,
        data: [conn2.stat.aPow[0] + conn2.stat.aPow[1] + conn2.stat.aPow[2], conn2.stat.aPow[0] + conn2.stat.aPow[1] + conn2.stat.aPow[2]],
        borderColor: Colors.blue,
        backgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        labels: [Date.now(), Date.now()]
      };
    };

    let dataFv: any = {
      label: "Solar",
      data: [this.infoModulator?.stat?.fvPower, this.infoModulator?.stat?.fvPower],
      borderColor: 'transparent',
      backgroundColor: Colors.yellow + '33',
      pointBorderColor: 'transparent',
      pointBackgroundColor: 'transparent',
      labels: [Date.now(), Date.now()]
    };

    let dataInst: any = {
      label: "Net",
      data: [this.infoModulator?.stat?.instPower, this.infoModulator?.stat?.instPower],
      borderColor: 'transparent',
      backgroundColor: Colors.blue + '33',
      pointBorderColor: 'transparent',
      pointBackgroundColor: 'transparent',
      labels: [Date.now(), Date.now()]
    };

    let dataHome: any = {
      label: "Home",
      data: [this.infoModulator.stat.homePower, this.infoModulator.stat.homePower],
      borderColor: Colors.accent,
      backgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointBackgroundColor: 'transparent',
      labels: [Date.now(), Date.now()]
    };

    let dataBatt: any = {
      label: "Battery",
      data: [this.infoModulator?.stat?.battEnergy, this.infoModulator?.stat?.battEnergy],
      borderColor: 'transparent',
      backgroundColor: Colors.orange + '33',
      pointBorderColor: 'transparent',
      pointBackgroundColor: 'transparent',
      labels: [Date.now(), Date.now()]
    };
    let datasets = this.infoModulator.stat.fvPower!=null ?
                      conn2 ? [dataConn, dataConn2, dataHome, dataFv, dataInst, dataLimit, dataTotal, dataBatt] : [dataConn, dataHome, dataFv, dataInst, dataLimit, dataTotal, dataBatt]
                      :
                      conn2 ? [dataConn, dataConn2, dataHome, dataLimit, dataTotal] : [dataConn, dataHome, dataLimit, dataTotal]
                      ;

    this.canvasPowers = document.getElementById("graphPowers");
    this.ctxPowers = this.canvasPowers.getContext("2d");
    this.ctxPowers.height = 350;
    this.chartPowers = new Chart(this.ctxPowers, {
      type: 'line',
      data: {
        labels: ["0s"],
        datasets: datasets,
      },
      options: {
        legend: {
          position: 'bottom'
        },

        scales: {
          yAxes: [{
            stacked: false,
            scaleLabel: {
              display: false,
              labelString: 'W',
            },
            ticks: {
              beginAtZero: true,
              //max: this.infoModulator.stat.limitPower + 2000
            }
          }]
        },
      }
    });
  }

  addData(chart, label) {
    if (!chart)
      return;

    let numberOfTags = 15;

    if (chart.data.datasets[0].data.length > numberOfTags) {
      chart.data.datasets[0].labels.splice(0, 1);
      chart.data.datasets[0].data.splice(0, 1);
    }
    if (this.connectors[1]) {
      if (chart.data.datasets[1].data.length > numberOfTags) {
        chart.data.datasets[1].labels.splice(0, 1);
        chart.data.datasets[1].data.splice(0, 1);
      }
      if (chart.data.datasets[2].data.length > numberOfTags) {
        chart.data.datasets[2].labels.splice(0, 1);
        chart.data.datasets[2].data.splice(0, 1);
      }
      if (chart.data.datasets[3].data.length > numberOfTags) {
        chart.data.datasets[3].labels.splice(0, 1);
        chart.data.datasets[3].data.splice(0, 1);
      }
      if (chart.data.datasets[4].data.length > numberOfTags) {
        chart.data.datasets[4].labels.splice(0, 1);
        chart.data.datasets[4].data.splice(0, 1);
      }
      if (chart.data.datasets[5]?.data.length > numberOfTags) {
        chart.data.datasets[5].labels.splice(0, 1);
        chart.data.datasets[5].data.splice(0, 1);
      }
      if (chart.data.datasets[6]?.data.length > numberOfTags) {
        chart.data.datasets[6].labels.splice(0, 1);
        chart.data.datasets[6].data.splice(0, 1);
      }
      if (chart.data.datasets[7]?.data.length > numberOfTags) {
        chart.data.datasets[7].labels.splice(0, 1);
        chart.data.datasets[7].data.splice(0, 1);
      }
    } else {
      if (chart.data.datasets[1].data.length > numberOfTags) {
        chart.data.datasets[1].labels.splice(0, 1);
        chart.data.datasets[1].data.splice(0, 1);
      }
      if (chart.data.datasets[2].data.length > numberOfTags) {
        chart.data.datasets[2].labels.splice(0, 1);
        chart.data.datasets[2].data.splice(0, 1);
      }
      if (chart.data.datasets[3].data.length > numberOfTags) {
        chart.data.datasets[3].labels.splice(0, 1);
        chart.data.datasets[3].data.splice(0, 1);
      }
      if (chart.data.datasets[4]?.data.length > numberOfTags) {
        chart.data.datasets[4].labels.splice(0, 1);
        chart.data.datasets[4].data.splice(0, 1);
      }
      if (chart.data.datasets[5]?.data.length > numberOfTags) {
        chart.data.datasets[5].labels.splice(0, 1);
        chart.data.datasets[5].data.splice(0, 1);
      }
      if (chart.data.datasets[6]?.data.length > numberOfTags) {
        chart.data.datasets[6].labels.splice(0, 1);
        chart.data.datasets[6].data.splice(0, 1);
      }
    }

    chart.options.animation.duration = 0;

    // chart.data.datasets[0].data.push(data.evsePower);
    // chart.data.datasets[1].data.push(data.homePower);
    if (this.connectors[1]) {
      chart.data.datasets[0].data.push(this.connectors[0].stat.aPow[0] + this.connectors[0].stat.aPow[1] + this.connectors[0].stat.aPow[2]);
      chart.data.datasets[1].data.push(this.connectors[1].stat.aPow[0] + this.connectors[1].stat.aPow[1] + this.connectors[1].stat.aPow[2]);
      chart.data.datasets[2].data.push(this.infoModulator.stat.homePower);
      if(this.infoModulator.stat.fvPower!=null){
        chart.data.datasets[3].data.push(this.infoModulator.stat.fvPower);
        chart.data.datasets[4].data.push(this.infoModulator.stat.instPower);
        chart.data.datasets[5].data.push(this.infoModulator.stat.limitPower);
        chart.data.datasets[6].data.push(this.infoModulator.stat.totalPower);
        chart.data.datasets[7].data.push(this.infoModulator.stat.battEnergy);
      } else {
        chart.data.datasets[3].data.push(this.infoModulator.stat.limitPower);
        chart.data.datasets[4].data.push(this.infoModulator.stat.totalPower);
      }

      chart.data.datasets[0].labels.push(label);
      chart.data.datasets[1].labels.push(label);
      chart.data.datasets[2].labels.push(label);
      chart.data.datasets[3].labels.push(label);
      chart.data.datasets[4].labels.push(label);
      if(this.infoModulator.stat.fvPower!=null){
        chart.data.datasets[5].labels.push(label);
        chart.data.datasets[6].labels.push(label);
        chart.data.datasets[7].labels.push(label);
      }
    } else {
      chart.data.datasets[0].data.push(this.connectors[0].stat.aPow[0] + this.connectors[0].stat.aPow[1] + this.connectors[0].stat.aPow[2]);
      chart.data.datasets[1].data.push(this.infoModulator.stat.homePower);

      if(this.infoModulator.stat.fvPower!=null){
        chart.data.datasets[2].data.push(this.infoModulator.stat.fvPower);
        chart.data.datasets[3].data.push(this.infoModulator.stat.instPower);
        chart.data.datasets[4].data.push(this.infoModulator.stat.limitPower);
        chart.data.datasets[5].data.push(this.infoModulator.stat.totalPower);
        chart.data.datasets[6].data.push(this.infoModulator.stat.battEnergy);
      } else {
        chart.data.datasets[2].data.push(this.infoModulator.stat.limitPower);
        chart.data.datasets[3].data.push(this.infoModulator.stat.totalPower);
      }

      chart.data.datasets[0].labels.push(label);
      chart.data.datasets[1].labels.push(label);
      chart.data.datasets[2].labels.push(label);
      chart.data.datasets[3].labels.push(label);

      if(this.infoModulator.stat.fvPower!=null){
        chart.data.datasets[4].labels.push(label);
        chart.data.datasets[5].labels.push(label);
        chart.data.datasets[6].labels.push(label);
      }
    }



    while (chart.data.labels.length > numberOfTags) {
      chart.data.labels.splice(0, 1);
    }
    chart.data.labels.push(this.indexAux);
    chart.data.labels.forEach((label, index) => {
      let diffSec = this._aux.subtractDates(new Date(), chart.data.datasets[0].labels[index]);
      chart.data.labels[index] = diffSec.charAt(0) == '0' ? diffSec.substring(3) : diffSec;
    });

    chart.update();
    this.indexAux++;
  }
  initialiseState() {
    this.rt = null;

    clearInterval(this.interval);

    this.historicData = null;
    this.infoModulator = new infoModulator();
    this.dataMennekes = [];
    this.sysData = null;
    this.splData = null;
    this.num_phases = 3;

    this.loading = true;

    this.error = false;

    this.canvasPowers = null;
    this.ctxPowers = null;
    this.chartPowers = null;

    this.NOW = Date.now();
    this.lastReport = Date.now() - 10000000;
    this.dateDisplay = 'short';
    this.deviceConnected = false;

    this.yourDevice = '';
    this.otherDevice = '';

    this.connectors = [];
    this.connectorsState = {};
    this.allStates = ConnectorState;

    this.rtLoaded = false;
  }

  makeStacked() {
    this.chartPowers.options.scales.yAxes.stacked = !this.chartPowers.options.scales.yAxes.stacked;
    console.log(this.chartPowers.options.scales.yAxes.stacked)
    this.chartPowers.update();
  }

  openAlerts(){
    this.modalAlerts = this._modal.open(AlertsDiagnosisComponent);
    this.modalAlerts.componentInstance.alerts = this.alerts;
  }

  duration(date){

    if (!date.includes('Z')){
      date = new Date(date + " UTC")

    }
    return date
  }


  toFloat(value){
    return parseFloat(value);
  }

}
