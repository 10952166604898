import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DeviceService } from 'src/app/services/devices/device.service';
import { UserService } from 'src/app/services/user/user.service';


@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {

  serial: string = '';
  splMode: number;

  objectDevice: any = {};
  deviceSubtype = "";

  n_phases=1;
  userRole;

  constructor(private _aRoute: ActivatedRoute, public _router: Router, private _device: DeviceService, private _user: UserService) { }

  ngOnInit(): void {

    this.serial = this._aRoute.snapshot.params.serial;

    // this.userRole = sessionStorage.getItem('role') || '';

    this._user.getUserInfo().subscribe((userInfo) => {
      this.userRole = userInfo.role || '';
      });


    this.getSplMode();
    this._device.getDeviceInfo(this.serial)
      .subscribe(data => {
        console.log('%c---' + this.serial + ': ---', 'background: black; color: white; font-size: 20px;', data);
        this.objectDevice.info = data;
        //this._device.setUserDevice(data.user_build);
        this._device.setActiveDeviceInfo(data);
        this._device.getModuleInfo(this.serial, "sys")
          .subscribe(data => {
            console.log(data);
            this.objectDevice.sys = data.stat;
            this.n_phases = data?.stat?.fwv_pot?.startsWith('3') ? data.cfg.phase!=1 ? 3 : 1: 1;
          })
      })

    //this._device.setUserDevice(null);
    //this._device.setActiveDeviceInfo(this.objectDevice);

    this._router.events.subscribe((val) => {
      if ((val instanceof NavigationEnd) && (this.serial != this._aRoute.snapshot.params.serial)) {
        this.getDeviceInfo();
      }
    });
  }

  getDeviceInfo(){
    this.serial = this._aRoute.snapshot.params.serial;
    this.getSplMode();

    this._device.getDeviceInfo(this.serial)
      .subscribe(data => {
        console.log('%c---' + this.serial + ': ---', 'background: black; color: white; font-size: 20px;', data);
        this.objectDevice.info = data;
        this.deviceSubtype = data.sub_type;
        //this._device.setUserDevice(data.user_build);
        this._device.setActiveDeviceInfo(data);
        this._device.getModuleInfo(this.serial, "sys")
          .subscribe(data => {
            console.log(data);
            this.objectDevice.sys = data.stat;
            this.n_phases = data.stat.fwv_pot.startsWith('3') ? data.cfg.phase!=1 ? 3 : 1: 1;
          })
    })
  }

  getSplMode(){
    this._device.getModuleInfo(this.serial, "spl").subscribe((data) => {
      if(data.cfg){
        this.splMode = data.cfg.splMode
      }
    });
  }

  goToLink(serial: string) {

    if (serial[4] === '4') {
      window.open(`https://iot.orbis.com.es/grafana/d/a903724c-ed58-4c41-bcce-bf91ecc51826/uni?orgId=1&refresh=5s&var-serial=${serial}`, "_blank");
    } else {
      window.open(`https://iot.orbis.com.es/grafana/d/a903724c-ed58-4c41-bcce-bf91ecc51826/uni?orgId=1&refresh=5s&var-serial=${serial}`, "_blank");
    }
  }
}
