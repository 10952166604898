import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'undefineds'
})
export class UndefinedsPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if(value != undefined)
      return value;
    else return "";
  }

}
