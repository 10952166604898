export function TestSolar(data, serial, testList){

  var taskCheck;

     //Comprueba si el inversor está habiltado, también esté habilitado la parte solar
     !data.cfg.enabled &&  data.cfg.inversor.enabled ?  taskCheck = false :  taskCheck = true;
     testList.push( {serial: serial, module: 'Solar Manager', test: 'El inversor está habilitado y la configuración solar también', config : JSON.stringify(data.cfg,null,2), result:  taskCheck})

     //Comprueba que si hay habilitada una batería, tiene que estar habilitado el inversor.
     data.cfg.battery && !data.cfg.inversor.enabled ?  taskCheck = false :  taskCheck = true;
     testList.push( {serial: serial, module: 'Solar Manager', test: 'Si la batería está habilitada y el opción del inversor también', config : JSON.stringify(data.cfg,null,2), result:  taskCheck})

     //Comprueba que si está habilitada la descarga de la batería, el modelo de inversor es correcto
     data.cfg.enabled && data.cfg.inversor.batterySaving !=100  &&  ( data.cfg.inversor.model != 3 &&  data.cfg.inversor.model != 11)  ?  taskCheck = false :  taskCheck = true;
     testList.push( {serial: serial, module: 'Solar Manager', test: 'Está activado la descarga de la batería y el modelo de inversor es correcto', config : JSON.stringify(data.cfg,null,2), result:  taskCheck})

}
