import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { ModalSchedulerTaskComponent } from 'src/app/components/ui/modal-scheduler-task/modal-scheduler-task.component';
import { SolarPriorityModalComponent } from 'src/app/components/ui/solar-priority-modal/solar-priority-modal.component';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { compare } from 'compare-versions';

@Component({
  selector: 'app-power-scheduler',
  templateUrl: './power-scheduler.component.html',
  styleUrls: ['./power-scheduler.component.scss']
})
export class PowerSchedulerComponent implements OnInit {

  device: string;
  tasks: any = {};
  powerTasks: any = { weekday: [], weekend: [] };

  originalTasks: any = {};
  newTaskId: number;

  @Input()
  allSettings = false;

  @Input()
  connectors: any[];

  modalAdd: NgbModalRef;

  selectedDays='weekday';

  successMessage: any;
  error: any ='';

  solarCfg: any;
  limitP: any;

  versionNewSchedulers: boolean = false;

  modalLoad: NgbModalRef;
  modalPriority: NgbModalRef;

  constructor(public _dService: DeviceService, private _aRoute: ActivatedRoute, private _modal: ModalService, public _utils: UtilsService) { }

  ngOnInit(): void {
    var deviceInfo = this._dService.getActiveDeviceInfo();
    this.versionNewSchedulers = compare(deviceInfo.firmware_version_name, '7.1.0', '>=');

    this.tasks = {};
    this.powerTasks = { weekday: [], weekend: [] };

    this._aRoute.parent?.parent?.params
      .subscribe((params: Params) => this.device = params['serial']);

      setTimeout(() => {
        this.connectors.forEach(conn => {
          if(conn.data.id!=1) return;
          this._dService.getElementModule(this.device, 'schedman', conn.name)
            .subscribe(data => {
              let t = JSON.parse(data.cfg.tasks);
              if(this.versionNewSchedulers){
                t.forEach(task => {
                  if(task?.initTime?.timeList[0])
                    task.maxPower = task.initTime.timeList[0].maxPower;
                });
              }
              this.tasks['timeTasks']=t.filter(task=>task.type==0);

              t = t.filter(task=>task.type==1);
              t = t.sort((t1, t2)=>t1.initTime.timeList[0].hourMin-t2.initTime.timeList[0].hourMin);
              this.tasks[conn.name]={};

              this.tasks[conn.name]['weekday'] = t.filter(ta=>ta.initTime.weekday==62)||[];
              this.powerTasks['weekday']=this.powerTasks['weekday'].concat(t.filter(ta=>ta.initTime.weekday==62));
              this.tasks[conn.name]['weekend'] = t.filter(ta=>ta.initTime.weekday==65)||[];
              this.powerTasks['weekend']=this.powerTasks['weekend'].concat(this.tasks[conn.name]['weekend']);
              this.newTaskId=this.getFreeIdFromTasks();
              this.originalTasks[conn.name] = JSON.parse(data.cfg.tasks);
            })
        });

        this._dService.getModuleInfo(this.device, 'solar')
          .subscribe(data => {
            this.solarCfg = data.cfg;
          })

        this._dService.getModuleInfo(this.device, 'modulator')
          .subscribe(data => {
            this.limitP = data.cfg.limitPower;
          })

      }, 500);
  }

  openAddTask(){
    this.modalAdd=this._modal.open(ModalSchedulerTaskComponent);
    this.modalAdd.componentInstance.id=this.newTaskId;
    this.modalAdd.componentInstance.type=1;
    this.modalAdd.componentInstance.weekday=this.selectedDays == 'weekday' ? 62 : 65;
    this.modalAdd.componentInstance.limitP=this.limitP;

    this.modalAdd.result.then((task) => {
      // let cName = this.connectors.find(c=>c.name == 'mennekes') != undefined ? cName ? 'mennekes' : this.connectors.find(c=>c.name == 'mennekes1') != undefined
      let s = this.selectedDays=='weekday' ? 62 : 65;
      this.powerTasks[this.selectedDays].push(task);
      // this.adjustPowerTasks(this.powerTasks['weekday']);
      // let t = [...this.powerTasks['weekday']];
      // this.adjustPowerTasks(this.powerTasks['weekend']);
      // this.powerTasks['weekend'].forEach(element => {
      //   t.push(element);
      // })
      // this.tasks['timeTasks'].forEach(element => {
      //   t.push(element);
      // })

      this.newTaskId=this.getFreeIdFromTasks();
      // let element = this.connectors.find(e=>e.name=='mennekes1') ? 'mennekes1' : this.connectors.find(e=>e.name=='mennekes2') ? 'mennekes2' : 'mennekes';
      // this.saveConfig(element, t);
    }, (reason) => {
      // on dismiss
    });
  }

  saveConfig(tasks){
    if(this.versionNewSchedulers){
      tasks.forEach(task => {
        if(task?.initTime?.timeList[0])
          task.initTime.timeList[0].maxPower = task.maxPower;
        delete(task.initTime.timeList[0]._initLocaltime);
        delete(task.initTime.timeList[0]._endLocaltime);
        delete(task.initTime.timeList[0].phases);
        delete(task.maxPower);
        delete(task.validFrom);
        delete(task.validTo);
        delete(task.minCurrent);
        delete(task.chargeRelated);
      });
      this._dService.setModuleCfg(this.device, { tasks }, 'schedman' )
      .subscribe(data=>{
        this.writeTaskPostSave()
      }, err => {
        this.error = err.error.message;
        this.modalLoad?.close();
        setTimeout(() => {this.error = '';}, 10000);
      })
    }
    else{
      this._dService.setModuleElement(this.device, 'schedman', { tasks })
      .subscribe(data=>{
        this.writeTaskPostSave()
      }, err => {
        this.error = err.error.message;
        this.modalLoad?.close();
        setTimeout(() => {this.error = '';}, 10000);
      })
    }
  }

  writeTaskPostSave(){
    this.successMessage = true;
    this.modalLoad?.close();
    this.ngOnInit();
    setTimeout(() => {
      this.successMessage = false;
    }, 10000);
  }



  adjustPowerTasks(tasks){
    tasks = tasks.sort((t1,t2)=>t1.initTime.timeList[0].hourMin-t2.initTime.timeList[0].hourMin);
    tasks.forEach((t, index) => {
      if(index==(tasks.length-1)){
        t.initTime.timeList[0].duration = (1440-t.initTime.timeList[0].hourMin) + tasks[0].initTime.timeList[0].hourMin;
      } else {
        t.initTime.timeList[0].duration = tasks[index+1].initTime.timeList[0].hourMin-t.initTime.timeList[0].hourMin;
      }

    });
  }

  deleteTask(id){
    var data = {
      tasks: [{id, type:1}]
    }

    if(this.versionNewSchedulers){
      this._dService.deleteModule(this.device, 'schedman', data)
      .subscribe(data=>{
        this.writeTaskPostDelete(id);
      }, err => {
        this.error = err.error.message;
        setTimeout(() => {this.error = '';}, 10000);
      })
    }
    else{
      let element = this.connectors.find(e=>e.name=='mennekes1') ? 'mennekes1' : this.connectors.find(e=>e.name=='mennekes2') ? 'mennekes2' : 'mennekes';

      this._dService.deleteModuleElement(this.device, 'schedman', data, element)
      .subscribe(data=>{
        this.writeTaskPostDelete(id);
      }, err => {
        this.error = err.error.message;
        setTimeout(() => {this.error = '';}, 10000);
      })
    }
  }

  writeTaskPostDelete(id){
    this.successMessage = true;
    this.powerTasks[this.selectedDays]=this.powerTasks[this.selectedDays].filter(t=>t.id!=id);
    this.adjustPowerTasks(this.powerTasks['weekday']);
    let t = [...this.powerTasks['weekday']];
    this.adjustPowerTasks(this.powerTasks['weekend']);
    this.powerTasks['weekend'].forEach(element => {
      t.push(element);
    })
    this.saveConfig(t);

    setTimeout(() => {
      this.successMessage = false;
    }, 10000);
  }


  getFreeIdFromTasks(){
    let idTaskDisp = 1;
    let found = false;

    let ids: any[] = [];

      this.powerTasks['weekday'].forEach(t => {
        ids.push(t.id);
      });

      this.powerTasks['weekend'].forEach(t => {
        ids.push(t.id);
      });

    if(!found) idTaskDisp++;

    if(this.tasks['timeTasks'])
        this.tasks['timeTasks'].forEach(t => {
          ids.push(t.id);
        });

    while(!found){
      if(ids.includes(idTaskDisp)) idTaskDisp++;
      else found=true;
    }

    return idTaskDisp;
  }

  openModalPriority(task) {
    this.modalPriority = this._modal.open(SolarPriorityModalComponent);
    this.modalPriority.componentInstance.task = task;
    this.modalPriority.componentInstance.limitP = this.limitP;

    let a;
    if(task.solarPriority==0){
      a='green';
    } else if (task.solarPriority==1)
      a='ac';
    else a='hybrid';

    this.modalPriority.componentInstance.modeSelected = a;
    this.modalPriority.result.then((mode) => {
      if(mode=='green') {
        task.maxPower = 0;
        task.solarPriority = 0;
      } else if(mode=='ac') {
        task.maxPower = this.limitP;
        task.solarPriority = 1;
      } else {
        task.maxPower = this.limitP;
        task.solarPriority = 2;
      }
    }, (reason) => {
      // on dismiss
    });
  }

  save(){
    if(!this.checkPowerTasks(this.powerTasks['weekday'])) {
      this.error='Las tareas deben cubrir todo el día y no estar solapadas';
      setTimeout(() => {
        this.error = '';
      }, 10000);
      return
    };
    let t = [...this.powerTasks['weekday']];
    if(!this.checkPowerTasks(this.powerTasks['weekend'])) {
      this.error='Las tareas deben cubrir todo el día y no estar solapadas';
      setTimeout(() => {
        this.error = '';
      }, 10000);
      return
    };
    this.modalLoad = this._modal.open(LoadingPopupComponent);
    t=t.concat(this.powerTasks['weekend']);

    this.saveConfig(t);
  }

  checkPriority(task){

    if(task.solarPriority!=1){
      if(task.maxPower==0)
        task.solarPriority = 0;
      else
        task.solarPriority = 2;
    }
  }

  checkPowerTasks(tasks){
    if(tasks.length==0) return true;
    tasks = tasks.sort((t1,t2)=>t1.initTime.timeList[0].hourMin-t2.initTime.timeList[0].hourMin);
    let sum = 0;
    tasks.forEach((t, i):any=> {
      sum+=t.initTime.timeList[0].duration;

      if(i<(tasks.length-1))
        if(tasks[i+1].initTime.timeList[0].hourMin<(t.initTime.timeList[0].hourMin+t.initTime.timeList[0].duration)) return false;
      else if(tasks[0].initTime.timeList[0].hourMin<(-1440+t.initTime.timeList[0].hourMin+t.initTime.timeList[0].duration)) return false;
    });

    if(sum!=1440) return false; else return true;
  }

  openEditTask(task){
    let t = {...task};
    this.modalAdd=this._modal.open(ModalSchedulerTaskComponent);
    this.modalAdd.componentInstance.type=1;
    this.modalAdd.componentInstance.weekday=this.selectedDays == 'weekday' ? 62 : 65;
    this.modalAdd.componentInstance.limitP=this.limitP;
    this.modalAdd.componentInstance.task=task;

    this.modalAdd.result.then((task) => {
    }, (reason) => {
      task = t;
    });
  }

}
