import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AdminLayoutModule } from './layouts/admin-layout/admin-layout.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotComponent } from './pages/recovery/forgot/forgot.component';
import { RecoveryComponent } from './pages/recovery/recovery.component';
import { RegisterComponent } from './pages/register/register.component';
import { AuthGuard } from './services/guards/auth.guard';
import { DeveloperLayoutComponent } from './layouts/developer-layout/developer-layout.component';
import { DeveloperLayoutModule } from './layouts/developer-layout/developer-layout.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: '#/recover',
    component: RecoveryComponent
  },
  {
    path: 'recover',
    component: RecoveryComponent
  },
  {
    path: 'forgot',
    component: ForgotComponent
  },
  {
    path: 'dashboard',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => AdminLayoutModule,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'developers',
    component: DeveloperLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => DeveloperLayoutModule,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(router: Router) {
    router.events
    .subscribe(e => {
      if (e instanceof NavigationStart) {
        if(e.url != '/login' && e.url != '/' ){
          localStorage.setItem('url_before_redirect', e.url);
        }
      }
    });
  }
 }
