

export default function calculoZoom( locations  ){

// console.log('dentro de la funcion cálculo ', locations.lenght);
// console.log('dentro de la funcion cálculo ', locations[0]);

  var distancia = 0;
  var maxDist = 0;

  for (let index = 0; index < locations.length; index++) {

    for (let index2 = 0; index2 < locations.length; index2++) {

      distancia = getDistanciaMetros( locations[index].position.lat,  locations[index].position.lng,  locations[index2].position.lat, locations[index2].position.lng)
      // console.log('distancia medida en metro ', distancia);
      // console.log('max dist', maxDist);


      if (distancia > maxDist)
        maxDist = distancia;
    }
  }
  // console.log(' maxima distanacia ', maxDist);


  if (maxDist < 0.2 )
    return 16;
  if (maxDist < 0.4 )
    return 15;
  if (maxDist < 0.6 )
    return 14;
  if (maxDist < 5 )
    return 13;
  if (maxDist < 10 )
    return 12;
  if (maxDist < 15 )
    return 10;
  if (maxDist < 25 )
    return 9;
  if (maxDist < 50 )
    return 8;
  if (maxDist < 200 )
    return 7;
  if (maxDist < 400 )
    return 6;
  return 6;

}


function getDistanciaMetros(lat1,lon1,lat2,lon2)
{

  var rad = function(x) {return x*Math.PI/180;}
  var R = 6378.137; //Radio de la tierra en km
  var dLat = rad( lat2 - lat1 );
  var dLong = rad( lon2 - lon1 );
  var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(rad(lat1)) *
  Math.cos(rad(lat2)) * Math.sin(dLong/2) * Math.sin(dLong/2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

  //aquí obtienes la distancia en metros por la conversion 1Km =1000m
  // var d = R * c * 1000;
  var d = R * c ;
  return d ;
}

