import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';


@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent implements OnInit {

  @Input()
  description: string;

  @Input()
  title: string;

  @Input()
  customButtonText: string;

  @Input()
  customButtonClass: string;

  @Input()
  customOkButtonClass: string;

  @Input()
  customFunction: () => void;

  constructor(private activeModal: NgbActiveModal, private _router: Router) { }

  ngOnInit(): void {
  }

  proceed(){
    switch (this.title) {
      case 'Recovery email':
        this.activeModal.close();
        this._router.navigate(['/login']);
        break;

      default:
    }
  }

  close(){
    this.activeModal.close();
  }

  executeCustomFunction() {
    if (this.customFunction) {
      this.customFunction();
    }
  }
}
