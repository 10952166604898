<div class="all-Width">
    <sidebar-cmp [userRole]="userRole" [userSubs]="userSubscriptions"></sidebar-cmp>
    <div id="main">
      <navbar-cmp [userName]="userName" [userEmail]="userEmail" [userRole]="userRole"></navbar-cmp>
      <div class="d-flex contenido">
        <main class="dashboard">
          <router-outlet></router-outlet>
        </main>
      </div>
      <app-footer></app-footer>
    </div>
</div>
