import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { isIPv4 } from 'is-ip';
import * as _ from 'lodash';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { compare } from 'compare-versions';

@Component({
  selector: 'app-power',
  templateUrl: './power.component.html',
  styleUrls: ['./power.component.scss']
})
export class PowerComponent implements OnInit {

  device = '';

  loading = false;

  showAdvanced: boolean = false;

  @Input()
  allSettings = false;

  body = {
    limitPower: 0,
    limitPowerByPhase: [0, 0, 0],
    switchEnabled: -1,
    unswitchCurrent: 0

  }

  bodyMinP = {
    minPower: 0
  }

  ampacity;
  originalAmpacity;

  solarOption;
  selectSplInterface;

  changes = {
    solar: false,
    spl: false,
    limitP: false,
    minP: false,
    ampacity: false,
    ampacityConn: false,
    rotationPhases: false,
    switchEnabled: false,
    unswitchCurrent : false,
  }

  successMessage = {
    spl: false,
    solar: false,
    limitP: false,
    minP: false,
    ampacity: false,
    rotationPhases: false
  };

  error = {
    spl : '',
    solar: '',
    limitP: '',
    minP: '',
    ampacity: '',
    unswitchCurrent: ''

  };

  collapsibles = {
    vehicle: false,
    chargerLimits: false,
    phaseRotation: false,
  }

  originalData: any = {};
  changesMade = false;
  modalLoad: NgbModalRef;

  splEnabled=false;
  splCfg:any;
  originalSPL:any;
  originalSolar:any;
  solarCfg: any;
  inversorParsed: any;
  deviceInfo: any;
  versionInversorCompatible: boolean = false;
  versionSPLInterfaceCompatible: boolean = false;
  solarCfgTriphasic = false;

  num_phases;

  focus='';

  @Input()
  connectors: any;

  connectorsMennekes: any[] = [];
  originalConnectorsMennekes: any[] = [];
  rotationPhases: any[] = [
    { name: 'Default (RST)', value: 0 },
    { name: 'TRS', value: 1 },
    { name: 'STR', value: 2 }
  ];
  inverters = [
    {label: 'INGETEAM - INGECON SUN STORAGE 1PLAY TLM (general profile)', value: 1},
    {label: 'INGETEAM - INGECON SUN STORAGE 1PLAY TLM (EV profile)', value: 2},
    {label: 'HUAWEI - SUN2000-(2KTL-6KTL)-L1', value: 3},
  ]

  origninalUnswitchCurrent: number;
  unswitchCurrent : number;



  constructor(public _dService: DeviceService, private _aRoute: ActivatedRoute, private _modal: ModalService) { }

  ngOnInit(): void {
    this._aRoute.parent?.parent?.params
      .subscribe((params: Params) => this.device = params['serial']);

    this._dService.getModuleInfo(this.device, 'modulator')
      .subscribe(data => {
        this.body.limitPower = data.cfg?.limitPower;
        if(data.cfg?.limitPowerByPhase){
          this.body.limitPowerByPhase = JSON.parse(data.cfg?.limitPowerByPhase);
          this.body.switchEnabled = data.cfg?.switchEnabled;
          this.body.unswitchCurrent = data.cfg?.unswitchCurrent;
        }
        this.origninalUnswitchCurrent = Math.floor(data.cfg.unswitchCurrent/1000*230);
        this.unswitchCurrent=  this.origninalUnswitchCurrent;
        this.originalData.limitP = _.cloneDeep(this.body)
      });

    this._dService.getDeviceInfo(this.device)
      .subscribe(data => {
        this.deviceInfo = data;
        this.versionInversorCompatible = compare(this.deviceInfo.firmware_version_name, '6.2.23', '>=');
        this.versionSPLInterfaceCompatible = compare(this.deviceInfo.firmware_version_name, '6.2.31', '>=');
      })

    this._dService.getModuleInfo(this.device, 'spl')
      .subscribe(data => {
        data.cfg.splLimitPowerByPhase=JSON.parse(data.cfg.splLimitPowerByPhase);
        this.splCfg = data.cfg;
        if(this.splCfg?.splInterface > 0)
          this.selectSplInterface = this.splCfg.splInterface;
        else
          this.selectSplInterface =1;
        this.originalSPL = _.cloneDeep(data.cfg);
      })

    this._dService.getModuleInfo(this.device, 'solar')
      .subscribe(data => {
        this.solarCfg = data.cfg;
        this.inversorParsed = this.parseInversorData(data.cfg.inversor);
        this.originalSolar = {...data.cfg};
        if(this.solarCfg.type >= 30)
          this.solarCfgTriphasic = true;
        else
          this.solarCfgTriphasic = false;
        if(!this.solarCfg.type){
          this.solarCfg.type=this.solarCfgTriphasic?32:12;
        }
        this.solarOption=this.solarCfg.type==30||this.solarCfg.type==10 ? 2 : this.solarCfg.type==31||this.solarCfg.type==11 ? 3 : 1
      })

    this._dService.getModuleInfo(this.device, "sys")
      .subscribe(data => {
        this.ampacity=data.cfg.ampacity;
        this.originalAmpacity=data.cfg.ampacity;
        this.num_phases = data.stat.fwv_pot.startsWith('3') ? data.cfg.phase!=1 ? 3 : 1: 1;
      })

    setTimeout(() => {
      this._dService.getElementModule(this.device, 'mennekes')
      .subscribe(data => {
        this.connectorsMennekes = [];
        data.forEach(element => {
          if(element.name.startsWith('mennekes')){
            element.data.cfg.limitPower = Math.floor(element.data.cfg.limitPower / (23 * this.num_phases))/10;
            this.connectorsMennekes.push(element);
            this.originalConnectorsMennekes.push(_.cloneDeep(element));
            this.bodyMinP.minPower = Math.floor(element.data.cfg.minPower / (23 * this.num_phases))/10;
            this.originalData.minP = { ...this.bodyMinP }
          }
        });
      })
    }, 500);
  }


  savePower() {
    this.modalLoad = this._modal.open(LoadingPopupComponent);
    this._dService.setModuleCfg(this.device, this.body, 'modulator')
      .subscribe(data => {
        this.modalLoad.close();
        this.successMessage.limitP = true;
        setTimeout(() => {
          this.successMessage.limitP = false;
        }, 10000);
      }, err => {
        console.error(err)
        this.error.limitP = err.error.message;
        setTimeout(() => {
          this.error.limitP = '';
        }, 10000);
        this.modalLoad.close();
      })
  }

  saveMinP() {
    this.modalLoad = this._modal.open(LoadingPopupComponent);
    let minPower = this.bodyMinP.minPower*230*this.num_phases;

    this._dService.setModuleElemData(this.device, 'mennekes', {minPower: minPower})
      .subscribe(data => {
        this.modalLoad.close();
        this.successMessage.minP = true;
        setTimeout(() => {
          this.successMessage.minP = false;
        }, 10000);
      }, err => {
        console.error(err)
        this.error.minP = err.error.message;
        setTimeout(() => {
          this.error.minP = '';
        }, 10000);
        this.modalLoad.close();
      })

  }

  saveElement() {
    if(!this.changes.ampacityConn && !this.changes.rotationPhases)
      return;

    var modalLoad = this._modal.open(LoadingPopupComponent);

    this.connectors.forEach((conn, i) => {
      if(!conn.name.startsWith('mennekes')) return;

      setTimeout(() => {
        var data={};

        if(this.changes.ampacityConn){
          data["limitPower"] = Math.floor(this.connectorsMennekes.find(c=>c.name==conn.name).data.cfg.limitPower*230*this.num_phases);
        }
        if(this.changes.rotationPhases){
          data["phaseRotation"] = parseInt(this.connectorsMennekes.find(c=>c.name==conn.name).data.cfg.phaseRotation);
        }

        this._dService.setModuleElemData(this.device, 'mennekes', data, conn.name)
          .subscribe(data => {
            modalLoad.close();
            if(this.changes.ampacityConn)
              this.successMessage.ampacity = true;
            if(this.changes.rotationPhases)
              this.successMessage.rotationPhases = true;
            setTimeout(() => {
              if(this.changes.ampacityConn)
                this.successMessage.ampacity = false;
              if(this.changes.rotationPhases)
                this.successMessage.rotationPhases = false;
            }, 10000);
          }, err => {
            console.error(err)
            this.error.ampacity = err.error.message;
            setTimeout(() => {
              this.error.ampacity = '';
            }, 10000);
            modalLoad.close();
          })
      }, 500*i);
    });
  }

  saveSPL() {
    this.modalLoad = this._modal.open(LoadingPopupComponent);
    console.log(this.selectSplInterface)
    if(this.splCfg.splMode > 0)
      this.splCfg.splInterface=parseInt(this.selectSplInterface);
    else
      this.splCfg.splInterface=0;
    this._dService.setModuleCfg(this.device, this.splCfg, 'spl')
      .subscribe(data => {
        this.modalLoad.close();
        this.successMessage.spl = true;
        setTimeout(() => {
          this.successMessage.spl = false;
        }, 10000);
      }, err => {
        console.error(err)
        this.error.spl = err.error.message;
        setTimeout(() => {
          this.error.spl = '';
        }, 10000);
        this.modalLoad.close();
      })
  }

  saveSolar() {
    this.modalLoad = this._modal.open(LoadingPopupComponent);
    if(this.inversorParsed.enabled)
      this.solarCfg.type=this.solarCfgTriphasic?32:12;

    this._dService.setModuleCfg(this.device, this.solarCfg, 'solar')
      .subscribe(data => {
        this.modalLoad.close();
        this.successMessage.solar = true;
        setTimeout(() => {
          this.successMessage.solar = false;
        }, 10000);
      }, err => {
        console.error(err)
        this.error.solar = err.error.message;
        setTimeout(() => {
          this.error.solar = '';
        }, 10000);
        this.modalLoad.close();
      })
  }

  saveAmpacity() {
    if(!this.changes.ampacity){
      this.saveElement();
      return;
    }
    var modalLoad = this._modal.open(LoadingPopupComponent);
    this._dService.setModuleCfg(this.device, {ampacity:this.ampacity}, 'sys')
      .subscribe(data => {
        modalLoad.close();
        this.successMessage.ampacity = true;
        setTimeout(() => {
          this.successMessage.ampacity = false;
        }, 10000);
      }, err => {
        console.error(err)
        this.error.ampacity = err.error.message;
        setTimeout(() => {
          this.error.ampacity = '';
        }, 10000);
        modalLoad.close();
      });

    setTimeout(()=>{this.saveElement()},500);
  }

  checkChangesUnswitchCurrent(){
      if (this.unswitchCurrent > 7360 ) this.unswitchCurrent = 7360;
      if (this.unswitchCurrent < 4140 ) this.unswitchCurrent = 4140;

      if (this.origninalUnswitchCurrent != this.unswitchCurrent){
        this.changes.unswitchCurrent = true;
        this.body.unswitchCurrent =  Math.floor(this.unswitchCurrent / 230 * 1000 ) ;
      }else{
        this.changes.unswitchCurrent = false
      }
  }

  checkChangesLimit(){
    this.changes.limitP = this._dService.checkChangesSettings(this.originalData.limitP, this.body);
    //If has changes, three values has to be the same
    if(this.changes.limitP) {
      for (let i = 0; i < this.body.limitPowerByPhase.length; i++) {
        this.body.limitPowerByPhase[i] = this.body.limitPowerByPhase[0];
      }
    }
    this.changes.limitP = this._dService.checkChangesSettings(this.originalData.limitP, this.body);
  }
  checkChangesMin(){
    this.changes.minP = this._dService.checkChangesSettings(this.originalData.minP.minPower, this.bodyMinP.minPower);
  }

  checkChangesSolar(){
    let solarChanges = this._dService.checkChangesSettings(this.originalSolar, this.solarCfg);
    let inversorChanges = this._dService.checkChangesSettings(this.inversorParsed, this.solarCfg.inversor);
    this.inversorParsed.model = parseInt(this.inversorParsed.model);
    if(inversorChanges){
      this.inversorParsed.model = parseInt(this.inversorParsed.model);
      if(isIPv4(this.inversorParsed.inversorIP)){
        this.error.solar = '';
        this.solarCfg.inversor = this.inversorParsed;
      }else {
        this.error.solar = "Dirección IP inválida";
        setTimeout(() => {
          this.error.solar = '';
        }, 5000);
        return;
      }
    }
    this.changes.solar = solarChanges || inversorChanges;
  }

  checkChangesSPL(){
    var tmpSplInterface = this.splCfg.splInterface==0?1:this.splCfg.splInterface;
    this.changes.spl = (this._dService.checkChangesSettings(this.originalSPL, this.splCfg) || this.selectSplInterface != tmpSplInterface);
  }

  checkChangesAmpacity(){
    this.changes.ampacity = this.originalAmpacity!=this.ampacity;
  }

  checkChangesAmpacityConn(){
    this.changes.ampacityConn = false;
    this.connectorsMennekes.forEach(c=>{
      if(!this.changes.ampacityConn)
        this.changes.ampacityConn = c.data.cfg.limitPower!=this.originalConnectorsMennekes.find(co=>co.name==c.name).data.cfg.limitPower;
    })
  }

  checkChangesRotation(){
    this.changes.rotationPhases = false;
    this.connectorsMennekes.forEach(c=>{
      if(!this.changes.rotationPhases)
      this.changes.rotationPhases = c.data.cfg.phaseRotation!=this.originalConnectorsMennekes.find(co=>co.name==c.name).data.cfg.phaseRotation;
    })
  }

  checkSwitchEnabled(){
    this.changes.switchEnabled = this.originalData.limitP.switchEnabled != this.body.switchEnabled;
  }

  setFocus(foc){
    this.focus=foc;
  }

  changeSolarOption(){
    switch(this.solarOption.toString()){
      case "1":
        this.solarCfg.type=this.solarCfgTriphasic?32:12;
        break;
      case "2":
        this.solarCfg.type=this.solarCfgTriphasic?30:10;
        break;
      case "3":
        this.solarCfg.type=this.solarCfgTriphasic?31:11;
        break;
    }
  }

  setAdvanced(){
    this.showAdvanced=!this.showAdvanced;
  }

  parseInversorData(data){
    if(data){
        if(typeof data === "string"){
            return JSON.parse(data);
        }else{
            return data;
        }
    }
  }

}
