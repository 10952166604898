import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import { FilterDevicesPipe } from 'src/app/services/pipes/filter/filter-devices.pipe';
import { FilterGroupsPipe } from 'src/app/services/pipes/filter/filter-groups.pipe';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        TranslateModule,
        MatTreeModule,
        MatIconModule
    ],
    declarations: [ SidebarComponent, FilterDevicesPipe, FilterGroupsPipe ],
    exports: [ SidebarComponent, TranslateModule ]
})

export class SidebarModule {
}
