import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-connectivity',
  templateUrl: './connectivity.component.html',
  styleUrls: ['./connectivity.component.scss']
})
export class ConnectivityComponent implements OnInit {

  device='';

  loading = false;

  @Input()
  allSettings=false;

  body={
    staEssid: '',
    staPasswd: '********'
  }

  originalData: any;
  changesMade=false;
  successMessage: boolean = false;
  error: string = '';
  modalLoad: NgbModalRef;

  constructor(public _dService: DeviceService, private _aRoute: ActivatedRoute, private _modal: ModalService) { }

  ngOnInit(): void {
    this._aRoute.parent?.parent?.params
    .subscribe((params: Params) => this.device = params['serial']);

    this._dService.getModuleInfo(this.device, 'network')
      .subscribe(data=>{
        console.log(data);
        this.body.staEssid=data.cfg?.staEssid;
        if(!data.cfg)
          this.body.staPasswd='';
        this.originalData={ ...this.body };
        //this.body.staPasswd=data.cfg.staPasswd;
      })
  }

  save(){
    this.modalLoad=this._modal.open(LoadingPopupComponent);

    this._dService.setModuleCfg(this.device, this.body, 'network')
      .subscribe(data=>{
        console.log(data);
        this.modalLoad.close();
        this.successMessage=true;
        setTimeout(() => {
          this.successMessage=false;
        }, 10000);
      }, err=>{
        console.error(err)
        this.error=err.error.message;
        setTimeout(() => {
          this.error='';
        }, 10000);
        this.modalLoad.close();
      })
  }

}
