import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-solar-priority-modal',
  templateUrl: './solar-priority-modal.component.html',
  styleUrls: ['./solar-priority-modal.component.scss']
})
export class SolarPriorityModalComponent implements OnInit {

  @Input()
  task: any;

  @Input()
  limitP: any;

  @Input()
  modeSelected: any;

  constructor(private _activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    
  }

  selectModeCard(mode){
    this.modeSelected=mode;
  }

  close(){
    this._activeModal.close(this.modeSelected);
  }

}
