import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss']
})
export class NameComponent implements OnInit {

  @Input()
  allSettings=false;

  device: string = '';

  successMessage: boolean = false;
  error: string= '';

  body: any = {};


  originalData: any;
  changesMade=false;
  modalLoad: NgbModalRef;

  constructor(private _aRoute: ActivatedRoute, public _dService: DeviceService, private _modal: ModalService) { }

  ngOnInit(): void {
    this._aRoute.parent?.parent?.params
    .subscribe((params: Params) => this.device = params['serial']);

    this._dService.getDeviceInfo(this.device)
      .subscribe(data=>{
        this.body = data;
        this.body.name = data.name;
        data.alias == null ? this.body.alias = '' : this.body.alias = data.alias;
        data.description == null ? this.body.description = '' : this.body.description = data.description;
        this.originalData = {...data};
      })
  }

  save(){
    this.modalLoad=this._modal.open(LoadingPopupComponent);

    const body = {
      name: this.body.name,
      alias: this.body.alias,
      description: this.body.description
    }

    this._dService.setDeviceInfo(this.device, body).subscribe ((data)=>{
      console.log(data);
      this.modalLoad.close();
      this.successMessage = true ;
      setTimeout(() => {
              this.successMessage=false;
            }, 5000);
    }, (err => {
      console.error(err)
          this.error=err.error.message;
          setTimeout(() => {
            this.error='';
          }, 10000);
          this.modalLoad.close();
    })
    )
  }

  restore(){
    this.body.name = this.originalData.name;
    this.body.alias = this.originalData.alias;
    this.body.description = this.originalData.description;
    this.changesMade = false;
  }
}
