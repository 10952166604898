import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CustomModalComponent } from 'src/app/components/ui/custom-modal/custom-modal.component';
import { ErrorModalComponent } from 'src/app/components/ui/error-modal/error-modal.component';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  email: string = '';

  modalLoad: NgbModalRef;
  errorModal: NgbModalRef;
  modalOk: NgbModalRef;

  rePass='';
  error=false;
  errorPasswords=false;


  token: string|undefined;

  captchaRef = undefined;

  constructor(private _router: Router, private _modal: ModalService, private _translate: TranslateService, private _auth: AuthService) {
    this.token = undefined;
  }

  ngOnInit(): void {
  }

  async checkRecaptcha(event){
    this.token = event;
    if(this.token){
      this.recover();
    }
  }

  recover(){
    this.modalLoad=this._modal.open(LoadingPopupComponent);

      this._auth.resetPassword(this.email)
      .then(data=>{
        this.modalLoad.close();
        this.modalOk = this._modal.open(CustomModalComponent);
        this.modalOk.componentInstance.title = this._translate.instant('recoverEmail_title');
        this.modalOk.componentInstance.description = this._translate.instant('recoverEmail_sent');

        this._router.navigate(['/login']);
      }, err=>{
        this.modalLoad.close();
        this.errorModal = this._modal.open(ErrorModalComponent);
        this.errorModal.componentInstance.description = this._translate.instant("recoverEmail_error");
      })

  }

}
