<div style="height: fit-content ; min-height: 850px; width: 100%; ">


  <div class="ps-5 bg-primary" style="min-height: 100px;">
    <nav aria-label="breadcrumb " class="ps-1">
      <ol class="breadcrumb" style=" width: fit-content;">
        <li *ngFor="let item of breadCrumbs; let i = index" class="breadcrumb-item" style="padding: 10px;"
          [class.active]="i === breadCrumbs.length - 1">

          <div *ngIf="i !== breadCrumbs.length - 1" style="cursor: pointer;" (click)="updateBreadCrums(item.alias, i)">

            <ng-container *ngIf="item.name === 'home' ; else normalBreadcrumb">
              <i class="fas fa-home text-white" (click)="home()"></i>
            </ng-container>
            <ng-template #normalBreadcrumb>
              <div class="text-white">
                {{ item.name }}
              </div>
            </ng-template>

          </div>

          <span *ngIf="i === breadCrumbs.length - 1 && item.name !='home'" class="text-white">{{ item.name }}</span>
          <span *ngIf="i === breadCrumbs.length - 1 && item.name ==='home'" ><i class="fas fa-home" (click)="home()"></i></span>
        </li>
      </ol>
    </nav>


    <div class="m-1  d-flex justify-content-between align-items-center " (click)="infoGroup(data.alias)">
      <div class="text-white fs-4">
        {{ data.name}}&nbsp;

        <span *ngIf="data.role == 'all'"> <i class="far fa-edit"></i> </span>
        <span *ngIf="data.role == 'owner'"> <i class="fas fa-user-shield"></i> </span>
        <span *ngIf="data.role == 'write'"> <i class="fas fa-pen"></i> </span>
        <span *ngIf="data.role == 'read'"> <i class="fas fa-book-open"></i> </span>
      </div>
      <div class="d-flex justify-content-between align-items-center pe-3">
        <button class="button-main-group text-white" (click)="infoGroup(data.alias)">
          <i class="far fa-eye"></i>
        </button>
        <button class="button-main-group text-white" (click)="viewGroup(data.alias)">
          <i class="far fa-chart-bar"></i>
        </button>
        <button class="nav-link text-white btn btn-light text-dark" ><i>SPL</i></button>

        <button class="button-main-group text-white" (click)="detailGroup(data.alias)"> <i class="fa fa-gear"></i>
        </button>
      </div>
    </div>
  </div>


  <div class="d-flex justify-content-center " *ngIf="loading">
    <div class="spinner-border  " role="status" style="height: 100px; width: 100px;margin-top: 10%; ">
      <span class="visually-hidden"></span>
    </div>
  </div>

  <div class="card  mt-4 ms-3 me-3" *ngIf="!loading">
    <div class="card-header">
      <h5 class="text-primary" > {{"configuration" | translate}} SPL</h5>
      <!-- <div class="fs-4">{{data?.name}} </div>
    <div  class="text-muted" style="cursor: pointer;" (click)="close()" > <i class="fas fa-times"></i></div> -->
    </div>

    <div class="card-body row p-1 pl-5">
      <div class="row">
        <div class="col-9 ps-4 d-flex justify-content-center align-items-center">
          <div class="row col-12 mb-3 mt-3">
            <div class="text-center ">{{"master" | translate}} <h3> {{master}} </h3>
            </div>
            <div class="form-group row d-flex mt-3">
              <label class="col-form-label col-sm-4 col-6">{{"interface" | translate}}:</label>
              <div class="col-sm-4 d-flex align-items-center">
                <select class="form-select" name="splInterface" [(ngModel)]="interface" [disabled]="master === ''">
                  <option value=1>RS485</option>
                  <option value=3>{{"networkInterface" | translate}}</option>
                </select>
              </div>
            </div>

            <div *ngIf="interface == 3">
              <div class="form-group row d-flex mt-3">
                <label class="col-form-label col-sm-4">{{"splIp" | translate}}:</label>
                <div class="col-sm-4 d-flex">
                  <input type="text" class="form-control" name="splIp" placeholder="0.0.0.0" [(ngModel)]="ip"
                    [disabled]="master === ''">
                </div>
              </div>
              <div class="form-group row mt-5">
                <label class="col-form-label col-sm-4">{{"splPort" | translate}}:</label>
                <div class="col-sm-4 d-flex">
                  <input type="number" class="form-control" name="splPort" placeholder="" [(ngModel)]="port"
                    [disabled]="master === ''">
                </div>
              </div>
            </div>

            <div class="form-group row mt-3">
              <div class="col-xl-4 col-md-6 d-flex align-items-center">
                <label class="col-form-label">{{"splLimitPower" | translate}}:</label>
                <i class="fas fa-info-circle pl-2" [matTooltip]="'splLimitPowerText' | translate"></i>
              </div>
              <div class="col-xl-4 col-sm-5">
                <div class="d-flex">
                  <input type="number" class="form-control form-control-group" name="splLimitPower" placeholder="W"
                    [disabled]="master === ''" [(ngModel)]="powerLine">
                  <div class="input_right_data">W</div>
                </div>
              </div>
            </div>
            <div class="form-group row mt-3">
              <div class="col-xl-4 col-md-6 d-flex align-items-center">
                <label class="col-form-label">{{"splLimitPowerByPhase" | translate}}:</label>
                <i class="fas fa-info-circle pl-2" [matTooltip]="'splLimitPowerByPhase_desc' | translate"></i>
              </div>
              <div class="col-xl-7 col-md-6">
                <div class="d-flex">
                  <input type="number" class="form-control form-control-group col-3" name="splLimitPowerByPhase_0"
                    placeholder="W" [disabled]="master === ''" [(ngModel)]="powerR">
                  <input type="number" class="form-control form-control-group col-3" name="splLimitPowerByPhase_1"
                    placeholder="W" [disabled]="master === ''" [(ngModel)]="powerS">
                  <input type="number" class="form-control form-control-group col-3" name="splLimitPowerByPhase_2"
                    placeholder="W" [disabled]="master === ''" [(ngModel)]="powerT">
                  <div class="input_right_data">W</div>
                </div>
              </div>
            </div>
            <div class="form-group row mt-3">
              <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                <label class="col-form-label">{{"splFailPowerLabel" | translate}}:</label>
                <i class="fas fa-info-circle pl-2" [matTooltip]="'splFailPowerText' | translate"></i>
              </div>
              <div class="col-xl-4 col-sm-5 d-flex">
                <input type="number" class="form-control form-control-group" name="splSlaveFailPower"
                  placeholder="6-10 A" [disabled]="master === ''" [(ngModel)]="chargeError">
                <div class="input_right_data">A</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-3" *ngIf="next != 'slave'">
          <div class="row">
            <p class="text-center"> {{"selectMaster" | translate}} </p>
            <div class="col d-flex justify-content-center">
              <table class="table ">
                <thead>
                  <tr>
                    <td>{{"master" | translate}}</td>
                    <td>{{"serial_number" | translate}}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let device of data.devices; let i = index">
                    <td>
                      <input type="radio" name="devices" [checked]="master === device.serial"
                        (click)="master = device.serial" id="check{{i}}" />
                      <!-- <label class="form-check-label" for="check{{i}}"> -->
                    </td>
                    <td>
                      <span> {{ device.serial }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-3 " *ngIf="next == 'slave'">
          <div class="row">
            <p class="text-center"> {{"selectSatellites"| translate}} </p>
            <div class="col d-flex justify-content-center">
              <table class="table ">
                <thead>
                  <tr>
                    <td>{{"satellites" | translate}}</td>
                    <td>{{"serial_number" | translate}}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let slave of slaves; let i = index">
                    <td>
                      <input type="checkbox" name="devicesSlaves" id="checkSlaves{{i}}" [(ngModel)]="slave.checked"
                        (change)="change(slave)" />
                    </td>
                    <td>
                      <span> {{ slave.serial }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="card-footer text-center">
      <button (click)="nextSection()" class="btn btn-primary m-1" *ngIf="next != 'slave'" [disabled]="master == ''">
        {{"chooseSatellites" | translate }} </button>
      <button (click)="nextSection()" class="btn btn-primary m-1" *ngIf="next === 'slave'"> {{"selectMaster" |
        translate}} </button>
      <button (click)="save()" class="btn btn-primary m-1" [disabled]="master == ''"> {{"overwrite" | translate}}
      </button>
    </div>
  </div>

</div>
