import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

  @Input()
  description: string;

  @Input()
  title: string;

  msgError:string;
  constructor(private activeModal: NgbActiveModal, private _translate: TranslateService) { }

  ngOnInit(): void {

    // if ( this.description == "setOcppAll")

    this.msgError = this._translate.instant('something_failed');
  }

  close(){
    this.activeModal.close();

  }



}
