<div style="height: fit-content ; min-height: 850px; width: 100%; ">


    <div class="ps-5 bg-primary" style="min-height: 100px;">
  
      <nav aria-label="breadcrumb" class="ps-1">
        <ol class="breadcrumb" style="width: fit-content;">
          <li *ngFor="let item of breadCrumbs; let i = index" class="breadcrumb-item" style="padding: 10px;"
              [class.active]="i === breadCrumbs.length - 1">
  
            <!-- Si no es el último elemento en la ruta -->
            <div *ngIf="i !== breadCrumbs.length - 1" style="cursor: pointer;" (click)="updateBreadCrumbs(item.alias, i)">
              <!-- Si el nombre es 'home' muestra el ícono, de lo contrario muestra el nombre -->
              <ng-container *ngIf="item.name === 'home'; else normalBreadcrumb">
                <i class="fas fa-home text-white" (click)="home()"></i>
              </ng-container>
              <ng-template #normalBreadcrumb>
                <div class="text-white">{{ item.name }}</div>
              </ng-template>
            </div>
  
            <!-- Si es el último elemento en la ruta -->
            <span *ngIf="i === breadCrumbs.length - 1" class="text-white" (click)="item.name === 'home' ? home() : null">
              <i *ngIf="item.name === 'home'" class="fas fa-home"></i>
              <span *ngIf="item.name !== 'home'">{{ item.name }}</span>
            </span>
          </li>
        </ol>
      </nav>
  
  
  
      <div class="m-1  d-flex justify-content-between align-items-center " >
        <div class="text-white fs-4">
          {{dataGroup?.name}}&nbsp;
  
          <span *ngIf="dataGroup.role == 'all'"> <i class="far fa-edit"></i> </span>
          <span *ngIf="dataGroup.role == 'owner'"> <i class="fas fa-user-shield"></i> </span>
          <span *ngIf="dataGroup.role == 'write'"> <i class="fas fa-pen"></i> </span>
          <span *ngIf="dataGroup.role == 'read'"> <i class="fas fa-book-open"></i> </span>
        </div>
        <div class="d-flex justify-content-between align-items-center pe-3 mb-2">
          <a class="nav-link text-white" [routerLink]="['/dashboard/group', dataGroup.alias]" [ngClass]="{'btn btn-light text-dark': currentRoute === 'group'}">
            <i class="far fa-eye "></i>
          </a>
          <a class="nav-link text-white" [routerLink]="['view']" [ngClass]="{'btn btn-light text-dark': currentRoute === 'view'}">
            <i class="far fa-chart-bar"></i>
          </a>
          <!-- <a class="nav-link text-white" (click)="goToSPL(dataGroup.alias)"  [disabled]="master =='' " ><i>SPL</i></a> -->
          <a class="nav-link text-white" [routerLink]="['settings']" [ngClass]="{'btn btn-light text-dark': currentRoute === 'settings'}">
            <i class="fa fa-gear"></i>
          </a>
          <a class="nav-link text-white" data-bs-toggle="modal" data-bs-target="#configmodal"
            (click)="openModal();">
            <i class="fas fa-sliders-h"></i>
          </a>
  
        </div>
      </div>
    </div>

  <app-show-group *ngIf="currentRoute==='group'" [data]="dataGroup" [loading]="loading"></app-show-group>
  <app-view-group *ngIf="currentRoute==='view'" [data]="dataGroup" [parentLoading]="loading"></app-view-group>
  <app-new-group *ngIf="currentRoute==='settings'" [dataGroup]="dataGroup" [loading]="loading" [userRole]="userRoleDB" (openModalRole)="openModalRoleFromChild($event)"></app-new-group>

</div>
  
    <!-- MODAL PARA PROGRAMAR  TODOS LOS EQUIPOS DEL GRUPO -->
    <div class="modal fade " id="configmodal" tabindex="-1" aria-labelledby="powerModalLabel" aria-hidden="true" >
      <div class="modal-dialog">
        <div
        class="modal-content"     [ngStyle]="{'width': chooseCfg === 'spl' ?  '1000px' :   '800px'}">
              <div class="modal-header">
            <h1 class="modal-title fs-5 text-primary" id="powerModalLabel">{{ "settings" | translate}}</h1>
            <div class="d-flex justify-content-center text-center" style="width: 100%;">
              <h3 class="text-muted"> {{chooseCfg |uppercase }}</h3>
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="  justify-content-center">
            <ul class="nav nav-tabs text-center ">
              <li class="nav-item" (click)="chooseCfg ='power'; cancel()">
                <a [ngClass]="chooseCfg == 'power' ? 'nav-link active' : 'nav-link'" style="width: 70px;"><i
                    class="fas fa-bolt tex"></i></a>
              </li>
              <li class="nav-item" (click)="chooseCfg ='reset'; cancel() ">
                <a [ngClass]="chooseCfg == 'reset' ? 'nav-link active' : 'nav-link'" style="width: 70px;">
                  <i >Reset</i></a>
              </li>
              <li class="nav-item" (click)="chooseCfg ='ocpp'; cancel() ">
                <a [ngClass]="chooseCfg == 'ocpp' ? 'nav-link active' : 'nav-link'" style="width: 70px;"><i>OCPP</i></a>
              </li>
              <li class="nav-item" (click)="chooseCfg ='cards'; cancel() ">
                <a [ngClass]="chooseCfg == 'cards' ? 'nav-link active' : 'nav-link'" style="width: 70px;"><i
                  class="fas fa-id-card"></i></a>
              </li>
              <li class="nav-item" (click)="chooseCfg ='wifi'; cancel()">
                <a [ngClass]="chooseCfg == 'wifi' ? 'nav-link active' : 'nav-link'" style="width: 70px;"><i
                    class="fas fa-broadcast-tower"></i></a>
              </li>
              <li class="nav-item" (click)="chooseCfg ='password' ; cancel()">
                <a [ngClass]="chooseCfg == 'password' ? 'nav-link active' : 'nav-link'" style="width: 70px;"><i
                    class="fas fa-key"></i></a>
              </li>
              <li class="nav-item" (click)="chooseCfg ='spl' ; cancel()">
                <a [ngClass]="chooseCfg == 'spl' ? 'nav-link active' : 'nav-link'" style="width: 70px;"><i
                    class="">SPL</i></a>
              </li>
              <li class="nav-item" (click)="chooseCfg ='rol' ; cancel()">
                <a [ngClass]="chooseCfg == 'rol' ? 'nav-link active' : 'nav-link'" style="width: 70px;"><i
                  class="fas fa-user-tag"></i></a>
              </li>
              <li class="nav-item" (click)="chooseCfg ='geolocation' ; initMap(); cancel()">
                <a [ngClass]="chooseCfg == 'geolocation' ? 'nav-link active' : 'nav-link'" style="width: 70px;"><i
                  class="fas fa-map-marked-alt"></i></a>
              </li>
              <li class="nav-item" (click)="chooseCfg ='fwupdate'; cancel()">
                <a [ngClass]="chooseCfg == 'fwupdate' ? 'nav-link active' : 'nav-link'" style="width: 70px;"><i
                  class="fas fa-cloud-download-alt"></i></a>
              </li>
            </ul>
          </div>
  
          <div class="modal-body">
            <div class="row mb-4">
              <div *ngIf="chooseCfg != 'spl'" class="col d-flex justify-content-center ml-3" style="height: 65vh; overflow: auto;">
                <div class="form-check" >
                  <input class="form-check-input mb-2" type="checkbox" [(ngModel)]="selectAll" (change)="toggleAllDevices()"
                    id="checkAll">
                  <label class="form-check-label text-primary fw-bold mb-2" for="checkAll"> {{"all" | translate | uppercase}}
                  </label>
                  <div *ngFor="let device of selectedDevices; let i = index" class="d-flex align-items-center mt-2">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="device.check" id="check{{i}}">
                    <label class="form-check-label d-flex align-items-center" for="check{{i}}">
                      <!-- <span> {{ device.serial }}</span> -->
                      <span class="row">
                         <span *ngIf="device.name" class="fs-5"> {{ device.name }}</span>
                         <span *ngIf="!device.name" > {{ device.serial }}</span>
                         <small class="text-dark fw-light">{{ device.serial }}</small>
                      </span>

                      <div>
                        <span class="status-ok"    *ngIf="status[i] ==1 && showStatus"> <i class="far fa-check-circle"></i>  </span>
                        <span class="status-error" *ngIf="status[i] ==2 && showStatus"> <i  class="far fa-times-circle"></i> </span>
                        <div class="spinner-border" role="status" *ngIf="status[i] ==3 && showStatus" style="height: 25px; width: 25px;"></div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
  
  
              <div *ngIf="chooseCfg == 'spl'" class="col d-flex justify-content-center">
  
  
                <div style="height: fit-content ; min-height: 850px; width: 100%; ">
                    <div class="card-header">
                      <h5 class="text-primary" > {{"configuration" | translate}} SPL</h5>
                      <!-- <div class="fs-4">{{dataGroup?.name}} </div>
                    <div  class="text-muted" style="cursor: pointer;" (click)="close()" > <i class="fas fa-times"></i></div> -->
                    </div>
  
                    <div class="card-body row p-1 ">
                      <div class="row">
                        <div class="col-7  d-flex justify-content-center align-items-baseline">
                          <div class="row col-12 mb-3 mt-3" >
                            <div class="text-left ">{{"master" | translate}}
                              <h3 class="text-primary" > {{masterName}} </h3>
                              <h6 class="pt-0"> {{master}} </h6>
                            </div>
                            <div class="form-group row d-flex mt-3 mb-0">
                              <label class="col-form-label col-sm-4 col-6">{{"interface" | translate}}:</label>
                            </div>
                            <div class="col-sm-4 d-flex align-items-center">
                              <select class="form-select" name="splInterface" [(ngModel)]="interface" [disabled]="master === ''">
                                <option value=1>RS485</option>
                                <option value=3>{{"networkInterface" | translate}}</option>
                              </select>
                            </div>
  
                            <div *ngIf="interface == 3" class ="ms-2">
                              <div class="form-group row d-flex mb-0">
                                <label class="col-form-label col-sm-4">{{"splIp" | translate}}:</label>
                              </div>
                              <div class="col-sm-4 d-flex">
                                <input type="text" class="form-control" name="splIp" placeholder="0.0.0.0" [(ngModel)]="ip"
                                  [disabled]="master === '' || next != 'slave'">
                              </div>
                              <div class="form-group row mb-0">
                                <label class="col-form-label col-sm-4">{{"splPort" | translate}}:</label>
                              </div>
                              <div class="col-sm-4 d-flex">
                                <input type="number" class="form-control" name="splPort" placeholder="" [(ngModel)]="port"
                                  [disabled]="master === ''">
                              </div>
                            </div>
  
                            <div class="mt-3" *ngIf="next != 'slave'">
                              {{ "Para configurar los parámetros de potencia de la instalación SPL, acceder a la página de configuración del master" | translate }}
                            </div>
                          </div>
                        </div>
  
                        <div class="col-5" *ngIf="next != 'slave'" style="height: 65vh; overflow: hidden;">
                          <div class="row me-2">
                            <p class="text-center">{{ "selectMaster" | translate }}</p>
                            <div class="col d-flex justify-content-center">
                              <div class="table-responsive" style="max-height: 60vh; overflow-y: auto; overflow-x: hidden;">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <td>{{ "master" | translate }}</td>
                                      <td>{{ "serial_number" | translate }}</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let device of dataGroup.devices; let i = index">
                                      <td class="pt-1 pb-1">
                                        <input type="radio" name="devices" [checked]="master === device.serial"
                                          (click)="master = device.serial" id="check{{i}}" />
                                      </td>
                                      <td class="pt-1 pb-1">
                                        <span class="row">
                                          <span>{{ device.name }}</span>
                                          <span *ngIf="!device.name" >{{ device.serial }}</span>
                                          <small class="text-dark">{{ device.serial }}</small>
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
  
                        <div class="col-5" *ngIf="next == 'slave'" style="height: 65vh; overflow: hidden;">
                          <div class="row">
                            <p class="text-center">{{ "selectSatellites" | translate }}</p>
                            <div class="col d-flex justify-content-center">
                              <div class="table-responsive" style="max-height: 60vh; overflow-y: auto;">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <td>{{ "satellites" | translate }}</td>
                                      <td>{{ "serial_number" | translate }}</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let slave of slaves; let i = index">
                                      <td class="pt-1 pb-1">
                                        <input type="checkbox" name="devicesSlaves" id="checkSlaves{{i}}" [(ngModel)]="slave.checked"
                                          (change)="change(slave)" />
                                      </td>
                                      <td class="pt-1 pb-1">
                                        <span class="row">
                                          <span>{{ slave.name }}</span>
                                          <span *ngIf="!slave.name " >{{ slave.serial }}</span>
                                          <small class="text-dark">{{ slave.serial }}</small>
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
  
  
                    <div class="card-footer text-center">
                      <button (click)="nextSection()" class="btn btn-primary m-1" *ngIf="next != 'slave'" [disabled]="master == ''">
                        {{"chooseSatellites" | translate }} </button>
                      <button (click)="nextSection()" class="btn btn-primary m-1" *ngIf="next === 'slave'"> {{"selectMaster" |
                        translate}} </button>
                      <button  class="btn btn-primary m-1" [disabled]="master == ''" (click)="save()" > {{"overwrite" | translate}}
                      </button>
                    </div>
  
                </div>
  
  
  
  
              </div>
  
              <!-- Instalación -->
  
              <div class="col-8 d-flex justify-content-center align-items-center ms-4 ps-3" *ngIf="chooseCfg =='power'">
                <form class="d-flex justify-content-center">
                  <div class="row" >
                    <div class="form-group row m-0">
                      <div class="col-12 d-flex align-items-center ps-0">
                        <label class="col-form-label">{{"limitPowerCharger" | translate}}:</label>
                        <i class="fas fa-info-circle pl-2" [matTooltip]="'limitPowerChargerText' | translate"></i>
                      </div>
                    </div>
                    <div class="form-group row pt-1">
                      <div class="col-xl-8 col-sm-6">
                        <div class="d-flex">
                          <input type="number" class="form-control form-control-group" name="limitPowerAll" [(ngModel)]="ampacity">
                          <div class="input_right_data ">A</div>
                        </div>
                      </div>
                    </div>
  
                    <!-- LimitPowerConnectors -->
                    <div class="form-group row m-0">
                      <div class="col-12 d-flex align-items-center ps-0">
                        <label class="col-form-label">{{"limitPowerConnector" | translate}} 1 </label><br>
                        <i class="fas fa-info-circle pl-2" [matTooltip]="'limitPowerConnectorText' | translate"></i>
                      </div>
                    </div>
                    <div class="form-group row pt-1">
                      <div class="col-xl-8 col-sm-6">
                        <div class="d-flex ">
                          <input type="number" class="form-control form-control-group " name="connPower" placeholder="0-32 A" max="32" min="6" [(ngModel)]="currentConn1" >
                          <div class="input_right_data">A</div>
                        </div>
                      </div>
                    </div>
  
                    <div class="form-group row m-0">
                      <div class="col-12 d-flex align-items-center ps-0">
                        <label class="col-form-label">{{"limitPowerConnector" | translate}} 2 </label><br>
                        <i class="fas fa-info-circle pl-2" [matTooltip]="'limitPowerConnectorText' | translate"></i>
                      </div>
                    </div>
                    <div class="form-group row pt-1">
                      <div class="col-xl-8 col-sm-6">
                        <div class="d-flex ">
                          <input type="number" class="form-control form-control-group " name="connPower" placeholder="0-32 A" max="32" min="6" [(ngModel)]="currentConn2" >
                          <div class="input_right_data">A</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row pt-1">
                      <div class="col-xl-8 col-sm-6">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="check1" [(ngModel)]="is3Ph">
                          <label class="form-check-label" for="check1">
                            {{ 'is3Ph' | translate}}
                          </label>
                          <i class="fas fa-info-circle pl-2" [matTooltip]="'is3PhText' | translate"></i>
                        </div>
                      </div>
                    </div>
  
  
  
  
                  </div>
                </form>
              </div>
  
              <!-- OCPP -->
              <div class="col-8 d-flex justify-content-center align-items-center" *ngIf="chooseCfg =='ocpp'">
                <form class="d-flex justify-content-center">
                  <div class="row">
                    <div class="form-group row">
                      <label class="col-form-label col-sm-4 text-sm-right">Url</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" placeholder="Url" name="serverUrl" [(ngModel)]="url">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-form-label col-sm-4 text-sm-right">{{"port" | translate}}</label>
                      <div class="col-sm-8">
                        <input type="number" class="form-control" [placeholder]="'port' | translate" name="port"
                          [(ngModel)]="port">
                      </div>
                    </div>
                    <div  class="form-group row">
                      <label  class="col-form-label col-sm-4 text-sm-right">BoxId
                        <i class="fas fa-info-circle pl-2" [matTooltip]="'boxId' | translate"></i>
                      </label>
                      <div class="col-sm-8 d-flex  align-items-center">
                      <input type="checkbox" id="boxId" class="" [(ngModel)]="addBoxId"  [disabled]="url =='' " >
                      </div>
                    </div>
                  </div>
                </form>
              </div>
  
              <!--  RFID  -->
              <div class="col-8 d-flex justify-content-center align-items-center" *ngIf="chooseCfg =='cards'">
                <form class="d-flex justify-content-center row">
                  <div class="row col-12">
                    <div class="text-center mb-2">
                      {{"authorizedCard"|translate}}
                    </div>
                    <div class="d-flex justify-content-center " *ngIf="!messageDelCards">
                      <input class="form-control form-control-sm" id="formFileSm" type="file" #input accept=".xls, .xlsx, .csv"
                        (change)="onFileSelected($event)">
                    </div>
                    <div class="d-flex justify-content-around ">
                      <div class="btn-group mt-2 float-left" role="group" aria-label=""
                        (click)=" messageDelCards = true; cardsMode = 'delete'; confirm.cards = true">
                        <div class="text-danger mt-4" *ngIf="messageDelCards"><i class="fas fa-exclamation-triangle"></i>
                          {{"deleteRfidAll" | translate }}
                        </div>
                        <div *ngIf="!messageDelCards" class=" text-danger fs-6">
                          <i class="fas fa-trash-alt"></i> {{ "deleteAll" | translate}}
                        </div>
                      </div>
                      <button *ngIf="!messageDelCards"  type="button" class="btn btn-primary mt-1"
                        (click)="confirm.cards= true; confirm.rfid=false" [disabled]="!fileSelected">{{'upload'|translate}}</button>
                    </div>
                  </div>
  
  
                  <div class="row mt-5" >
                    <div class="text-center ">
                      {{"rfidMode"|translate}}
                    </div>
                    <div class="d-flex w-80 m-auto button-group-container ">
                      <div class="col-4 text-center p-1 button-group" (click)="changeMode('touch')"
                        [ngClass]="{'bg-primary text-white' : rfidEnabled == 1 && rfidMode == 0 }">
                        {{"touch" | translate}}
                      </div>
                      <div class="col-4 text-center p-1 button-group" (click)="changeMode('rfid')"
                        [ngClass]="{'bg-primary text-white' : rfidEnabled == 1 && rfidMode == 1  }">
                        {{"rfid" | translate}}
                      </div>
                      <div class="col-4 text-center p-1 button-group" (click)="changeMode('off')"
                        [ngClass]="{'bg-primary text-white' : rfidEnabled == 0 }">
                        OFF
                      </div>
                    </div>
                  </div>
                </form>
              </div>
  
              <!-- CONECTIVIDAD -->
              <div class="col-8 d-flex justify-content-center align-items-center" *ngIf="chooseCfg =='wifi'">
                <form class="d-flex justify-content-center">
                  <div class="row">
                    <div class="form-group row">
                      <label class="col-form-label col-sm-4 text-sm-right">SSID</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" placeholder="SSID" name="ssid" autocomplete="one-time-code" [(ngModel)]="ssid">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-form-label col-sm-4 text-sm-right">{{"password" | translate}}</label>
                      <div class="col-sm-8">
                        <input type="password" class="form-control" [placeholder]="'password' | translate" name="password"
                          [(ngModel)]="wifiPass">
                      </div>
                    </div>
                  </div>
                </form>
              </div>
  
  
              <!-- PASSWORD -->
              <div class="col-8 d-flex justify-content-center align-items-center" *ngIf="chooseCfg =='password'">
                <form class="d-flex justify-content-center">
                  <div class="row">
                    <div class="d-flex w-80 m-auto button-group-container  justify-content-center">
                      <div class="col-4 text-center p-1 button-group" (click)="accessPoint=1"
                        [ngClass]="{'bg-primary text-white' : accessPoint == 1 }">
                        {{"activated" | translate}}
                      </div>
                      <div class="col-4 text-center p-1 button-group" (click)="accessPoint=0"
                        [ngClass]="{'bg-primary text-white' : accessPoint == 0  }">
                        {{"disactivated" | translate}}
                      </div>
                    </div>
                    <div class="form-group row mt-5">
                      <label class="col-form-label col-sm-4 text-sm-right">{{"password_new" | translate}}</label>
                      <div class="col-sm-8">
                        <input type="password" class="form-control" name="apPassNew" [(ngModel)]="newPass"
                          [placeholder]="'password_new' | translate">
                      </div>
                    </div>
                  </div>
                </form>
              </div>
  
                <!-- ROl  -->
              <div class="col-8 d-flex justify-content-center align-items-center" *ngIf="chooseCfg =='rol'">
                <form class="d-flex justify-content-center">
                  <div class="row">
                    <div class="form-group row">
                      <label class="col-form-label col-sm-4 text-sm-right">{{'user' | translate}}</label>
                      <div class="col-sm-8">
                        <input type="email" class="form-control" placeholder="email"  autocomplete="one-time-code" [(ngModel)]="userRole">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-form-label col-sm-4 text-sm-right">{{"role" | translate}}</label>
                      <div class="col-sm-8">
                        <select class="form-select form-select-group" [(ngModel)]="selectedRole"   name="userRoles">
                          <option *ngFor="let role of roles" [ngValue]="role.value">{{role.viewValue | translate}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
  
              <!-- GEOLOCALIZACION -->
              <div class="col-8 d-flex justify-content-center align-items-center" *ngIf="chooseCfg =='geolocation'">
                <div class="card-body">
                  <form >
                    <div class="form-group row d-flex align-items-center">
                      <div class="col-auto">
                        <label class="col-form-label"> {{"address" | translate}}:</label>
                      </div>
                      <div class="col-12">
                        <input id="pac-input" class="controls" type="text" placeholder="Search Box" [(ngModel)]="addressGeolocation" name="address" />
                      </div>
                    </div>
                  </form>
                  <div id="mapGeolocation"></div>
                </div>
              </div> 

              <!-- UPDATE  -->
              <div class="col-8 d-flex justify-content-center align-items-center" *ngIf="chooseCfg =='fwupdate'">
                <form class="d-flex justify-content-center">
                  <div class="row">
                    <div class="form-group row">
                      <div class="col">
                        <input type="text" class="form-control" placeholder="urlUpdate" [(ngModel)]="versionUrl">
                      </div>
                      <div class="mt-2 d-flex justify-content-center">
                        <div class="col-6 pl-0">
                          <select class="form-select" name="typeUpdate" [(ngModel)]="typeUpdate">
                            <option value="uni">UNI</option>
                            <option value="combi">COMBI+</option>
                            <option value="exo">EXO</option>
                            <option value="city">CITY+</option>
                          </select>
                        </div>
                        <div class="col-6 pr-0" *ngIf="userRoleDB != 'user'">
                          <select class="form-select" name="roleUpdate" [(ngModel)]="roleUpdate">
                            <option value="admin" *ngIf="userRoleDB == 'admin'">admin</option>
                            <option value="developer" *ngIf="userRoleDB == 'admin' || userRoleDB == 'developer'">developer</option>
                            <option value="tester" *ngIf="userRoleDB != 'user'">tester</option>
                            <option value="user">user</option>
                          </select>
                        </div>
                      </div>
                      <div class="col mt-2">
                        <button class="btn btn-primary btn-tertiary-hard col-12" (click)="getLastVersion()">{{"getLastVersion" | translate}}</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
  
  
  
              <form class="d-flex justify-content-center" *ngIf="checkAnyFieldTrue() && cardsMode !='delete'">
                <div class="form-group row d-flex">
                  <div class=" d-flex align-items-center">
                    <label class="col-form-label text-warning">{{ messageConfirm() | translate}}:</label>
                  </div>
                </div>
              </form>
  
            </div>
            <div class="modal-footer" *ngIf="chooseCfg != 'spl'">

              <button *ngIf="chooseCfg =='power' && !confirm.power" type="button" class="btn btn-primary"
                (click)="confirm.power= true">{{"send" | translate}}</button>
              <button *ngIf="chooseCfg =='reset' && !confirm.reset" type="button" class="btn btn-primary"
                (click)="confirm.reset= true">{{"send" | translate}}</button>
              <button *ngIf="chooseCfg =='ocpp'  && !confirm.ocpp" type="button" class="btn btn-primary"
                (click)="confirm.ocpp= true">{{"send" | translate}}</button>
              <button *ngIf="chooseCfg =='wifi'  && !confirm.wifi" type="button" class="btn btn-primary"
                (click)="confirm.wifi= true">{{"send" | translate}}</button>
              <button *ngIf="chooseCfg =='password' &&  !confirm.password" type="button" class="btn btn-primary"
                (click)="confirm.password= true">{{"send" | translate}}</button>
              <button *ngIf="chooseCfg =='rol' &&  !confirm.role" type="button" class="btn btn-primary"
                (click)="confirm.role= true">{{"send" | translate}}</button>
  
              <button *ngIf="chooseCfg =='rfid'  && !confirm.rfid && !confirm.cards" type="button" class="btn btn-primary"
                (click)="confirm.rfid= true">{{"send" | translate}}</button>
                <!-- <button *ngIf="chooseCfg =='rfid' &&  confirm.cards && cardsMode == 'upload' " type="button"
                class="btn btn-primary" (click)="confirm.cards= true">card{{"send" | translate}}</button> -->
  
              <!-- <button *ngIf="chooseCfg =='rfid' && cardsMode == 'upload' " type="button"
                class="btn btn-primary" (click)="confirm.cards= true">{{"send" | translate}}</button> -->
              <button *ngIf="chooseCfg =='geolocation' &&  !confirm.geolocation" type="button" class="btn btn-primary"
                (click)="confirm.geolocation= true">{{"send" | translate}}</button>
              <button *ngIf="chooseCfg =='fwupdate' &&  !confirm.fwupdate" type="button" class="btn btn-primary"
                (click)="confirm.fwupdate= true">{{"send" | translate}}</button>
  
  
  
              <button *ngIf="confirm.power" type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="setLimitPower()">{{"accept" | translate}}</button>
              <button *ngIf="confirm.reset" type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="reset()">{{"accept"
                | translate}}</button>
              <button *ngIf="confirm.ocpp" type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="ocpp()">{{"accept" |
                translate}}</button>
              <button *ngIf="confirm.wifi" type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="setWifi()">{{"accept" | translate}}</button>
              <button *ngIf="confirm.password" type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="setPassword()">{{"accept" | translate}}</button>
              <button *ngIf="confirm.role" type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="setRole()">{{"accept" | translate}}</button>
  
              <button *ngIf="confirm.rfid" type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="setRFID()">{{"accept" | translate}}</button>
              <button *ngIf="confirm.cards " type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="setCards(cardsMode)">{{"accept" | translate}}</button>

              <button *ngIf="confirm.cards " type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="setCards(cardsMode)">{{"accept" | translate}}</button>
              <button *ngIf="confirm.geolocation " type="button" class="btn btn-primary"
                (click)="setGeolocation()">{{"accept" | translate}}</button>
                <button *ngIf="confirm.fwupdate" type="button" class="btn btn-primary"
                (click)="setFwUpdate()">{{"accept" | translate}}</button>
  
              <button *ngIf="checkAnyFieldTrue()" type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="cancel()">{{"cancel" | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  