<div class="modal-container">

  <div class="modal-header">
    <div class="d-flex w-100 ">

      <h2 class="modal-title text-primary text-center w-100">CHECK</h2>
      <h2 class="text-primary modal-title text-right" (click)="close()">
        <i class="fas fa-times"></i>
      </h2>
    </div>

  </div>
  <div class="modal-body m-3">
    <h3 class="card-subtitle text-muted">  {{data[0].serial }}</h3>
    <table  class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Modulo</th>
          <th scope="col">Test</th>
          <th scope="col">Resultado</th>
          <th scope="col">+info</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data; let i = index">
          <th scope="row">{{i+1}}</th>
          <td >{{item.module}}</td>
          <td>{{item.test}}</td>
          <td *ngIf="item.result"> <i class="fas fa-check-circle text-success i-s"> </i> </td>
          <td *ngIf="!item.result"> <i class="fas fa-exclamation-circle text-danger i-s"> </i> </td>
          <td><i class="fa fa-info-circle btn btn-secondary "  (click)="showJSON(item)" ></i> </td>
        </tr>
      </table>

      <div  *ngIf="moreInfo">
        <h5> {{dataJSON.module}} </h5>
        <span>
          {{dataJSON.config}}
        </span>
      </div>
    </div>
  </div>
