import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Chart } from 'chart.js';
import * as Chartt from 'chart.heatmap.js';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { trigger, transition, useAnimation } from '@angular/animations';
import { zoomIn } from 'ng-animate';
import { ConnectorState } from 'src/assets/js/constants';
import { Colors } from 'src/assets/js/colors';
import { ModalService } from 'src/app/services/modal/modal.service';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from 'src/app/components/ui/error-modal/error-modal.component';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { infoModulator } from 'src/app/models/Modulator/infoModulator';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { compare } from 'compare-versions';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-device-dashboard',
  templateUrl: './device-dashboard.component.html',
  styleUrls: ['./device-dashboard.component.scss'],
  animations: [
    trigger('zoomIn', [transition('* => *', useAnimation(zoomIn))])
  ]
})
export class DeviceDashboardComponent implements OnInit, OnDestroy {

  @Output() event = new EventEmitter();
  splMode: number;
  zoomIn: any;
  canvasPie: any;
  ctxPie: any;
  chartPie: any;
  chartPieInside: any;
  canvas: any;
  ctx: any;
  chart: any;
  canvasHeat: any;
  ctxHeat: any;
  chartHeat: any;
  type_device: string;
  maxPower: number;

  statesPermission=[2,4,5,6,7,8,14,30];
  statesNoPermission=[1,3,31];

  user: string = '';
  device: string = '';
  infoModulator: infoModulator;

  errors = {
    heatmap: false,
    consumptions: false,
    state: false
  }

  historicStart = new Date();
  historicEnd = new Date();
  dataAnalytics;
  dataHistoric: any = [];
  dataHeatmap;
  showModal = false;

  loading = true;
  loadingConsumptions = true;
  loadingHeatmap = true;

  expandBool = {
    state: false,
    analytics: false,
    historic: false,
  }

  connectors: any ={};
  connectorsState: any = {};
  allStates = ConnectorState;

  totalEvse = 0;
  totalHome = 0;

  loadingModal: NgbModalRef;
  errorModal: NgbModalRef;

  deviceConnected=false;
  lastReport = Date.now();
  dateDisplay = 'short';

  intervalRT: any;

  solarInfo: any;

  version7_2_0 = false;
  subscription:Subscription;


  constructor(private _device: DeviceService, private _aRoute: ActivatedRoute, private _router: Router,
              private _ws: WebsocketService, private _modal: ModalService, private _translate: TranslateService, private _utils: UtilsService) {
      this.createDoughnutChart();
      this.subscription = this._device.serial$.subscribe((data) => {
        //receiving the data from the Parent component(app) and setting it to name field.
        if(data!=''){
          var deviceInfo = this._device.getActiveDeviceInfo();
          if(deviceInfo)
            this.version7_2_0 = compare(deviceInfo.firmware_version_name, '7.2.0', '>=');
        }
      });
    }

  ngOnInit(): void {

    this._router.events.subscribe((val) => {
      if ((val instanceof NavigationEnd) && (this.device != this._aRoute.snapshot.params.serial)) {
        this.completeRT();
        this.totalHome = 0;
        this.totalEvse = 0;
        this.infoModulator = new infoModulator();
        this.loading = true;
        this.loadingHeatmap = true;
        this.loadingConsumptions = true;
        this.errors.state = false;
        this.errors.consumptions = false;
        this.errors.heatmap = false;
        this.deviceConnected=false;

        this.device = this._aRoute.snapshot.params.serial;
        this.loadData();
        this.connectWS();
      }
    });

    var time = this._device.isFirstLoad ? 1000 : 500;

    this.infoModulator = new infoModulator();
    this.device = this._aRoute.snapshot.params.serial;
    this.device.charAt(4)=='3' ?
      this.type_device='UNI' : this.type_device='COMBIPLUS';

    setTimeout(() => {
      this.loadData();
    }, time);
    this.connectWS();


  }

  connectWS(){
    this._ws.connect(this.device)
                    .subscribe(data => {
                      this.lastReport=Date.now();
                      switch (data.module) {
                        case "modulator":

                          if(this.infoModulator){
                            if (!data.element) {
                              Object.entries(data.data.stat).forEach(entry => {
                                this.infoModulator.stat[entry[0]] = entry[1];
                              })
                              this.infoModulator.stat.timestamp = new Date();
                              this.updatePies();
                              if(data.data?.stat?.maxPower)
                                this.maxPower = data.data.stat.maxPower;
                            }
                            console.log("infoModulator: ", 'background: brown; color: white', this.infoModulator);
                          }
                          break;

                        case "evsm":
                          if(data.data.stat.state){
                            console.log("Change state: ", data.data.stat.state);
                            this.connectorsState[data.element].state=data.data.stat.state;
                            if(this.statesPermission.includes(data.data.stat.state))
                              this.connectorsState[data.element].permission=data.data.stat=true;
                            else if(this.statesNoPermission.includes(data.data.stat.state))
                              this.connectorsState[data.element].permission=false;
                            else
                              this.connectorsState[data.element].permission=-1;
                          }
                          console.log("%cconnectorsState: ", 'background: orange; color: white', this.connectorsState);
                          break;
                      };

                    },
                    err => {
                      //console.log(err);

                      this._modal.showWarn(this._translate.instant("deviceDisconnectedFromServer"), this._translate.instant("connectionCouldNotBeStablished"));
                      this.deviceConnected=false;
                    },
                    () => console.log('complete')
                    )
  }

  ngOnDestroy(){
    this.completeRT();
  }

  startRT(context) {
    context._device.setRT(this.device, { period: 1, timeout: 120, status: true })
      .subscribe(data => {
        if(!this.deviceConnected)
          this._modal.showSuccess(this._translate.instant("deviceConnectedToServer"), '');
        this.deviceConnected=true;
      }, (err)=>{
        if(this.deviceConnected || this.deviceConnected==undefined)
          this._modal.showWarn(this._translate.instant("deviceDisconnectedFromServer"), this._translate.instant("connectionCouldNotBeStablished"));
      })
  }

  completeRT(){
    this._device.setRT(this.device, { period: 1, timeout: 120, status: false })
    .subscribe(data => {
    })

    clearInterval(this.intervalRT);
    this._ws.close();
  }

  loadData() {
    this.startRT(this);
    let context = this;
    this.intervalRT = setInterval(() => {
      this.startRT(context);
    }, 120000)

    this._device.getModuleInfo(this.device, "solar")
      .subscribe(data => {
        this.solarInfo = data;
        if(typeof this.solarInfo?.cfg?.inversor === "string")
          this.solarInfo.cfg.inversor = JSON.parse(this.solarInfo.cfg.inversor);
      })

    this._device.getElemDevice(this.device)
      .subscribe(data => {
        this.connectors=data;
        this._device.getElementModule(this.device, "evsm")
          .subscribe(data=>{
            console.log(data);
            data.forEach((conn, i) => {
              this.connectorsState[conn.name]=conn.data.stat;
              if(this.statesPermission.includes(conn.data.stat.state))
                this.connectorsState[conn.name].permission=conn.data.stat=true;
              else if(this.statesNoPermission.includes(conn.data.stat.state))
                this.connectorsState[conn.name].permission=conn.data.stat=false;
              else
                this.connectorsState[conn.name].permission=conn.data.stat=-1;

                  // this._device.getElementModule(this.device, 'schedman', conn.name)
                  //   .subscribe(data => {
                  //     this.maxPower=data.cfg.tasks;
                  //     console.log(data);
                  //   }, (err) => {

              //   })

              if(this.version7_2_0)
              {
                this._device.getElementModule(this.device, 'sys', conn.name, 'boost', {localdata:true})
                .subscribe(data => {
                  this.connectors[i].booster = data.boost<0?false:true;
                  console.log(data);
                })
              }
            });

            if(!data[1])
              this.connectors.splice(1,1);
            console.log("%cconnectorsState: ", 'background: orange; color: white', this.connectorsState);
          })
      });

    this._device.getHistoricGraphView(this.device,
      this._utils.getFormatedLocalDate(this.historicEnd, 'YYYY-MM-DD'),
      this._utils.getFormatedLocalDate(this.historicStart, 'YYYY-MM-DD'))
          .subscribe(data => {

            let filledData: any[] = [];
            for(let i=0; i<24; i++){

              let findHour = data.data.find(d=>Number.parseInt(this._utils.getFormatedLocalDate(d.hour, 'HH'))==i)
              findHour?filledData.push(findHour):filledData.push({});
            }
            this.dataAnalytics = filledData;
            console.log("%cHistorics: ", 'background: blue; color: white', this.dataAnalytics)
            this.loadingConsumptions = false;
            setTimeout(() => {
              try {
                this.loadGraphHistorics();
              } catch (error: any) {
                console.error("%cLoad Graph Historics: ", 'background: black; color: white;', error.message);
              }
            }, 300);
          }, err => {
            this.loadingConsumptions = false;
            this.errors.consumptions = true;
          });

    // this._device.getHeatmap(this.device)
    //   .subscribe(data => {
    //     this.dataHeatmap = data.data;
    //     this.loadingHeatmap = false;
    //     console.log("%cHeatmap: ", 'background: blue; color: white', this.dataHeatmap)
    //     setTimeout(() => {
    //       this.loadHeatmap();
    //     }, 300);
    //   }, err => {
    //     this.loadingHeatmap = false;
    //     this.errors.heatmap = true;
    //   });

    // this._device.getModuleInfo(this.device, 'evsm')
    //   .subscribe(data => {
    //     this.loading = false;
    //     console.log(data);
    //   })

    this._device.getModuleInfo(this.device, 'modulator')
      .subscribe(data => {
        console.log("%cinfoModulator: ", 'background: brown; color: white', this.infoModulator);
        this.infoModulator.update(data);
        if(data.stat?.maxPower)
          this.maxPower = data.stat.maxPower;
        //console.log(data);
        this.loading = false;
        console.log("%cinfoModulator: ", 'background: brown; color: white', this.infoModulator);
        setTimeout(() => {
          try {
            this.loadGraphPie();
          } catch (error) {
            console.error("%cLoad Graph Pie: ", 'background: black; color: white;', error);
          }
        }, 300);
      }, (err) => {
        this.errors.state = true;
        this.loading = false;
      })



    // this._device.getModuleInfo(this.device, 'sys')
    //   .subscribe(data => {
    //     console.log(data);
    //   })
    this.dataHistoric=[];

    this._device.getHistorics(this.device).subscribe(data => {
        this.dataHistoric = data.historic;
      });
    this._device.getModuleInfo(this.device, "spl").subscribe((data) => {
      if(data.cfg){
        this.splMode = data.cfg.splMode
        this.event.emit({ title: this.splMode });

        console.log(this.splMode);
      }

    });
  }

  loadGraphPie() {
    this.canvasPie = document.getElementById("chartjs-dashboard-pie");
    this.ctxPie = this.canvasPie.getContext("2d");

    var myDataOut :number [] = [];
    var myLabelsOut :string [] = [];
    var myBackColorOut :string [] = [];

    if(this.infoModulator!=null){
      myDataOut = [this.infoModulator.stat.evsePower, this.infoModulator.stat.homePower];
      myLabelsOut = ["Car", "House"];
      myBackColorOut = [Colors.accent_blue,Colors.secondary];
      if(this.infoModulator?.stat?.battEnergy<0){
        myDataOut.push(-this.infoModulator?.stat?.battEnergy);
        myLabelsOut.push("Battery");
        myBackColorOut.push(Colors.green);
        myDataOut.push(this.infoModulator?.cfg.limitPower - this.infoModulator?.stat.evsePower - this.infoModulator?.stat.homePower + this.infoModulator?.stat?.battEnergy);
      }
      else{
        myDataOut.push(this.infoModulator?.cfg.limitPower - this.infoModulator?.stat.evsePower - this.infoModulator?.stat.homePower);
      }
      myBackColorOut.push(Colors.grey);
    }

    this.chartPieInside = new Chart(this.ctxPie, {
      type: 'RoundedDoughnut',
      data: {
        labels: myLabelsOut,
        datasets: [{
          data: myDataOut,
          backgroundColor: myBackColorOut,
          borderColor: "transparent"
        }]
      },
      options: {
        responsive: false,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        cutoutPercentage: 75,
        tooltips: {
          enabled: false
        }
      },
    });


    this.canvasPie = document.getElementById("chartjs-dashboard-pie-inside");
    this.ctxPie = this.canvasPie.getContext("2d");



    var myData :number [] = [];
    var myLabels :string [] = [];
    var myBackColor :string [] = [];
    if(this.infoModulator!=null){
      if(this.solarInfo?.cfg?.enabled){
        if(this.infoModulator?.stat.instPower<0){
          myData.push(this.infoModulator?.stat.instPower || this.infoModulator?.stat.totalPower);
          myData.push(this.infoModulator?.stat.fvPower+this.infoModulator?.stat.instPower);
          myLabels.push("Net");
          myLabels.push("Solar");
          myBackColor.push(Colors.primary);
          myBackColor.push(Colors.yellow);
          if(this.infoModulator?.stat?.battEnergy>0){
            myData.push(this.infoModulator?.stat?.battEnergy);
            myLabels.push("Battery");
            myBackColor.push(Colors.orange);
            myData.push(this.infoModulator.cfg.limitPower - this.infoModulator?.stat.fvPower - this.infoModulator?.stat?.battEnergy)
          }
          else{
            myData.push(this.infoModulator.cfg.limitPower - this.infoModulator?.stat.fvPower)
          }
          myBackColor.push(Colors.grey);
        }
        else{
          myData.push(this.infoModulator?.stat.fvPower||0);
          myData.push(this.infoModulator?.stat.instPower || this.infoModulator?.stat.totalPower);
          myLabels.push("Solar");
          myLabels.push("Net");
          myBackColor.push(Colors.yellow);
          myBackColor.push(Colors.primary);
          if(this.infoModulator?.stat?.battEnergy>0){
            myData.push(this.infoModulator?.stat?.battEnergy);
            myLabels.push("Battery");
            myBackColor.push(Colors.orange);
            myData.push(this.infoModulator.cfg.limitPower - (this.infoModulator?.stat.instPower+this.infoModulator?.stat.fvPower+this.infoModulator?.stat?.battEnergy));
          }
          else{
            myData.push(this.infoModulator.cfg.limitPower - (this.infoModulator?.stat.instPower+this.infoModulator?.stat.fvPower));
          }
          myBackColor.push(Colors.grey);
        }
      }
      else{
        myData = [
          this.infoModulator?.stat.instPower || this.infoModulator?.stat.totalPower,
          this.infoModulator.cfg.limitPower - (this.infoModulator?.stat.instPower||this.infoModulator?.stat?.totalPower)
        ];
        myLabels = ["Net"];
        myBackColor = [Colors.primary,Colors.grey];
      }
    }
    else{
      myData = [0,0];
      myLabels = ["Net"];
      myBackColor = [Colors.primary,Colors.grey];
    }


    this.chartPie = new Chart(this.ctxPie, {
      type: 'RoundedDoughnut',
      data: {
        labels: myLabels,
        datasets: [{
          data: myData,
          backgroundColor: myBackColor,
          borderColor: "transparent"
        }]
      },
      options: {
        responsive: false,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        rotation: this.infoModulator?.stat.instPower<0 ?
                  this.infoModulator?.stat.instPower/this.infoModulator.cfg.limitPower * Math.PI * 2 - Math.PI/2
                  : -Math.PI/2,
        cutoutPercentage: 75
      }
    });
  }

  loadHeatmap() {
    var data = {
      labels: ["00", "", "02", "", "04", "", "06", "", "08", "", "10", "", "12", "", "14", "", "16", "", "18", "", "20", "", "22", ""],
      datasets: [
        {
          label: 'Mon', data: new Array(new Number())
        }, {
          label: 'Tue', data: []
        }, {
          label: 'Wed', data: []
        }, {
          label: 'Thu', data: []
        }, {
          label: 'Fri', data: []
        }, {
          label: 'Sat', data: []
        }, {
          label: 'Sun', data: []
        }
      ]
    };
    this.dataHeatmap.forEach((day, index) => {
      data.datasets[day.weekday].data = [];
      for (let i = 0; i < 24; i++) {
        let energyHour = day.data.find(dataHour => dataHour.hour == i);
        if (energyHour)
          data.datasets[day.weekday].data.push(parseInt(energyHour.homeEnergy));
        else
          data.datasets[day.weekday].data.push(0);
      }
    });
    //relleno los vacíos
    for (let i = 0; i < 7; i++) {
      if (data.datasets[i].data.length < 24) {
        for (let j = 0; j < 24; j++) {
          data.datasets[i].data.push(0);
        }
      }
    }
    this.canvasHeat = document.getElementById('heatmap');
    this.ctxHeat = this.canvasHeat.getContext('2d');
    this.chartHeat = new Chartt(this.ctxHeat).HeatMap(data, {
      backgroundColor: '#fff',
      colors: ["rgba(1, 106, 124,0.2)", "rgb(1, 106, 124)"],
      showLabels: false,
    });
    this.loadingHeatmap = false;
  }

  loadGraphHistorics() {
    var dataEvse = {
      label: 'Evse',
      data: [0],
      backgroundColor: [
        Colors.secondary
      ]
    };
    var dataHome = {
      label: 'Home',
      data: [0],
      backgroundColor: [
        Colors.primary
      ]
    };

    this.dataAnalytics.forEach((hour, index) => {
      let evsePower = hour.elements?.reduce((acc, element) => { return acc +  Number.parseInt(element.active)}, 0);

      if (index == 0) {
        dataEvse.data[0] = (evsePower);
        dataHome.data[0] = (parseInt(hour.homeEnergy));
      } else {
        dataEvse.data.push(evsePower);
        dataEvse.backgroundColor.push(Colors.secondary);
        dataHome.backgroundColor.push(Colors.primary);
        dataHome.data.push(parseInt(hour.homeEnergy));
      }

      this.totalEvse += evsePower;
      this.totalHome += hour.homeEnergy;
    });


    var labels = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
    this.canvas = document.getElementById("chart");
    this.ctx = this.canvas.getContext("2d");
    this.chart = new Chart(this.ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [dataEvse, dataHome]
      },
      options: {
        scales: {
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });

  }

  expand(type) {
    switch (type) {
      case 'state':
        this.expandBool.state = true;
        break;
      case 'analytics':
        this.expandBool.analytics = true;
        break;
      case 'historic':
        this.expandBool.historic = true;
        break;
    }
    this.showModal = true;
    document.getElementById('button-modal-activate')?.click();
    // var modal = document.getElementById('modal-content');
    // var card=document.getElementById('card-state');
    // if(modal && card)
    //   modal.innerHTML=card.innerHTML;
  }

  resetModals() {
    this.showModal = false;
    this.expandBool.state = false;
    this.expandBool.analytics = false;
    this.expandBool.historic = false;
  }

  updatePies(){
    try {
      var myDataOut :number [] = [];
      var myBackColorOut :string [] = [];

      if(this.infoModulator!=null){
        myDataOut = [this.infoModulator.stat.evsePower, this.infoModulator.stat.homePower];
        myBackColorOut = [Colors.accent_blue,Colors.secondary];
        if(this.infoModulator?.stat?.battEnergy<0){
          myDataOut.push(-this.infoModulator?.stat?.battEnergy);
          myBackColorOut.push(Colors.green);
          myDataOut.push(this.infoModulator?.cfg.limitPower - this.infoModulator?.stat.evsePower - this.infoModulator?.stat.homePower + this.infoModulator?.stat?.battEnergy);
        }
        else{
          myDataOut.push(this.infoModulator?.cfg.limitPower - this.infoModulator?.stat.evsePower - this.infoModulator?.stat.homePower);
        }
        myBackColorOut.push(Colors.grey);
      }

      this.chartPieInside.data.datasets[0].data = myDataOut;
      this.chartPieInside.data.datasets[0].backgroundColor = myBackColorOut;

      var myData :number [] = [];
      var myBackColor :string [] = [];
      if(this.infoModulator!=null){
        if(this.solarInfo?.cfg?.enabled){
          if(this.infoModulator?.stat.instPower<0){
            myData.push(-this.infoModulator?.stat.instPower || this.infoModulator?.stat.totalPower);
            myData.push(this.infoModulator?.stat.fvPower+this.infoModulator?.stat.instPower);
            myBackColor.push(Colors.primary);
            myBackColor.push(Colors.yellow);
            if(this.infoModulator?.stat?.battEnergy>0){
              myData.push(this.infoModulator?.stat?.battEnergy);
              myBackColor.push(Colors.orange);
            }
            myData.push(this.infoModulator.cfg.limitPower - this.infoModulator?.stat.fvPower - this.infoModulator?.stat?.battEnergy);
            myBackColor.push(Colors.grey);
          }
          else{
            myData.push(this.infoModulator?.stat.fvPower||0);
            myData.push(this.infoModulator?.stat.instPower || this.infoModulator?.stat.totalPower);
            myBackColor.push(Colors.yellow);
            myBackColor.push(Colors.primary);
            if(this.infoModulator?.stat?.battEnergy>0){
              myData.push(this.infoModulator?.stat?.battEnergy);
              myBackColor.push(Colors.orange);
            }
            myData.push(this.infoModulator.cfg.limitPower - (this.infoModulator?.stat.instPower+this.infoModulator?.stat.fvPower+this.infoModulator?.stat?.battEnergy));
            myBackColor.push(Colors.grey);
          }
        }
        else{
          myData = [
            this.infoModulator?.stat.instPower || this.infoModulator?.stat.totalPower,
            this.infoModulator.cfg.limitPower - (this.infoModulator?.stat.instPower||this.infoModulator?.stat?.totalPower)
          ];
          myBackColor = [Colors.primary,Colors.grey];
        }
      }
      else{
        myData = [0,0];
        myBackColor = [Colors.primary,Colors.grey];
      }

      this.chartPie.data.datasets[0].data = myData;

      this.chartPie.options.rotation = this.infoModulator?.stat.instPower<0 ?
        this.infoModulator?.stat.instPower/this.infoModulator?.stat.limitPower * Math.PI * 2 - Math.PI/2
        : -Math.PI/2;

      this.chartPie.data.datasets[0].backgroundColor=myBackColor;
      this.chartPieInside.update();
      this.chartPie.update();
    } catch (error) {

    }
  }

  changeStateConnector(name){
    this.loadingModal=this._modal.open(LoadingPopupComponent);
    var body = {
      uid: 1,
      source: "app",
      priority: 0,
      action: this.connectorsState[name].permission?1:0,
      user: "",
      group: 0
  }
    this._device.updateModuleElement(this.device, "reqman", body, name).subscribe( data => {
        this.loadingModal.close();
      }, err=>{
        this.loadingModal.close();
        this.errorModal = this._modal.open(ErrorModalComponent);
        this.errorModal.componentInstance.description = this._translate.instant("operation_error");
        this.connectorsState[name].permission = !this.connectorsState[name].permission;
      })
  }

  createDoughnutChart(){
    Chart.defaults.RoundedDoughnut    = Chart.helpers.clone(Chart.defaults.doughnut);
    Chart.controllers.RoundedDoughnut = Chart.controllers.doughnut.extend({
    draw: function(ease) {
        var ctx           = this.chart.ctx;
        var easingDecimal = ease || 1;
        var arcs          = this.getMeta().data;
        Chart.helpers.each(arcs, function(arc, i) {
            arc.transition(easingDecimal).draw();

            var pArc   = arcs[i === 0 ? arcs.length - 1 : i - 1];
            var pColor = pArc._view.backgroundColor;

            var vm         = arc._view;
            var radius     = (vm.outerRadius + vm.innerRadius) / 2;
            var thickness  = (vm.outerRadius - vm.innerRadius) / 2;
            var startAngle = Math.PI - vm.startAngle - Math.PI / 2;
            var angle      = Math.PI - vm.endAngle - Math.PI / 2;

            ctx.save();
            ctx.translate(vm.x, vm.y);

            ctx.fillStyle = i === 0 ? vm.backgroundColor : pColor;
            ctx.beginPath();
            ctx.arc(radius * Math.sin(startAngle), radius * Math.cos(startAngle), thickness, 0, 2 * Math.PI);
            ctx.fill();

            ctx.fillStyle = vm.backgroundColor;
            ctx.beginPath();
            ctx.arc(radius * Math.sin(angle), radius * Math.cos(angle), thickness, 0, 2 * Math.PI);
            ctx.fill();

            ctx.restore();
        });
    }
});
  }

  subtractDates(date1, date2) {
    var difference_ms = new Date(date1).getTime() - new Date(date2).getTime();
    //take out milliseconds
    difference_ms = difference_ms / 1000;
    var seconds = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    var minutes = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    var hours = Math.floor(difference_ms % 24);
    if (hours != 0)
      return hours + 'h ' + minutes.toString() + 'm ' + seconds.toString() + 's';
    else return minutes.toString() + 'm ' + seconds.toString() + 's';
  }

  getTranslatedText(translationKey: string) {
    if(translationKey)
      return this._translate.instant(translationKey);
    else
      return null;
  }

  setBoost(active, name){
    if(this.infoModulator?.stat){
      this.loadingModal=this._modal.open(LoadingPopupComponent);
      var body = {
        value: active?this.infoModulator.stat.limitPower:-1
      }
      this._device.updateModuleElement(this.device, "sys", body, name, "boost").subscribe( data => {
        this.loadingModal.close();
        this.connectors.find(conn => conn.name==name).booster=active;
      }, err=>{
        this.loadingModal.close();
        this.errorModal = this._modal.open(ErrorModalComponent);
        this.errorModal.componentInstance.description = this._translate.instant("operation_error");
      })
    }
  }

  isBoosterActivable(state){
    if(state >= 5 && state <= 7)
      return true;
    else
      return false;
  }
}
