import { Component, EventEmitter, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ConnectorState } from 'src/assets/js/constants';
import { forkJoin, Observable, of } from 'rxjs';
import { mergeMap, map, concatMap, catchError, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { idTagInfo, LocalAuthorizationList } from 'src/app/shared/models/components.interface';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-show-group',
  templateUrl: './show-group.component.html',
  styleUrls: ['./show-group.component.scss']
})
export class ShowGroupComponent  implements OnInit{

  breadCrumbs : any;

  group: any;
  @Input() data: any = {}
  linkedDevices: any[] = [];
  // data: any[]=[];
  allStates = ConnectorState;
  modalLoad: any;
  updateStatus: number;
  status : any[] = []
  showStatus = false;
  modal : any;
  ampacity = 32;
  power = 0;
  url = '';
  port = 0;
  @Input() loading : boolean = true;
  confirm = {
    power : false,
    reset : false,
    ocpp: false,
    rfid: false,
    wifi : false,
    password : false,
    cards : false,
    role : false
  }

  selectAll = false;
  selectedDevices: any[] = [];

  chooseCfg : string = 'power';
  rfidEnabled = 0;
  rfidMode = 0;
  eocMode : string | 'any' | 'own' = 'own';
  messageDelCards = false;
  cardsMode : string | 'upload' | 'delete' = 'upload'
  ssid = '';
  wifiPass = '';
  newPass = '';
  accessPoint = 1;
  addBoxId : boolean  = false;
  master = '';
  masterName = '';
  next ='master';
  slaves : any[] = [];
  chargeError : number ;
  interface = 1;
  powerLine : number;
  powerR : number;
  powerS : number;
  powerT : number;
  ip = '';
  slave= true ;

  event = new EventEmitter();
  file: File;
  tagList : any;

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['icon', 'estado', 'nombre', 'rol', 'firmware', 'toma1','toma2', 'potencia', 'spl','config','acciones'];
  sortField: string = '';
  sortDirection: 'asc' | 'desc' = 'asc';
  orginalName :any[] = [];
  fileSelected : boolean = false;
  connectorsMennekes = [];
  currentConn1;
  currentConn2;
  userRole : string = '';
  roles = [
    {value: 'all', viewValue: 'All'},
    {value: 'read', viewValue: 'Read'},
    {value: 'write', viewValue: 'Write'},
    {value: 'owner', viewValue: 'Owner'},
  ];
  selectedRole = '';
  is3Ph = false;
  addressGeolocation : string = '';
  map: google.maps.Map;
  mark: any ;
  latitud: number = 40.4165;
  longitud: number = -3.70256;

  constructor(private _router: Router,
              private _modal: ModalService,
              private _devices: DeviceService,
              private route: ActivatedRoute,
              private _translate: TranslateService,
              private _dashboard: DashboardService
            ) {              }


  ngOnInit() {}

  async ngOnChanges() {
    if(!this.loading){
      this.status=[];
      await Promise.all(
        this.data.devices.map(async (device) => {
          this.status.push({serial: device.serial, status: 3});
          this.orginalName.push({
            serial: device.serial,
            name: device.name,
            alive: device.alive
          });
          await this.getDeviceData(device);
        })
      );

      await this.sortData();
      await this.sortArrayByName(this.data.devices, 'name');
      await this.sortArrayByName(this.selectedDevices, 'name');
      this.dataSource = new MatTableDataSource(this.data.devices);
      this.loading = false;
    }
  }

  async getDeviceData(device){
    await this._devices.getMultiModules(device.serial, ['evsm', 'modulator', 'solar', 'rfid', 'schedman', 'spl', 'ocpp'], 'all', true)
    .toPromise()
    .then(data => {
      data.forEach(module => {
        switch (module.topic_name) {
          case "evsm":
            device.evsmData = module.elements;
            break;
          case "modulator":
            device.modulator = module;
            break;
          case "solar":
            device.solar = module;
            break;
          case "rfid":
            device.rfid = module;
            break;
          case "schedman":
            device.scheduler = module.elements[0].data;
            if(module.elements.length > 1){
              device.scheduler2 = module.elements[1].data;
            }
            break;
          case "spl":
            device.spl = module;
            if ( module.cfg.splMode == 1){
              this.master = device.serial;
              this.masterName = device.name;
              this.powerLine = module.cfg.splLimitPower;
              var splLimitPowerByPhaseArray = JSON.parse( module.cfg.splLimitPowerByPhase);
              this.powerR = splLimitPowerByPhaseArray[0],
              this.powerS = splLimitPowerByPhaseArray[1],
              this.powerT = splLimitPowerByPhaseArray[2],
              this.chargeError = module.cfg.splSlaveFailPower
            }
            break;
          case "ocpp":
            device.ocpp = module;
            break;
        
          default:
            break;
        }
      });
    })
    .catch(error => console.error('Error fetching device data:', error));
  }

  sortArrayByName(array, key) {
    return array.sort((a, b) => {
        const valorA = key.split('.').reduce((o, k) => (o?.[k] ?? ""), a).toString().toLowerCase();
        const valorB = key.split('.').reduce((o, k) => (o?.[k] ?? ""), b).toString().toLowerCase();
        return valorA.localeCompare(valorB);
    });
  }

  checkTasks(tasks: string | any[]): boolean {
    let tasksParsed = typeof tasks === 'string' ? JSON.parse(tasks) : tasks;
    return tasksParsed.some((element: any) => element.initTime?.weekday != 0);
  }

  getNames(){
    this.orginalName = [];
    this.data.devices.forEach(element => {
      this.orginalName.push({
        serial: element.deviceInfo.serial,
        name: element.deviceInfo.name
      });
    });
  }

  getTranslatedText(translationKey: string) {
    if (translationKey)
      return this._translate.instant(translationKey);
    else
      return null;
  }


  updateBreadCrumbs(alias: string, index: number): void {
    const currentBreadCrumbs = this.breadCrumbs;
    const updatedBreadCrumbs = currentBreadCrumbs.slice(0, index +1);
    this._dashboard.replaceBreadCrumbs(updatedBreadCrumbs )
    if (index === 0) {
      this._dashboard.setModoDashboard('list')
      this._router.navigate(['dashboard']);
    } else {
      this._router.navigate(['dashboard/group', alias]);
    }
  }


  configSPL(){

  }

  getLinkedDevices(subgroup){

    this._devices.getLinkedDevices(subgroup)
      .subscribe( data => {
          this.linkedDevices = data
      })
  }

  goToDevice(serial) {
    this._router.navigateByUrl(`/dashboard/device/${serial}`)
  }


  // goToSettings(serial) {
  //   this._router.navigateByUrl(`/dashboard/device/${serial}/settings`)
  // }

  changeName( serial: string ,name : string){

    this.orginalName.forEach(element => {
      if ( element.serial == serial  && element.name != name ) {
        const isConfirmed = confirm('Estas seguro de querer cambiar nombre del cargador ' + element.name + ' por ' + name  +' ?')
        if (isConfirmed) {
          element.name = name; // Realiza el cambio si el usuario acepta
          this._devices.setDeviceInfo(serial, {name: name} ).subscribe( (data)=>{
            console.log('Nombre cambiado exitosamente:', data);
          } )
        } else {
            this.dataSource.filteredData.forEach(fila => {
              if ( fila.serial == serial){
                fila.name  = element.name
              }
            });

        }
      }
    });
  }

  reset(){
      this.confirm.reset = false;
      this.modalLoad=this._modal.open(LoadingPopupComponent);
      for (let index = 0; index < this.selectedDevices.length; index++) {
        if (this.selectedDevices[index].check){
          this._devices.reset( this.selectedDevices[index].serial, 'hard')
          .subscribe( data => {
            this.status[index].status = 1
          }, err => {
            console.log(err);
            this.status[index].status = 2
          }
        )
       }
      }
      setTimeout(() => {
        this.modalLoad.close();
        this.showStatus = true;
      }, 1000);
      setTimeout(() => {
        this.showStatus = false;
      }, 8000);
    // }
  }

  setLimitPower() {
    let phases = 1;
    this.is3Ph ? phases = 3 : phases = 1
    this.confirm.power = false;
    this.modalLoad = this._modal.open(LoadingPopupComponent);
    this.currentConn1 < 6 ? this.currentConn1 = 6 : (this.currentConn1 > 32 ? this.currentConn1 = 32 : this.currentConn1)
    this.currentConn2 < 6 ? this.currentConn2 = 6 : (this.currentConn2 > 32 ? this.currentConn2 = 32 : this.currentConn2)
    for (let index = 0; index < this.selectedDevices.length; index++) {
      if (this.selectedDevices[index].check) {

        this._devices.setModuleCfg(this.selectedDevices[index].serial, {"ampacity" : this.ampacity}, 'sys', ).subscribe( data => { console.log(data);})

        const nameConn =  this.buscarConn(this.selectedDevices[index].serial);
          for (let index2 = 0; index2 < nameConn.length; index2++) {
            this._devices.setModuleElemData(this.selectedDevices[index].serial, 'mennekes', {"limitPower": (index2 == 0 ?  this.currentConn1 : this.currentConn2)  *230 * phases}, nameConn[index2])
              .subscribe(data => {
                this.status[index].status = 1
              }, err => {
                console.log(err);
                this.status[index].status = 2
              })
        };
      }
    }
    setTimeout(() => {
      this.modalLoad.close();
      this.showStatus = true;
    }, 1000);
    setTimeout(() => {
      this.showStatus = false;
    }, 8000);
  }

  buscarConn(serial: string) : any[] {
    let conns: any[] = []

    this.data.devices.forEach(element => {
      if( serial == element.serial ){
        element.element.forEach( conn => {
          conns.push(conn.name)
        })
      }
    }
  );

    return conns

  }


  ocpp() {
      this.confirm.ocpp = false;
      this.modalLoad = this._modal.open(LoadingPopupComponent);
      for (let index = 0; index < this.data.devices.length; index++) {
        if (this.selectedDevices[index].check){
          var body = {
            serverUrl: this.addBoxId && this.url !="" ?  this.url + this.data.devices[index].serial : this.url ,
            // serverUrl: this.url,
            port: this.port
          }

          this._devices.setModuleCfg(this.data.devices[index].serial, body, 'ocpp')
            .subscribe(data => {
              this.status[index].status = 1;
            }, err => {
              console.log(err);
              this.status[index].status = 2;
            })
        }
      }
      setTimeout(() => {
        this.modalLoad.close();
        this.showStatus = true;
      }, 1000);
      setTimeout(() => {
        this.showStatus = false;
      }, 8000);
  }

  setRFID(){
    this.confirm.rfid = false;
    this.modalLoad=this._modal.open(LoadingPopupComponent);
    var body = {
      enabled : this.rfidEnabled,
      rfid : this.rfidMode,
      eocMode : this.eocMode
    }
    for (let index = 0; index < this.selectedDevices.length; index++) {
      if (this.selectedDevices[index].check){
        this._devices.setModuleCfg( this.selectedDevices[index].serial, body, 'rfid' )
        .subscribe( data => {
          this.status[index].status = 1
        }, err => {
          this.status[index].status = 2;
        } )
      }
    }
    setTimeout(() => {
      this.modalLoad.close();
      this.showStatus = true;
    }, 1000);
    setTimeout(() => {
      this.showStatus = false;
    }, 8000);
  }

  setWifi(){
    this.confirm.wifi = false;
    this.modalLoad=this._modal.open(LoadingPopupComponent);
    var body = {
        staEssid: this.ssid,
        staPasswd: this.wifiPass
    }
    for (let index = 0; index < this.selectedDevices.length; index++) {
      if (this.selectedDevices[index].check){
        this._devices.setModuleCfg( this.selectedDevices[index].serial, body, 'network' )
        .subscribe( data => {
          this.status[index].status = 1
        }, err => {
          this.status[index].status = 2;
        } )
      }
    }
    setTimeout(() => {
      this.modalLoad.close();
      this.showStatus = true;
    }, 1000);
    setTimeout(() => {
      this.showStatus = false;
    }, 8000);
  }

  setPassword(){
    this.confirm.password = false;
    this.modalLoad=this._modal.open(LoadingPopupComponent);
    var body = {
      apPasswd: this.newPass,
      apEnabled : this.accessPoint
    }
    for (let index = 0; index < this.selectedDevices.length; index++) {
      if (this.selectedDevices[index].check){
        this._devices.setModuleCfg( this.selectedDevices[index].serial, body, 'network' )
        .subscribe( data => {
          this.status[index].status = 1
        }, err => {
          this.status[index].status = 2;
        } )
      }
    }
    setTimeout(() => {
      this.modalLoad.close();
      this.showStatus = true;
    }, 1000);
    setTimeout(() => {
      this.showStatus = false;
    }, 8000);

  }

  toggleAllDevices(){
    this.selectedDevices.forEach(device => {
      device.check = this.selectAll;
    });
  }


  openModal(){
    this.chooseCfg = 'power';
      this.cancel();
    this.initMap();
  }

  cancel(){
    this.confirm = {
      power : false,
      reset : false,
      ocpp: false,
      rfid: false,
      wifi : false,
      password : false,
      cards : false,
      role : false
    };
    this.cardsMode = 'upload';
    this.messageDelCards = false;
  }

  checkAnyFieldTrue(): boolean {
    return Object.values(this.confirm).some(value => value === true);
  }

  messageConfirm(){
    if (this.confirm.power ) return "setPowerAll";
    if (this.confirm.reset) return "resetAll";
    if (this.confirm.ocpp) return "setOcppAll";
    if (this.confirm.rfid) return "setRfidAll";
    if (this.confirm.wifi) return "setWifiAll";
    if (this.confirm.password) return "setPassAll";
    if (this.confirm.cards) return "setCardsAll";
    if (this.confirm.role) return "setRoleAll";
    return ''
  }

  changeMode(modo : string){
    if ( modo === 'touch'){ this.rfidEnabled = 1; this.rfidMode = 0};
    if ( modo === 'rfid') { this.rfidEnabled = 1; this.rfidMode = 1};
    if ( modo === 'off')  { this.rfidEnabled = 0};
  }

  setCards(modo :string ){
    this.confirm.cards = false;
    this.modalLoad=this._modal.open(LoadingPopupComponent);
    for (let index = 0; index < this.selectedDevices.length; index++) {
      if (this.selectedDevices[index].check){
        if ( modo === 'delete'){
          this._devices.deleteRfidListCard( this.selectedDevices[index].serial)
          .subscribe( data => {
            this.status[index].status = 1
          }, err => {
            this.status[index].status = 2;
          } )
        }else{
          this._devices.modifyRfidListCards( this.selectedDevices[index].serial , this.tagList)
          .subscribe( data => {
            this.status[index].status = 1
          }, err => {
            this.status[index].status = 2;
          } )
        }
      }
    }
    setTimeout(() => {
      this.modalLoad.close();
      this.showStatus = true;
    }, 1000);
    setTimeout(() => {
      this.showStatus = false;
    }, 8000);
  }



  sortData(){
    this.data.devices.sort((a, b) => {
      if(a.spl?.cfg && b.spl?.cfg){
        const modeA = a.spl.cfg.splMode;
        const modeB = b.spl.cfg.splMode;

        if (modeA === 1) return -1; // Coloca primero los 'splmode' con valor 1
        if (modeB === 1) return 1;

        if (modeA === 2) return -1; // Luego los 'splmode' con valor 2
        if (modeB === 2) return 1;
      }
      return 0; // Finalmente los 'splmode' con valor 0
    });

  }

  home(){
    this._dashboard.setUpdateSidebar();
  }

  viewGroup(group) {
    this._router.navigate(['/dashboard/viewgroup', group]);
  }
  // goToSPL(group : string){
  //   this._router.navigate(['/dashboard/splconfig', group]);
  // }
  goToSPL(serial : string){
    if (this.master != '')
      this._router.navigateByUrl(`/dashboard/device/${serial}/spl`)
  }
  goTodetailGroup(group){
    this._router.navigate(['/dashboard/newgroup', group]);
  }

  infoGroup(group){
    this._dashboard.setBreadCrumbs({
      name: group.name,
      alias: group.alias
    });
    this._router.navigate(['/dashboard/group', group.alias]);
  }

  detailGroup(group){
    this._dashboard.setBreadCrumbs({
      name: group.name,
      alias: group.alias
    });
    this._router.navigate(['/dashboard/group', group.alias, 'settings']);
  }

  onFileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      this.file = inputElement.files[0];
       this.onUpload();
    }
  }


  async onUpload() {
    const workbook = XLSX.read(await this.file.arrayBuffer());
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    let raw_dat = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    let result = this.filterDuplicatedItems(raw_dat);
    let idTagRpt = this.checkIdTag(raw_dat)

    if (idTagRpt < 1) {
      if (result.duplicateRows.length < 1) {
        if (this.checkInvalidDates(result.uniqueRows) > 0) {
          this.fileSelected = false;
          console.log('---- ERROR ----')
        } else {
          try {
            const raw_data = this.getStatusCodeString(raw_dat);
            const xlsCardsArray: LocalAuthorizationList[] = [];
            for (let i = 1; i < raw_data.length; i++) {
              if(raw_data[i].length > 1){
                const item = raw_data[i];
                const idTag = item[0];
                const expiryDate = item[1] ? moment(item[1]).format('YYYY-MM-DDTHH:mm') : '';
                const parentIdTag = item[2];
                const status = item[3] ? item[3] : 'Accepted';
                const tagInfo: idTagInfo = { parentIdTag, expiryDate, status };
                const lal: LocalAuthorizationList = { idTag: idTag, idTagInfo: tagInfo };
                xlsCardsArray.push(lal);
              }
            }
            this.tagList = this.formatJson(xlsCardsArray);
            this.fileSelected = true;
          } catch (error) {
            console.log(error);
          }
        }
      }
      else {
        console.log('---- ERROR ----');
      }
    }
    else {
          console.log('----- Error al cargar archivo de tarjetas ------- ');
    }
  }

  filterDuplicatedItems(data: any): { uniqueRows: any[][]; duplicateRows: any[][] } {
    var uniqueRows: any[][] = [];
    var duplicateRows: any[][] = [];
    var seenValues: { [key: string]: boolean } = {};
    for (var i = 0; i < data.length; i++) {
      var row = data[i];
      var value = String(row[0]);
      if (row.length > 0 && !seenValues[value]) {
        uniqueRows.push(row);
        seenValues[value] = true;
      } else if (row.length > 0) {
        duplicateRows.push(row);
      }
    }
    return { uniqueRows: uniqueRows, duplicateRows: duplicateRows };
  }

  checkIdTag(obj: any[]): number {
    let count = 0;
    for (let i = 0; i < obj.length - 1; i++) {
      const array = obj[i];
      if (array.length === 0 || array[0] === null || array[0] === undefined || array[0] === '') {
        count++;
      }
    }
    return count;
  }

  checkInvalidDates(dataObject){
    const datesArray: string[] = dataObject.slice(1).map((item: any) => item[1]);
    let falseCount: number = 0;
    datesArray.forEach((item) => {
      if (!this.isValidDate(item)) {
        falseCount++;
      }
    });
    return falseCount;
  }
  isValidDate(dateString) {
    return dateString === '' || dateString === undefined|| moment(dateString, 'YYYY-MM-DDTHH:mm', true).isValid();
  }

  getStatusCodeString(array: any){
    const statusMap: { [key: number]: string } = {
        0: 'Accepted',
        1: 'Rejected',
        2: 'Expired',
        3: 'Invalid',
        4: 'In transaction',
        5: 'No credit',
        // 6: 'Blocked',
        // 7: 'ConcurrentTx',
        // 8: 'NotDefined'
    };

    const newArray = array.map(innerArray => {
      return innerArray.map(element => {
          if (typeof element === 'number') {
              return statusMap[element] || '';
          }
          return element;
      });
  });
  return newArray;
}

formatJson(jsonData: any[]): { file: string } {
  const csvData = jsonData.reduce((acc, obj, i) => {
    const idTag = obj.idTag || '';
    const expiryDate = obj.idTagInfo?.expiryDate || '';
    const parentIdTag = obj.idTagInfo?.parentIdTag || '';
    const status = obj.idTagInfo.status ? this.getCodeFromStatus(obj.idTagInfo.status) : 0;
    const statusCode = status;
    const lineSeparator = i === 0 ? '\n' : '';
    return `${acc}${lineSeparator}${idTag};${expiryDate};${parentIdTag};${statusCode}\r\n`;
  }, '2\r');

  return { file: csvData };}


getCodeFromStatus(status: string): number | string {
  const statusMap: { [key: string]: number | string } = {
    'Accepted': 0,
    'Rejected': 1,
    'Expired': 2,
    'Invalid': 3,
    'In transaction': 4,
    'No credit': 5,
    //'Blocked': 6,
    //'ConcurrentTx': 7,
    //'NotDefined': 8
  };
  const code = statusMap[status];
  return code !== undefined ? code : '';
}

sortTable(field: 'name' | 'estado' | 'icon') {
  if (!this.data.devices) {
    return; // Evita errores si los datos aún no están cargados
  }

  this.sortField = field;
  this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';

  this.data.devices.sort((a, b) => {
    let valueA: any, valueB: any;

    switch (field) {
      case 'name':
        valueA = a.name?.toLowerCase() || '';
        valueB = b.name?.toLowerCase() || '';
        break;
      case 'estado':
        valueA = a.alive || 0;
        valueB = b.alive || 0;
        break;
      case 'icon':
        valueA = a.serial || '';
        valueB = b.serial || '';
        break;
    }

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return this.sortDirection === 'asc'
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    }

    return this.sortDirection === 'asc'
      ? valueA - valueB
      : valueB - valueA;
  });

  this.dataSource.data = this.data.devices;
}


change(slave){
  this.slaves.forEach(element => {
    if (element.serial == slave.serial){
      element.checked = slave.checked
    }
  });
  this.slave = this.checkAnyFieldTrue2()
}

checkAnyFieldTrue2(): boolean {
  return this.slaves.some(element => element.checked);
}

nextSection(){
  this.next === 'master' ? this.next = 'slave' : this.next = 'master'
  this.slaves = this.data.devices
    .filter(device => device.serial !== this.master)
    .map(device => ({
      ...device,
      checked: true
    }));
}

save(){
  this.modalLoad=this._modal.open(LoadingPopupComponent);

  var bodyMaster = {
    splFactory : 2,
    splMode: 1, // 1 master 2 slave 0 deshabilitado
    splInterface: this.interface == 3 ? 3 : 1,
    splIp : this.ip,
    splPort: this.port,
    splLimitPower: this.powerLine,
    splSlaveFailPower: this.chargeError,
    splLimitPowerByPhase:[this.powerR,this.powerS,this.powerT]
  }

  var bodySlave = { ...bodyMaster, splMode: 2 };

  this._devices.setModuleCfg(this.master, bodyMaster, 'spl' )
  .subscribe( (data)=> {
    this.modalLoad.close();
  }, err => {
    console.log(err);
    this.modalLoad.close();
  });
  this.slaves.forEach(element => {
    this._devices.setModuleCfg(element.serial, bodySlave, 'spl')
    .subscribe ( (data) => {
      console.log('uptade slave spl ', data);
    })
  });
}

setRole() {
  this.modalLoad=this._modal.open(LoadingPopupComponent);

  for (let index = 0; index < this.selectedDevices.length; index++) {
    if (this.selectedDevices[index].check) {
      this._devices.assignDevices(
        this.selectedDevices[index].serial,
        this.userRole,
        this.selectedRole
      ).subscribe((data) => {
        console.log(`Dispositivo ${this.selectedDevices[index].serial} asignado con rol ${this.selectedRole} al usuario ${this.userRole}` );
        this.status[index].status = 1
      }, error => {
        console.error(`Error al asignar el rol ${this.selectedRole} al dispositivo ${this.selectedDevices[index].serial}`, error);
        this.status[index].status = 2
      });
    }
    setTimeout(() => {
      this.modalLoad.close();
      this.showStatus = true;
    }, 1000);
    setTimeout(() => {
      this.showStatus = false;
    }, 8000);
  }


}



goToSettings(serial, component){
  this._router.navigate(['dashboard/device', serial, 'settings', component]);

}

  async initMap(): Promise<void> {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    const Places = await google.maps.importLibrary("places") as google.maps.PlacesLibrary;

    var geocoder = new google.maps.Geocoder();

    this.mark = new AdvancedMarkerElement({
      position: { lat: this.latitud, lng: this.longitud },
      title: 'cargador'
    });

    const mapOptions = {
      zoom: 17,
      center: { lat: this.latitud, lng: this.longitud },
      mapId: 'mapaCargadores',  //Campo obligatorio
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false
      // disableDefaultUI: true,
    };


    geocoder.geocode( { 'location' : {lat : this.latitud, lng: this.longitud}}, (results) =>{
      if(results?.[0])
      this.addressGeolocation = results![0].formatted_address;
    } )

    this.map = new Map(document.getElementById('mapGeolocation') as HTMLElement, mapOptions);
    this.mark.setMap(this.map);

    this.map.addListener('click', (location) => {
      this.latitud = location.latLng.lat()
      this.longitud = location.latLng.lng()

      this.mark.map = null; // Borra la anterior marca

      this.mark = new AdvancedMarkerElement({
        position: { lat: this.latitud, lng: this.longitud },
        map: this.map
      })
      this.changeGeolocation(this.latitud, this.longitud);
    })

    const input = document.getElementById("pac-input") as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);
    // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input); // Añade el input del Autocomplete al mapa
    this.map.addListener("bounds_changed", () => {
      searchBox.setBounds(this.map.getBounds() as google.maps.LatLngBounds);
    });

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (places?.length == 0) {
        return;
      }
      // Por cada 'Place', get icono, nombre y localización.
      const bounds = new google.maps.LatLngBounds();
      places?.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }
        this.latitud = place.geometry.location.lat();
        this.longitud = place.geometry.location.lng();

        this.mark.setMap(null);
        this.mark = new AdvancedMarkerElement({
          position: place.geometry.location,
          map: this.map,
          title: place.name
        })

        this.changeGeolocation(this.latitud, this.longitud);

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.map.fitBounds(bounds);
    })

  }

  changeGeolocation(lat : number, lng : number) {
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({location: {lat: lat, lng: lng}}, (results, status) =>{
      if (status === 'OK'){
        if (results![0] ){
          console.log(results![0].formatted_address);
          this.addressGeolocation = results![0].formatted_address;
        }
      }
    })
  }

}
