import { HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone, OnDestroy } from '@angular/core';

import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
import { Cookie } from 'ng2-cookies';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DeviceService } from '../devices/device.service';

@Injectable({
  providedIn: 'root'
})
export class SseService implements OnDestroy {

  private url = environment.baseUrl;
  eventSource= NativeEventSource || EventSourcePolyfill;

  device: string;

  constructor(private _zone: NgZone, private _device: DeviceService) { }

  getEventSource(device) {    
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + Cookie.get('access_token')
    }

    if(this.device!=device || !this.device){
      if(this.device)
        this.closeEventSource();
      this.eventSource = new EventSourcePolyfill(this.url + '/user/' + this._device.user + '/device/' + device + '/eventSSE',
      { headers });
    }
    this.device = device;
  }

  getEvents(){
    return Observable.create(observer=>{ 
      this.eventSource.onmessage = event => {
        this._zone.run(()=>{
          observer.next(event)
        })
      }
      
      this.eventSource.onopen = (a) => {
        console.log('opened')
      };
  //     eventSource.onerror = (e) => {
  //       // Do stuff here
  //     }
     })
  }

  ngOnDestroy(){
    this.closeEventSource();
  }

  closeEventSource(){
    //this.eventSource.removeAllListeners();
    this.eventSource.close();
  }

}
