import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CustomModalComponent } from 'src/app/components/ui/custom-modal/custom-modal.component';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { UserService } from 'src/app/services/user/user.service';


@Component({
  selector: 'app-admin-search',
  templateUrl: './admin-search.component.html',
  styleUrls: ['./admin-search.component.scss']
})
export class AdminSearchComponent {

  userSearch: string = '';
  serialSearch: string = '';

  userVerificationField: string = '';

  resultsSearch: any[] = [];

  loading=false;
  modalNoUser: NgbModalRef;
  errorMessage=false;
  deleteMessage=false;

  constructor(private _device: DeviceService, private _router: Router, private _modal: ModalService, private _user: UserService) { }

  ngOnInit(): void {

  }

  searchBySerial(){
    this.deleteMessage=false;
    this.errorMessage=false;
    this.loading=true;
    this.resultsSearch = [];

    if(this.serialSearch!=''){
      this._device.getDeviceInfo(this.serialSearch)
      .subscribe(data=>{
        console.log(data);
        this.resultsSearch.push(data);
        this.userSearch=data.user_build;
        this.loading=false;
        /*if(!data.user_build){
          this.modalNoUser = this._modal.open(CustomModalComponent);
          this.modalNoUser.componentInstance.title="El dispositivo no tiene ningún usuario asignado";
          this.modalNoUser.componentInstance.description="Por favor, asígnele ahora uno";
          this.modalNoUser.result.then(() => { this._router.navigate(['/dashboard/administration/assign-device']) }, () => { this._router.navigate(['/dashboard/administration/assign-device'])})
        }*/
      }, err=>{
        this.loading=false;
        this.errorMessage=true;
      })
    }

  }

  searchByUser(){
    this.errorMessage=false;
    this.loading=true;
    if(this.userSearch!=''){
      this._device.getDevicesByUser()
      .subscribe(data=>{
        console.log(data);
        this.resultsSearch=data;
        this.loading=false;

      }, err=>{
        this.loading=false;
        this.errorMessage=true;
      })
    }

  }

  navigateDevice(url, device, subpath?: string){
    //this._device.setUserDevice(this.userSearch);
    setTimeout(() => {
      subpath ? this._router.navigate([url, device, subpath]) : this._router.navigate([url, device]);
    }, 500);
  }

  resetHistorics(deviceSerial){
    if(!confirm("¿Está seguro de que desea eliminar todos los históricos del dispositivo? Esta acción no se puede deshacer."))
      return;

    this._device.resetHistoricsByDevice(deviceSerial)
    .subscribe((response: HttpResponse<Blob>) => {
      var downloadURL = window.URL.createObjectURL(response.body);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "historics_"+deviceSerial+".xlsx";
      link.click();
      this.deleteMessage=true;
    })
  }

  verifyUser(){
    this.loading=true;
    this._user.verifyUserByAdministrator(this.userVerificationField).subscribe(data=>{
      if(data.emailVerified){
        alert("Usuario activado correctamente");
      }else{
        alert("El usuario no se ha podido activar, compruebe que el email introducido es correcto");
      }
    }, err=>{
      alert("Error al activar el usuario: "+err.message);
    }, 
    ()=>{this.loading=false})
  }

}
