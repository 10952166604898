import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DeviceService } from 'src/app/services/devices/device.service';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { ModalService } from 'src/app/services/modal/modal.service';
import { DOCUMENT } from '@angular/common';
import { Inject }  from '@angular/core';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements OnInit {

  serial: string = '';
  modules: any[] = [];

  successMessage = {
    cfg: '',
    stat: ''
  };
  error = {
    cfg: '',
    stat: ''
  };
  successMessageElement = '';
  errorElement = '';

  document:any = null;
  analyzers: any[] = [];
  formattedData: any[] = [];
  clock : any = null
  object : any

  constructor(private _device: DeviceService, private _aRoute: ActivatedRoute, private _modal: ModalService, @Inject(DOCUMENT) document: Document) {
    this.document=document;
  }

  ngOnInit(): void {

    this._aRoute.parent?.params
    .subscribe((params: Params) => {
      this.serial = params['serial'];
      this.loadData();
    });

  }

  loadData(){
    this._device.getModulesByDevice(this.serial, "level=all&elements=true")
      .subscribe(data => {
        this.modules=data;
        /*data.forEach(module => {
          this.modules.push(module.topic_name);
        });
        console.log(this.modules)*/
      })
  }

  getData(module, command, element = undefined){
    var modalLoad = this._modal.open(LoadingPopupComponent);
    var submodule = "";
    if(module == "rfid" || module == "spl" || module == "network"){
      submodule = module;
      module = "sys";
    }
    else if(module == "clock"){
      submodule = module;
      module = "astcal";
    }
    this._device.getModuleInfo(this.serial, module, command, {localdata: true})
    .subscribe(data => {
      console.log(data)
      modalLoad.close();
      if(submodule=="clock"){
        this.modules.find(m=>m.topic_name==submodule)[command] = data[submodule][command];
        this.successMessage[command] = submodule;
      }
      else if(submodule!=""){
        this.modules.find(m=>m.topic_name==submodule)[command] = data.modules[submodule][command];
        this.successMessage[command] = submodule;
      }
      else{
        this.modules.find(m=>m.topic_name==module)[command] = data[command];
        this.successMessage[command] = module;
      }
      setTimeout(() => {
        this.successMessage[command] = '';
      }, 5000);
    }, err => {
      console.error(err)
      this.error[command] = module;
      setTimeout(() => {
        this.error[command] = '';
      }, 5000);
      modalLoad.close();
    });
  }

  getElementData(module, idModule, indexElement){
    var element = this.modules.find(m=>m.topic_name==module).elements[indexElement];
    if(this.document.getElementById("element_tab_"+idModule+"_"+(indexElement+1)+"_1").classList.contains("active"))
      var command = "cfg";
    else
      var command = "stat";
    var modalLoad = this._modal.open(LoadingPopupComponent);
    this._device.getElementModule(this.serial, module, element.name, command , {localdata: true})
    .subscribe(data => {
      console.log(data)
      element.data[command] = data[command];
      modalLoad.close();
      this.successMessageElement = module;
        setTimeout(() => {
          this.successMessageElement = '';
        }, 5000);
    }, err => {
      console.error(err)
      this.errorElement = module;
      setTimeout(() => {
        this.errorElement = '';
      }, 5000);
      modalLoad.close();
    }
  );
  }

  exportModules(){
    const jsonData = JSON.stringify(this.modules, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = 'InfoModulos.json';
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);
  }

  getDataAnalyzers(){
    var modalLoad = this._modal.open(LoadingPopupComponent);
    this._device.getModuleanalyzers(this.serial).subscribe( data=>{
      this.analyzers=data.analyzers
      console.log(this.analyzers)
      this.formattedData = this.analyzers.map(analyzer => ({
        serial: analyzer.serial,
        stat: analyzer.stat
      }))
      modalLoad.close();
      this.successMessageElement = 'Analyzer';
      setTimeout(() => {
        this.successMessageElement = '';
      }, 5000)

  },err => {
    console.error(err)
    this.errorElement = 'Analyzer';
    setTimeout(() => {
      this.errorElement = '';
    }, 5000);
    modalLoad.close();
  }
)
  }

  getAstCalendar(){
    var modalLoad = this._modal.open(LoadingPopupComponent);
    this._device.getModuleInfo( this.serial, 'astcal', 'cfg', {localdata: true})
      .subscribe(  data =>{
        this.clock = data.clock.cfg.geoloc
        modalLoad.close();
        console.log('data clock ', this.clock);
        this.object = JSON.stringify(data)
        console.log(this.object);
      })


  }

}
