export const Colors={
    primary:'#00778B',
    secondary:'#029ab4',
    primary_light: '#d9f1f5',
    primary_lighter: '#74B7CC',
    accent: '#6DF0C9',
    accent2: '#8be6c0',
    grey:"#E8EAED",
    yellow: '#F0D966',
    yellow_brown: '#F0C382',
    dark_red: '#BD231D',
    blue: '#2280D1',
    accent_blue: '#56DAE0',
    green: '#38C485',
    orange: '#fcac48'
}