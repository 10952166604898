import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-verification-modal',
  templateUrl: './verification-modal.component.html',
  styleUrls: ['./verification-modal.component.scss'],
  providers: [NgbModal]
})
export class VerificationModalComponent implements OnInit {

  @Input()
  description: string;
  title: string;
  sendResult: string;
  resendVerificationSpan: string;
  resendingVerification:boolean = false;
  checkVerificationSpan: string;
  checkingVerification:boolean = false;

  constructor(private activeModal: NgbActiveModal, private _auth: AuthService) {
    this.description = 'Cuenta no verificada, comprueba tu email. Puedes volver a solicitar el email de verificación haciendo click en el botón.';
    this.title = 'Cuenta no verificada';
    this.resendVerificationSpan = 'Reenviar';
    this.checkVerificationSpan = 'Comprobar';
  }

  ngOnInit(): void {
  }

  reCheckUserValidation(){
    this.checkVerificationSpan = 'Comprobando...';
    this.checkingVerification = true;
    this._auth.user.subscribe(data=>{
      if(data?.emailVerified){
        this.activeModal.close();
      }else{
        this.sendResult = 'Email no verificado';
      }
      //Metemos un pequeño delay
      setTimeout(() => {
        this.checkVerificationSpan = 'Comprobar';
        this.checkingVerification = false;
      }, 1000);
    });

  }

  resendVerification(){
    this.resendVerificationSpan = 'Enviando...';
    this.resendingVerification = true;
    try {
      this._auth.verifyEmail();
      this.sendResult = 'Email enviado';

    } catch (error) {
      this.sendResult = 'Error al enviar el email. Inténtalo de nuevo más tarde.';
    }
    //Metemos un pequeño delay
    setTimeout(() => {
      this.resendVerificationSpan = 'Reenviar';
      this.resendingVerification = false;
    }, 1000);
  }

  logout(){
    this.activeModal.close();
    this._auth.logout();
  }

}
