  <div class=" header p-3 bg-primary" style="min-height: 100px;">
    <h1 class="header-title">
      {{"search" | translate}}
    </h1>
</div>
<div class="col-12 col-xl-12">
    <div class="card">
        <div class="card-header">
            <h5 class="card-title">{{"searchBy" | translate}}</h5>
            <h6 class="card-subtitle text-muted"></h6>
        </div>
        <div class="card-body">
            <form class="row align-items-center">
                <div class="form-group col-xl-6 justify-content-center d-flex">
                    <div>
                        <label class="col-form-label col-sm-6">{{"user" | translate}}</label>
                    <div class="col-12">
                        <input type="email" class="form-control" [placeholder]="'user' | translate" name="user" [(ngModel)]="userSearch">
                    </div>
                    </div>
                </div>

                <div class="form-group col-xl-6 justify-content-center d-flex">
                    <div>
                        <label class="col-form-label col-sm-12">{{"serial" | translate}}</label>
                    <div class="col-12">
                        <input type="text" class="form-control" [placeholder]="'serial_number' | translate" name="serial" [(ngModel)]="serialSearch">
                    </div>
                    </div>
                </div>

                <div class="form-group row justify-content-center d-flex">
                    <div class="justify-content-center d-flex col-6">
                        <button type="submit" class="btn btn-primary" (click)="searchByUser()">{{"search" | translate}}</button>
                    </div>
                    <div class="justify-content-center d-flex col-6">
                        <button type="submit" class="btn btn-primary" (click)="searchBySerial()">{{"search" | translate}}</button>
                    </div>
                </div>
                <div *ngIf="loading">
                    <div class="d-flex justify-content-center align-items-center h-100">
                      <div class="spinner-grow text-primary" style="width: 4rem; height: 4rem;" role="status">
                        <span class="sr-only">{{"loading" | translate}}...</span>
                      </div>
                    </div>
                  </div>
            </form>
            <div class="results-search p-3">
                <div class="alert alert-warning alert-dismissible" role="alert" *ngIf="errorMessage">
                    <div class="alert-message">
                      {{"notFoundResults" | translate}}
                    </div>
                </div>
                <div class="alert alert-success alert-dismissible" role="alert" *ngIf="deleteMessage">
                  <div class="alert-message">
                      {{"deletedData" | translate}}
                  </div>
                </div>
                <div class="row mb-2" *ngIf="resultsSearch.length > 0">
                    <div class="col-3">
                        <h5>{{"serial" | translate}}</h5>
                    </div>
                    <div class="col-3">
                        <h5>{{"name" | translate}}</h5>
                    </div>
                    <div class="col-3">
                        <h5>{{"owner" | translate}}</h5>
                    </div>
                </div>
                <div class="row mb-2" *ngFor="let device of resultsSearch">
                    <div class="col-3 align-self-center">
                        {{device.serial}}
                    </div>
                    <div class="col-3 align-self-center">
                        {{device.name}}
                    </div>
                    <div class="col-3 align-self-center">
                        {{device.user_build}}
                    </div>
                    <div class="col-3 d-flex justify-content-center">
                        <button class="btn btn-primary mr-2" (click)="navigateDevice('/dashboard/device', device.serial, 'diagnosis')"  [ngbTooltip]="'diagnosis' | translate">
                            <h4 class="m-0"><i class="fas fa-stethoscope text-white"></i></h4>
                        </button>
                        <button class="btn btn-primary mr-4" (click)="navigateDevice('/dashboard/device', device.serial)"  [ngbTooltip]="'settings' | translate">
                            <h4 class="m-0"><i class="fas fa-angle-right text-white"></i></h4>
                        </button>
                        <button class="btn btn-danger mr-2" (click)="resetHistorics(device.serial)" [ngbTooltip]="'deleteAll' | translate">
                          <h4 class="m-0"><i class="fas fa-trash text-white"></i></h4>
                        </button>
                        <button class="btn btn-danger mr-2" (click)="unlinkDevice(userSearch, device.serial)"  [ngbTooltip]="'unlinkDevice' | translate">
                          <h4 class="m-0"><i class="fas fa-unlink text-white"></i></h4>
                      </button>
                    </div>
                </div>
            </div>
        </div>


<div class="card-body">
    <div class="card-header">
        <h5 class="card-title">Verificar usuario</h5>
        <h6 class="card-subtitle text-muted"></h6>
    </div>
    <form class="row">
        <div class="form-group col-xl-6 justify-content-center d-flex">
            <div>
                <label class="col-form-label col-sm-6">{{"user" | translate}}</label>
                <div class="col-12">
                    <input type="email" class="form-control" placeholder="Email del usuario" name="email" [(ngModel)]="userVerificationField">
                </div>
            </div>
        </div>
        <div *ngIf="loading">
            <div class="d-flex justify-content-center align-items-center h-100">
                <div class="spinner-grow text-primary" style="width: 4rem; height: 4rem;" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </form>
</div>
</div>


