<div [ngClass]="{'col-12 col-xl-6' : !allSettings}">
    <div class="card">
        <div class="card-header">
            <h5 class="card-title">{{"fw_update" | translate}}</h5>
        </div>
        <div class="card-body">
            <form>
                <div class="form-group row">
                    <div class="col-8">
                        <strong>{{"versionDevice" | translate}}</strong>
                    </div>
                    <div class="col-4">
                        {{device?.firmware_version_name}}
                    </div>
                </div>
                <div class="form-group row" *ngIf="availableNewVersion">
                    <p class="text-grey">{{"newVersionAv" | translate}}</p>
                    <strong class="text-center">
                        {{latestVersion?.name}}
                    </strong>
                </div>

                <div class="form-group row">
                    <div class="col-sm-4 m-auto text-center">
                        <button type="submit" class="btn btn-primary" (click)="update()"
                            [disabled]="!availableNewVersion">{{"do_update" | translate}}</button>
                    </div>
                </div>
            </form>
            <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error!=''">
                <div class="alert-message">
                    {{ error }}
                </div>
            </div>
            <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage">
                <div class="alert-message">
                    {{"updatedSuccesfully" | translate}}
                </div>
            </div>
        </div>
    </div>
</div>
