<div class="header d-flex justify-content-between p-3 bg-primary" style="min-height: 100px;">
  <h1 class="header-title">
    {{"device_assignment" | translate}}
  </h1>
  </div>
<div class="col-12 col-xl-12" style="min-height: 35vmax;">
  <div class="card">
    <div class="card-header">
      <h5 class="card-title"></h5>
      <h6 class="card-subtitle text-muted"></h6>
    </div>
    <div class="card-body">
      <form class="row align-items-center">
        <div class="ps-5  form-group col-xl-5 justify-content-center d-flex  align-items-center">
          <div class="w-100 ps-5" >
            <label class="col-10 ">{{"user" | translate}}</label>
            <div class="col-12 ">
              <input type="email" class="col-12 form-control" placeholder="User" [(ngModel)]="user" name="user">
            </div>
          </div>
        </div>
        <div class="col-2 justify-content-center d-flex">
          <i style="font-size: 40px;" class="fas fa-link"></i>
        </div>
        <div class="form-group col-xl-5 justify-content-center d-flex  align-items-center">
          <div class="w-100">
            <label class="col-12 col-form-label col-sm-12">{{"serial" | translate}}</label>
            <div class="col-12 row">
              <div class="col-6">
                <input type="text" class="form-control" placeholder="Serial" [(ngModel)]="body.serial" name="serial">
              </div>
              <div class="col-4" >
                <button type="button" class="col-6 btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  {{'succesDownload' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row justify-content-center d-flex">
          <div class="justify-content-center d-flex">
            <button type="submit" class="btn btn-primary" [disabled]="!user || !body.serial" (click)="save()">{{"link" | translate}}</button>
          </div>
        </div>
      </form>

      <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="response.error && error404">
        <div class="alert-message">
          {{ "error404" | translate }}
        </div>
      </div>

      <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="response.error && error500">
        <div class="alert-message">
          {{ "error500" | translate }}
        </div>
      </div>

      <div class="alert alert-success alert-dismissible" role="alert" *ngIf="response.success">
        <div class="alert-message">
          {{"settings.successMessage" | translate}}
        </div>
      </div>
    </div>
  </div>
</div>

<button type="button" id="toggle-name-modal" class="btn btn-primary d-none" data-toggle="modal"
  data-target="#centeredModalPrimary">
  Primary
</button>
<div class="modal fade" id="centeredModalPrimary" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{"confirmAssgnmt" | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body m-3 text-center">
        <p class="mb-0">¿{{"assignDev" | translate}} {{body.serial}} {{"toUser" | translate}} {{user}}?</p>
        <p>{{"youWantToContinue" | translate}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" id="dismiss-confirm-modal" class="btn btn-secondary" data-dismiss="modal">{{"cancel" | translate}}</button>
        <button type="button" class="btn btn-primary" (click)="assign()">{{"assign" | translate}}</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{'uploadFile' | translate}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="col-12 text-right">
          <input class="form-control" type="file" (change)="onFileChange($event)"  #input accept=".xls, .xlsx, .csv">
        </div>
        <div *ngIf="data.length >0" class="text-center p-1">
          <h6 >{{'devices'|translate}}:</h6>
          <div class=""  *ngFor="let serial of data">
            <span class=" text-primary"  style="width: fit-content;">{{serial}}</span>
          </div>
        </div>
        <div *ngIf="user == ''" class="d-flex justify-content-center  p-5">
          <h6 class="text-warning">{{'needEmail' | translate}}</h6>
        </div>
        <div *ngIf="showResult" class="col-12  d-flex justify-content-center mt-3">
          <div *ngIf="messageOK.length > 0  " class="col-6">
            <h6 > {{'assignOK' | translate}}: </h6>
              <div *ngFor="let serialOK of messageOK" > {{serialOK.serial}}</div>
          </div>
          <div *ngIf="messageError.length == 0 && messageOK.length > 0"  class="col-6 d-flex justify-content-center align-items-center">
            <i class="fas fa-check-circle fs-1"></i>
          </div>
          <div *ngIf="messageError.length > 0 && messageOK.length == 0"  class="col-6 d-flex justify-content-center align-items-center">
            <i class="text-danger fas fa-times-circle fs-1"></i>
          </div>
          <div *ngIf="messageError.length > 0" class="col-6 ">
            <h6 class="text-danger" > {{'assignKO' | translate }}:</h6>
            <div class="text-warning" *ngFor="let serialError of messageError" > {{serialError.serial}}</div>
          </div>

        </div>
        <div class="d-flex justify-content-center p-3">
          <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal" (click)="resetData()" >{{'close' | translate}} </button>
          <button type="button" class="btn btn-primary ms-2" (click)="send()" >{{'accept'| translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
