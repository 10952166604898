<h3>Modules
  <button class="btn btn-primary float-right"  type="submit" (click)="exportModules()" >{{"export" | translate}}</button>
</h3>

<div class="d-flex flex-sm-wrap">
    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12" *ngFor="let module of modules; let i = index">
        <div class="tab">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active text-primary font-weight-bold" href="#icon-tab-1_{{ i }}" data-bs-toggle="tab" role="tab">
                        {{module.name}}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#icon-tab-2_{{ i }}" data-bs-toggle="tab" role="tab">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings align-middle"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#icon-tab-3_{{ i }}" data-bs-toggle="tab" role="tab">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square align-middle"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                    </a>
                </li>
                <li class="nav-item" *ngIf="module.name ==='AMManager'">
                  <a class="nav-link" href="#icon-tab-analyzer_{{ i }}" data-bs-toggle="tab" role="tab">
                    <i class="fas fa-pager fa-lg" ></i>
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square align-middle"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg> -->
                  </a>
              </li>
              <li class="nav-item" *ngIf="module.name ==='AstCalendar'">
                <a class="nav-link" href="#icon-tab-clock{{ i }}" data-bs-toggle="tab" role="tab">
                  <i class="far fa-clock"></i>
                </a>
            </li>
                <li class="nav-item" *ngIf="module.elements && module.elements[0]">
                    <a class="nav-link" href="#icon-tab-4_{{ i }}" data-bs-toggle="tab" role="tab"
                    *ngIf="module.elements[0].name.startsWith('mennekes')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" viewBox="4 4 16 16" class="feather feather-message-square align-middle">
                            <path d="M7.5 11C7.5 11.28 7.28 11.5 7 11.5S6.5 11.28 6.5 11 6.72 10.5 7 10.5 7.5 10.72 7.5 11M9.5 14.5C9.22 14.5 9 14.72 9 15S9.22 15.5 9.5 15.5 10 15.28 10 15 9.78 14.5 9.5 14.5M9.5 8C9.78 8 10 7.78 10 7.5S9.78 7 9.5 7 9 7.22 9 7.5 9.22 8 9.5 8M14.5 8C14.78 8 15 7.78 15 7.5S14.78 7 14.5 7 14 7.22 14 7.5 14.22 8 14.5 8M12 10.5C11.72 10.5 11.5 10.72 11.5 11S11.72 11.5 12 11.5 12.5 11.28 12.5 11 12.28 10.5 12 10.5M20 11C20 15.42 16.42 19 12 19S4 15.42 4 11C4 8.61 5.06 6.47 6.72 5H17.28C18.94 6.47 20 8.61 20 11M13 7.5C13 8.33 13.67 9 14.5 9S16 8.33 16 7.5 15.33 6 14.5 6 13 6.67 13 7.5M8 7.5C8 8.33 8.67 9 9.5 9S11 8.33 11 7.5 10.33 6 9.5 6 8 6.67 8 7.5M7 13C8.11 13 9 12.11 9 11C9 9.9 8.11 9 7 9S5 9.9 5 11C5 12.11 5.9 13 7 13M11.5 15C11.5 13.9 10.61 13 9.5 13S7.5 13.9 7.5 15C7.5 16.11 8.4 17 9.5 17S11.5 16.11 11.5 15M12 13C13.11 13 14 12.11 14 11C14 9.9 13.11 9 12 9S10 9.9 10 11C10 12.11 10.9 13 12 13M16.5 15C16.5 13.9 15.61 13 14.5 13S12.5 13.9 12.5 15C12.5 16.11 13.4 17 14.5 17S16.5 16.11 16.5 15M19 11C19 9.9 18.11 9 17 9S15 9.9 15 11C15 12.11 15.9 13 17 13S19 12.11 19 11M17 10.5C16.72 10.5 16.5 10.72 16.5 11S16.72 11.5 17 11.5 17.5 11.28 17.5 11 17.28 10.5 17 10.5M14.5 14.5C14.22 14.5 14 14.72 14 15S14.22 15.5 14.5 15.5 15 15.28 15 15 14.78 14.5 14.5 14.5Z" />
                        </svg>
                    </a>
                    <a class="nav-link" href="#icon-tab-4_{{ i }}" data-bs-toggle="tab" role="tab"
                    *ngIf="module.elements[0].name.startsWith('schuko')">
                        <svg width="30" height="30" fill="currentColor" viewBox="1 1 22 22" >
                            <path d="M7.5,10.5A1.5,1.5 0 0,1 9,12A1.5,1.5 0 0,1 7.5,13.5C6.66,13.5 6,12.83 6,12A1.5,1.5 0 0,1 7.5,10.5M16.5,10.5A1.5,1.5 0 0,1 18,12A1.5,1.5 0 0,1 16.5,13.5A1.5,1.5 0 0,1 15,12A1.5,1.5 0 0,1 16.5,10.5M4.22,2H19.78C21,2 22,3 22,4.22V19.78A2.22,2.22 0 0,1 19.78,22H4.22C3,22 2,21 2,19.78V4.22A2.22,2.22 0 0,1 4.22,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z" />
                        </svg>
                    </a>
                </li>
                <li class="nav-item" *ngIf="module.elements && module.elements[1]">
                    <a class="nav-link" href="#icon-tab-5_{{ i }}" data-bs-toggle="tab" role="tab"
                    *ngIf="module.elements[1].name.startsWith('mennekes')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" viewBox="4 4 16 16" class="feather feather-message-square align-middle">
                            <path d="M7.5 11C7.5 11.28 7.28 11.5 7 11.5S6.5 11.28 6.5 11 6.72 10.5 7 10.5 7.5 10.72 7.5 11M9.5 14.5C9.22 14.5 9 14.72 9 15S9.22 15.5 9.5 15.5 10 15.28 10 15 9.78 14.5 9.5 14.5M9.5 8C9.78 8 10 7.78 10 7.5S9.78 7 9.5 7 9 7.22 9 7.5 9.22 8 9.5 8M14.5 8C14.78 8 15 7.78 15 7.5S14.78 7 14.5 7 14 7.22 14 7.5 14.22 8 14.5 8M12 10.5C11.72 10.5 11.5 10.72 11.5 11S11.72 11.5 12 11.5 12.5 11.28 12.5 11 12.28 10.5 12 10.5M20 11C20 15.42 16.42 19 12 19S4 15.42 4 11C4 8.61 5.06 6.47 6.72 5H17.28C18.94 6.47 20 8.61 20 11M13 7.5C13 8.33 13.67 9 14.5 9S16 8.33 16 7.5 15.33 6 14.5 6 13 6.67 13 7.5M8 7.5C8 8.33 8.67 9 9.5 9S11 8.33 11 7.5 10.33 6 9.5 6 8 6.67 8 7.5M7 13C8.11 13 9 12.11 9 11C9 9.9 8.11 9 7 9S5 9.9 5 11C5 12.11 5.9 13 7 13M11.5 15C11.5 13.9 10.61 13 9.5 13S7.5 13.9 7.5 15C7.5 16.11 8.4 17 9.5 17S11.5 16.11 11.5 15M12 13C13.11 13 14 12.11 14 11C14 9.9 13.11 9 12 9S10 9.9 10 11C10 12.11 10.9 13 12 13M16.5 15C16.5 13.9 15.61 13 14.5 13S12.5 13.9 12.5 15C12.5 16.11 13.4 17 14.5 17S16.5 16.11 16.5 15M19 11C19 9.9 18.11 9 17 9S15 9.9 15 11C15 12.11 15.9 13 17 13S19 12.11 19 11M17 10.5C16.72 10.5 16.5 10.72 16.5 11S16.72 11.5 17 11.5 17.5 11.28 17.5 11 17.28 10.5 17 10.5M14.5 14.5C14.22 14.5 14 14.72 14 15S14.22 15.5 14.5 15.5 15 15.28 15 15 14.78 14.5 14.5 14.5Z" />
                        </svg>
                    </a>
                    <a class="nav-link" href="#icon-tab-5_{{ i }}" data-bs-toggle="tab" role="tab"
                    *ngIf="module.elements[1].name.startsWith('schuko')">
                        <svg width="30" height="30" fill="currentColor" viewBox="1 1 22 22" >
                            <path d="M7.5,10.5A1.5,1.5 0 0,1 9,12A1.5,1.5 0 0,1 7.5,13.5C6.66,13.5 6,12.83 6,12A1.5,1.5 0 0,1 7.5,10.5M16.5,10.5A1.5,1.5 0 0,1 18,12A1.5,1.5 0 0,1 16.5,13.5A1.5,1.5 0 0,1 15,12A1.5,1.5 0 0,1 16.5,10.5M4.22,2H19.78C21,2 22,3 22,4.22V19.78A2.22,2.22 0 0,1 19.78,22H4.22C3,22 2,21 2,19.78V4.22A2.22,2.22 0 0,1 4.22,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z" />
                        </svg>
                    </a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="icon-tab-1_{{ i }}" role="tabpanel">
                    <div class="d-flex">
                        <span class="badge rounded-pill bg-primary mr-3">{{module.version}}</span>
                        <h6>{{module.topic_name}}</h6>
                    </div>
                    {{module.description}}
                </div>
                <div class="tab-pane" id="icon-tab-2_{{ i }}" role="tabpanel">
                    <div class="d-flex justify-content-between">
                        <h4 class="tab-title">Config</h4>
                        <button type="submit" class="btn btn-primary" (click)="getData(module.topic_name, 'cfg')">Get local</button>
                    </div>
                    <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error.cfg==module.topic_name">
                        <div class="alert-message">
                            {{ "notResponse" | translate }}
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage.cfg==module.topic_name">
                        <div class="alert-message">
                            {{"updatedSuccesfully" | translate}}
                        </div>
                    </div>
                    <table class="table white">
                        <thead>
                            <tr>
                                <th>{{"key" | translate}}</th>
                                <th>{{"value" | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of module.cfg | keyvalue">
                                <td>{{item.key}}</td>
                                <td>{{item.value | stringify}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="tab-pane" id="icon-tab-3_{{ i }}" role="tabpanel">
                    <div class="d-flex justify-content-between">
                        <h4 class="tab-title">State</h4>
                        <button type="submit" class="btn btn-primary" (click)="getData(module.topic_name, 'stat')">Get local</button>
                    </div>
                    <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error.stat==module.topic_name">
                        <div class="alert-message">
                            {{ "notResponse" | translate }}
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage.stat==module.topic_name">
                        <div class="alert-message">
                            {{"updatedSuccesfully" | translate}}
                        </div>
                    </div>
                    <table class="table white">
                        <thead>
                            <tr>
                                <th>{{"key" | translate}}</th>
                                <th>{{"value" | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of module.stat | keyvalue">
                                <td>{{item.key}}</td>
                                <td>{{item.value | stringify}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="tab-pane" id="icon-tab-analyzer_{{ i }}" role="tabpanel">
                  <div class="d-flex justify-content-between">
                      <h4 class="tab-title">Analyzers</h4>
                      <button type="submit" class="btn btn-primary" (click)="getDataAnalyzers()">Get </button>
                  </div>
                  <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="errorElement==='Analyzer'">
                      <div class="alert-message">
                          {{ "notResponse" | translate }}
                      </div>
                  </div>
                  <div class="alert alert-success alert-dismissible" role="alert"  *ngIf="successMessageElement==='Analyzer'" >
                      <div class="alert-message">
                          {{"updatedSuccesfully" | translate}}
                      </div>
                  </div>
                  <table class="table white">
                      <thead>
                          <tr>
                            <th>{{"serial" | translate}}</th>
                              <th>{{"energyValues" | translate}}</th>
                              <th>{{"measureValues" | translate}}</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let item of formattedData ">
                              <td  >{{item.serial }}</td>
                              <td> {{item.stat.energyValues | jsonStringify }} </td>
                              <td> {{item.stat.measureValues | jsonStringify }} </td>
                            </tr>
                      </tbody>
                  </table>
              </div>

              <div class="tab-pane" id="icon-tab-clock{{ i }}" role="tabpanel">
                <div class="d-flex justify-content-between">
                    <h4 class="tab-title">Clock</h4>
                    <button type="submit" class="btn btn-primary" (click)="getAstCalendar()">Get </button>
                </div>
                <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="errorElement==='Analyzer'">
                    <div class="alert-message">
                        {{ "notResponse" | translate }}
                    </div>
                </div>
                <div class="alert alert-success alert-dismissible" role="alert"  *ngIf="successMessageElement==='Analyzer'" >
                    <div class="alert-message">
                        {{"updatedSuccesfully" | translate}}
                    </div>
                </div>
                <table class="table" style="background-color: white; margin-bottom: 0px;">
                  <thead>
                        <tr>
                            <th>geoloc</th>
                            <th>{{"values" | translate}}</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="clock != null">
                      <tr>
                        <td>timezone</td>
                        <td>{{clock.timezone }}</td>
                      </tr>
                      <tr>
                        <td>timezoneCode</td>
                        <td>{{clock.timezoneCode }}</td>
                      </tr>
                      <tr>
                        <td>coords</td>
                        <td>{{clock.coords }}</td>
                      </tr>
                      <tr>
                        <td>astCorr</td>
                        <td>  {{clock.astCorr[0]  }}</td>
                      </tr>
                      <tr>

                    </tbody>
                </table>
            </div>

                <div class="tab-pane" id="icon-tab-4_{{ i }}" role="tabpanel" *ngIf="module.elements && module.elements[0]">
                    <div class="d-flex justify-content-between">
                        <h4 class="tab-title">{{module.elements[0].name}}</h4>
                        <button type="submit" class="btn btn-primary" (click)="getElementData(module.topic_name, i, 0)">Get local</button>
                    </div>
                    <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="errorElement==module.topic_name">
                        <div class="alert-message">
                            {{ "notResponse" | translate }}
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessageElement==module.topic_name">
                        <div class="alert-message">
                            {{"updatedSuccesfully" | translate}}
                        </div>
                    </div>
                    <div class="tab tab-primary">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item" *ngIf="module.elements[0].data && module.elements[0].data.cfg">
                                <a class="nav-link" [ngClass]="{'active': module.elements[0].data.cfg}" href="#element_tab_{{i}}_1_1" data-bs-toggle="tab" role="tab">Config</a>
                            </li>
                            <li class="nav-item" *ngIf="module.elements[0].data && module.elements[0].data.stat">
                                <a class="nav-link" [ngClass]="{'active': !module.elements[0].data.cfg}" href="#element_tab_{{i}}_1_2" data-bs-toggle="tab" role="tab">State</a>
                            </li>
                        </ul>
                        <div class="tab-content" style="padding:5px">
                            <div class="tab-pane" [ngClass]="{'active show': module.elements[0].data.cfg}" id="element_tab_{{i}}_1_1" role="tabpanel">
                                <table class="table" style="background-color: white; margin-bottom: 0px;">
                                    <thead>
                                        <tr>
                                            <th>{{"key" | translate}}</th>
                                            <th>{{"value" | translate}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of module.elements[0].data.cfg | keyvalue">
                                            <td>{{item.key}}</td>
                                            <td>{{item.value | stringify}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="tab-pane" [ngClass]="{'show active': !module.elements[0].data.cfg}" id="element_tab_{{i}}_1_2" role="tabpanel">
                                <table class="table" style="background-color: white; margin-bottom: 0px;">
                                    <thead>
                                        <tr>
                                            <th>{{"key" | translate}}</th>
                                            <th>{{"value" | translate}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of module.elements[0].data.stat | keyvalue">
                                            <td>{{item.key}}</td>
                                            <td>{{item.value | stringify}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="icon-tab-5_{{ i }}" role="tabpanel" *ngIf="module.elements && module.elements[1]">
                    <div class="d-flex justify-content-between">
                        <h4 class="tab-title">{{module.elements[1].name}}</h4>
                        <button type="submit" class="btn btn-primary" (click)="getElementData(module.topic_name, i, 1)">Get local</button>
                    </div>
                    <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="errorElement==module.topic_name">
                        <div class="alert-message">
                            {{ "notResponse" | translate }}
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessageElement==module.topic_name">
                        <div class="alert-message">
                            {{"updatedSuccesfully" | translate}}
                        </div>
                    </div>
                    <div class="tab tab-primary">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item" *ngIf="module.elements[1].data && module.elements[1].data.cfg">
                                <a class="nav-link" [ngClass]="{'active': module.elements[1].data.cfg}" href="#element_tab_{{i}}_2_1" data-bs-toggle="tab" role="tab">{{"configuraton" | translate}}</a>
                            </li>
                            <li class="nav-item" *ngIf="module.elements[1].data && module.elements[1].data.stat">
                                <a class="nav-link" [ngClass]="{'active': !module.elements[1].data.cfg}" href="#element_tab_{{i}}_2_2" data-bs-toggle="tab" role="tab">{{"state" | translate}}</a>
                            </li>
                        </ul>
                        <div class="tab-content" style="padding:5px">
                            <div class="tab-pane" [ngClass]="{'active show': module.elements[1].data.cfg}" id="element_tab_{{i}}_2_1" role="tabpanel">
                                <table class="table" style="background-color: white; margin-bottom: 0px;">
                                    <thead>
                                        <tr>
                                            <th>{{"key" | translate}}</th>
                                            <th>{{"value" | translate}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of module.elements[1].data.cfg | keyvalue">
                                            <td>{{item.key}}</td>
                                            <td>{{item.value | stringify}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="tab-pane" [ngClass]="{'active show': !module.elements[1].data.cfg}" id="element_tab_{{i}}_2_2" role="tabpanel">
                                <table class="table" style="background-color: white; margin-bottom: 0px;">
                                    <thead>
                                        <tr>
                                            <th>{{"key" | translate}}</th>
                                            <th>{{"value" | translate}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of module.elements[1].data.stat | keyvalue">
                                            <td>{{item.key}}</td>
                                            <td>{{item.value | stringify}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="tab-pane" id="icon-tab-4_{{i}}_0" role="tabpanel" *ngIf="module.elements?.lenght>0">
                    <h4 class="tab-title">{{module.elements[0].name}}</h4>
                    <table class="table white">
                        <thead>
                            <tr>
                                <th>Key</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
                <div class="tab-pane" id="icon-tab-4_{{i}}_1" role="tabpanel" *ngIf="module.elements?.lenght>1">
                    <h4 class="tab-title">{{module.elements[1].name}}</h4>
                    <table class="table white">
                        <thead>
                            <tr>
                                <th>Key</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>-->
            </div>
        </div>
    </div>
</div>
