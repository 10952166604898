import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {

  @Input() header: string;
  @Input() body: string;
  @Output() emitService = new EventEmitter();


  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}


  continue(){
    this.emitService.next(true)
  }

  close() {
    this.activeModal.close();
  }
}
