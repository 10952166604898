<div class="modal-header">
    <h3><i class="fas fa-exclamation-triangle text-warning-2"></i> {{"alerts" | translate}}</h3>
</div>

<div class="modal-body">
    <ul>
        <li *ngFor="let alert of alerts">
            {{alert}}
        </li>
    </ul>
    <div *ngIf="alerts && alerts.length==0">
        <p>No hay avisos</p>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="close()">Cerrar</button>
</div>

