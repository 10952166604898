import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { ModalAddGroupComponent } from 'src/app/components/ui/modal-add-group/modal-add-group.component';
import { ModalConfirmComponent } from 'src/app/components/ui/modal-confirm/modal-confirm.component';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/modals/confirmation-modal/confirmation-modal.component';
import { DashboardService } from '../../../services/dashboard/dashboard.service';
import { forkJoin } from 'rxjs';
import * as XLSX from 'xlsx';




@Component({
  selector: 'app-new-group',
  templateUrl: './new-group.component.html',
  styleUrls: ['./new-group.component.scss']
})
export class NewGroupComponent  implements OnInit, OnChanges{



  @Input() group : any = null;
  @Input() dataGroup: any = {}
  listGroups: any[] = []
  serialGroup : string = ""
  serialUser : string = ""
  user : any;
  devicesInGruoup : any[] = [];
  subgroup : any =''
  check = {
    group : false,
    usuario : false,
    update : false
  }
  newSubgroup  = { name : '', description : '', alias : null}
  @Input() loading = false;
  breadCrumbs : any;
  sortField : string = 'name';
  sortDirection: string = '';
  sortFieldUsers : string = 'name';
  sortDirectionUsers: string = '';
  file: File | null = null;
  isFileLoaded = true;
  infoTree: any = null;

  usersInGroup : any[] = [];
  userToAdd : string = '';
  roleToAdd : string = 'all';
  groupToAdd : string = '';
  roles = [
    {value: 'all', viewValue: 'All'},
    {value: 'write', viewValue: 'Write'},
    {value: 'read', viewValue: 'Read'},
    {value: 'owner', viewValue: 'Owner'}
  ];
  @Output() openModalRole= new EventEmitter<string>();
  @Input() userRole : string = 'user';
  userToLink : string = '';
  selectedUserToLinkRole = 'all';
  devicesToLink : any[] = [];





  constructor(private _router: Router,  private _modal: ModalService, private _devices: DeviceService, private route: ActivatedRoute, private _dashboard: DashboardService) {}

  modalAdd: NgbModalRef;
  modal : NgbModalRef;


  inputNombre = true;
  disableButtons = true;

  ngOnInit() {
    if (!this.dataGroup || !this.dataGroup.alias) {
      this.inputNombre = false;
    }
  }

  ngOnChanges(){
    if(!this.loading){
      this._dashboard.getBreadCrumbs().subscribe(
        data => this.breadCrumbs = data
      )
      this.readData();
    }
  }

  readData2(){
    this.route.params.subscribe(params => {

      this.group = params['group'] || null;

      if (this.group == null) this.inputNombre=false;

      if (this.group != null){

        this.disableButtons = false;
        this.subgroup = this.group;
        this.groupToAdd = this.group;

        this._devices.getGroup(this.group)
          .subscribe( data => {
            this.dataGroup = data;
            const exists = this.breadCrumbs.some(breadcrumb => breadcrumb.alias === this.dataGroup.alias);
            if (!exists) {
              this._dashboard.setBreadCrumbs({name: this.dataGroup.name, alias: this.dataGroup.alias })
            }


            this.devicesInGruoup = data.devices;
            console.log(this.devicesInGruoup);

            // Lista de observables de getDeviceInfo
            const deviceInfoRequests = data.devices.map(device =>
              this._devices.getDeviceInfo(device.serial)
            );
            // Espera a que todas las peticiones terminen
            forkJoin(deviceInfoRequests).subscribe(devicesInfo => {
              console.log('Información de los dispositivos:', devicesInfo);

              // Ahora se puede procesar la lista completa de dispositivos
              this.devicesInGruoup = devicesInfo;
            });

            this.listGroups.push({alias: this.dataGroup.alias, name: this.dataGroup.name})
            this.dataGroup.subgroups.forEach(element => {
            this.listGroups.push( {alias: element.alias, name: element.name })
          });
        })
      }
    });

  }


  readData() {
    if (!this.dataGroup || !this.dataGroup.alias) {
      this.inputNombre = false;
      return;
    }

    this.group = this.dataGroup.alias;

    this.disableButtons = false;
    this.subgroup = this.group;
    this.groupToAdd = this.group;

    const exists = this.breadCrumbs.some(breadcrumb => breadcrumb.alias === this.dataGroup.alias);
    if (!exists) {
      this._dashboard.setBreadCrumbs({ name: this.dataGroup.name, alias: this.dataGroup.alias });
    }
    const rolesData = this.dataGroup.devices; // Guardar roles
    this.devicesInGruoup = rolesData;

    this.listGroups.push({ alias: this.dataGroup.alias, name: this.dataGroup.name });
    this.dataGroup.subgroups.forEach(element => {
      this.listGroups.push({ alias: element.alias, name: element.name });
    });

    this._devices.getLinkedUsers(this.group).subscribe(data => {
      this.usersInGroup = data;
    }
    );
  }

  addGroup(){
    this._dashboard.setUpdateSidebar();
    this.modalAdd = this._modal.open(ModalAddGroupComponent)
    if (this.group != null){
      this.modalAdd.componentInstance.parentGroup =  this.group
    }
        // Comprobar cuando el modal se cierra
        this.modalAdd.closed.subscribe(() => {
          this.modal=this._modal.open(LoadingPopupComponent);
          setTimeout(() => {
            this.modal.close()
            this.readData();
            this._dashboard.setUpdateSidebar();
          }, 1000);
      });
  }

  asignarGruop(){
    this.modal=this._modal.open(LoadingPopupComponent);
    this._devices.addDevices(this.subgroup,  { "devices":[this.serialGroup]  } )
      .subscribe ( data => {
        setTimeout(() => {
          this.modal.close()
          this.readData();
          this.check.group = true
        }, 1000);
        setTimeout(() => {
          this.check.group = false
        }, 3000);
      }, err => {
        console.error(err)
        this.modal.close()
      }
    )
  }


  asignarUser(){
    this.modal=this._modal.open(LoadingPopupComponent);

    var body = {
      user: this.userToAdd,
      role: this.roleToAdd
    }

    this._devices.addUser(this.groupToAdd, body)
    .subscribe( data => {
      setTimeout(() => {
        this.modal.close()
        this.readData();
        this.check.usuario = true
      }, 1000);
      setTimeout(() => {
        this.modal.close()
        this.check.usuario = false
      }, 3000);
    })

  }

  deleteGroup(mainGroup){
    this._dashboard.setUpdateSidebar();
    this.modal = this._modal.open(LoadingPopupComponent)
    if( confirm( '¿Estas seguro de querer eliminar el grupo?') ){
      this._devices.deleteGroup( mainGroup)
      .subscribe ( () => {
        setTimeout(() => {
          this.modal.close()
          this._dashboard.setUpdateSidebar();
          this._router.navigate(['/dashboard']);
        }, 1200);
      })
    }
  }

  deleteSubgroup(group, subgroup){
    this.modal = this._modal.open(LoadingPopupComponent)
    if( confirm( '¿Estas seguro de querer eliminar el subgrupo?') ){
      this._devices.removeSubgroup(group, subgroup)
      .subscribe(  ()=> {
        this._devices.deleteGroup( subgroup)
        .subscribe ( ( ) => {
          this._dashboard.setUpdateSidebar();
        })
        setTimeout(() => {
          this.modal.close()
          this.readData();
        }, 1200);
      })
    }
  }

  cancel(){
    this._router.navigate(['/dashboard']);
  }

  saveGroup(){
    var body = {
      name: this.dataGroup.name,
      description: this.dataGroup.description
      // devices: null
      }
    this.modal=this._modal.open(LoadingPopupComponent);

    if (this.group == null && this.dataGroup.name !=""){
      this._devices.createGroup(body)
        .subscribe( (data) => {
          this._dashboard.setUpdateSidebar();
          this.group = data.group_alias;
          this._router.navigate([`/dashboard/group/${this.group}/settings`]);
          this.disableButtons = !this.disableButtons
          setTimeout(() => {
            this.modal.close()
            this.check.update = true
          }, 3000);
          setTimeout(() => {
            this.check.update = false
          }, 3000);
        }, err => {
          console.log(err);
          this.modal.close();
        });

    };

    if ( this.group != null && (this.dataGroup.name != "" || this.dataGroup.description !="") ){
      this._devices.updateGroup(this.dataGroup.alias, body)
        .subscribe( (data ) => {
          setTimeout(() => {
            this.modal.close()
            this.check.update = true
          }, 1000);
          setTimeout(() => {
            this.check.update = false
          }, 3000);

        }, err => {
          console.log(err);
          this.modal.close();
        }
      )
    }
  }


  removeDevice(serial, aliasGroup) {

    if (confirm('¿Estas seguro de querer desvincular el dispositivo del grupo?')) {

      this._devices.removeDevice(aliasGroup, serial)
        .subscribe((data) => {
          this.readData();
        })
    }
  }

  saveSubgroup(){
    this._devices.createGroup( this.newSubgroup )
     .subscribe ( (data) => {
      this.newSubgroup.alias = data.group_alias;
      this._devices.addSubGroups(this.group , {subgroup: this.newSubgroup.alias  })
        .subscribe( data => {
          this._dashboard.setUpdateSidebar();
          this.readData();
          this.newSubgroup  = { name : '', description : '', alias : null}
        })
     })
  }

  updateBreadCrums(alias: string, index: number): void {
    const currentBreadCrumbs = this.breadCrumbs;
    const updatedBreadCrumbs = currentBreadCrumbs.slice(0, index +1);
    this._dashboard.replaceBreadCrumbs(updatedBreadCrumbs )
    if (index === 0) {
      this._dashboard.setModoDashboard('list')
      this._router.navigate(['dashboard']);
    } else {
      this._router.navigate(['dashboard/newgroup', alias]);
    }
  }

  home(){
    this._dashboard.setUpdateSidebar();
  }

  sortName(){
    this.sortField='name'
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';

    this.devicesInGruoup.sort((a, b) => {
      const nameA = (a.name ?? "").toLowerCase();
      const nameB = (b.name ?? "").toLowerCase();

      if (this.sortDirection === 'asc') {
        return nameA.localeCompare(nameB); // Orden ascendente (A-Z)
      } else {
        return nameB.localeCompare(nameA); // Orden descendente (Z-A)
      }
  })}

  sortSerial(){
    this.sortField='serial'
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';

    this.devicesInGruoup.sort((a, b) => {
      const serialA = a.serial.toLowerCase();
      const serialB = b.serial.toLowerCase();

      if (this.sortDirection === 'asc') {
        return serialA.localeCompare(serialB); // Orden ascendente (menor a mayor)
      } else {
        return serialB.localeCompare(serialA); // Orden descendente (mayor a menor)
      }
    });
  }

  sortEmail(){
    this.sortFieldUsers='email'
    this.sortDirectionUsers = this.sortDirectionUsers === 'asc' ? 'desc' : 'asc';

    this.usersInGroup.sort((a, b) => {
      const nameA = a.email.toLowerCase();
      const nameB = b.email.toLowerCase();

      if (this.sortDirectionUsers === 'asc') {
        return nameA.localeCompare(nameB); // Orden ascendente (A-Z)
      } else {
        return nameB.localeCompare(nameA); // Orden descendente (Z-A)
      }
  })}

  sortUsername(){
    this.sortFieldUsers='name'
    this.sortDirectionUsers = this.sortDirectionUsers === 'asc' ? 'desc' : 'asc';

    this.usersInGroup.sort((a, b) => {
      const nameA = (a.name ?? "").toLowerCase() + " " + (a.last_name ?? "").toLowerCase();
      const nameB = (b.name ?? "").toLowerCase() + " " + (b.last_name ?? "").toLowerCase();

      if (this.sortDirectionUsers === 'asc') {
        return nameA.localeCompare(nameB); // Orden ascendente (A-Z)
      } else {
        return nameB.localeCompare(nameA); // Orden descendente (Z-A)
      }
  })}

  goToSettings(serial, component){
    this._router.navigate(['dashboard/device', serial, 'settings', component]);
  }

  onFileSelected(event: any) {
    this.file = event.target.files[0];
    if (!this.file) return;

    console.log('📂 Archivo seleccionado:', this.file);

    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.processExcel(e.target.result);
      this.isFileLoaded = true; // Habilitar el botón "Guardar cambios"
    };
    reader.readAsArrayBuffer(this.file);
  }


  processExcel(content: ArrayBuffer) {
    const workbook = XLSX.read(content, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const rows: any[] = XLSX.utils.sheet_to_json(sheet, { header: 1 }) || [];

    if (rows.length === 0) {
      alert("El archivo Excel está vacío.");
      return;
    }

    this.devicesToLink = rows.map(row => row[0]);
  }

  async confirmAndExecute() {
    // Validaciones iniciales
    if (this.devicesToLink.length === 0) {
      alert("Seleccione un listado de dispositivos");
      return;
    }
    if (!this.userToLink) {
      alert("Especifique un usuario");
      return;
    }
    if (!confirm('¿Estas seguro de querer vincular ' + this.devicesToLink.length + ' dispositivos al usuario y al grupo actual?'))
      return;
  
    // Abrir el modal de carga una sola vez
    this.modal = this._modal.open(LoadingPopupComponent);
  
    try {
      // 1. Asignar dispositivos de forma secuencial usando toPromise para esperar la respuesta del HTTP POST.
      const successDevices: any[] = [];
      const errorDevices: any[] = [];
      for (const serial of this.devicesToLink) {
        try {
          const resp = await this._devices.assignDevices(serial, this.userToLink, this.selectedUserToLinkRole).toPromise();
          // Aquí puedes validar "resp" según lo necesites
          successDevices.push(serial);
        } catch (error) {
          errorDevices.push(serial);
        }
      }
  
      // Mostrar el resultado de la asignación
      if (errorDevices.length > 0) {
        alert("Error al vincular los siguientes dispositivos: " + errorDevices.join(", "));
      } else {
        alert(successDevices.length + " dispositivos vinculados correctamente.");
      }

      if(errorDevices.length == this.devicesToLink.length)
        return;
  
      // 2. Agregar el usuario al grupo si no existe ya
      const userExists = this.usersInGroup.some(user => user.email === this.userToLink);
      if (!userExists) {
        const body = {
          user: this.userToLink,
          role: this.selectedUserToLinkRole
        };
        await this._devices.addUser(this.dataGroup.alias, body).toPromise();
      }
  
      // 3. Filtrar y agregar dispositivos que aún no están en el grupo
      const needToAddDevices = this.devicesToLink.filter(serial => {
        return !this.devicesInGruoup.some(device => device.serial === serial);
      });
      if (needToAddDevices.length > 0) {
        await this._devices.addDevices(this.dataGroup.alias, { devices: needToAddDevices }).toPromise();
      }
    } catch (error) {
      console.error("❌ Error en confirmAndExecute:", error);
      alert("Ocurrió un error durante el proceso. Revise la consola para más detalles.");
      this.readData();
    } finally {
      // Cerrar el modal una sola vez al finalizar todas las operaciones
      this.modal.close();
      this.readData();
    }
  }

  async makeGroups(infoTree) {
    let aliasPadre: any;
    let aliasHijos: any[] = [];

    for (let index1 = 0; index1 < infoTree.length - 1; index1++) {
      for (let index2 = 0; index2 < infoTree[index1].length; index2++) {
        let body = { name: infoTree[index1][index2] };

        try {
          const data: any = await this._devices.createGroup(body).toPromise();

          if (index1 === 0) aliasPadre = { alias: data.group_alias, name: body.name };
          if (index1 === 1) aliasHijos.push({ alias: data.group_alias, name: body.name });

          if (index1 === 0 && this.dataGroup.name && this.group) await this._devices.addSubGroups(this.group, { subgroup: aliasPadre.alias }).toPromise();
          if (index1 === 1) {
            await this._devices.addSubGroups(aliasPadre.alias, { subgroup: data.group_alias }).toPromise();
          }
        } catch (error) {
          console.error('❌ Error al crear grupo:', error);
        }
      }
    }

    await Promise.all(
      infoTree[infoTree.length - 1].map(element =>
        this._devices.addDevices(element.nombre, { devices: Array.isArray(element.serial) ? element.serial : [element.serial] })
          .toPromise()
          // .then(data => console.log("✅ Respuesta addDevices:", data))
          .catch(error => console.error("❌ Error en addDevices:", error))
      )
    );
    setTimeout(() => {
      this._dashboard.setUpdateSidebar();
      this._router.navigate([`/dashboard/newgroup/${aliasPadre.alias}`]);
    }, 1000);
  }

  removeUser(email, aliasGroup) {
    if (confirm('¿Estas seguro de querer desvincular el usuario del grupo?')) {

      this._devices.removeUser(aliasGroup, email)
        .subscribe((data) => {
          this.readData();
        })
    }
  }  

  openModalRoleUser(user) {
    this.openModalRole.emit(user);
  }
}
