<div>
    <div class="card">
        <div class="card-header">
            <h5 class="card-title">{{"schedule" | translate}}</h5>
            <h6 class="card-subtitle text-muted"></h6>
        </div>
        <div class="card-body">
            <div class="container">
                <div class="conn mb-3" *ngFor="let conn of connectors">
                    <h4>{{conn.name.startsWith('mennekes') ? 'Tipo-2' : 'Schuko'}}</h4>
                <table class="table w-100">
                    <thead class="w-100">
                        <tr>
                            <th class="col-3">{{"status" | translate}}</th>
                            <th class="col-3">{{"initTime" | translate}}</th>
                            <th class="col-3">{{"endTime" | translate}}</th>
                            <th class="col-3"></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="tasks['timeTasks'][conn.name]">
                        <tr *ngIf="tasks['timeTasks'][conn.name].length==0"><td colspan="3">{{"noTasksForThisConnector" | translate}}</td></tr>
                        <tr *ngFor="let task of tasks['timeTasks'][conn.name]" class="tr_sched">
                            <td>
                                <div>
                                    <label class="switch">
                                        <input type="checkbox" [checked]="task.active==1" (click)="task.active==1 ? task.active=0 : task.active=1" name="active">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </td>
                            <td>{{_utils.getDateFromMinutes(task?.initTime?.timeList[0]?.hourMin)}}</td>
                            <td>{{_utils.getDateFromMinutes(task?.initTime?.timeList[0]?.hourMin+task?.initTime?.timeList[0]?.duration)}}</td>
                            <td class="d-flex">
                                <button class="btn btn-primary btn-primary-hard" (click)="openEditTask(task)">
                                    <i class="fas fa-edit"></i>
                                </button>
                                <button class="btn btn-danger btn-danger-hard ml-2" (click)="deleteTask(task.id, conn.name)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>

                    </tbody>
                    <tr>
                        <button class="btn btn-outline-primary" (click)="openAddTask(conn.name)">
                            <i class="fas fa-plus"></i>
                        </button>
                    </tr>
                </table>

                <div class="w-100 d-flex justify-content-center">
                    <button class="btn btn-primary" (click)="save(conn.name)">
                      {{"save" | translate}}
                    </button>
                </div>
                <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error[conn.name]!=''">
                    <div class="alert-message">
                        {{ error }}
                    </div>
                </div>
                <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage[conn.name]">
                    <div class="alert-message">
                        {{"updatedSuccesfully" | translate}}
                    </div>
                </div>
                </div>
            </div>

        </div>

    </div>
</div>
