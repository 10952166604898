<div class="header d-flex ps-4 pt-4" style="background-color: '#006272';">
  <div class="w-50">
      <h1 class="header-title">
          {{userInfo.name}} {{userInfo.last_name}}
        </h1>
        <h5 class="text-white">{{userRole | titlecase}}</h5>
  </div>
  <div class="image-profile" style="margin: -28px;">
      <i class="fas fa-user-circle text-white"></i>
  </div>
</div>

  <div class="row">
    <div class="col-12 col-xl-6 m-auto">
        <div class="card">
            <div class="card-header">
                <div class="d-flex justify-content-between">
                    <h4 class="card-title">{{"profile" | translate}}</h4>
                    <i class="fas fa-edit" (click)="editActivated=!editActivated"></i>
                </div>
            </div>
            <div class="card-body">
                <form>
                    <div class="form-group row">
                        <label class="col-form-label col-sm-4 text-sm-right">{{"name" | translate}}</label>
                        <div class="col-sm-8">
                            <input type="email" class="form-control" placeholder="{{ 'name' | translate }}" name="name" [(ngModel)]="userInfo.name" [disabled]="!editActivated">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-form-label col-sm-4 text-sm-right">{{"surname" | translate}}</label>
                        <div class="col-sm-8">
                            <input type="email" class="form-control" placeholder="{{ 'surname' | translate }}" name="lastname" [(ngModel)]="userInfo.last_name" [disabled]="!editActivated">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-form-label col-sm-4 text-sm-right">{{"email" | translate}}</label>
                        <div class="col-sm-8">
                            <input type="email" class="form-control" [placeholder]="'email' | translate" name="email" [(ngModel)]="userInfo.email" disabled>
                        </div>
                    </div>
                    <!--
                    <div class="form-group row">
                        <label class="col-form-label col-sm-4 text-sm-right">{{"alias" | translate}}</label>
                        <div class="col-sm-8">
                            <input type="email" class="form-control" [placeholder]="'alias' | translate" name="alias" [(ngModel)]="userInfo.alias" [disabled]="!editActivated">
                        </div>
                    </div> -->

                    <div class="form-group row" *ngIf="editActivated">
                        <div class="col-sm-10 ml-sm-auto">
                          <a class="btn btn-lg btn-primary" (click)="editProfile()">{{"overwrite" | translate}}</a> &nbsp;
                          <a class="btn btn-lg btn-warning" (click)="resetPassword()">{{"restore" | translate}}</a>&nbsp;
                        <!-- <a class="btn btn-lg btn-danger" (click)="editProfile()">Eliminar cuenta</a> -->
                        </div>
                    </div>
                </form>
                <div class="col-sm-8 ml-sm-auto" *ngIf="userRole !== 'developer'">
                  <a class="btn btn-lg btn-warning" (click)="askDeveloperMode()">{{"profileDeveloperRequestDeveloperMode" | translate}}</a> &nbsp;
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="col-12 col-xl-6 m-auto">
    <div class="card">
        <div class="card-header ">
            <div class="d-flex justify-content-between">
                <h4 class="card-title">{{"dashboardConfig" | translate}} </h4>
            </div>
        </div>
        <div class="card-body ">
          <form>
            <div class="d-flex justify-content-center w-80 m-auto button-group-container">
              <div class="col-4 text-center p-1 button-group" (click)="changeOption('mapa')"
              [ngClass]="{'bg-primary text-white' : !dashboard}">
              {{"mapView" | translate}}
            </div>
            <div class="col-4 text-center p-1 button-group" (click)="changeOption('lista')"
            [ngClass]="{'bg-primary text-white' : dashboard}">
            {{"listView" | translate}}
          </div>
            </div>
          </form>
        </div>
    </div>
</div>

  <div class="row">
    <div class="col-12 col-xl-6 m-auto">
      <div class="card">
          <div class="card-header">
              <div class="d-flex justify-content-between">
                  <h4 class="card-title">{{"betatesterRequest" | translate}}</h4>
                  <i class="fas fa-quiz"></i>
              </div>
          </div>

          <div class="card-body" >
            <div class="row">
              <div class="col-sm-12 text-sm-center">
                <ng-container *ngIf="userRole === 'user' && userInfo.tester_requested === 0">
                  {{"betatesterRequestInvitation" | translate}}
                </ng-container>
                <ng-container *ngIf="userRole === 'tester'">
                  {{"betatesterDowngrade" | translate}}
                </ng-container>
                <ng-container *ngIf="userRole === 'user' && userInfo.tester_requested === 1">
                  {{"alreadyAppliedAsBetatester" | translate}}
                </ng-container>
              </div>
            </div>
            <div class="row text-center">
              <div class="col-sm-12 ml-sm-auto mt-3">
                <div *ngIf="userRole === 'user' && userInfo.tester_requested === 0">
                  <button type="button" class="btn btn-success" (click)="requestTester()">{{"request" | translate}}</button>
                </div>
                <div *ngIf="userRole === 'tester'">
                  <button type="button" class="btn btn-warning" (click)="showUnsubscribeConfirmModal()">{{"betatesterDowngrade" | translate}}</button>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>

</div>
