<div [ngClass]="{'col-12 col-xl-6' : !allSettings}">
    <div class="card">
        <div class="card-header">
            <h5 class="card-title">{{"reset" | translate}}</h5>
        </div>
        <div class="card-body">
            <div class="form-group row align-items-sm-center">
                <div class="col-sm-8">
                    {{"hardReset" | translate}}
                </div>
                <div class="col-sm-4">
                    <button type="submit" class="btn btn-primary" (click)='reset("hard")'>{{"hardResetButton" | translate}}</button>
                </div>
            </div>
            <div class="form-group row align-items-sm-center">
                <div class="col-sm-8">
                    {{"factoryReset" | translate}}
                </div>
                <div class="col-sm-4">
                    <button type="submit" class="btn btn-primary" (click)='reset("factoryresetsoft")'>{{"factoryResetButton" | translate}}</button>
                </div>
            </div>
            <div class="form-group row align-items-sm-center">
                <div class="col-sm-8">
                    {{"factoryResetHard" | translate}}
                </div>
                <div class="col-sm-4">
                    <button type="submit" class="btn btn-primary" (click)='reset("factoryresethard")'>{{"factoryResetHardButton" | translate}}</button>
                </div>
            </div>

            <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error!=''">
                <div class="alert-message">
                    {{ error }}
                </div>
            </div>
            <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage">
                <div class="alert-message" *ngIf="lastType=='hard'">
                    {{"resetingDevice" | translate}}
                </div>
                <div class="alert-message" *ngIf="lastType!='hard'">
                    {{"restoringDevice" | translate}}
                </div>
            </div>
        </div>
    </div>
</div>