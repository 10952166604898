<div class="header">
  <h1 class="header-title">{{"testers" | translate}}</h1>
  <p class="header-description">
    {{"testersListDescription" | translate}}
  </p>
</div>

<div class="card">

  <div class="card-header">

    <!-- Campo de filtrado según Angular Material -->
    <mat-form-field>
      <mat-label>{{"filter_by" | translate}}</mat-label>
      <input matInput (keyup)="applyFilter($event)">
    </mat-form-field>

    <div *ngIf="selectedTesters.length > 0" class="selected-counter">
      <span>{{ selectedTesters.length }} {{"selected" | translate}}</span>
    </div>
  </div>

  <div class="card-body">
    <mat-table [dataSource]="dataSource">

      <!-- Columna de Checkbox -->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="masterToggle()"
                        [checked]="isAllSelected()"
                        [indeterminate]="selectedTesters.length > 0 && !isAllSelected()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let tester">
          <mat-checkbox (change)="selectTester(tester)" [checked]="selectedTesters.indexOf(tester) > -1"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- Columna de Nombre -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>{{"name" | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let tester">{{ tester.name }}</mat-cell>
      </ng-container>

      <!-- Columna de Email -->
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef>{{"email" | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let tester">{{ tester.email }}</mat-cell>
      </ng-container>

      <!-- Columna de Role -->
      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef>{{"role" | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let tester">{{ tester.role }}</mat-cell>
      </ng-container>

      <!-- Columna de Acciones -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>{{"actions" | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let tester">
          <button *ngIf="tester.tester_requested" class="btn btn-success" (click)="confirmTester(tester)">{{"confirm" | translate}}</button>&nbsp;
          <button class="btn btn-danger" (click)="cancelTester(tester)">{{(tester.tester_requested === 0 ? 'delete' : 'cancel') | translate}}</button> &nbsp;
          <button *ngIf="!tester.tester_requested" class="btn" [style.background-color]="'#0088cc'" (click)="sendTelegramInvitation(tester)">
            <i class="fab fa-telegram-plane" [style.color]="'#ffffff'"></i>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="['select', 'name', 'email', 'role', 'actions']"></mat-header-row>
      <mat-row *matRowDef="let row; columns: ['select', 'name', 'email', 'role', 'actions']"></mat-row>

    </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 50, 100, 300]" showFirstLastButtons></mat-paginator>

  </div>

  <div class="card-footer">
    <button class="btn btn-primary" (click)="sendInvitations()" [style.background-color]="'#0088cc'">
      <i class="fab fa-telegram-plane" [style.color]="'#ffffff'"></i> {{"resendTelegram" | translate}}
    </button> &nbsp;
    <!-- Botones de confirmación y cancelación masiva -->
    <button class="btn btn-success" (click)="confirmSelectedTesters()">{{"confirmSelected" | translate}}</button> &nbsp;
    <button class="btn btn-danger" (click)="cancelSelectedTesters()">{{"cancelSelected" | translate}}</button>
  </div>

</div>
