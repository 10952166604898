import { Routes } from '@angular/router';
import { AddDeviceComponent } from 'src/app/pages/admin/add-device/add-device.component';
import { AdminSearchComponent } from 'src/app/pages/admin/admin-search/admin-search.component';
import { AdminComponent } from 'src/app/pages/admin/admin.component';
//import { AssignDeviceComponent } from 'src/app/pages/admin/assign-device/assign-device.component';
import { LogsComponent } from 'src/app/pages/admin/logs/logs.component';
import { DeviceDashboardComponent } from 'src/app/pages/device/device-dashboard/device-dashboard.component';
import { DeviceComponent } from 'src/app/pages/device/device.component';
import { AllSettingsComponent } from 'src/app/pages/device/settings/all-settings/all-settings.component';
import { ConnectivityComponent } from 'src/app/pages/device/settings/connectivity/connectivity.component';
import { OcppComponent } from 'src/app/pages/device/settings/ocpp/ocpp.component';
import { PasswordComponent } from 'src/app/pages/device/settings/password/password.component';
import { PowerComponent } from 'src/app/pages/device/settings/power/power.component';
import { SettingsComponent } from 'src/app/pages/device/settings/settings.component';
import { WifiComponent } from 'src/app/pages/device/settings/wifi/wifi.component';
import { ProfileComponent } from 'src/app/pages/profile/profile.component';
import { ModulesComponent } from 'src/app/pages/device/modules/modules.component';
import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { MqttApiComponent } from '../../pages/developers/documentation/mqtt-api/mqtt-api.component';
import { MultipleCommandComponent } from 'src/app/pages/admin/multiple-command/multiple-command.component';
import { TestersComponent } from 'src/app/pages/admin/testers/testers.component';
import { DeviceSettingsSplComponent } from 'src/app/pages/device/device-settings-spl/device-settings-spl.component';
import { DiagnosisHomeComponent } from 'src/app/pages/device/diagnosis-home/diagnosis-home.component';
import { NewGroupComponent } from 'src/app/pages/group/new-group/new-group.component';
import { ViewGroupComponent } from 'src/app/pages/group/view-group/view-group.component';
import { SplGroupComponent } from 'src/app/pages/group/spl-group/spl-group.component';
import { ShowGroupComponent } from 'src/app/pages/group/show-group/show-group.component';
import { GroupComponent } from 'src/app/pages/group/group.component';

import { SubscriptionsComponent } from 'src/app/pages/device/subscriptions/subscriptions.component';

export const AdminLayoutRoutes: Routes = [
    { path: '',      component: DashboardComponent },
    { path: 'newgroup',      component: NewGroupComponent },
    // { path: 'splconfig/:splGroup',      component: SplGroupComponent},
    { path: 'device/:serial', component: DeviceComponent,
      children:[
        { path: '', component: DeviceDashboardComponent },
        { path: 'diagnosis', component: DiagnosisHomeComponent },
        { path: 'spl', component: DeviceSettingsSplComponent },
        { path: 'settings', component: SettingsComponent,
          children:[
            { path: '', component: AllSettingsComponent },
            { path: ':option', component: AllSettingsComponent },
            { path: 'connectivity', component: ConnectivityComponent },
            { path: 'password', component: PasswordComponent },
            { path: 'wifi', component: WifiComponent },
            { path: 'power', component: PowerComponent },
            { path: 'ocpp', component: OcppComponent },
          ]
        },
        { path: 'subscriptions', component: SubscriptionsComponent },
        { path: 'modules', component: ModulesComponent },
      ]
    },
    { path: 'administration', component: AdminComponent,
      children:[
        { path: 'search', component: AdminSearchComponent },
      //  { path: 'assign-device', component: AssignDeviceComponent },
        { path: 'addUser', component: AddDeviceComponent },
        { path: 'sendCommand', component: MultipleCommandComponent },
        { path: 'logs', component: LogsComponent },
        { path: 'testers', component: TestersComponent}
      ]
    },
    { path: 'profile', component: ProfileComponent },
    { path: 'developers/documentation/mqtt_api', component: MqttApiComponent},
    { path: 'group/:group', component: GroupComponent,
      children:[
        { path: '', component: ShowGroupComponent },
        { path: 'view', component: ViewGroupComponent },
        { path: 'settings', component: NewGroupComponent}
      ]
    }
];
