<div [ngClass]="{'col-12 col-xl-6' : !allSettings}">
  <div class="card">
    <div class="card-header">
      <h5 class="card-title"> {{ 'nameCharger' | translate }}</h5>
      <h6 class="card-subtitle text-muted"> {{ 'nameChargerDescription' | translate }} </h6>
    </div>
    <div class="card-body">
      <form>
        <!-- <div class="form-group row">
          <label class="col-form-label col-sm-4 text-sm-right"> {{ 'serial_number' | translate }}</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" value="{{device}}" readonly>
          </div>
        </div> -->
        <div class="form-group row">
          <label class="col-form-label col-sm-4 text-sm-right"> {{ 'nameCharger' | translate }} </label>
          <div class="col-sm-8">
            <!-- <input type="text" class="form-control" name="name" [(ngModel)]="body.name" (change)="changesMade=true"> -->
            <input type="text" class="form-control" name="name" [(ngModel)]="body.name" [ngModelOptions]="{standalone: true}" (keyup)="changesMade=_dService.checkChangesSettings(originalData, body)">
          </div>
        </div>
        <!-- <div class="form-group row">
          <label class="col-form-label col-sm-4 text-sm-right"> {{ 'alias' | translate  }} </label>
          <div class="col-sm-8">
            <input type="text" class="form-control" name="alias" [(ngModel)]="body.alias" (change)="changesMade=true">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-sm-4 text-sm-right"> {{ 'chargerDescription' | translate}}</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" name="description" [(ngModel)]="body.description" (change)="changesMade=true">
          </div>
        </div> -->

        <div class="form-group row">
          <div class="col-sm-10 ml-sm-auto">
            <button type="submit" class="btn btn-primary" (click)="save()" [disabled]="!changesMade">{{"overwrite" |
              translate}}</button>
            <button type="submit" class="btn btn-light ml-2" (click)="restore()" [disabled]="!changesMade">{{"restore" |
              translate}}</button>
          </div>

        </div>
      </form>
      <div class="alert alert-danger alert-dismissible mt-2" role="alert" *ngIf="error!=''">
        <div class="alert-message">
          {{ error }}
        </div>
      </div>
      <div class="alert alert-success alert-dismissible mt-2" role="alert" *ngIf="successMessage">
        <div class="alert-message">
          {{"updatedSuccesfully" | translate}}
        </div>
      </div>
    </div>
  </div>
</div>
