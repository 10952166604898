<div>
    <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-8">
              <h5 class="card-title">{{"installation" | translate}}</h5>
              <h6 class="card-subtitle text-muted"></h6>
            </div>

            <div class="col-sm-4 text-right">
              <label class="col-7 text-right text-bold">{{showAdvanced ? 'Advanced' : 'Basic'}}</label>
              <label class="switch">
                  <input type="checkbox" [checked]="showAdvanced" (click)="setAdvanced();" name="advancedEnabled">
                  <span class="slider round"></span>
              </label>
          </div>
          </div>
        </div>
        <div class="card-body">
            <form>


                <div class="form-group row">
                    <div class="col-sm-6 text-sm-right">
                        <label class="col-form-label col-sm-6 text-sm-right"><i (mouseover)="setFocus('limitPower')" (mouseout)="setFocus('')" class="fas fa-info"></i>&nbsp; {{"hiredPower" | translate}} (W)</label><br>
                        <small class="text-grey" *ngIf="focus=='limitPower'">{{"limitPowerDesc" | translate}}<br></small>
                    </div>

                    <div class="col-sm-4">
                        <input type="number" class="form-control" name="limitPower" [(ngModel)]="body.limitPower" placeholder="{{'hiredPower' | translate}}"
                                (change)="checkChangesLimit()" [disabled]="splCfg?.splMode == 2">
                    </div>
                </div>

                <div *ngIf="(num_phases === 3 || (splCfg!=undefined && splCfg?.splMode>0)) && showAdvanced" class="form-group row">
                  <div class="col-sm-6 text-sm-right">
                    <label class="col-form-label col-sm-6 text-sm-right"><i (mouseover)="setFocus('limitPowerByPhase')" (mouseout)="setFocus('')" class="fas fa-info"></i>&nbsp; {{"limitPowerByPhase" | translate}} (W)</label><br>
                    <small class="text-grey" *ngIf="focus=='limitPowerByPhase'">{{"maxPowerPerPhase" | translate}}<br></small>
                  </div>
                  <div class="col-sm-4">
                    <input type="number" class="form-control" name="limitPowerByPhase" [(ngModel)]="body.limitPowerByPhase[0]"
                        placeholder="{{'hiredPower' | translate}}" (change)="checkChangesLimit()" max="22080" [disabled]="splCfg?.splMode == 2">
                  </div>
                </div>

                <div class="card-footer">
                    <div class="form-group row">
                        <div class="col-sm-10 ml-sm-auto">
                            <button type="submit" class="btn btn-primary" (click)="savePower()"
                                [disabled]="!this.changes.limitP">{{"overwrite" | translate}}</button>
                            <button type="submit" class="btn btn-light ml-2" [disabled]="!this.changes.limitP">{{"reset"
                                | translate}}</button>
                        </div>

                    </div>
                    <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error.limitP!=''">
                        <div class="alert-message">
                            {{ error.limitP }}
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage.limitP">
                        <div class="alert-message">
                            {{"updatedSuccesfully" | translate}}
                        </div>
                    </div>
                </div>

                <!-- Vehicle collapse-->
                <a (click)="collapsibles.vehicle = !collapsibles.vehicle" [attr.aria-expanded]="!collapsibles.vehicle" style="cursor: pointer;">
                  <i class="fas fa-car" style="font-size: 12px;"></i>&nbsp; <span class="align-middle">Vehicle</span>&nbsp;<i style="font-size: 12px;" class="fas fa-chevron-down"></i>
                </a>
                <div #collapse="ngbCollapse" [(ngbCollapse)]="!collapsibles.vehicle">
                  <div class="form-group row">
                      <div class="col-sm-6 text-sm-right">
                          <label class="col-form-label"><i (mouseover)="setFocus('minPower')" (mouseout)="setFocus('')" class="fas fa-info"></i>&nbsp; {{"minPowerLabel" |
                              translate}} (A) {{"byPhase" | translate}}</label><br>
                          <small class="text-grey" *ngIf="focus=='minPower'">{{"minPowerText" | translate}}<br></small>
                          <small class="text-grey" *ngIf="focus=='minPower'">{{"minPowerBetween6and10" | translate}}</small>
                      </div>

                      <div class="col-sm-4">
                          <input type="number" class="form-control" name="minPower" [(ngModel)]="bodyMinP.minPower"
                              placeholder="6-10 A" (change)="checkChangesMin()">
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-10 ml-sm-auto">
                            <button type="submit" class="btn btn-primary" (click)="saveMinP()"
                                [disabled]="!this.changes.minP">{{"overwrite" | translate}}</button>
                            <button type="submit" class="btn btn-light ml-2" [disabled]="!this.changes.minP">{{"restore"
                                | translate}}</button>
                        </div>

                    </div>
                    <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error.minP!=''">
                        <div class="alert-message">
                            {{ error.minP }}
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage.minP">
                        <div class="alert-message">
                            {{"updatedSuccesfully" | translate}}
                        </div>
                    </div>
                  </div>
                </div>
                <p></p>

                <!--Charger limits-->
                <div *ngIf="showAdvanced">
                  <a (click)="collapsibles.chargerLimits = !collapsibles.chargerLimits" [attr.aria-expanded]="!collapsibles.chargerLimits" style="cursor: pointer;">
                    <i class="fas fa-plug" style="font-size: 12px;"></i>&nbsp; <span class="align-middle">{{"chargerLimits" | translate}}</span>&nbsp;<i style="font-size: 12px;" class="fas fa-chevron-down"></i>
                  </a>
                  <div #collapse="ngbCollapse" [(ngbCollapse)]="!collapsibles.chargerLimits">
                    <div class="form-group row">
                        <div class="col-sm-6 text-sm-right">
                            <label class="col-form-label"><i (mouseover)="setFocus('maxCharger')" (mouseout)="setFocus('')" class="fas fa-info"></i>&nbsp; {{"limitPowerCharger" | translate}}</label><br>
                            <small class="text-grey" *ngIf="focus=='maxCharger'">{{"limitPowerChargerText" | translate}}<br></small>
                        </div>

                        <div class="col-sm-4">
                            <input type="number" class="form-control" name="ampacity" [(ngModel)]="ampacity"
                                placeholder="0-32 A" (change)="checkChangesAmpacity()">
                        </div>
                    </div>

                    <div class="form-group row" *ngFor="let c of connectorsMennekes, let i=index">
                        <div class="col-sm-6 text-sm-right">
                            <label class="col-form-label"><i (mouseover)="setFocus('maxConnector_'+i)" (mouseout)="setFocus('')" class="fas fa-info"></i>&nbsp; {{"limitPowerConnector" | translate}} {{i+1}}</label><br>
                            <small class="text-grey" *ngIf="focus=='maxConnector_'+i">{{"limitPowerConnectorText" | translate}}</small>
                        </div>
                        <div class="col-sm-4">
                            <input type="number" class="form-control" name="connPower_{{i}}" [(ngModel)]="c.data.cfg.limitPower"
                                placeholder="0-32 A" (change)="checkChangesAmpacityConn()">
                        </div>
                    </div>

                    <div class="card-footer">
                        <div class="form-group row">
                            <div class="col-sm-10 ml-sm-auto">
                                <button type="submit" class="btn btn-primary" [disabled]="!this.changes.ampacity && !this.changes.ampacityConn" (click)="saveAmpacity();">{{"overwrite" | translate}}</button>
                                <button type="submit" class="btn btn-light ml-2" [disabled]="!this.changes.ampacity && !this.changes.ampacityConn">{{"restore" | translate}}</button>
                            </div>

                        </div>
                        <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error.ampacity!=''">
                            <div class="alert-message">
                                {{ error.minP }}
                            </div>
                        </div>
                        <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage.ampacity">
                            <div class="alert-message">
                                {{"updatedSuccesfully" | translate}}
                            </div>
                        </div>
                    </div>
                  </div>
                  <p></p>
                </div>

                <!-- Rotación de fases -->
                <div *ngIf="showAdvanced && (num_phases === 3 || (splCfg!=undefined && splCfg?.splMode>0))">
                  <a (click)="collapsibles.phaseRotation = !collapsibles.phaseRotation" [attr.aria-expanded]="!collapsibles.phaseRotation" style="cursor: pointer;">
                    <i class="fas fa-sync" style="font-size: 12px;"></i>&nbsp; <span class="align-middle">{{"phaseRotation" | translate}}</span>&nbsp;<i style="font-size: 12px;" class="fas fa-chevron-down"></i>
                  </a>
                  <div #collapse="ngbCollapse" [(ngbCollapse)]="!collapsibles.phaseRotation">
                    <div *ngFor="let c of connectorsMennekes, let i=index" >
                      <span>{{"connector" | translate}} {{i+1}}</span> &nbsp;
                      <select class="form-select" id="phRotSel_{{i}}" name="phRotSel_{{i}}" [(ngModel)]="c.data.cfg.phaseRotation" (change)="checkChangesRotation()">
                        <option *ngFor="let item of rotationPhases" [value]="item.value">{{item.name}}</option>
                      </select>
                    </div>
                    <div class="card-footer">
                      <div class="form-group row">
                        <div class="col-sm-10 ml-sm-auto">
                          <button type="submit" class="btn btn-primary" (click)="saveAmpacity()"
                          [disabled]="!this.changes.rotationPhases">{{"overwrite" | translate}}</button>
                        <button type="submit" class="btn btn-light ml-2" [disabled]="!this.changes.rotationPhases">{{"restore"
                            | translate}}</button>
                        </div>
                      </div>
                      <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage.rotationPhases">
                          <div class="alert-message">
                              {{"updatedSuccesfully" | translate}}
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
                <p></p>

                <!--Automatic phase switch.-->
                <div class="row col-12 mb-2"  *ngIf="showAdvanced && body.switchEnabled !== -1 && (num_phases === 3 || (splCfg!=undefined && splCfg?.splMode>0))">
                    <label class="col-4 text-left text-bold">{{"automaticPhaseSwitching" | translate}}</label>
                    <label class="switch">
                        <input type="checkbox" [checked]="body.switchEnabled" (click)="body.switchEnabled === 0 ? body.switchEnabled = 1 : body.switchEnabled = 0; checkSwitchEnabled()" name="automaticSwitch">
                        <span class="slider round"></span>
                    </label>

                  <div class="form-group row" *ngIf="body.switchEnabled">
                    <div class="col-sm-6 text-sm-right">
                        <label class="col-form-label"><i (mouseover)="setFocus('maxCharger')" (mouseout)="setFocus('')" class="fas fa-info"></i>&nbsp; {{"automaticPhaseSwitching" | translate}} (W)</label><br>
                        <small class="text-grey" *ngIf="focus=='maxCharger'">{{"switchingPhasesText" | translate}}<br></small>
                    </div>
                    <div class="col-sm-4">
                            <input type="number" max="7360" min="4140" class="form-control"  name="unswitchCurrent" id="unswitchCurrent" [(ngModel)]="unswitchCurrent" (change)="checkChangesUnswitchCurrent()">
                    </div>
                  </div>

                    <div class="card-footer" *ngIf="body.switchEnabled || this.changes.switchEnabled">
                        <div class="form-group row">
                          <div class="col-sm-10 ml-sm-auto">
                           <button type="submit" class="btn btn-primary"  [disabled]="!this.changes.unswitchCurrent && !this.changes.switchEnabled" (click)="savePower()">{{"overwrite" | translate}}</button>
                          <button type="submit" class="btn btn-light ml-2">{{"restore" | translate}}</button>
                          </div>
                        </div>
                        <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage.minP">
                            <div class="alert-message">
                                {{"updatedSuccesfully" | translate}}
                            </div>
                        </div>
                    </div>
                </div>


                <!-- <div class="form-group row">
                  <div class="col-sm-6 text-sm-right">
                      <label class="col-form-label"><i (mouseover)="setFocus('maxCharger')" (mouseout)="setFocus('')" class="fas fa-info"></i>&nbsp; {{"limitPowerCharger" | translate}}</label><br>
                      <small class="text-grey" *ngIf="focus=='maxCharger'">{{"limitPowerChargerText" | translate}}<br></small>
                  </div>

                  <div class="col-sm-4">
                      <input type="number" class="form-control" name="ampacity" [(ngModel)]="ampacity"
                          placeholder="0-32 A" (change)="checkChangesAmpacity()">
                          <input type="number" max="7360" class="form-control"  name="unswitchCurrent" id="unswitchCurrent" [(ngModel)]="unswitchCurrent" (change)="checkChangesUnswitchCurrent()">
                          <div class="input_right_data">(W)</div>
                  </div>
                </div>

 -->


                <!--Multiequipo-->
                <div class="row col-12 mb-2" *ngIf="splCfg!=undefined">
                    <label class="col-2 text-left text-bold">{{splCfg?.splOpMode==2? 'SPL' : ("multidevice" | translate)}}</label>
                    <label class="switch">
                        <input type="checkbox" [checked]="splCfg.splMode!=0" (click)="splCfg.splMode!=0 ? splCfg.splMode=0 : splCfg.splMode=1;checkChangesSPL();" name="splEnabled">
                        <span class="slider round"></span>
                    </label>
                </div>

                <div class="spl-container" *ngIf="splCfg!=undefined && splCfg?.splMode!=0">

                    <div class="form-group row d-flex" *ngIf="versionSPLInterfaceCompatible">
                        <label class="col-form-label col-sm-4 text-sm-right col-6">{{"interface" | translate}}</label>
                        <div class="col-sm-4 d-flex align-items-center">
                            <select class="form-select" name="splInterface" [(ngModel)]="selectSplInterface" (change)="checkChangesSPL()">
                                <option value="1">RS485</option>
                                <option value="3">{{"networkInterface" | translate}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row d-flex">
                        <label class="col-form-label col-sm-4 text-sm-right col-6">{{"mode" | translate}}</label>
                        <div class="col-sm-4 d-flex align-items-center">
                            <div class="col-6 d-flex">
                                <label for="splMaster" class="form-check-label m-0">{{"master" | translate}}</label>
                            </div>
                            <div class="form-check">
                                <input type="radio" class="form-check-input" name="splMaster" [checked]="splCfg?.splMode==1"
                                    (click)="splCfg.splMode=1;checkChangesSPL();">
                            </div>
                        </div>
                        <div class="col-sm-4 d-flex align-items-center">
                            <div class="col-6 d-flex">
                                <label for="splSlave" class="form-check-label m-0">{{"slave" | translate}}</label>
                            </div>
                            <div class="form-check">
                                <input type="radio" class="form-check-input" name="splSlave" [checked]="splCfg?.splMode==2"
                                    (click)="splCfg.splMode=2;checkChangesSPL();">
                            </div>
                        </div>
                    </div>

                    <div *ngIf="selectSplInterface==3">
                        <div class="form-group row" *ngIf="splCfg.splMode==2">
                            <label class="col-form-label col-sm-4 text-sm-right">{{"splIp" | translate}}</label>
                            <div class="col-sm-8 d-flex">
                                <input type="text" class="form-control col-4" name="splIp" [(ngModel)]="splCfg.splIp"
                                    placeholder="0.0.0.0" (change)="checkChangesSPL()">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-form-label col-sm-4 text-sm-right">{{"splPort" | translate}}</label>
                            <div class="col-sm-8 d-flex">
                                <input type="number" class="form-control col-4" name="splPort" [(ngModel)]="splCfg.splPort"
                                    placeholder="" (change)="checkChangesSPL()">
                            </div>
                        </div>
                    </div>

                    <div class="form-group row d-flex" *ngIf="splCfg.splOpMode==2 && splCfg?.splMode==1">
                        <label class="col-form-label col-sm-4 text-sm-right col-6">{{"splMeter" | translate}}</label>
                        <div class="col-sm-4 d-flex align-items-center">
                            <div class="col-6 d-flex">
                                <label for="splMaster" class="form-check-label m-0">TMC100</label>
                            </div>
                            <div class="form-check">
                                <input type="radio" class="form-check-input" name="splTMC" [checked]="splCfg?.splMeter==1"
                                    (click)="splCfg.splMeter=1;checkChangesSPL();">
                            </div>
                        </div>
                        <div class="col-sm-4 d-flex align-items-center">
                            <div class="col-6 d-flex">
                                <label for="splSlave" class="form-check-label m-0">Contax</label>
                            </div>
                            <div class="form-check">
                                <input type="radio" class="form-check-input" name="splContax" [checked]="splCfg?.splMeter==0"
                                    (click)="splCfg.splMeter=0;checkChangesSPL();">
                            </div>
                        </div>
                    </div>

                    <div class="form-group row" *ngIf="splCfg?.splMode==1 && showAdvanced">
                        <div class="col-sm-4 text-sm-right">
                            <label class="col-form-label text-sm-right"><i (mouseover)="setFocus('splLimitPower')" (mouseout)="setFocus('')" class="fas fa-info"></i>&nbsp; {{"splLimitPower" |
                                translate}} (W)</label><br>
                            <small class="text-grey" *ngIf="focus=='splLimitPower'">{{"splLimitPowerText" | translate}}<br></small>
                        </div>

                        <div class="col-sm-4">
                            <input type="number" class="form-control" name="splLimitPower" [(ngModel)]="splCfg.splLimitPower"
                                placeholder="W" (change)="checkChangesSPL()">
                        </div>
                    </div>
                    <div *ngIf="splCfg?.splMode==1 && showAdvanced" class="form-group row">
                        <div class="col-sm-4 text-sm-right">
                            <label class="col-form-label text-sm-right"><i (mouseover)="setFocus('splLimitPowerByPhase')" (mouseout)="setFocus('')" class="fas fa-info"></i>&nbsp; {{"splLimitPowerByPhase" |
                                translate}} (W)</label><br>
                            <small class="text-grey" *ngIf="focus=='splLimitPowerByPhase'">{{"splLimitPowerByPhase_desc" | translate}}<br></small>
                        </div>
                        <div class="col-sm-8 d-flex">
                            <input type="number" class="form-control col-4" name="splLimitPowerByPhase_0" [(ngModel)]="splCfg.splLimitPowerByPhase[0]"
                                placeholder="W" (change)="checkChangesSPL()">
                            <input type="number" class="form-control col-4" name="splLimitPowerByPhase_1" [(ngModel)]="splCfg.splLimitPowerByPhase[1]"
                                placeholder="W" (change)="checkChangesSPL()">
                            <input type="number" class="form-control col-4" name="splLimitPowerByPhase_2" [(ngModel)]="splCfg.splLimitPowerByPhase[2]"
                                placeholder="W" (change)="checkChangesSPL()">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4 text-sm-right">
                            <label class="col-form-label text-sm-right"><i (mouseover)="setFocus('splErrorCurrent')" (mouseout)="setFocus('')" class="fas fa-info"></i>&nbsp; {{"splFailPowerLabel" |
                                translate}} (A)</label><br>
                            <small class="text-grey" *ngIf="focus=='splErrorCurrent'">{{"currentChargeWhenConnectionLoss" | translate}}<br></small>
                        </div>
                        <div class="col-sm-4">
                            <input type="number" class="form-control" name="splSlaveFailPower" [(ngModel)]="splCfg.splSlaveFailPower"
                                placeholder="6-10 A" (change)="checkChangesSPL()">
                        </div>
                    </div>
                </div>
                <div class="card-footer" *ngIf="changes.spl || splCfg?.splMode!=0">
                    <div class="form-group row">
                        <div class="col-sm-10 ml-sm-auto">
                            <button type="submit" class="btn btn-primary" (click)="saveSPL()"
                                [disabled]="!changes.spl">{{"overwrite" | translate}}</button>
                            <button type="submit" class="btn btn-light ml-2" [disabled]="!changes.spl">{{"restore"
                                | translate}}</button>
                        </div>

                    </div>
                    <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error.spl!=''">
                        <div class="alert-message">
                            {{ error.spl }}
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage.spl">
                        <div class="alert-message">
                            {{"updatedSuccesfully" | translate}}
                        </div>
                    </div>
                </div>

                <div class="row col-12 mb-2" *ngIf="splCfg!=undefined">
                    <label class="col-2 text-left text-bold">{{"solar" | translate}}</label>
                    <label class="switch">
                        <input type="checkbox" [checked]="solarCfg?.enabled!=0" (click)="solarCfg.enabled=solarCfg.enabled==0?1:0;checkChangesSolar();" name="solarEnabled">
                        <span class="slider round"></span>
                    </label>
                </div>

                <div class="solar-container" *ngIf="solarCfg!=undefined && solarCfg?.enabled!=0">
                    <div class="form-group row">
                        <label class="col-form-label col-sm-4 text-sm-right">{{"fvPower" | translate}} (W)</label>
                        <div class="col-sm-8 d-flex">
                            <input type="number" class="form-control col-4" name="maxFvPower" [(ngModel)]="solarCfg.maxFvPower"
                                placeholder="W" (change)="checkChangesSolar()">

                        </div>
                    </div>
                    <div class="form-group row d-flex">
                        <label class="col-form-label col-sm-4 text-sm-right col-6">{{"mode" | translate}}</label>
                        <div class="col-sm-4 d-flex align-items-center">
                            <div class="col-6 d-flex">
                                <label for="splMaster" class="form-check-label m-0">{{"solar_injects" | translate}}</label>
                            </div>
                            <div class="form-check">
                                <input type="radio" class="form-check-input" name="injects" [checked]="solarCfg.injected==1"
                                    (click)="solarCfg.injected=1;checkChangesSolar();"
                                    >
                            </div>
                        </div>
                        <div class="col-sm-4 d-flex align-items-center">
                            <div class="col-6 d-flex">
                                <label for="splSlave" class="form-check-label m-0">{{"solar_noInjects" | translate}}</label>
                            </div>
                            <div class="form-check">
                                <input type="radio" class="form-check-input" name="noInjects" [checked]="solarCfg.injected==0"
                                    (click)="solarCfg.injected=0;checkChangesSolar();"
                                    >
                            </div>
                        </div>
                    </div>

                    <div class="form-group row d-flex">
                        <label class="col-form-label col-sm-4 text-sm-right col-6">{{"solarType" | translate}}</label>
                        <div class="col-sm-4 d-flex align-items-center">
                            <div class="col-6 d-flex">
                                <label for="splMaster" class="form-check-label m-0">{{"monophasic" | translate}}</label>
                            </div>
                            <div class="form-check">
                                <input type="radio" class="form-check-input" name="monophasic" [checked]="!solarCfgTriphasic"
                                    (click)="solarCfgTriphasic=false;changeSolarOption();checkChangesSolar();"
                                    >
                            </div>
                        </div>
                        <div class="col-sm-4 d-flex align-items-center">
                            <div class="col-6 d-flex">
                                <label for="splSlave" class="form-check-label m-0">{{"triphasic" | translate}}</label>
                            </div>
                            <div class="form-check">
                                <input type="radio" class="form-check-input" name="triphasic" [checked]="solarCfgTriphasic"
                                    (click)="solarCfgTriphasic=true;changeSolarOption();checkChangesSolar();"
                                    >
                            </div>
                        </div>
                    </div>

                    <div class="form-group row d-flex" *ngIf="!inversorParsed?.enabled">
                        <label class="col-form-label col-sm-4 text-sm-right col-6">{{"solarOption" | translate}}</label>
                        <div class="col-sm-4 d-flex align-items-center">
                            <select class="form-select" name="solarType"
                                    [(ngModel)]="solarOption"
                                    (change)="changeSolarOption();checkChangesSolar();"
                                >
                                <option value="1">{{"solarOption1" | translate}}</option>
                                <option value="2">{{"solarOption2" | translate}}</option>
                                <option value="3">{{"solarOption3" | translate}}</option>
                            </select>
                        </div>
                    </div>

                    <!-- Inversor-->
                    <div class="form-group row d-flex" *ngIf="versionInversorCompatible">
                        <label class="col-form-label col-sm-4 text-sm-right col-6">{{"inverter" | translate}}</label>

                        <div class="col-sm-4 d-flex align-items-center">
                            <label class="switch">
                                <input type="checkbox" [checked]="inversorParsed?.enabled!=false" (click)="inversorParsed.enabled=!inversorParsed.enabled?true:false;checkChangesSolar();" name="inversorEnabled">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>


                    <div class="form-group row d-flex" *ngIf="inversorParsed?.enabled">
                      <label class="col-form-label col-sm-4 text-sm-right col-6"></label>
                      <div class="col-8">
                          <div *ngIf="versionInversorCompatible">
                              <div class="row col-12">
                                  <div class="form-group row">
                                      <select [disabled]="!inversorParsed.enabled" class="form-select" name="inversorParsed.model" [(ngModel)]="inversorParsed.model" (change)="checkChangesSolar()">
                                            <option value="0" selected disabled>{{"solarInverterDropdownPlaceholder" | translate}}</option>
                                            <option *ngFor="let item of inverters" [value]=item.value>{{item.label}}</option>
                                      </select>
                                  </div>
                                  <div class="form-group row">
                                      <label class="col-form-label col-sm-4 text-sm-right">{{"ipAddress" | translate}}</label>
                                      <div class="col-sm-8 d-flex">
                                          <input [disabled]="!inversorParsed.enabled" type="text" class="form-control col-12" name="inversorIP" [(ngModel)]="inversorParsed.inversorIP"
                                              placeholder="0.0.0.0" (change)="checkChangesSolar()" >
                                      </div>
                                  </div>
                                  <div class="form-group row">
                                      <label class="col-form-label col-sm-4 text-sm-right">{{"port" | translate}}</label>
                                      <div class="col-sm-8 d-flex">
                                          <input [disabled]="!inversorParsed.enabled" type="number" min="0" class="form-control col-12" name="inversorPort" [(ngModel)]="inversorParsed.inversorPort"
                                              placeholder="0" (change)="checkChangesSolar()">
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>

                </div>
                <div class="card-footer" *ngIf="this.changes.solar || solarCfg?.enabled!=0">
                    <div class="form-group row">
                        <div class="col-sm-10 ml-sm-auto">
                            <button type="submit" class="btn btn-primary" (click)="saveSolar()"
                                [disabled]="!this.changes.solar">{{"overwrite" | translate}}</button>
                            <button type="submit" class="btn btn-light ml-2" [disabled]="!this.changes.solar">{{"restore"
                                | translate}}</button>
                        </div>
                    </div>
                    <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error.solar!=''">
                        <div class="alert-message">
                            {{ error.solar }}
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage.solar">
                        <div class="alert-message">
                            {{"updatedSuccesfully" | translate}}
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>
