import { Component, OnInit, ViewChild } from '@angular/core';
import { Location} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';

@Component({
    //moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['navbar.component.scss']
})

export class NavbarComponent implements OnInit{
    // private listTitles: any[];
    location: Location;
    // private nativeElement: Node;
    // private toggleButton;
    // private sidebarVisible: boolean;

    public isCollapsed = true;
    @ViewChild("navbar-cmp", {static: false}) button;

    constructor(location:Location,
        private translate: TranslateService, private _auth: AuthService, private _theme: ThemeService, private _dashboardService : DashboardService) {
        this.location = location;
        // this.nativeElement = element.nativeElement;
        // this.sidebarVisible = false;
    }

    ngOnInit(){
      setTimeout(() => {
        document.getElementById('sidebar-toggle')?.classList.remove('d-none')
      }, 1000);
    }

    toggleTheme() {
        if (this._theme.isDarkTheme()) {
          this._theme.setLightTheme();
        } else {
          this._theme.setDarkTheme();
        }
      }

    changeLang(lang){
        this.translate.use(lang);
    }

    logout(){
        this._auth.logout();
    }

    home(){
        this._dashboardService.setUpdateSidebar();
    }

}
