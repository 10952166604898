<div>
    <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-8">
              <h5 class="card-title">{{"installation" | translate}}</h5>
              <h6 class="card-subtitle text-muted"></h6>
            </div>

            <div class="col-sm-4 text-right">
              <label class="col-7 text-right text-bold">{{showAdvanced ? 'Advanced' : 'Basic'}}</label>
              <label class="switch">
                  <input type="checkbox" [checked]="showAdvanced" (click)="setAdvanced();" name="advancedEnabled">
                  <span class="slider round"></span>
              </label>
          </div>
          </div>
        </div>
        <div class="card-body">
            <form>

                <!--Charger limits-->
                <div *ngIf="showAdvanced" class="col-12 mb-3 pl-5">
                    <a (click)="collapsibles.chargerLimits = !collapsibles.chargerLimits" [attr.aria-expanded]="!collapsibles.chargerLimits" style="cursor: pointer;">
                        <i style="font-size: 12px;" class="fas fa-chevron-down" *ngIf="collapsibles.chargerLimits"></i>
                        <i style="font-size: 12px;" class="fas fa-chevron-right" *ngIf="!collapsibles.chargerLimits"></i>
                        <span class="align-middle ml-2 text-bold">{{"chargerLimits" | translate}}</span>
                    </a>
                    <div #collapse="ngbCollapse" [(ngbCollapse)]="!collapsibles.chargerLimits" class="col-12 pt-2 pl-5">
                        <!-- LimitPowerCharger -->
                        <div class="form-group row">
                            <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                                <label class="col-form-label">{{"limitPowerCharger" | translate}}<span class="text-muted" *ngIf="num_phases === 3"> (x3)</span>:</label>
                                <i class="fas fa-info-circle pl-2" [matTooltip]="'limitPowerChargerText' | translate"></i>
                            </div>

                            <div class="col-xl-4 col-sm-5 d-flex">
                                <input type="number" class="form-control form-control-group" name="ampacity" [(ngModel)]="ampacity" placeholder="0-32 A" (change)="checkChangesAmpacity()">
                                <div class="input_right_data">A</div>
                            </div>
                        </div>

                        <!-- LimitPowerConnectors -->
                        <div class="form-group row" *ngFor="let c of connectorsMennekes, let i=index">
                            <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                                <label class="col-form-label">{{"limitPowerConnector" | translate}} {{i+1}}<span class="text-muted" *ngIf="num_phases === 3"> (x3)</span>:</label><br>
                                <i class="fas fa-info-circle pl-2" [matTooltip]="'limitPowerConnectorText' | translate"></i>
                            </div>
                            <div class="col-xl-4 col-sm-5 d-flex">
                                <input type="number" class="form-control form-control-group" name="connPower_{{i}}" [(ngModel)]="c.data.cfg.limitPower" placeholder="0-32 A" (change)="checkChangesAmpacityConn()">
                                <div class="input_right_data">A</div>
                            </div>
                        </div>

                        <!--div class="card-footer">
                            <div class="form-group row">
                                <div class="col-sm-10 ml-sm-auto">
                                    <button type="submit" class="btn btn-primary" [disabled]="!this.changes.ampacity && !this.changes.ampacityConn" (click)="saveAmpacity();">{{"settings.overwrite" | translate}}</button>
                                    <button type="submit" class="btn btn-light ml-2" [disabled]="!this.changes.ampacity && !this.changes.ampacityConn">{{"settings.restore" | translate}}</button>
                                </div>

                            </div>
                            <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error.ampacity!=''">
                                <div class="alert-message">
                                    {{ error.minP }}
                                </div>
                            </div>
                            <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage.ampacity">
                                <div class="alert-message">
                                    {{"settings.successMessage" | translate}}
                                </div>
                            </div>
                        </div-->
                    </div>
                </div>

                <!-- Rotación de fases -->
                <div class="col-12 mb-3 pl-5" *ngIf="showAdvanced && (num_phases === 3 || (splCfg!=undefined && splCfg?.splMode>0))">
                    <a (click)="collapsibles.phaseRotation = !collapsibles.phaseRotation" [attr.aria-expanded]="!collapsibles.phaseRotation" style="cursor: pointer;">
                        <i style="font-size: 12px;" class="fas fa-chevron-down" *ngIf="collapsibles.phaseRotation"></i>
                        <i style="font-size: 12px;" class="fas fa-chevron-right" *ngIf="!collapsibles.phaseRotation"></i>
                        <span class="align-middle ml-2 text-bold">{{"phaseRotation" | translate}}</span>
                    </a>
                    <div #collapse="ngbCollapse" [(ngbCollapse)]="!collapsibles.phaseRotation" class="col-12 pt-2 pl-5">
                        <div class="form-group row" *ngFor="let c of connectorsMennekes, let i=index">
                            <div class="col-xl-4 col-md-6 col-sm-7">
                                <label class="col-form-label"> {{"connector" | translate}} {{i+1}}:</label>
                                <i class="fas fa-info-circle pl-2" [matTooltip]="'rotPhasesText' | translate"></i>

                            </div>
                            <div class="col-xl-4 col-sm-5">
                                <select class="form-select" id="phRotSel_{{i}}" name="phRotSel_{{i}}" [(ngModel)]="c.data.cfg.phaseRotation" (change)="checkChangesRotation()">
                                    <option *ngFor="let item of rotationPhases" [value]="item.value">{{item.name}}</option>
                                </select>
                            </div>
                        </div>
                        <!--div class="card-footer">
                            <div class="form-group row">
                            <div class="col-sm-10 ml-sm-auto">
                                <button type="submit" class="btn btn-primary" (click)="saveAmpacity()"
                                [disabled]="!this.changes.rotationPhases">{{"settings.overwrite" | translate}}</button>
                            <button type="submit" class="btn btn-light ml-2" [disabled]="!this.changes.rotationPhases">{{"settings.restore"
                                | translate}}</button>
                            </div>
                            </div>
                            <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage.rotationPhases">
                                <div class="alert-message">
                                    {{"settings.successMessage" | translate}}
                                </div>
                            </div>
                        </div-->
                    </div>
                </div>

                <!--Automatic phase switch.-->
                <div  class="col-12 mb-3 pl-5"  *ngIf="showAdvanced && body.switchEnabled !== -1 && (num_phases === 3 || (splCfg!=undefined && splCfg?.splMode>0))">
                  <a (click)="collapsibles.phaseSwitching = !collapsibles.phaseSwitching" [attr.aria-expanded]="!collapsibles.phaseSwitching" style="cursor: pointer;">
                    <i style="font-size: 12px;" class="fas fa-chevron-down" *ngIf="collapsibles.phaseSwitching"></i>
                    <i style="font-size: 12px;" class="fas fa-chevron-right" *ngIf="!collapsibles.phaseSwitching"></i>
                    <span class="align-middle ml-2 text-bold">{{"phaseSwitching" | translate}}</span>
                </a>
                <div #collapse="ngbCollapse" [(ngbCollapse)]="!collapsibles.phaseSwitching" class="col-12 pt-2 pl-5">
                      <div class="form-group row">
                          <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                            <label class="col-form-label"> {{"switchingPhases" | translate}}:</label>
                            <i class="fas fa-info-circle pl-2" [matTooltip]="'switchingPhasesText' | translate"></i>
                          </div>

                          <div class="col-xl-4 col-sm-5 d-flex">
                            <label class="switch">
                              <input type="checkbox" [checked]="body.switchEnabled" (click)="body.switchEnabled === 0 ? body.switchEnabled = 1 : body.switchEnabled = 0; checkSwitchEnabled()" name="automaticSwitch">
                              <span class="slider round"></span>
                          </label>
                          </div>
                      </div>

                      <div class="form-group row" *ngIf="body.switchEnabled">
                        <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                          <label class="col-form-label">  {{"valueSwitchingPhases" | translate}}:</label>
                          <i class="fas fa-info-circle pl-2" [matTooltip]="'powerSwitchingPhasesText' | translate"></i>
                        </div>

                        <div class="col-xl-4 col-sm-5 d-flex">
                            <input type="number" max="7360" min="4140" class="form-control form-control-group "  name="unswitchCurrent" id="unswitchCurrent" [(ngModel)]="unswitchCurrent" (change)="checkChangesUnswitchCurrent()">
                            <div class="input_right_data">W</div>
                        </div>
                    </div>
                 </div>
              </div>


                              <!--safetyOnRetries.-->
                              <div class="col-12 mb-3 pl-5" *ngIf="showAdvanced && num_phases != 3 && version230BetweenPhases">
                                <a (click)="collapsibles.safetyOnRetries = !collapsibles.safetyOnRetries"
                                  [attr.aria-expanded]="!collapsibles.safetyOnRetries" style="cursor: pointer;">
                                  <i style="font-size: 12px;" class="fas fa-chevron-down" *ngIf="collapsibles.phaseSwitching"></i>
                                  <i style="font-size: 12px;" class="fas fa-chevron-right" *ngIf="!collapsibles.phaseSwitching"></i>
                                  <span class="align-middle ml-2 text-bold"> {{'powerBetweenPhases'| translate }}</span>
                                </a>
                                <div #collapse="ngbCollapse" [(ngbCollapse)]="!collapsibles.safetyOnRetries" class="col-12 pt-2 pl-5">
                                  <div class="form-group row">
                                    <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                                      <label class="col-form-label"> {{'powerBetweenPhasesText'| translate }}:</label>
                                      <i class="fas fa-info-circle pl-2" [matTooltip]="'powerBetweenPhasesDesc'| translate "></i>
                                    </div>

                                    <div class="col-xl-4 col-sm-5 d-flex">
                                      <label class="switch">
                                        <input type="checkbox" [checked]="safetyOnRetries" (change)="changeSafetyOnRetries()">
                                        <span class="slider round"></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                <!-- Modulator -->
                <div class="row col-12 mb-3">
                    <label class="col-auto text-left text-bold mb-0">{{"modulator" | translate}}</label>
                    <label class="switch">
                        <input type="checkbox" [checked]="modulatorActiv==true" (click)="activeModulator()" name="modulatorEnabled">
                        <span class="slider round"></span>
                    </label>
                </div>

                <div *ngIf="modulatorActiv" class="pl-5">
                    <!-- LimitPower -->
                    <div class="form-group row d-flex">
                        <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                            <label class="col-form-label">{{"hiredPower" | translate}}:</label>
                            <i class="fas fa-info-circle pl-2" [matTooltip]="'limitPowerDesc' | translate"></i>
                        </div>

                        <div class="col-xl-4 col-sm-5">
                            <div class="d-flex">
                                <input type="number" class="form-control form-control-group" name="limitPower" [(ngModel)]="body.limitPower" placeholder="{{'hiredPower' | translate}}"
                                (change)="checkChangesLimit()" [disabled]="splCfg?.splMode==2">
                                <div class="input_right_data">W</div>
                            </div>
                            <small class="ml-2 text-grey" *ngIf="splCfg?.splMode==2">{{"masterProperty" | translate}}</small>
                        </div>
                    </div>

                    <!-- LimitPowerByPhase -->
                    <div *ngIf="(num_phases === 3 || (splCfg!=undefined && splCfg?.splMode>0)) && showAdvanced" class="form-group row">
                        <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                            <label class="col-form-label">{{"limitPowerByPhase" | translate}}:</label>
                            <i class="fas fa-info-circle pl-2" [matTooltip]="'limitPowerByPhaseText' | translate"></i>
                        </div>
                        <div class="col-xl-4 col-sm-5">
                            <div class="d-flex">
                                <input type="number" class="form-control form-control-group" name="limitPowerByPhase" [(ngModel)]="body.limitPowerByPhase[0]" placeholder="{{'hiredPower' | translate}}"
                                (change)="checkChangesLimit()" max="22080" [disabled]="splCfg?.splMode==2">
                                <div class="input_right_data">W</div>
                            </div>
                            <small class="ml-2 text-grey" *ngIf="splCfg?.splMode==2">{{"masterProperty" | translate}}</small>
                        </div>
                    </div>

                    <!-- MainMeter -->
                    <div class="form-group row">
                        <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                            <label class="col-form-label">{{"mainMeter" | translate}}:</label>
                            <i class="fas fa-info-circle pl-2" [matTooltip]="'mainMeterText' | translate"></i>
                        </div>
                        <div class="col-xl-4 col-sm-5 d-flex">
                            <select class="form-select form-select-group" id="mainMeter" name="mainMeter" [(ngModel)]="splCfg.splMeter" (change)="checkChangesMainMeter()">
                                <option *ngFor="let item of mainMeters" [ngValue]="item.value">{{item.label | translate}}</option>
                            </select>
                            <div class="input_right_data minPad" [ngClass]="{'notRadRight' : (splCfg.splMeter==3 || splCfg.splMeter==4 || splCfg.splMeter==6)}">
                                <i *ngIf="infoModulator.stat.ctxDetected == undefined" class="fas fa-circle text-light fa-fw" style="font-size: 20px;"></i>
                                <i *ngIf="infoModulator.stat.ctxDetected != undefined && !infoModulator.stat.ctxDetected" class="fas fa-circle text-danger fa-fw" style="font-size: 20px;"></i>
                                <i *ngIf="infoModulator.stat.ctxDetected" class="fas fa-circle text-success fa-fw" style="font-size: 20px;"></i>
                            </div>
                            <div *ngIf="splCfg.splMeter==3 || splCfg.splMeter==4 || splCfg.splMeter==6" class="input_right_data minPad" (click)="openMainMeterCfg()">
                                <i class="fas fa-cog fa-fw" style="font-size: 20px;"></i>
                            </div>
                        </div>
                    </div>

                    <!-- Vehicle collapse-->
                    <div class="mb-3">
                        <a (click)="collapsibles.vehicle = !collapsibles.vehicle" [attr.aria-expanded]="!collapsibles.vehicle" style="cursor: pointer;">
                            <i style="font-size: 12px;" class="fas fa-chevron-down" *ngIf="collapsibles.vehicle"></i>
                            <i style="font-size: 12px;" class="fas fa-chevron-right" *ngIf="!collapsibles.vehicle"></i>
                            <span class="align-middle ml-2 text-bold">{{"vehicle" | translate}}</span>
                        </a>

                        <div #collapse="ngbCollapse" [(ngbCollapse)]="!collapsibles.vehicle" class="col-12 pt-2 pl-5">
                            <div class="form-group row">
                                <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                                    <label class="col-form-label">{{"minPowerLabel" | translate}}:</label>
                                    <i class="fas fa-info-circle pl-2" [matTooltip]="'minPowerText' | translate"></i>
                                </div>

                                <div class="col-xl-4 col-sm-5 d-flex">
                                    <input type="number" class="form-control form-control-group" name="minPower" [(ngModel)]="bodyMinP.minPower" placeholder="6-10 A" (change)="checkChangesMin()">
                                    <div class="input_right_data">A</div>
                                </div>
                                <!--div class="form-group row">
                                    <div class="col-sm-10 ml-sm-auto">
                                        <button type="submit" class="btn btn-primary" (click)="saveMinP()"
                                            [disabled]="!this.changes.minP">{{"settings.overwrite" | translate}}</button>
                                        <button type="submit" class="btn btn-light ml-2" [disabled]="!this.changes.minP">{{"settings.restore"
                                            | translate}}</button>
                                    </div>
                                </div-->
                                <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error.minP!=''">
                                    <div class="alert-message">
                                        {{ error.minP }}
                                    </div>
                                </div>
                                <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage.minP">
                                    <div class="alert-message">
                                        {{"successMessage" | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--div class="card-footer">
                    <div class="form-group row">
                        <div class="col-sm-10 ml-sm-auto">
                            <button type="submit" class="btn btn-primary" (click)="savePower()"
                                [disabled]="!this.changes.limitP">{{"settings.overwrite" | translate}}</button>
                            <button type="submit" class="btn btn-light ml-2" [disabled]="!this.changes.limitP">{{"settings.restore"
                                | translate}}</button>
                        </div>

                    </div>
                    <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error.limitP!=''">
                        <div class="alert-message">
                            {{ error.limitP }}
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage.limitP">
                        <div class="alert-message">
                            {{"settings.successMessage" | translate}}
                        </div>
                    </div>
                </div-->

                <!--Multiequipo-->
                <div class="row col-12 mb-3" *ngIf="splCfg!=undefined">
                    <label class="col-auto text-left text-bold mb-0">{{(splCfg?.splOpMode==2? 'SPL' : 'multidevice') | translate}}</label>
                    <label class="switch">
                        <input type="checkbox" [checked]="splCfg.splMode!=0" (click)="splCfg.splMode!=0 ? splCfg.splMode=0 : splCfg.splMode=1;checkChangesSPL();" name="splEnabled">
                        <span class="slider round"></span>
                    </label>
                </div>

                <div class="spl-container pl-5" *ngIf="splCfg!=undefined && splCfg?.splMode!=0">

                    <div class="form-group row d-flex" *ngIf="versionSPLInterfaceCompatible">
                        <label class="col-form-label col-sm-4 col-6">{{"interface" | translate}}:</label>
                        <div class="col-sm-4 d-flex align-items-center">
                            <select class="form-select" name="splInterface" [(ngModel)]="selectSplInterface" (change)="checkChangesSPL()">
                                <option value="1">RS485</option>
                                <option value="3">{{"networkInterface" | translate}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row d-flex">
                        <label class="col-form-label col-sm-4 col-6">{{"splMode" | translate}}:</label>
                        <div class="col-6 d-flex">
                            <div class="d-flex align-items-center mr-3">
                                <div class="d-flex">
                                    <label for="splMaster" class="form-check-label m-0">Master</label>
                                </div>
                                <div class="form-check ml-2">
                                    <input type="radio" class="form-check-input" name="splMaster" [checked]="splCfg?.splMode==1"
                                        (click)="splCfg.splMode=1;checkChangesSPL();">
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="d-flex">
                                    <label for="splSlave" class="form-check-label m-0">Slave</label>
                                </div>
                                <div class="form-check ml-2">
                                    <input type="radio" class="form-check-input" name="splSlave" [checked]="splCfg?.splMode==2"
                                        (click)="splCfg.splMode=2;checkChangesSPL();">
                                </div>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="selectSplInterface==3">
                        <div class="form-group row" *ngIf="splCfg.splMode==2">
                            <label class="col-form-label col-sm-4">{{"splIp" | translate}}:</label>
                            <div class="col-sm-4 d-flex">
                                <input type="text" class="form-control" name="splIp" [(ngModel)]="splCfg.splIp"
                                    placeholder="0.0.0.0" (change)="checkChangesSPL()">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-form-label col-sm-4">{{"splPort" | translate}}:</label>
                            <div class="col-sm-4 d-flex">
                                <input type="number" class="form-control" name="splPort" [(ngModel)]="splCfg.splPort"
                                    placeholder="" (change)="checkChangesSPL()">
                            </div>
                        </div>
                    </div>

                    <!-- Chargers line -->
                    <div class="mb-3" *ngIf="showAdvanced">
                        <a (click)="collapsibles.chargersLine = !collapsibles.chargersLine" [attr.aria-expanded]="!collapsibles.chargersLine" style="cursor: pointer;">
                            <i style="font-size: 12px;" class="fas fa-chevron-down" *ngIf="collapsibles.chargersLine"></i>
                            <i style="font-size: 12px;" class="fas fa-chevron-right" *ngIf="!collapsibles.chargersLine"></i>
                            <span class="align-middle ml-2 text-bold">{{"chargersLine" | translate}}</span>
                        </a>

                        <div #collapse="ngbCollapse" [(ngbCollapse)]="!collapsibles.chargersLine" class="col-12 pt-2 pl-5">
                            <div class="form-group row" *ngIf="showAdvanced">
                                <div class="col-xl-4 col-md-6 d-flex align-items-center">
                                    <label class="col-form-label">{{"splLimitPower" | translate}}:</label>
                                    <i class="fas fa-info-circle pl-2" [matTooltip]="'splLimitPowerText' | translate"></i>
                                </div>

                                <div class="col-xl-4 col-sm-5">
                                    <div class="d-flex">
                                        <input type="number" class="form-control form-control-group" name="splLimitPower" [(ngModel)]="splCfg.splLimitPower"
                                            placeholder="W" (change)="checkChangesSPL()" [disabled]="splCfg?.splMode==2">
                                        <div class="input_right_data">W</div>
                                    </div>
                                    <small class="ml-2 text-grey" *ngIf="splCfg?.splMode==2">{{"masterProperty" | translate}}</small>
                                </div>
                            </div>
                            <div *ngIf="showAdvanced" class="form-group row">
                                <div class="col-xl-4 col-md-6 d-flex align-items-center">
                                    <label class="col-form-label">{{"splLimitPowerByPhase" | translate}}:</label>
                                    <i class="fas fa-info-circle pl-2" [matTooltip]="'splLimitPowerByPhase_desc' | translate"></i>
                                </div>
                                <div class="col-xl-7 col-md-6">
                                    <div class="d-flex">
                                        <input type="number" class="form-control form-control-group col-3" name="splLimitPowerByPhase_0" [(ngModel)]="splCfg.splLimitPowerByPhase[0]"
                                            placeholder="W" (change)="checkChangesSPL()" [disabled]="splCfg?.splMode==2">
                                        <input type="number" class="form-control form-control-group col-3" name="splLimitPowerByPhase_1" [(ngModel)]="splCfg.splLimitPowerByPhase[1]"
                                            placeholder="W" (change)="checkChangesSPL()" [disabled]="splCfg?.splMode==2">
                                        <input type="number" class="form-control form-control-group col-3" name="splLimitPowerByPhase_2" [(ngModel)]="splCfg.splLimitPowerByPhase[2]"
                                            placeholder="W" (change)="checkChangesSPL()" [disabled]="splCfg?.splMode==2">
                                        <div class="input_right_data">W</div>
                                    </div>
                                    <small class="ml-2 text-grey" *ngIf="splCfg?.splMode==2">{{"masterProperty" | translate}}</small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                            <label class="col-form-label">{{"splFailPowerLabel" | translate}}:</label>
                            <i class="fas fa-info-circle pl-2" [matTooltip]="'splFailPowerText' | translate"></i>
                        </div>
                        <div class="col-xl-4 col-sm-5 d-flex">
                            <input type="number" class="form-control form-control-group" name="splSlaveFailPower" [(ngModel)]="splCfg.splSlaveFailPower"
                                placeholder="6-10 A" (change)="checkChangesSPL()">
                            <div class="input_right_data">A</div>
                        </div>
                    </div>
                </div>
                <!--div class="card-footer" *ngIf="changes.spl || splCfg?.splMode!=0">
                    <div class="form-group row">
                        <div class="col-sm-10 ml-sm-auto">
                            <button type="submit" class="btn btn-primary" (click)="saveSPL()"
                                [disabled]="!changes.spl">{{"settings.overwrite" | translate}}</button>
                            <button type="submit" class="btn btn-light ml-2" [disabled]="!changes.spl">{{"settings.restore"
                                | translate}}</button>
                        </div>

                    </div>
                    <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error.spl!=''">
                        <div class="alert-message">
                            {{ error.spl }}
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage.spl">
                        <div class="alert-message">
                            {{"settings.successMessage" | translate}}
                        </div>
                    </div>
                </div-->

                <!-- Solar -->
                <div *ngIf="solarActive">
                    <div class="d-flex mb-3" *ngIf="splCfg!=undefined">
                        <label class="col-auto text-left text-bold mb-0">Solar</label>
                        <label class="switch" [ngClass]="{'switchDis' : (splCfg.splMeter==1 || splCfg?.splMode==2)}">
                            <input type="checkbox" [disabled]="splCfg.splMeter==1 || splCfg?.splMode==2" [checked]="solarCfg?.enabled!=0 && splCfg?.splMeter!=1" (click)="solarCfg.enabled=solarCfg.enabled==0?1:0;checkChangesSolar();" name="solarEnabled">
                            <span class="slider round"></span>
                        </label>
                        <small class="ml-3 text-grey" *ngIf="splCfg.splMeter==1">{{"tmc100Depends" | translate}}</small>
                        <small class="ml-3 text-grey" *ngIf="splCfg.splMeter!=1 && splCfg?.splMode==2">{{"masterProperty" | translate}}</small>
                    </div>

                    <div class="solar-container pl-5" *ngIf="solarCfg!=undefined && solarCfg?.enabled!=0 && splCfg?.splMeter!=1">
                        <!-- Potencia solar -->
                        <div class="form-group row">
                            <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                                <label class="col-form-label text-sm-right">{{"fvPower_text" | translate}}:</label>
                                <i class="fas fa-info-circle pl-2" [matTooltip]="'fvPower_text2' | translate"></i>
                            </div>
                            <div class="col-xl-4 col-sm-5 d-flex">
                                <input type="number" class="form-control form-control-group" name="maxFvPower" [(ngModel)]="solarCfg.maxFvPower"
                                    placeholder="W" (change)="checkChangesSolar()">
                                <div class="input_right_data">W</div>
                            </div>
                        </div>

                        <!-- Acometida -->
                        <div class="form-group row">
                            <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                                <label class="col-form-label text-sm-right">{{"solar_type" | translate}}:</label>
                            </div>
                            <div class="col-xl-4 col-sm-5 d-flex">
                                <select class="form-select" name="solarCfgTriphasic"
                                        [(ngModel)]="solarCfgTriphasic"
                                        (change)="changeSolarOption();checkChangesSolar();"
                                    >
                                    <option [ngValue]=false>{{"monophasic" | translate}}</option>
                                    <option [ngValue]=true>{{"triphasic" | translate}}</option>
                                </select>
                            </div>
                        </div>

                        <!-- Vertido -->
                        <div class="form-group row">
                            <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                                <label class="col-form-label text-sm-right">{{"solar_injection" | translate}}:</label>
                            </div>
                            <div class="col-xl-4 col-sm-5 d-flex">
                                <select class="form-select" name="solarInjected"
                                        [(ngModel)]="solarCfg.injected"
                                        (change)="checkChangesSolar();"
                                    >
                                    <option [ngValue]=true>{{"yes" | translate}}</option>
                                    <option [ngValue]=false>{{"no" | translate}}</option>
                                </select>
                            </div>
                        </div>

                        <!-- Medidor adicional -->
                        <div class="form-group row" *ngIf="splCfg?.splMeter!=4">
                            <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                                <label class="col-form-label">{{"meterAux" | translate}}:</label>
                                <i class="fas fa-info-circle pl-2" [matTooltip]="'meterAuxText' | translate"></i>
                            </div>
                            <div class="col-xl-4 col-sm-5">
                                <div class="d-flex">
                                    <select class="form-select form-select-group notRadBot" id="secondaryMeter" name="secondaryMeter" [(ngModel)]="splCfg.splMeterAux" (change)="checkChangesAuxMeter()">
                                        <option *ngFor="let item of secondaryMeters" [ngValue]="item.value">{{item.label | translate}}</option>
                                    </select>
                                    <div class="input_right_data minPad notRadBot" [ngClass]="{'notRadRight' : (splCfg.splMeterAux==3)}">
                                        <i *ngIf="infoModulator.stat.mbusDetected == undefined" class="fas fa-circle text-light fa-fw" style="font-size: 20px;"></i>
                                        <i *ngIf="infoModulator.stat.mbusDetected != undefined && !infoModulator.stat.mbusDetected" class="fas fa-circle text-danger fa-fw" style="font-size: 20px;"></i>
                                        <i *ngIf="infoModulator.stat.mbusDetected" class="fas fa-circle text-success fa-fw" style="font-size: 20px;"></i>
                                    </div>
                                    <div *ngIf="splCfg.splMeterAux==3" class="input_right_data minPad notRadBot" (click)="openSecondaryMeterCfg()">
                                        <i class="fas fa-cog fa-fw" style="font-size: 20px;"></i>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div class="input_left_data form-control-group form-control-group-bottom">
                                        <label class="col-form-label">{{"measureType" | translate}}:</label>
                                    </div>
                                    <select class="form-select notRadLeft form-control-group-bottom" id="secondaryMeterPos" name="secondaryMeterPos" [(ngModel)]="secondaryMeterPos" (change)="changeSolarOption()">
                                        <option value="solar">{{"solar" | translate}}</option>
                                        <option value="home">{{"home" | translate}}</option>
                                        <option value="total">{{"homecar" | translate}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <!-- BattSaving -->
                        <div class="form-group row" *ngIf="solarCfg?.battery && splCfg?.splMeter == 4 && inversorParsed?.model != 2">
                            <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                                <label class="col-form-label text-sm-right">{{"batteryDischarge" | translate}}:</label>
                                <i class="fas fa-info-circle pl-2" [matTooltip]="'batteryDischargeText' | translate"></i>
                            </div>
                            <div class="col-xl-4 col-sm-5 d-flex align-items-center">
                                <div class="col-3" style="padding-left: 0;">
                                    <label class="switch">
                                        <input type="checkbox" [checked]="inversorParsed?.batterySaving!=100" (click)="inversorParsed?.batterySaving!=100?(inversorParsed.batterySaving=100):(inversorParsed.batterySaving=0);checkChangesSolar();" name="solarEnabled">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="inversorParsed?.batterySaving!=100" >
                        <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                            <label class="col-form-label text-sm-right" > {{"limitBatteryDischarge" | translate }}: </label>
                        </div>
                            <div class="col-xl-4 col-sm-5 d-flex"  >
                                <input type="number" class="form-control form-control-group" name="batterySaving" [(ngModel)]="inversorParsed.batterySaving"
                                    placeholder="%" (change)="checkChangesSolar()" min="0" max="95">
                                <div class="input_right_data">%</div>
                            </div>
                            <p style="color: rgb(240, 155, 28);" class="mt-2" *ngIf="inversorParsed.batterySaving<20 && (inversorParsed.model == 3 || inversorParsed.model==11 )"> <i class="fas fa-exclamation-triangle"></i> {{"alertBatteryDischar" | translate}}  </p>
                        </div>
                        <div class="form-group row" *ngIf="inversorParsed?.batterySaving!=100 && showAdvanced && versionInversorExtraUnitIdsCompatible"  >
                        <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                            <label class="col-form-label text-sm-right" > {{"maxDischargePower" | translate}}: </label>
                        </div>
                            <div class="col-xl-4 col-sm-5 d-flex"  >
                                <input type="number" class="form-control form-control-group" id="initialBattPower" name="initialBattPower" [(ngModel)]="inversorParsed.initialBattPower[0]"
                                    placeholder="2000 w" (change)="checkChangesSolar()" min="500" max="10000">
                                <div class="input_right_data">W</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
        <div class="card-footer pt-0">
            <div class="form-group row">
                <div>
                    <button type="submit" class="btn btn-primary" (click)="save()" [disabled]="hasChanges()">{{"overwrite" | translate}}</button>
                    <button type="submit" class="btn btn-light ml-2" (click)="restore()" [disabled]="hasChanges()">{{"restore" | translate}}</button>
                </div>
            </div>
            <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error.solar!=''">
                <div class="alert-message">
                    {{ error.solar }}
                </div>
            </div>
            <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successAllMessage">
                <div class="alert-message">
                    {{"successMessage" | translate}}
                </div>
            </div>
        </div>
    </div>
</div>
