<div class="container-fluid">
    <div class="body card">
        <div class="card-header pb-0">
            <div class="btn btn-pill btn-warning-light-2 float-right text-dark" *ngIf="alerts.length>0" (click)="openAlerts()">
                {{alerts.length}} <i class="fas fa-exclamation-triangle text-warning fa-fw"></i>
            </div>
            <div class="btn btn-pill btn-primary-light float-right text-dark" *ngIf="alerts.length==0">
                0 <i class="fas fa-check text-success fa-fw"></i>
            </div>
            <h5> {{"configuration" | translate }} </h5>
        </div>
        <div class="card-body d-flex justify-content-center align-items-center h_300" *ngIf="loading">
            <div class="spinner-border text-primary mr-2" role="status">
                <span class="sr-only">{{"loading" | translate}}...</span>
            </div>
        </div>
        <div *ngIf="!loading && !error">
            <div class="card-body pb-2">
                <div class="row">
                    <div class="col-xl-6">
                        <div class="row" *ngIf="this.infoModulator.cfg&&this.infoModulator.cfg.selectorPosition == 0">
                            <div class="col-xl-2"></div>
                            <h5 class="text-lightt col-5"> {{ "hiredPower" | translate}}</h5>
                            <h3 class="minw-h col-xl-5 col-7">
                                &nbsp;{{infoModulator?.stat?.limitPower | number: '1.0-2'}} W
                            </h3>
                        </div>
                        <div class="row" *ngIf="this.infoModulator.cfg&&this.infoModulator.cfg.selectorPosition != 0">
                            <div class="col-xl-2"></div>
                            <h5 class="text-lightt col-5">Selector power</h5>
                            <h3 class="minw-h col-xl-5 col-7">
                                &nbsp;{{sysData?.selectorPower | number: '1.0-2'}} W
                            </h3>
                        </div>
                        <div class="row">
                            <div class="col-xl-2"></div>
                            <h5 class="text-lightt col-5">{{"diagnosisSelectorPosition" | translate}}</h5>
                            <h6 class="minw-h col-xl-5 col-7">
                                &nbsp;{{selectorPosition[this.infoModulator?.cfg?.selectorPosition]?.text}}
                            </h6>
                        </div>
                        <div class="row">
                            <div class="col-xl-2"></div>
                            <h5 class="text-lightt col-5">{{"minPowerLabel" | translate}} <small> ({{"byPhase" | translate}})</small></h5>
                            <h3 class="minw-h col-xl-5 col-7">
                                &nbsp;{{dataMennekes[0]?.data?.cfg?.minPower/230/num_phases | number: '1.0-0' }} A
                            </h3>
                        </div>
                        <div class="row">
                            <div class="col-xl-2"></div>
                            <div class="col-5">
                                <h5 class="text-lightt m-0">{{"ampacity" | translate}}</h5>
                                <small class="text-lightt">{{"charger-power" | translate}}</small>
                            </div>

                            <div class="minw-h col-xl-5 col-7">
                                <h3>
                                    &nbsp;{{sysCfg?.ampacity | number: '1.0-0' }} A
                                </h3>
                                <h6 *ngIf="sysCfg?.maxAmpacity">
                                    / {{sysCfg?.maxAmpacity | number: '1.0-0' }}
                                </h6>
                            </div>

                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="row" *ngIf="num_phases==1">
                            <label class="col-4" for="">{{"type" | translate}}:</label>
                            <p class="col-8">{{"monophasic" | translate}}</p>

                        </div>
                        <div class="row" *ngIf="num_phases==3">
                            <label class="col-4" for="">{{"type" | translate}}:</label>
                            <p class="col-8">{{"triphasic" | translate}}</p>
                        </div>


                        <div class="row">
                            <div class="col-4 p-0">
                                <label class="col-12" for="" *ngIf="splData?.cfg?.splOpMode==1">{{"multidevice" | translate}}:</label>
                                <label class="text-bold col-12" for="" *ngIf="splData?.cfg?.splOpMode==2">SPL:</label>
                            </div>
                            <div class="col-8">
                                <button class="btn btn-success" role="button"*ngIf="splData?.cfg?.splMode == 1">{{"master" | translate}}</button>
                                <button class="btn btn-primary" role="button"*ngIf="splData?.cfg?.splMode == 2">{{"slave" | translate}}</button>
                                <button class="btn btn-light" role="button"*ngIf="splData?.cfg?.splMode == 0">{{"disabled" | translate}}</button>
                            </div>
                        </div>
                        <div class="row mt-1" *ngIf="solarInfo?.cfg.enabled">
                            <div class="col-4 p-0">
                                <label class="col-6 text-bold">Solar:</label>
                            </div>
                            <div class="col-8">
                                <button class="btn btn-warning" role="button"*ngIf="(solarInfo?.cfg.type==32||solarInfo?.cfg.type==12) && !solarInfo?.cfg?.inversor?.enabled">{{"solarOption1" | translate}}</button>
                                <button class="btn btn-warning" role="button"*ngIf="(solarInfo?.cfg.type==30||solarInfo?.cfg.type==10) && !solarInfo?.cfg?.inversor?.enabled">{{"solarOption2" | translate}}</button>
                                <button class="btn btn-warning" role="button"*ngIf="(solarInfo?.cfg.type==31||solarInfo?.cfg.type==11) && !solarInfo?.cfg?.inversor?.enabled">{{"solarOption3" | translate}}</button>
                                <button class="btn btn-warning" role="button"*ngIf="solarInfo?.cfg?.inversor?.enabled">{{"inverter" | translate}}</button>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="pb-0 pt-0 col-4">
                                <label>{{"meters" | translate}}:</label>
                            </div>

                            <div class="col-8" *ngIf="!solarInfo?.cfg?.enabled ">
                                <div class="row col-12">
                                    <h5 class="text-lightt col-6 p-0"  *ngIf="splData?.cfg?.splMeter == 0">Contax</h5>
                                    <h5 class="text-lightt col-6 p-0"  *ngIf="splData?.cfg?.splMeter == 1">TMC100</h5>
                                    <h5 class="text-lightt col-6 p-0"  *ngIf="splData?.cfg?.splMeter == 2">Solar Contax</h5>
                                    <h5 class="text-lightt col-6 p-0"  *ngIf="splData?.cfg?.splMeter == 3">Shelly</h5>
                                    <h5 class="text-lightt col-6 p-0"  *ngIf="splData?.cfg?.splMeter == 5">P1</h5>
                                    <h5 class="text-lightt col-6 p-0"  *ngIf="splData?.cfg?.splMeter == 6">P1 Wifi</h5>
                                    <h5 class="text-lightt col-6 p-0"  *ngIf="splData?.cfg?.splMeter == 8">Chain2Gate P1/P2</h5>
                                    <h5 class="text-lightt col-6 p-0"  *ngIf="splData?.cfg?.splMeter == 9">Chain2Gate P4</h5>
                                    <h6 class="col-6 p-0" *ngIf="(this.infoModulator?.stat?.ctxDetected==1 && splData?.cfg?.splMeterAux != null)
                                      || ( splData?.cfg?.splMeterAux == null && splData?.cfg?.splMeter == 1 && this.infoModulator?.stat?.mbusDetected==1)">
                                      <i class="fas fa-check-circle text-success"></i>
                                    </h6>
                                    <h6 class="col-6 p-0" *ngIf="(this.infoModulator?.stat?.ctxDetected==0 && splData?.cfg?.splMeterAux != null)
                                       || ( splData?.cfg?.splMeterAux == null && splData?.cfg?.splMeter == 1 && this.infoModulator?.stat?.mbusDetected==0)">
                                      <i class="fas fa-times-circle text-danger"></i>
                                    </h6>
                                </div>
                            </div>

                            <div class="col-8" *ngIf="solarInfo?.cfg?.enabled && !solarInfo?.cfg?.inversor?.enabled && version">
                              <div class="row col-12">

                                <h5 class="text-lightt col-6 p-0" *ngIf="splData?.cfg?.splMeterAux == 1  || splData?.cfg?.splMeterAux == null">TMC100</h5>
                                <h5 class="text-lightt col-6 p-0" *ngIf="splData?.cfg?.splMeterAux == 3"  >Shelly</h5>
                                <h6 class="col-6 p-0"
                                  *ngIf="this.infoModulator?.stat?.mbusDetected==1 ">
                                  <i class="fas fa-check-circle text-success"></i>
                                </h6>
                                <h6 class="col-6 p-0"
                                  *ngIf="this.infoModulator?.stat?.mbusDetected==0">
                                  <i class="fas fa-times-circle text-danger"></i>
                                </h6>
                              </div>
                              <div class="row col-12">
                                <h5 class="text-lightt col-6 p-0"  *ngIf="splData?.cfg.splMeter == 0">Contax</h5>
                                <h5 class="text-lightt col-6 p-0"  *ngIf="splData?.cfg.splMeter == 1">TMC100</h5>
                                <h5 class="text-lightt col-6 p-0"  *ngIf="splData?.cfg.splMeter == 2">Solar Contax</h5>
                                <h5 class="text-lightt col-6 p-0"  *ngIf="splData?.cfg.splMeter == 3">Shelly</h5>
                                <h5 class="text-lightt col-6 p-0"  *ngIf="splData?.cfg.splMeter == 5">P1</h5>
                                <h5 class="text-lightt col-6 p-0"  *ngIf="splData?.cfg.splMeter == 6">P1 Wifi</h5>
                                <h5 class="text-lightt col-6 p-0"  *ngIf="splData?.cfg.splMeter == 8">Chain2Gate P1/P2</h5>
                                <h5 class="text-lightt col-6 p-0"  *ngIf="splData?.cfg.splMeter == 9">Chain2Gate P4</h5>
                                <h6 class="col-6 p-0" *ngIf="this.infoModulator?.stat?.ctxDetected==1  || (splData?.cfg?.splMeterAux == null && splData?.cfg.splMeter == 1)"  >
                                  <i class="fas fa-check-circle text-success"></i>
                                </h6>
                                <h6 class="col-6 p-0" *ngIf="this.infoModulator?.stat?.ctxDetected==0">
                                  <i class="fas fa-times-circle text-danger"></i>
                                </h6>
                              </div>
                            </div>

                            <!--  Para versiones antiguas por debajo de la 7.2.0 y tengan configuración solar activada sin inversor -->
                            <div class="col-8" *ngIf="solarInfo?.cfg?.enabled && !solarInfo?.cfg?.inversor?.enabled  && !version"  >
                              <div class="row col-12">

                                <div class="row col-12">
                                  <h5 class="text-lightt col-6 p-0">TMC100</h5>
                                  <h6 class="col-6 p-0" *ngIf="this.infoModulator?.stat?.mbusDetected==1">
                                  <i class="fas fa-check-circle text-success"></i>
                                  </h6>
                                  <h6 class="col-6 p-0" *ngIf="this.infoModulator?.stat?.mbusDetected==0">
                                  <i class="fas fa-times-circle text-danger"></i>
                                  </h6>
                                </div>
                                <div class="row col-12">
                                  <h5 class="text-lightt col-6 p-0">Contax</h5>
                                  <h6 class="col-6 p-0" *ngIf="this.infoModulator?.stat?.ctxDetected==1">
                                    <i class="fas fa-check-circle text-success"></i>
                                  </h6>
                                  <h6 class="col-6 p-0" *ngIf="this.infoModulator?.stat?.ctxDetected==0">
                                    <i class="fas fa-times-circle text-danger"></i>
                                  </h6>
                                </div>
                              </div>
                            </div>

                            <div class="col-8" *ngIf="solarInfo?.cfg?.inversor?.enabled && solarInfo?.cfg.enabled">
                                <div class="row col-12">
                                    <h5 class="text-lightt col-6 p-0">{{"inverter" | translate}}</h5>
                                    <h6 class="col-6 p-0" *ngIf="this.infoModulator?.stat?.ctxDetected==1">
                                        <i class="fas fa-check-circle text-success"></i>
                                    </h6>
                                    <h6 class="col-6 p-0" *ngIf="this.infoModulator?.stat?.ctxDetected==0">
                                        <i class="fas fa-times-circle text-danger"></i>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="card-body h_300 d-flex w-100 justify-content-center align-items-center" *ngIf="!loading && error">
            <h3>{{"noHistorics" | translate}}</h3>
        </div>
    </div>

    <div class="card">
        <div class="card-header pb-0 d-flex justify-content-between">
            <h5>{{"state" | translate}}</h5>
            <div class="btn btn-pill btn-primary-light" *ngIf="deviceConnected">
              {{"connectors" | translate}} <i class="fas fa-circle text-success fa-fw"></i>
            </div>
            <!-- <div class="btn btn-pill btn-primary-light" *ngIf="deviceConnected">
                {{new Date(lastReport)}} <i class="fas fa-circle text-success fa-fw"></i>
            </div> -->
            <div class="btn btn-pill btn-warning-light-2" *ngIf="!deviceConnected">
                {{"disconnected" | translate}}
            </div>
        </div>
        <div class="card-body d-flex justify-content-center align-items-center h_300" *ngIf="loading">
            <div class="spinner-border text-primary mr-2" role="status">
                <span class="sr-only">{{"loading" | translate}}...</span>
            </div>
        </div>
        <div class="card-body" *ngIf="!loading && !error">
            <!-- <div class="row justify-content-around">
                <div class="sm-card col-xl-3 d-flex justify-content-between" *ngFor="let connector of connectors">
                    <h4 class="text-primary">{{connector.name | titlecase}}</h4>
                    <span
                        class="badge badge-pill badge-{{allStates[connectorsState[connector.name]?.state]?.icon}} mw-100">
                        {{allStates[connectorsState[connector.name]?.state]?.text}}</span>
                </div>
            </div> -->
            <div class="row">
                <div class="col-xl-6">
                    <div class="d-flex justify-content-center align-items-end position-relative">

                        <h3 class="text-center minw-h" [ngClass]="{'text-danger': infoModulator?.stat?.status==1}">
                            <!-- {{infoModulator?.stat?.totalPower}} W -->
                            {{infoModulator?.stat?.evsePower + infoModulator?.stat?.homePower | number: '1.0-2'}} W
                        </h3>
                        <h5 class="text-lightt">
                            / {{infoModulator?.stat?.maxPower | number: '1.0-2'}} <small>({{"stretch" | translate }})</small>
                        </h5>
                        <p class="position-absolute mb-1" style="left: 0px;" *ngIf="dateDisplay=='short'">
                            {{infoModulator?.stat?.timestamp | date : 'short'}}
                        </p>
                        <p class="position-absolute mb-1" style="left: 0px;" *ngIf="dateDisplay=='seconds'">
                            {{infoModulator?.stat?.timestamp | date: 'mediumTime'}}
                        </p>
                    </div>

                    <div class="chart chart-xs">
                        <canvas id="graphPowers" height="250" *ngIf="mobileView"></canvas>
                        <canvas id="graphPowers" *ngIf="!mobileView"></canvas>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="row">
                        <div class="col-5 d-flex align-items-start">
                            <div class="col-6">
                                <!-- <h5 class="text-lightt">Status</h5> -->
                                <p class="text-warning text-nowrap m-0" *ngIf="infoModulator?.stat?.status==2">{{"onRecovery" | translate}}</p>
                                <p class="text-danger text-nowrap m-0" *ngIf="infoModulator?.stat?.status==1">{{"onOverload" | translate}}</p>
                                <p class="text-primary text-nowrap m-0" *ngIf="infoModulator?.stat?.status==0">{{"onRange" | translate}}</p>
                            </div>
                            <div class="text-center minw-h col-6">
                                <i *ngIf="infoModulator?.stat?.status==2" class="fas fa-circle text-warning fa-fw" style="font-size: 20px !important;"></i>
                                <i *ngIf="infoModulator?.stat?.status==1" class="fas fa-circle text-danger fa-fw" style="font-size: 20px;"></i>
                                <i *ngIf="infoModulator?.stat?.status==0" class="fas fa-circle text-success fa-fw" style="font-size: 20px !important;"></i>
                            </div>
                        </div>
                        <div class="col-7 d-flex align-items-start">
                            <h6 class="col-4 p-0 text-lightt text-center">{{"onOverload" | translate}}</h6>
                            <h5 class="text-center minw-h col-3">
                                {{infoModulator?.stat?.relOverload | number: '1.0-2'}}
                            </h5>
                            <h6 *ngIf="num_phases==3" class="text-center minw-h col-4 text-lightt">
                                [{{toFloat(infoModulator?.stat?.relOverloadByPhase?.[0]) | number: '1.0-2'}} - {{toFloat(infoModulator?.stat?.relOverloadByPhase?.[1]) | number: '1.0-2'}} - {{toFloat(infoModulator?.stat?.relOverloadByPhase?.[2]) | number: '1.0-2'}}]
                            </h6>
                        </div>
                    </div>
                    <div class="row mt-2 mb-1">
                        <div *ngIf="userRole == 'admin'" class="col-10 d-flex align-items-start mr-auto ml-auto bg-light pt-1 border-bottom mb-1">
                            <div class="col-3 d-flex align-items-center h-100"><h5 class="text-primary">{{"heapFree" | translate}}</h5></div>
                            <div class="col-9">
                                <h4 class="text-center minw-h mb-0">
                                    {{infoModulator?.stat?.heapFree}} MB
                                </h4>
                            </div>
                        </div>
                        <div class="col-10 d-flex align-items-start m-auto bg-light pt-1 border-bottom">
                            <!-- <div class="badge badge-primary position-absolute badge-tmc100"
                                *ngIf="this.isTMC100.totCurrent||(this.solarInfo?.cfg.enabled==0&&this.infoModulator?.stat?.mbusDetected==1)">TMC100</div> -->
                                <div class="badge badge-primary position-absolute badge-tmc100"
                                *ngIf="solarInfo?.cfg?.enabled && splData?.cfg?.splMeterAux == 1 &&  solarInfo?.cfg?.type?.toString().includes('0')">TMC100</div>
                                <div class="badge badge-primary position-absolute badge-tmc100"
                                *ngIf="solarInfo?.cfg?.enabled && !version &&  solarInfo?.cfg?.type?.toString().includes('0')">TMC100</div>
                                <div class="badge badge-primary position-absolute badge-tmc100"
                                *ngIf="solarInfo?.cfg?.enabled && splData?.cfg?.splMeterAux == 3 && solarInfo?.cfg?.type?.toString().includes('0')">Shelly</div>





                            <div class="col-3 d-flex align-items-center h-100"><h5 class="text-primary">{{"totalCurrent" | translate}}</h5></div>
                            <div class="col-9">
                                <h4 class="text-center minw-h mb-0"
                                    [ngClass]="{'text-italic': solarInfo?.cfg.enabled&&(solarInfo?.cfg.type==10 || solarInfo?.cfg.type==30)
                                                || (!solarInfo?.cfg.enabled&&this.infoModulator?.stat?.mbusDetected==1)}">
                                    {{infoModulator?.stat?.totalCurrent[0]/1000
                                        + infoModulator?.stat?.totalCurrent[1]/1000
                                        + infoModulator?.stat?.totalCurrent[2]/1000 | number: '1.0-2'
                                    }} A
                                </h4>
                                <p class="text-center text-grey" *ngIf="num_phases==3">{{infoModulator?.stat?.totalCurrent[0]/1000 | number: '1.0-2'}}
                                    - {{infoModulator?.stat?.totalCurrent[1]/1000 | number: '1.0-2'}}
                                    - {{infoModulator?.stat?.totalCurrent[2]/1000 | number: '1.0-2'}} A
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card_state">
                        <div class="d-flex header_section_state align-items-start justify-content-between">

                            <div class="col-12 p-0 d-flex justify-content-between">
                                <div class="row col-6 mr-0 pt-2 p-0 bg-primary-lightt card-diag-cuadre">
                                    <!-- <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="!solarInfo?.cfg?.inversor?.enabled && (isTMC100.inst||(!solarInfo?.cfg.enabled&&splData?.cfg.splOpMode!=2))">TMC100</div>
                                    <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="!solarInfo?.cfg?.inversor?.enabled && (infoModulator?.stat?.ctxDetected==1 || solarInfo?.cfg.enabled==1 || splData?.cfg.splOpMode==2)">Contax</div>
                                    <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="solarInfo?.cfg?.inversor?.enabled">{{"inverter" | translate}}</div> -->
                                    <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="splData?.cfg?.splMeter == 0">{{"Contax" }}</div>
                                    <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="splData?.cfg?.splMeter == 1">{{"TMC100" }}</div>
                                    <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="splData?.cfg?.splMeter == 2">{{"SolarContax" }}</div>
                                    <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="splData?.cfg?.splMeter == 3">{{"Shelly" }}</div>
                                    <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="splData?.cfg?.splMeter == 4">{{"inverter" | translate}}</div>
                                    <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="splData?.cfg?.splMeter == 5">{{"P1"}}</div>
                                    <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="splData?.cfg?.splMeter == 6">{{"P1 Wifi" }}</div>
                                    <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="splData?.cfg?.splMeter == 8">{{"P1/P2" }}</div>
                                    <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="splData?.cfg?.splMeter == 9">{{"P4" }}</div>
                                    <!-- <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="isTMC100.inst">Contax</div> -->
                                    <div class="col-xl-12 col-12 d-flex">
                                        <div class="col-2 p-0 d-flex flex-column align-items-center">
                                            <div class="avatar">
                                                <div class="avatar-title rounded-circle bg-white border-primary border text-primary">
                                                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M8.28,5.45L6.5,4.55L7.76,2H16.23L17.5,4.55L15.72,5.44L15,4H9L8.28,5.45M18.62,8H14.09L13.3,5H10.7L9.91,8H5.38L4.1,10.55L5.89,11.44L6.62,10H17.38L18.1,11.45L19.89,10.56L18.62,8M17.77,22H15.7L15.46,21.1L12,15.9L8.53,21.1L8.3,22H6.23L9.12,11H11.19L10.83,12.35L12,14.1L13.16,12.35L12.81,11H14.88L17.77,22M11.4,15L10.5,13.65L9.32,18.13L11.4,15M14.68,18.12L13.5,13.64L12.6,15L14.68,18.12Z" />
                                                      </svg>
                                                </div>
                                            </div>
                                            <small>{{"network" | translate}}</small>
                                        </div>
                                        <div class="col-10" *ngIf="!solarInfo?.cfg.enabled&&splData?.cfg?.splMode!=2">
                                            <h4 class="text-center mt-1 mb-1 text-nowrap minw-h col-10 p-0">
                                                {{infoModulator?.stat?.totalPower | number: '1.0-2'}} W
                                            </h4>
                                            <p class="text-grey text-center col-10 mb-0" *ngIf="num_phases==3">
                                                {{infoModulator?.stat?.totalPowerByPhase[0] | number: '1.0-0' }} |
                                                {{infoModulator?.stat?.totalPowerByPhase[1] | number: '1.0-0' }} |
                                                {{infoModulator?.stat?.totalPowerByPhase[2] | number: '1.0-0' }}
                                            </p>
                                        </div>
                                        <div class="col-10" *ngIf="solarInfo?.cfg.enabled||splData?.cfg?.splMode==2">
                                            <h4 class="text-center mt-1 mb-1 text-nowrap minw-h col-10 p-0">
                                                {{infoModulator?.stat?.instPower | number: '1.0-2'}} W
                                            </h4>
                                            <p class="text-grey text-center col-10 mb-0" *ngIf="infoModulator?.stat?.instPowerByPhase && num_phases==3">
                                                {{infoModulator?.stat?.instPowerByPhase[0] | number: '1.0-0' }} |
                                                {{infoModulator?.stat?.instPowerByPhase[1] | number: '1.0-0' }} |
                                                {{infoModulator?.stat?.instPowerByPhase[2] | number: '1.0-0' }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-6 mr-0 p-2 pr-2 bg-accent2 card-diag-cuadre">
                                    <div class="badge badge-primary position-absolute badge-tmc100_left" *ngIf="solarInfo?.cfg?.enabled && splData?.cfg?.splMeterAux == 1 && (solarInfo?.cfg?.type.toString().includes('11') || solarInfo?.cfg?.type.toString().includes('31') )">TMC100</div>
                                    <div class="badge badge-primary position-absolute badge-tmc100_left" *ngIf="solarInfo?.cfg?.enabled && !version && (solarInfo?.cfg?.type.toString().includes('11') || solarInfo?.cfg?.type.toString().includes('31') )">TMC100</div>
                                    <div class="badge badge-primary position-absolute badge-tmc100_left" *ngIf="solarInfo?.cfg?.enabled && splData?.cfg?.splMeterAux == 3 && (solarInfo?.cfg?.type.toString().includes('11') || solarInfo?.cfg?.type.toString().includes('31'))">Shelly</div>
                                    <div class="col-10">
                                        <h4 class="text-center mt-1 mb-1 text-nowrap minw-h col-10 p-0">
                                            {{infoModulator?.stat?.homePower | number: '1.0-2'}} W
                                        </h4>
                                    </div>
                                    <div class="col-2 p-0 d-flex flex-column align-items-center">
                                        <div class="avatar">
                                            <div class="avatar-title rounded-circle bg-white border-primary border">
                                                <i class="fas fa-home text-primary align-middle"></i>
                                            </div>
                                        </div>
                                        <small>{{"house" | translate}}</small>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="card_state">
                        <div class="d-flex header_section_state align-items-start justify-content-between">

                            <div class="col-12 p-0 d-flex mt-1 justify-content-between">
                                <div class="col-6" *ngIf="!solarInfo?.cfg.enabled"></div>
                                <div class="row col-6 mr-1 pl-2 p-2 card-diag-cuadre bg-solar" *ngIf="solarInfo?.cfg.enabled">
                                    <!-- <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="isTMC100.solar && !solarInfo?.cfg?.inversor?.enabled">TMC100</div>
                                    <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="solarInfo?.cfg?.inversor?.enabled">{{"inverter" | translate}}</div> -->
                                    <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="solarInfo?.cfg?.enabled && splData?.cfg?.splMeterAux == 4">{{"inverter" | translate}}</div>
                                    <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="solarInfo?.cfg?.enabled && splData?.cfg?.splMeterAux == 1  && solarInfo?.cfg?.type.toString().includes('2')">{{"TMC100" }}</div>
                                    <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="solarInfo?.cfg?.enabled && !version  && solarInfo?.cfg?.type.toString().includes('2')">{{"TMC100" }}</div>
                                    <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="solarInfo?.cfg?.enabled && splData?.cfg?.splMeterAux == 3  && solarInfo?.cfg?.type.toString().includes('2')">{{"Shelly"}}</div>
                                    <div class="col-2 p-0 d-flex flex-column align-items-center">
                                        <div class="avatar">
                                            <div class="avatar-title rounded-circle bg-white border-warning border text-warning">
                                                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                                    <path fill="currentColor" d="M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,2L14.39,5.42C13.65,5.15 12.84,5 12,5C11.16,5 10.35,5.15 9.61,5.42L12,2M3.34,7L7.5,6.65C6.9,7.16 6.36,7.78 5.94,8.5C5.5,9.24 5.25,10 5.11,10.79L3.34,7M3.36,17L5.12,13.23C5.26,14 5.53,14.78 5.95,15.5C6.37,16.24 6.91,16.86 7.5,17.37L3.36,17M20.65,7L18.88,10.79C18.74,10 18.47,9.23 18.05,8.5C17.63,7.78 17.1,7.15 16.5,6.64L20.65,7M20.64,17L16.5,17.36C17.09,16.85 17.62,16.22 18.04,15.5C18.46,14.77 18.73,14 18.87,13.21L20.64,17M12,22L9.59,18.56C10.33,18.83 11.14,19 12,19C12.82,19 13.63,18.83 14.37,18.56L12,22Z" />
                                                </svg>
                                                </div>
                                        </div>
                                        <small>{{"solar" | translate}}</small>
                                    </div>
                                    <div class="col-10">
                                        <h4 class="text-center mt-1 mb-1 text-nowrap minw-h col-10 p-0">
                                            {{infoModulator?.stat?.fvPower | number: '1.0-2'}} W
                                        </h4>
                                        <p class="text-grey text-center col-10 mb-0" *ngIf="infoModulator?.stat?.fvPowerByPhase">
                                            {{infoModulator?.stat?.fvPowerByPhase[0] | number: '1.0-0' }} |
                                            {{infoModulator?.stat?.fvPowerByPhase[1] | number: '1.0-0' }} |
                                            {{infoModulator?.stat?.fvPowerByPhase[2] | number: '1.0-0' }}
                                        </p>
                                    </div>
                                </div>
                                <div class="row col-6 mr-0 p-2 pr-2 bg-accent card-diag-cuadre">
                                    <div class="badge badge-primary position-absolute badge-tmc100_left" *ngIf="this.isTMC100.car">TMC100</div>
                                    <div class="col-10">
                                        <h4 class="text-center mt-1 mb-1 text-nowrap minw-h col-10 p-0">
                                            {{infoModulator?.stat?.evsePower | number: '1.0-2'}} W
                                        </h4>
                                    </div>
                                    <div class="col-2 p-0 d-flex flex-column align-items-center">
                                        <div class="avatar">
                                            <div class="avatar-title rounded-circle bg-white border-primary border">
                                                <svg style="width:24px;height:24px" class="text-primary" viewBox="0 0 24 24">
                                                    <path fill="currentColor" d="M16,6L19,10H21C22.11,10 23,10.89 23,12V15H21A3,3 0 0,1 18,18A3,3 0 0,1 15,15H9A3,3 0 0,1 6,18A3,3 0 0,1 3,15H1V12C1,10.89 1.89,10 3,10L6,6H16M10.5,7.5H6.75L4.86,10H10.5V7.5M12,7.5V10H17.14L15.25,7.5H12M6,13.5A1.5,1.5 0 0,0 4.5,15A1.5,1.5 0 0,0 6,16.5A1.5,1.5 0 0,0 7.5,15A1.5,1.5 0 0,0 6,13.5M18,13.5A1.5,1.5 0 0,0 16.5,15A1.5,1.5 0 0,0 18,16.5A1.5,1.5 0 0,0 19.5,15A1.5,1.5 0 0,0 18,13.5Z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <small>{{"car" | translate}}</small>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="card_state" *ngIf="solarInfo?.cfg?.battery==1  && solarInfo?.cfg?.enabled==1">
                        <div class="d-flex header_section_state align-items-start justify-content-between">
                            <div class="col-12 p-0 d-flex mt-1 justify-content-between">
                                <div class="row col-6 mr-1 pl-2 p-2 card-diag-cuadre bg-battery">
                                    <div class="badge badge-primary position-absolute badge-tmc100" *ngIf="solarInfo?.cfg?.inversor?.enabled">{{"inverter" | translate}}</div>
                                    <div class="d-flex align-items-center" [ngClass]="infoModulator?.stat?.battPercent==null?'col-2':'col-4'">
                                        <div class="p-0 d-flex flex-column align-items-center">
                                            <div class="avatar">
                                                <div class="avatar-title rounded-circle bg-white border" [ngClass]="infoModulator?.stat?.battEnergy>=0 ? 'border-danger text-danger' : 'border-primary text-success'">
                                                    <i class="fas fa-car-battery" style="font-size: 19px;"></i>
                                                    </div>
                                            </div>
                                            <small>{{"battery" | translate}}</small>
                                        </div>
                                        <h4 *ngIf="infoModulator?.stat?.battPercent != null" class="text-center mt-1 mb-1 text-nowrap minw-h col-10 p-0">
                                            {{infoModulator?.stat?.battPercent | number: '1.0-2'}} %
                                        </h4>
                                    </div>
                                    <div [ngClass]="infoModulator?.stat?.battPercent==null?'col-10':'col-8'">
                                        <h5 class="text-grey text-center col-10" *ngIf="infoModulator?.stat?.battEnergy<0">{{"charging_at" | translate}}</h5>
                                        <h5 class="text-grey text-center col-10" *ngIf="infoModulator?.stat?.battEnergy>0">{{"discharging_at" | translate}}</h5>
                                        <h4 class="text-center mt-1 mb-1 text-nowrap minw-h col-10 p-0">
                                            {{infoModulator?.stat?.battEnergy | abs | number: '1.0-2'}} W
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mr-0 p-1 pr-2 card-diag-cuadre">
                        <h5 class="col-3">{{"total" | translate}}</h5>
                        <div class="col-3" *ngIf="!solarInfo?.cfg.enabled">{{infoModulator?.stat?.instPower | number: '1.0-2'}} W</div>
                        <div class="col-3" *ngIf="solarInfo?.cfg.enabled">{{infoModulator?.stat?.instPower+infoModulator?.stat?.fvPower+infoModulator?.stat?.battEnergy | number: '1.0-2'}} W</div>
                        <h5 class="col-1">=</h5>
                        <div class="col-5">{{infoModulator?.stat?.homePower+infoModulator?.stat?.evsePower | number: '1.0-2'}} W</div>
                    </div>

                    <!-- <div class="row mt-2">
                        <div class="col-10 d-flex align-items-start m-auto" *ngIf="!deviceInfo?.firmware_version_name.startsWith('0.1')">
                            <h5 class="col-6 text-primary">TOTAL ENERGY</h5>
                            <div class="col-6">
                                <h3 class="text-center minw-h">
                                    {{infoModulator?.stat?.totalEnergy/1000 | number: '1.0-2'}} kWh
                                </h3>
                                <h6 class="text-center" *ngIf="num_phases==3">
                                    {{infoModulator?.stat?.totalEnergyByPhase[0]/1000 | number: '1.0-0'}} -
                                    {{infoModulator?.stat?.totalEnergyByPhase[1]/1000 | number: '1.0-0'}} -
                                    {{infoModulator?.stat?.totalEnergyByPhase[2]/1000 | number: '1.0-0'}} kWh
                                </h6>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="card-body h_300 d-flex w-100 justify-content-center align-items-center" *ngIf="!loading && error">
            <h3>{{"noHistorics" | translate}}</h3>
        </div>
    </div>

    <div class="card">
        <div class="card-header pb-0 d-flex justify-content-between">
            <h5>{{"connectors" | translate}}</h5>
            <!-- <div class="btn btn-pill btn-primary-light" *ngIf="(NOW - lastReport)/1000 < 10">
                Connected <i class="fas fa-circle text-success fa-fw"></i>
            </div> -->
        </div>
        <div class="card-body d-flex justify-content-center align-items-center h_300" *ngIf="loading">
            <div class="spinner-border text-primary mr-2" role="status">
                <span class="sr-only">{{"loading" | translate}}...</span>
            </div>
        </div>
        <div class="card-body pt-0" *ngIf="!loading && !error">

            <div class="row">
                <div class="col-xl-6" *ngFor="let connector of connectors; index as i">
                    <div class="row align-items-end position-relative d-flex align-items-center mb-1 position-relative
                    btn-{{allStates[connectorsState[connector.name]?.state]?.color}}-light row_header_btn_connector">
                        <div class="position-absolute text-right">
                            <span
                                class="badge badge-pill badge-{{allStates[connectorsState[connector.name]?.state]?.icon}} mw-100 mr-2">
                                {{connectorsState[connector.name]?.state}} - {{allStates[connectorsState[connector.name]?.state]?.text}}</span>
                        </div>
                        <div class="div_text">
                            <h4 class="text-center minw-h m-0" >
                              {{connector.name.startsWith('mennekes') ? ("connector" | translate | uppercase ) + (i+1)  : connector.name | titlecase}}
                              <img src="./../../../../assets/img/orbis/baseT2.svg" alt="baseT2" style="height: 25px; width: 25px;" *ngIf="this.connectorsType[i].data.type =='mennekes_base'" >
                              <img src="./../../../../assets/img/orbis/mangueraT2.svg" alt="mangueraT2" style="height: 25px; width: 25px; " *ngIf="this.connectorsType[i].data.type =='mennekes'" >
                              <img src="./../../../../assets/img/orbis/schuko.svg" alt="schuko" style="height: 25px; width: 25px; " *ngIf="this.connectorsType[i].data.type =='schuko'" >
                            </h4>
                        </div>

                        <div [class.text-changes]="[5].includes(connectorsState[connector.name]?.state)"
                            [class.bg_linear_gradient_text]="![5].includes(connectorsState[connector.name]?.state)">

                        </div>
                    </div>
                    <!-- <hr class="row_header_connector m-1 mb-3"> -->
                    <div class="card_connector_body mt-3">
                        <div class="row">
                            <div class="col-6 d-flex align-items-start">
                                <h5 class="col-6 text-lightt">Active E</h5>
                                <div class="text-center minw-h col-6">
                                    <h5>{{connector?.stat?.active/1000 | number: '1.0-2'}} kWh</h5>
                                </div>
                            </div>
                            <div class="col-6 d-flex align-items-start">
                                <h5 class="col-6 text-lightt">Reactive E</h5>
                                <div class="text-center minw-h col-6">
                                    <h5>{{connector?.stat?.reactive/1000 | number: '1.0-2' }} kvarh</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex position-relative w-100 justify-content-center">
                        <div class="avatar avatar-small icon_charge p-0 bg-white">
                            <div class="avatar-title rounded-circle bg-white border-primary border">
                                <i class="fas fa-bolt text-primary align-middle" style="font-size: 12px;"></i>
                            </div>
                        </div>
                        <hr class="position-absolute hr-primary w-75">
                    </div>
                    <div class="row">
                        <div class="col-6 d-flex flex-column">
                            <small class="text-primary text-bold text-center">{{"idCharge" | translate}}</small>
                            <div class="text-center minw-h">
                                <h4>-</h4>
                            </div>
                        </div>
                        <div class="col-6 d-flex flex-column">
                            <small class="text-primary text-bold text-center">{{"user" | translate}}</small>
                            <div class="text-center minw-h">
                                <h6 *ngIf="connector?.stat?.user && connector?.stat?.user!=''">{{connector?.stat?.user || ''}}</h6>
                                <h6 *ngIf="!connector?.stat?.user || connector?.stat?.user==''">{{connector?.stat?.source || ''}}</h6>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 d-flex align-items-start">
                            <h5 class="col-5 text-lightt">{{"duration" | translate}}</h5>
                            <div class="text-center minw-h col-7 p-0">
                                <h5 *ngIf="[5,6,7,8].includes(connectorsState[connector.name]?.state)">
                                    {{connector?.stat?.durationCharge}}</h5>
                            </div>
                        </div>
                        <div class="col-6 d-flex align-items-start">
                            <h5 class="col-6 text-lightt">{{"started" | translate}}</h5>
                            <div class="text-left minw-h col-6">
                              <small *ngIf="[5, 6, 7, 8].includes(connectorsState[connector.name]?.state)">
                                {{ duration(connector?.stat?.startTimestamp )  | date: 'short'}}
                              </small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 d-flex align-items-start">
                            <h5 class="col-6 text-lightt">A pow</h5>
                            <div class="minw-h col-6" *ngIf="num_phases==3">
                                <h5>{{connector?.stat?.aPow[0] | number: '1.0-0'}} - {{connector?.stat?.aPow[1] |
                                    number: '1.0-0'}} -
                                    {{connector?.stat?.aPow[2] | number: '1.0-0'}} W</h5>
                            </div>
                            <div class="minw-h col-6" *ngIf="num_phases==1">
                                <h5>{{connector?.stat?.aPow[0] | number: '1.0-0'}} W</h5>
                            </div>
                        </div>
                        <div class="col-6 d-flex align-items-start">
                            <h5 class="col-6 text-lightt">R pow</h5>
                            <div class="minw-h col-6" *ngIf="num_phases==3">
                                <h5>{{connector?.stat?.rPow[0] | number: '1.0-0'}} - {{connector?.stat?.rPow[1] |
                                    number: '1.0-0'}} -
                                    {{connector?.stat?.rPow[2] | number: '1.0-0'}} var</h5>
                            </div>
                            <div class="minw-h col-6" *ngIf="num_phases==1">
                                <h5>{{connector?.stat?.rPow[0] | number: '1.0-0'}} var</h5>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 d-flex align-items-start">
                            <h5 class="col-6 text-lightt">{{"current" | translate}}</h5>
                            <div class="minw-h col-6" *ngIf="num_phases==3">
                                <h5>{{connector?.stat?.current[0]/1000 | number: '1.0-2'}} -
                                    {{connector?.stat?.current[1]/1000 | number: '1.0-2'}} -
                                    {{connector?.stat?.current[2]/1000 | number: '1.0-2'}} A</h5>
                            </div>
                            <div class="minw-h col-6" *ngIf="num_phases==1">
                                <h5>{{connector?.stat?.current[0]/1000 | number: '1.0-2'}} A</h5>
                            </div>
                        </div>
                        <div class="col-6 d-flex align-items-start">
                            <h5 class="col-6 text-lightt">PWM</h5>
                            <div class="minw-h col-6">
                                <h5>{{connector?.stat?.assignment/1000 | number: '1.0-2'}} A</h5>
                            </div>
                        </div>
                        <!-- <div class="col-6 d-flex align-items-start">
                                <h5 class="col-6 text-lightt">Home energy</h5>
                                <h3 class="text-center minw-h col-6">
                                    - Wh
                                </h3>
                            </div> -->
                    </div>

                    <div class="row">
                        <div class="col-6 d-flex align-items-start">
                            <h5 class="col-6 text-lightt">Charge E</h5>
                            <div class="minw-h col-6" *ngIf="[5,6,7,8].includes(connectorsState[connector.name]?.state)">
                                <h5>{{connector?.stat?.active-connector?.stat?.startActive}} Wh</h5>
                                <h5>{{connector?.stat?.reactive-connector?.stat?.startReactive}} VArh</h5>
                            </div>
                            <div class="minw-h col-6" *ngIf="![5,6,7,8].includes(connectorsState[connector.name]?.state)">
                                <h5>0 Wh</h5>
                                <h5>0 VArh</h5>
                            </div>
                        </div>
                        <div class="col-6 d-flex align-items-start">
                            <h5 class="col-6 text-lightt">P factor</h5>
                            <div class="minw-h col-6" *ngIf="[5,6,7,8].includes(connectorsState[connector.name]?.state)">
                                <h5>{{connector?.stat?.pfactor[0]}} | {{connector?.stat?.pfactor[1]}} | {{connector?.stat?.pfactor[2]}}</h5>
                            </div>

                        </div>
                    </div>
                    <div class="row" *ngIf="connector.mennekes?.stat && connector.mennekes.stat.tempEvent < 3">
                      <div class="col-6 d-flex align-items-center">
                        <h5 class="col-6 text-lightt mb-0">Temperature</h5>
                        <div class="minw-h col-6">
                          <div class="row">
                            <div class="col-6 d-flex align-items-center">
                              <h5 class="mb-0">{{connector.mennekes.stat.temperature}}ºC</h5>
                            </div>
                            <div class="col-6 px-0 d-flex align-items-center">
                              <i *ngIf="connector.mennekes?.stat?.tempEvent === 1 || connector.mennekes?.stat?.tempEvent === 2" class="fas fa-thermometer-full tempDang" matTooltipPosition="right" matTooltip="{{tempMessages[connector.mennekes?.stat?.tempEvent].vbl | translate}}">
                              </i>
                              <i *ngIf="connector.mennekes?.stat?.tempEvent === 0 || connector.mennekes?.stat?.tempEvent === 3" class="fas fa-info-circle" matTooltipPosition="right" matTooltip="{{tempMessages[connector.mennekes?.stat?.tempEvent].vbl | translate}}">
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 d-flex align-items-start">

                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body h_300 d-flex w-100 justify-content-center align-items-center" *ngIf="!loading && error">
            <h3>{{"noHistorics" | translate}}</h3>
        </div>
    </div>

</div>
