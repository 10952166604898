import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class TestersSearchPipe implements PipeTransform {
  transform(testers: any[], searchText: string): any[] {
    if (!testers) return [];
    if (!searchText) return testers;

    searchText = searchText.toLowerCase();

    return testers.filter( tester => {
      return tester.name.toLowerCase().includes(searchText);
      // puedes agregar más campos de búsqueda aquí si es necesario
    });
  }
}
