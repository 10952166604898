import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceService } from 'src/app/services/devices/device.service';


@Component({
  selector: 'app-modal-add-group',
  templateUrl: './modal-add-group.component.html',
  styleUrls: ['./modal-add-group.component.scss']
})
export class ModalAddGroupComponent {

  @Input() parentGroup : any

  group  = { name : '', description : '', alias : null}


  constructor(private _router: Router,  public activeModal: NgbActiveModal,  private _devices: DeviceService) { }


close(){
  this.activeModal.close();

}

save(){
  console.log(this.group)

  this._devices.createGroup( this.group )
   .subscribe ( (data) => {
    console.log('respuesta create grupo', data)
    this.group.alias = data.group_alias;
    console.log('alias subgroup' ,this.group.alias),


    this._devices.addSubGroups(this.parentGroup , {subgroup: this.group.alias  })
      .subscribe( data => {
        console.log('data add subgrupo ', data)
        this.activeModal.close();
      })
   })


}

}
