import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';



@Component({
  selector: 'app-spl-group',
  templateUrl: './spl-group.component.html',
  styleUrls: ['./spl-group.component.scss']
})
export class SplGroupComponent implements OnInit {

  group : string;
  data : any;
  loading = false;
  master = '';
  slave= true ;
  interface = 1;
  ip = '';
  port : number
  powerLine : number;
  powerR : number;
  powerS : number;
  powerT : number;
  chargeError : number ;
  next = 'master';
  slaves : any[] = []
  selectSlaves : any[] = []
  dataSPL : any[] = []
  modalLoad: any;
  breadCrumbs : any;


  constructor(
    private _router: Router,
    private _modal: ModalService,
    private _devices: DeviceService,
    private route: ActivatedRoute,
    private _translate: TranslateService,
    private _dashboard: DashboardService

  ) {              }


  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.loading= true;
      this.group = params['splGroup'] ;
      this._dashboard.getBreadCrumbs().subscribe(
        data => this.breadCrumbs = data
      )

      this._devices.getGroup(this.group)
        .subscribe( data => {
          this.data = data;
          for (let index = 0; index < data.devices.length; index++) {
            this._devices.getModuleInfo(data.devices[index].serial, 'spl')
            .subscribe( dataSpl => {
              this.dataSPL.push( dataSpl );
              if( dataSpl.cfg.splMode == 1){
                this.master = data.devices[index].serial;
                this.powerLine = dataSpl.cfg.splLimitPower;
                var splLimitPowerByPhaseArray = JSON.parse( dataSpl.cfg.splLimitPowerByPhase);
                this.powerR = splLimitPowerByPhaseArray[0],
                this.powerS = splLimitPowerByPhaseArray[1],
                this.powerT = splLimitPowerByPhaseArray[2],
                this.chargeError = dataSpl.cfg.splSlaveFailPower
              }
            } )
          }
          setTimeout(() => {
            this.loading = false;
            const exists = this.breadCrumbs.some(breadcrumb => breadcrumb.alias === this.data.alias);
            if (!exists) {
              this._dashboard.setBreadCrumbs({name: this.data.name, alias: this.data.alias })
            }
          }, 1000);
        })
    })
  }

  nextSection(){
    this.next === 'master' ? this.next = 'slave' : this.next = 'master'
    this.slaves = this.data.devices
      .filter(device => device.serial !== this.master)
      .map(device => ({
        ...device,
        checked: true
      }));
  }

  change(slave){
    this.slaves.forEach(element => {
      if (element.serial == slave.serial){
        element.checked = slave.checked
      }
    });
    this.slave = this.checkAnyFieldTrue()
  }

  checkAnyFieldTrue(): boolean {
    return this.slaves.some(element => element.checked);
  }

  save(){
    this.modalLoad=this._modal.open(LoadingPopupComponent);

    var bodyMaster = {
      splFactory : 2,
      splMode: 1, // 1 master 2 slave 0 deshabilitado
      splInterface: this.interface == 3 ? 3 : 1,
      splIp : this.ip,
      splPort: this.port,
      splLimitPower: this.powerLine,
      splSlaveFailPower: this.chargeError,
      splLimitPowerByPhase:[this.powerR,this.powerS,this.powerT]
    }

    var bodySlave = { ...bodyMaster, splMode: 2 };

    this._devices.setModuleCfg(this.master, bodyMaster, 'spl' )
    .subscribe( (data)=> {
      this.modalLoad.close();
    }, err => {
      console.log(err);
      this.modalLoad.close();
    });
    this.slaves.forEach(element => {
      this._devices.setModuleCfg(element.serial, bodySlave, 'spl')
      .subscribe ( (data) => {
        console.log('uptade slave spl ', data);
      })
    });
  }

  close(){
    this._router.navigateByUrl('/dashboard');
  }

  viewGroup(group : string){
    this._router.navigate(['/dashboard/viewgroup', group]);
  }

  detailGroup(group){
    this._router.navigate(['/dashboard/newgroup', group]);
  }

  infoGroup(group){
    this._router.navigate(['/dashboard/group', group]);
  }

  updateBreadCrums(alias: string, index: number): void {
    const currentBreadCrumbs = this.breadCrumbs;
    const updatedBreadCrumbs = currentBreadCrumbs.slice(0, index +1);
    this._dashboard.replaceBreadCrumbs(updatedBreadCrumbs )
    if (index === 0) {
      this._dashboard.setModoDashboard('list')
      this._router.navigate(['dashboard']);
    } else {
      this._router.navigate(['dashboard/splconfig', alias]);
    }
  }

  home(){
    this._dashboard.setUpdateSidebar();
  }


}
