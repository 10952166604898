<div class="container-fluid">
  <div class="row">
    <div class="col-xl-6 col-xxl-7">
      <div class="card flex-fill w-100" id="card-state">
        <div class="card-header">
          <div class="card-actions float-right w-50 d-flex card-actions-state">
            <!-- <div class="col-10 d-flex flex-column align-items-end">
              <span *ngIf="deviceConnected">{{"connected" | translate}} <i class="fas fa-circle text-primary fa-fw"></i></span>
              <small>Last report: {{lastReport | date : 'short'}}</small>
            </div> -->
            <!-- <div class="col-2 text-right">
              <i class="fas fa-expand ml-2" (click)="expand('state')"></i>
            </div> -->
          </div>
          <h5 class="card-title mb-0">{{"state" | translate}}</h5>
        </div>
        <div class="card-body mh-300" *ngIf="loading">
          <div class="d-flex justify-content-center align-items-center h-100 mh-300">
            <div class="spinner-grow text-primary" style="width: 4rem; height: 4rem;" role="status">
              <span class="sr-only">{{"loading" | translate}}...</span>
            </div>
          </div>
        </div>
        <div class="card-body px-4 mh-300 d-flex justify-content-center align-items-center"
          *ngIf="!loading && errors.state">
          <h3>{{"noDataAvailable" | translate}}</h3>
        </div>
        <div class="card-body py-3" *ngIf="!loading && !errors.state">
          <div class="d-flex flex-wrap">
            <div class="col-xl-6 col-12">
              <div class="sm-card m-1 d-flex" *ngFor="let connector of connectors">
                <div class="col-5 p-0">
                  <h4 class="text-primary">{{connector.name.startsWith('mennekes') ? ("type" | translate)+'-2' : connector.name | titlecase}}</h4>
                  <span
                    class="badge badge-pill badge-{{allStates[connectorsState[connector.name]?.state]?.icon}} mw-100">
                    {{connectorsState[connector.name]?.state}} - {{getTranslatedText(allStates[connectorsState[connector.name]?.state]?.text)}}</span>
                </div>
                <div class="col-5 p-0 d-flex align-items-center justify-content-end">
                  <div class="d-flex w-80 button-group-container" *ngIf="version7_2_0 && isBoosterActivable(connectorsState[connector.name]?.state) && (maxPower<infoModulator?.stat?.limitPower || connector.booster)">
                    <div class="col-6 text-center button-group d-flex justify-content-center" (click)="setBoost(false, connector.name)"
                      [ngClass]="{'bg-primary text-white' : !connector.booster}" style="padding: 7px 12px; border-radius: 18px 0px 0px 18px; border-right-width: 0px;">
                        <i class="fas fa-clock" style="font-size: 18px;"></i>
                    </div>
                    <div class="col-6 text-center button-group d-flex justify-content-center" (click)="setBoost(true, connector.name)"
                      [ngClass]="{'bg-primary text-white' : connector.booster}" style="padding: 7px 12px; border-radius: 0px 18px 18px 0px; border-left-width: 0px;">
                        <i class="fas fa-bolt" style="font-size: 18px;"></i>
                    </div>
                  </div>
                </div>
                <div class="col-2 p-0 d-flex align-items-center justify-content-end pr-2">
                  <label class="switch-sm mb-0" *ngIf="connectorsState[connector.name]?.permission!=-1 && connectorsState[connector.name]?.permission!=undefined">
                    <input type="checkbox" [(ngModel)]="connectorsState[connector.name].permission" (change)="changeStateConnector(connector.name)">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-12 container-rings-device">
              <div class="chart chart-xs">
                <canvas class="ring-outside" id="chartjs-dashboard-pie"></canvas>
              </div>
              <div class="chart chart-xs">
                <canvas class="ring-inside" id="chartjs-dashboard-pie-inside"></canvas>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-4">
              <h6 class="text-lightt">
                {{"homePower" | translate}}
              </h6>
              <h4 class="text-right">{{infoModulator.stat.homePower}} W</h4>
            </div>
            <div class="col-4">
              <h6 class="text-lightt">
                {{"chargerPower" | translate}}
              </h6>
              <h4 class="text-right">{{infoModulator.stat.evsePower}} W</h4>
            </div>
            <div class="col-4">
              <h6 class="text-lightt float-right">{{infoModulator.stat.limitPower}}</h6>
              <h6 class="text-lightt">
                <!-- Límite de tramo --> {{ "hiredPower" | translate }}
              </h6>
              <h4 class="text-right">{{maxPower}} W </h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-xxl-5 d-flex">
      <div class="w-100">
        <div class="row">
          <!-- Inst. Power -->
          <div class="col-sm-6">
            <div class="card" [class.mb-2]="solarInfo?.cfg?.inversor?.enabled">
              <div class="card-body">
                <div class="row">
                  <div class="col mt-0">
                    <h5 class="card-title"> <!-- Inst. power --> {{ "network" | translate }}</h5>
                  </div>

                  <div class="col-auto">
                    <div class="avatar">
                      <div class="avatar-title rounded-circle bg-primary">
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                          <path fill="currentColor" d="M8.28,5.45L6.5,4.55L7.76,2H16.23L17.5,4.55L15.72,5.44L15,4H9L8.28,5.45M18.62,8H14.09L13.3,5H10.7L9.91,8H5.38L4.1,10.55L5.89,11.44L6.62,10H17.38L18.1,11.45L19.89,10.56L18.62,8M17.77,22H15.7L15.46,21.1L12,15.9L8.53,21.1L8.3,22H6.23L9.12,11H11.19L10.83,12.35L12,14.1L13.16,12.35L12.81,11H14.88L17.77,22M11.4,15L10.5,13.65L9.32,18.13L11.4,15M14.68,18.12L13.5,13.64L12.6,15L14.68,18.12Z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <h1 class="display-5 mt-1">{{solarInfo?.cfg?.enabled?(infoModulator?.stat?.instPower):(infoModulator?.stat?.totalPower)}} W</h1>
              </div>
            </div>
          </div>
          <!-- Home Power -->
          <div class="col-sm-6">
            <div class="card" [class.mb-2]="solarInfo?.cfg?.inversor?.enabled">
              <div class="card-body">
                <div class="row">
                  <div class="col mt-0">
                    <h5 class="card-title">{{"home" | translate}}</h5>
                  </div>

                  <div class="col-auto">
                    <div class="avatar">
                      <div class="avatar-title rounded-circle bg-primary-light">
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                          <path fill="currentColor" d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <h1 class="display-5 mt-1">{{infoModulator?.stat?.homePower}} W</h1>
              </div>
            </div>
          </div>
          <!-- Solar Power -->
          <div class="col-sm-6" *ngIf="solarInfo?.cfg?.enabled==1">
            <div class="card" [class.mb-2]="solarInfo?.cfg?.inversor?.enabled">
              <div class="card-body">
                <div class="row">
                  <div class="col mt-0">
                    <h5 class="card-title">{{"solar" | translate}}</h5>
                  </div>

                  <div class="col-auto">
                    <div class="avatar">
                      <div class="avatar-title rounded-circle bg-yellow text-primary">
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                          <path fill="currentColor" d="M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,2L14.39,5.42C13.65,5.15 12.84,5 12,5C11.16,5 10.35,5.15 9.61,5.42L12,2M3.34,7L7.5,6.65C6.9,7.16 6.36,7.78 5.94,8.5C5.5,9.24 5.25,10 5.11,10.79L3.34,7M3.36,17L5.12,13.23C5.26,14 5.53,14.78 5.95,15.5C6.37,16.24 6.91,16.86 7.5,17.37L3.36,17M20.65,7L18.88,10.79C18.74,10 18.47,9.23 18.05,8.5C17.63,7.78 17.1,7.15 16.5,6.64L20.65,7M20.64,17L16.5,17.36C17.09,16.85 17.62,16.22 18.04,15.5C18.46,14.77 18.73,14 18.87,13.21L20.64,17M12,22L9.59,18.56C10.33,18.83 11.14,19 12,19C12.82,19 13.63,18.83 14.37,18.56L12,22Z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <h1 class="display-5 mt-1">{{infoModulator?.stat?.fvPower}} W</h1>
              </div>
            </div>
          </div>
          <!-- Evse Power -->
          <div class="col-sm-6">
            <div class="card" [class.mb-2]="solarInfo?.cfg?.inversor?.enabled">
              <div class="card-body">
                <div class="row">
                  <div class="col mt-0">
                    <h5 class="card-title">{{"car" | translate}}</h5>
                  </div>

                  <div class="col-auto">
                    <div class="avatar">
                      <div class="avatar-title rounded-circle bg-blue-accent">
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                          <path fill="currentColor" d="M16,6L19,10H21C22.11,10 23,10.89 23,12V15H21A3,3 0 0,1 18,18A3,3 0 0,1 15,15H9A3,3 0 0,1 6,18A3,3 0 0,1 3,15H1V12C1,10.89 1.89,10 3,10L6,6H16M10.5,7.5H6.75L4.86,10H10.5V7.5M12,7.5V10H17.14L15.25,7.5H12M6,13.5A1.5,1.5 0 0,0 4.5,15A1.5,1.5 0 0,0 6,16.5A1.5,1.5 0 0,0 7.5,15A1.5,1.5 0 0,0 6,13.5M18,13.5A1.5,1.5 0 0,0 16.5,15A1.5,1.5 0 0,0 18,16.5A1.5,1.5 0 0,0 19.5,15A1.5,1.5 0 0,0 18,13.5Z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <h1 class="display-5 mt-1">{{infoModulator?.stat?.evsePower}} W</h1>
              </div>
            </div>
          </div>
          <!-- Battery Power -->
          <div class="col-sm-6" *ngIf="solarInfo?.cfg?.battery==1 &&solarInfo?.cfg?.enabled==1">
            <div class="card mb-2">
              <div class="card-body">
                <div class="row">
                  <div class="col mt-0">
                    <h5 class="card-title">{{"battery" | translate}}</h5>
                  </div>

                  <div class="col-auto">
                    <div class="avatar">
                      <div class="avatar-title rounded-circle" [ngClass]="infoModulator?.stat?.battEnergy>=0 ? 'bg-orange text-primary' : 'bg-green'">
                        <i class="fas fa-car-battery" style="font-size: 19px;"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <h5 class="text-lightt mb-0" *ngIf="infoModulator?.stat?.battEnergy<0">{{"charging_at" | translate}}</h5>
                <h5 class="text-lightt mb-0" *ngIf="infoModulator?.stat?.battEnergy>0">{{"discharging_at" | translate}}</h5>
                <h1 class="display-5 mt-1" style="float:left">{{(infoModulator?.stat?.battEnergy) | abs}} W</h1>
                <h1 class="display-5 mt-1" style="float:right; font-weight: 400;" *ngIf="infoModulator?.stat?.battPercent != null">{{infoModulator?.stat?.battPercent}} %</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-6 col-xxl-6">
      <div class="card flex-fill w-100">
        <div class="card-header">
          <div class="card-actions float-right">
            <i class="fas fa-expand ml-2" (click)="expand('analytics')"></i>
          </div>
          <h5 class="card-title mb-0">{{"consumptions" | translate}}</h5>
        </div>

        <div class="card-body mh-300" *ngIf="loadingConsumptions">
          <div class="d-flex justify-content-center align-items-center h-100 mh-300">
            <div class="spinner-grow text-primary" style="width: 4rem; height: 4rem;" role="status">
              <span class="sr-only">{{"loading" | translate }}...</span>
            </div>
          </div>
        </div>

        <div class="card-body py-3 h_300" *ngIf="!loadingConsumptions && !errors.consumptions">
          <div class="chart chart-sm">
            <canvas id="chart" class="w-100 h-100"></canvas>
          </div>
        </div>

        <div class="card-body px-4 mh-300 d-flex justify-content-center align-items-center"
          *ngIf="!loadingConsumptions && errors.consumptions">
          <h3>{{"noHistoricDay" | translate}}</h3>
        </div>

      </div>

      <!-- <div class="card flex-fill w-100">
        <div class="card-header">
          <h5 class="card-title mb-0">{{"heatMap" | translate}} </h5>
        </div>
        <div class="card-body mh-300" *ngIf="loadingHeatmap">
          <div class="d-flex justify-content-center align-items-center h-100">
            <div class="spinner-grow text-primary" style="width: 4rem; height: 4rem;" role="status">
              <span class="sr-only">{{"loading" | translate }}... </span>
            </div>
          </div>
        </div>
        <div class="card-body px-4 h_300" *ngIf="!loadingHeatmap && !errors.heatmap">
          <canvas id="heatmap" class="w-100 h-100"></canvas>
        </div>
        <div class="card-body px-4 mh-300 d-flex justify-content-center align-items-center"
          *ngIf="!loadingHeatmap && errors.heatmap">
          <h3>{{"noHistoricDay" | translate}}</h3>
        </div>
      </div> -->
    </div>

    <div class="col-xl-6 col-xxl-6">
      <div class="card flex-fill w-100">

        <div class="card-header">
          <div class="card-actions float-right">
            <i class="fas fa-expand ml-2" (click)="expand('historic')"></i>
          </div>
          <h5 class="card-title mb-0">{{"historics" | translate}}</h5>
        </div>

        <div class="card-body mh-300" *ngIf="loadingConsumptions">
          <div class="d-flex justify-content-center align-items-center h-100 mh-300">
            <div class="spinner-grow text-primary" style="width: 4rem; height: 4rem;" role="status">
              <span class="sr-only">{{"loading" | translate }}...</span>
            </div>
          </div>
        </div>

        <div class="card-body py-3 h_600">
          <table *ngIf="dataHistoric?.length>0" class="table table-striped my-0">
            <thead>
              <tr>
                <th> {{"date" | translate}} </th>
                <th class="d-none d-xl-table-cell">{{"historicsStartTime" | translate}}</th>
                <th class="d-none d-md-table-cell">{{"historicsDuration" | translate}}</th>
                <th class="d-none d-md-table-cell">{{"historicsEnergy" | translate}}</th>
                <th class="d-none d-md-table-cell">{{"historicsStartSource" | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let historic of (dataHistoric?.length < 16 ? dataHistoric : dataHistoric.slice(0,16))">
                <td>{{historic.start_datetime | date:'dd/MM/yyyy'}}</td>
                <td class="d-none d-xl-table-cell">{{historic.start_datetime | date:'h:mm a'}}</td>
                <td class="d-none d-md-table-cell">{{subtractDates(historic.end_datetime, historic.start_datetime)}}</td>
                <td class="d-none d-md-table-cell">{{historic.end_energy-historic.start_energy | number: '1.0-0'}} Wh</td>
                <div *ngIf="historic.start_source; then thenHistSource else elseHistSource"></div>
                <ng-template #thenHistSource><td class="d-none d-md-table-cell">{{historic.start_source}}<small *ngIf="historic.user!=''"> ({{historic.user}})</small></td></ng-template>
                <ng-template #elseHistSource><td class="d-none d-md-table-cell">{{historic.user}}</td></ng-template>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="card-body px-4 mh-300 d-flex justify-content-center align-items-center"
          *ngIf="!loadingConsumptions && dataHistoric?.length<=0">
          <h3>{{"noHistoricDay" | translate}}</h3>
        </div>

      </div>
    </div>
  </div>
</div>

<button type="button" id="button-modal-activate" class="btn btn-primary d-none" data-toggle="modal"
  data-target="#centeredModalPrimary">
  Primary
</button>

<div class="modal" id="centeredModalPrimary" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog-centered modal-card-devices m-auto" role="document">
    <div class="w-100 h-100">
      <div class="modal-header" style="background: transparent !important; border-bottom: none;">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetModals()">
          <span class="text-white" style="font-size: 35px;" aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-content" [@zoomIn]="zoomIn" *ngIf="showModal">
        <app-card-state [infoModulator]="infoModulator" [connectors]="connectors" [connectorsState]="connectorsState" *ngIf="expandBool.state"></app-card-state>
        <app-card-analytics [dataAnalytics]="dataAnalytics" [connectors]="connectors" *ngIf="expandBool.analytics"></app-card-analytics>
        <app-card-historic [dataHistoric]="dataHistoric" [connectors]="connectors" *ngIf="expandBool.historic"></app-card-historic>
      </div>
    </div>
  </div>
</div>
