import { Injectable } from '@angular/core';
import * as Moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }


  secondsToDhms(date1, date2) {
    let actualDate = new Date(date1).getTime();
    let initDate = new Date(date2).getTime();
    let seconds = (actualDate - initDate) / 1000;
    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor((seconds % (3600 * 24)) / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor(seconds % 60);

    let dDisplay = d > 0 ? d + (d == 1 ? ' d ' : ' d ') : '';
    let hDisplay = h > 0 ? h + (h == 1 ? ' h ' : ' h ') : '';
    let mDisplay = m > 0 ? m + (m == 1 ? ' m ' : ' m ') : '';
    // let sDisplay = s > 0 ? s + (s == 1 ? ' s' : ' s') : '';
    console.log(dDisplay + hDisplay + mDisplay );
    return dDisplay + hDisplay + mDisplay ;
  }

  subtractDates(date1, date2) {
    // if (!date2.toString().includes('Z') && !date2.toString().includes('GMT')) {
    //   date2 = new Date(date2 + " UTC");
    // }
    var difference_ms = new Date(date1).getTime() - new Date(date2).getTime();
    //take out milliseconds
    difference_ms = difference_ms / 1000;
    var seconds = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    var minutes = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    var hours = Math.floor(difference_ms % 24);
    if (hours != 0)
      return hours + 'h ' + minutes.toString() + 'm ' + seconds.toString() + 's';
    else return minutes.toString() + 'm ' + seconds.toString() + 's';
  }

  subtractDates2(date1, date2) {
    if (!date2.toString().includes('Z') && !date2.toString().includes('GMT')) {
      date2 = new Date(date2 + " UTC");
    }
    var difference_ms = new Date(date1).getTime() - new Date(date2).getTime();
    //take out milliseconds
    difference_ms = difference_ms / 1000;
    var seconds = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    var minutes = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    var hours = Math.floor(difference_ms % 24);
    if (hours != 0)
      return hours + 'h ' + minutes.toString() + 'm ' + seconds.toString() + 's';
    else return minutes.toString() + 'm ' + seconds.toString() + 's';
  }

  getDateFromMinutes(minutes){
    let hour = Math.floor(minutes/60);

    hour>23 ?
      hour = hour - 24 : null
    let min = (minutes%60).toString();
    min.length<2 ? min='0'+min : null;

    return (hour)+':'+min;
  }

  update(objToUpdate, updateObj){
    if(objToUpdate!=null&&objToUpdate!=undefined&&updateObj!=null&&updateObj!=undefined){
      objToUpdate=updateObj;
    } else objToUpdate=[-1,-1,-1];
  }

  getFormatedLocalDate = (date = new Date(), format = 'YYYY-MM-DD') => {
      return Moment(date).local().format(format)
  }

  getFormatedUTCDate = (date = new Date(), format = 'YYYY-MM-DDTHH') => {
    return Moment(date).utc().format(format)
  }

}
