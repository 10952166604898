<div class="ml-4 mr-4">
    <h3>{{"subscriptions" | translate}}</h3>
    
    <div class="d-flex flex-sm-wrap justify-content-center" *ngIf="checkWarrantyDate() && !checkWarrantyActive()">
        <!-- Seccion de extensión de garantía-->
        <div class="card text-center">
            <div class="card-header bg-primary text-white font-weight-bolder">
                {{'Extensión de Garantía' | translate}}
            </div>
            <div class="card-body p-5">
                <h4>{{'Su garantía vence el ' | translate}}{{dateEndWarranty | date:'dd/MM/yyyy'}}</h4>
                <p>{{'Contrata el plan de Extensión de Garantía por tan sólo 39,00 € al año para ampliar la garantía de tu dispositivo' | translate}}</p>
                <button class="btn btn-primary" (click)="subscribeWarranty()">{{"subscribe" | translate}}</button> &nbsp;
            </div>
        </div>
    </div>
    <div class="d-flex flex-sm-wrap">
        <div class="card-body p-0">
            <mat-table [dataSource]="dataSource">
                <!-- Columna de Name -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>{{"module" | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let module">{{ module.name | translate }}</mat-cell>
                </ng-container>

                <!-- Estado -->
                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef>{{"expiryDate" | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let module">{{ module.dateEnd }}</mat-cell>
                </ng-container>

                <!-- Columna de Acciones -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let module">
                    <!--Unlink-->
                    <button *ngIf="module.active" class="btn btn-danger" [disabled]="module.link==''" (click)="unsubscribe(module)">{{"cancel" | translate}}</button> &nbsp;
                    <!--Edit-->
                    <button *ngIf="!module.active" class="btn btn-primary" (click)="subscribe(module)">{{"subscribe" | translate}}</button> &nbsp;
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="['name', 'date', 'actions']"></mat-header-row>
                <mat-row *matRowDef="let row; columns: ['name', 'date', 'actions']"></mat-row>
            </mat-table>
        </div>
    </div>
</div>  