<div [ngClass]="{'col-12 col-xl-12' : !allSettings}">

  <div class="card ">

    <div class="card-header row align-items-center btn-primary-light mx-0 py-0 p-4">
      <div class="col-xl-3">
        <mat-form-field *ngIf="deviceOwner" subscriptSizing="dynamic">
          <mat-label>{{"filter_by" | translate}}</mat-label>
          <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>
      </div>

      <div class="col-xl-6 text-center">
        <mat-card-title>{{"usersLinked" | translate}}: {{device}}</mat-card-title>
      </div>

      <div class="col-xl-3 my-auto text-end pr-3">
        <button *ngIf="deviceOwner" class="collapsed btn btn-outline-primary btn-primary-light p-3 my-1" type="button"
          data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          <i class="fas fa-plus fa-lg"></i>
        </button>
      </div>
    </div>

    <!--LINK USER-->
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo">
      <div class="accordion-body">
        <div class="row ">
          <!--LINK USER BODY-->
          <div class="col-12 col-xl-12">
            <form class="w-100">
              <div class="row justify-content-between mt-5">
                <!--Email-->
                <div class="col-xl-4">
                  <div class="row justify-content-start">
                    <div class="col-xl-3 text-right ">
                      <label class="form-label mt-1">{{"userToLink" | translate}}:</label>
                    </div>
                    <div class="col-xl-7">
                      <input type="email" class="form-control" placeholder="" [(ngModel)]="user" name="user">
                    </div>
                  </div>
                </div>
                <!--User Role-->
                <div class="col-xl-4">
                  <div class="row justify-content-start">
                    <div class="col-xl-3 text-right ">
                      <label class="form-label mt-1">{{"userRole" | translate}}:</label>
                    </div>
                    <div class="col-xl-7">
                      <select class="form-select form-select-group"  [(ngModel)]="roles.values"  name="userRole">
                        <option *ngFor="let role of roles" [ngValue]="role.value">{{role.viewValue | translate}}</option>
                    </select>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3">
                  <button type="submit" class="btn btn-primary" [disabled]="!user" (click)="save()">
                    <i style="font-size: 20px;" class="fas fa-link"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!--ALERTS-->
        <div class="row align-items-center justify-content-center mt-3">
          <div class="col-xl-4">
            <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="response.error && error404">
              <div class="alert-message">
                {{ "error404" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center mt-3">
          <div class="col-xl-4">
            <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="response.error && error500">
              <div class="alert-message">
                {{ "error500" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center mt-3">
          <div class="col-xl-4">
            <div class="alert alert-success alert-dismissible" role="alert" *ngIf="response.success">
              <div class="alert-message">
                {{"settings.successMessage" | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!--MODAL ASSIGN-->
    <button type="button" id="toggle-name-modal" class="btn btn-primary d-none" data-toggle="modal" data-target="#centeredModalPrimary">
      Primary
    </button>
    <div class="modal fade" id="centeredModalPrimary" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{"confirmAssgnmt" | translate}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body m-3 text-center">
            <p class="mb-0">¿{{"assignDev" | translate}} {{body.serial}} {{"toUser" | translate}} {{user}}?</p>
            <p>{{"youWantToContinue" | translate}}</p>
          </div>
          <div class="modal-footer">
            <button type="button" id="dismiss-confirm-modal" class="btn btn-secondary" data-dismiss="modal">{{"cancel" |
              translate}}</button>
            <button type="button" class="btn btn-primary" (click)="assign(user)">{{"vincButton" | translate}}</button>
          </div>
        </div>
      </div>
    </div>

      <!--MODAL UNLINK-->
      <button type="button" id="unlink-modal" class="btn btn-primary d-none" data-toggle="modal" data-target="#centeredModal">
        Primary
      </button>
      <div class="modal fade" id="centeredModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{"unlinkDevice" | translate}}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body m-3 text-center">
              <p class="mb-0">{{"sureUnlink" | translate}}</p>
              <p>{{"youWantToContinue" | translate}}</p>
            </div>
            <div class="modal-footer">
              <button type="button" id="dismiss-unlink-modal" class="btn btn-secondary" data-dismiss="modal">{{"cancel" |
                translate}}</button>
              <button type="button" class="btn btn-primary" (click)="unlinkUser()">{{"unlinkBtn" | translate}}</button>
            </div>
          </div>
        </div>
      </div>
      <!--Manage User Permissions-->
      <button type="button" id="transfer-modal" class="btn btn-primary d-none" data-toggle="modal" data-target="#centeredModalTransfer">
        Primary
      </button>
      <div class="modal fade" id="centeredModalTransfer" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{"userPermissions" | translate}}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form class="w-100 mb-5">
              <div class="row justify-content-center mt-5">
                <div class="col-xl-2 text-right ">
                  <label class="form-label mt-1">{{"userRole" | translate}}:</label>
                </div>
                <div class="col-xl-8">
                  <!--<input type="email" class="form-control" placeholder="" [(ngModel)]="user" name="user">-->
                  <select class="form-select form-select-group" [(ngModel)]="roles.values"  name="userRole">
                    <option *ngFor="let role of roles" [ngValue]="role.value">{{role.viewValue | translate}}</option>
                </select>
                </div>
                </div>
            </form>
            <div class="modal-footer">
              <button type="button" id="dismiss-transfer-modal" class="btn btn-secondary" data-dismiss="modal">{{"cancel" |
                translate}}</button>
              <button type="button" class="btn btn-primary" (click)="editRoles()">{{"accept" | translate}}</button>
            </div>
          </div>
        </div>
      </div>

    <div class="card-body p-0" *ngIf="deviceOwner">
      <mat-table [dataSource]="dataSource">

        <!-- Columna de User -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>{{"user" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ user.user_name + ' ' + user.user_last_name}}</mat-cell>
        </ng-container>

        <!-- Columna de Email -->
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef>{{"email" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ user.user_email }}</mat-cell>
        </ng-container>

        <!-- Columna de Role -->
        <ng-container matColumnDef="role">
          <mat-header-cell *matHeaderCellDef>{{"role" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let user">
            <mat-cell *ngIf="user.user_device_role == 'owner'">{{"owner" | translate}}</mat-cell>
            <mat-cell *ngIf="user.user_device_role != 'owner'">{{user.user_device_role | translate | capitalizeFirst}}</mat-cell>
          </mat-cell>
        </ng-container>

        <!-- Columna de Vinculacion -->
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef>{{"vincDate" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ user.user_device_date_create | date:'dd-MM-yyyy' }}</mat-cell>
        </ng-container>

        <!-- Columna de Acciones -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>{{"actions" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let user">
            <!--Unlink-->
            <button *ngIf="user.user_device_role != 'owner'" class="btn btn-danger" (click)="setUnlink(user)" [matTooltip]="'unlinkBtn' | translate"><i class="fas fa-unlink"></i></button> &nbsp;
            <!--Edit-->
            <button *ngIf="this.deviceOwner" class="btn btn-success" (click)="setEdit(user)" [matTooltip]="'edit' | translate"><i class="far fa-edit"></i></button> &nbsp;
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="['name', 'email', 'role', 'date', 'actions']"></mat-header-row>
        <mat-row *matRowDef="let row; columns: ['name', 'email', 'role', 'date', 'actions']"></mat-row>


      </mat-table>
      <mat-paginator [pageSizeOptions]="[10, 50, 100, 300]" showFirstLastButtons></mat-paginator>

    </div>
    <div class="card-body p-4 text-center" *ngIf="!deviceOwner">
      <h6 class="card-subtitle text-muted">{{"needOwner" | translate}}</h6>
    </div>

  </div>
</div>
