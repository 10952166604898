import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { UtilsService } from '../utils/utils.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  public isFirstLoad=true;

  constructor(public _http: HttpClient, private _auth: AuthService, private _router: Router, private _utils: UtilsService) {
    this.isFirstLoad=false;
    if(this._auth.userToken){
      this.getDevicesByUser();
    }
  }

  private url = environment.baseUrl;
  public user;
  public token;
  public headers;
  public userDevice;
  public activeDeviceInfo;
  private activeSerial: BehaviorSubject<string> = new BehaviorSubject<string>('');
  serial$ = this.activeSerial.asObservable();

  public userDevices;
  private activeDeviceInfoSubject = new BehaviorSubject<any>(null); 
  public activeDeviceInfo$ = this.activeDeviceInfoSubject.asObservable();

  getHeaders = () => {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this._auth.userToken
    });
  }
  setSerial(serial:string){
    this.activeSerial.next(serial);
  }

  getFirstLoad(){
    return this.isFirstLoad;
  }
  setCred(data) {
    this.token = data.token;
    this.user = data.email;
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': data.token
    })
  }
  setActiveDeviceInfo(device_info){
    this.activeDeviceInfo = device_info;
    if(this.activeDeviceInfo!=null)
      this.setSerial(device_info.serial);
    this.activeDeviceInfoSubject.next(device_info);
  }
  getActiveDeviceInfo(){
    return this.activeDeviceInfo;
  }
  setUserDevice(user) {
    this.userDevice = user;
  }
  getUserDevices() {
    return this.userDevices;
  }
  getUserActive() {
    let u = this.user;
    if (this.userDevice!=null)
      u = this.userDevice

    return u;
  }

  getDevicesByUser() {
    return this._http.get<any>(this.url + '/user/device', { headers: this.getHeaders() });
  }
  getDeviceInfo(device) {
    return this._http.get<any>(this.url + '/user/device/' + device, { headers: this.getHeaders() });
  }
  getModuleInfo(device, module, command?, params?) {
    let aux = '';
    params ? Object.entries(params).forEach(entry => {
      aux += `&${entry[0]}=${entry[1]}`
    }) : null;
    return this._http.get<any>(this.url + '/user/device/' + device + '/module/' + module + (command ? '?level=' + command : "") + aux, { headers: this.getHeaders() });
  }

  getElementModule(device, module, elemName?, command?, params?) {
    let aux = command?'&':'?';
    params ? Object.entries(params).forEach(entry => {
      aux += `${entry[0]}=${entry[1]}`
    }) : null;
    return this._http.get<any>(this.url + "/user/device/" + device + '/module/'
      + module + '/element' + (elemName ? "/" + elemName : "") + (command ? '?level=' + command : "") + aux, { headers: this.getHeaders() });
  }

  getModuleanalyzers(device ) {

    return this._http.get<any>(this.url + '/user/device/' + device + '/module/energy?level=analyzers&localdata=true' , { headers:  this.getHeaders() });
  }
  getElemDevice(device) {
    return this._http.get<any>(this.url + '/user/device/' + device + '/element', { headers: this.getHeaders() });
  }
  getModulesByDevice(device, params?) {
    return this._http.get<any>(this.url + '/user/device/' + device + '/module' +(params?"?"+params:""), { headers: this.getHeaders() });
  }
  getLinkedUsersToCharger(device){
    return this._http.get<any>(this.url + '/user/device/' + device + '/linkedUsers', {headers: this.getHeaders()});
  }

  getChargerOwner(device){
    return this._http.get<any>(this.url + '/user/device/' + device, {headers: this.getHeaders()});
  }


  // START RFID CRUD functions ---------------------------------------------------------------------------------------------------------------------------------
  getRfidTagList(serial: string){
    return this._http.get<any>(this.url + '/user/device/' + serial + '/module/rfid/tagList', {headers: this.getHeaders()});
  }
  deleteRfidCard(serial: string, idTag: string ){
    return this._http.delete<any>(this.url + '/user/device/' + serial + '/module/rfid/tagList/' + idTag, {headers: this.getHeaders()})
  }
  updateRfidCard(serial: string, idTag: string, body: any){
    return this._http.put<any>(this.url + '/user/device/' + serial + '/module/rfid/tagList/' + idTag, JSON.stringify(body),{headers: this.getHeaders()})
  }
  addRfidCard(serial: string, idTag: string, body: any){
    return this._http.post<any>(this.url + '/user/device/' + serial + '/module/rfid/tagList/' + idTag, JSON.stringify(body),{headers: this.getHeaders()})
  }
  modifyRfidListCards(serial: string, body: any){
    return this._http.put<any>(this.url + '/user/device/' + serial + '/module/rfid/tagList',  JSON.stringify(body), {headers: this.getHeaders()})
  }
  deleteRfidListCard(serial: string){
    return this._http.delete<any>(this.url + '/user/device/' + serial + '/module/rfid/tagList', {headers: this.getHeaders()})
  }


  /*
  updateRfidTagList(device, module, body){
    return this._http.put<any>(this.url + '/user/device/' + device + '/module/' + module + '/tagList', body, {headers: this.getHeaders()});
  }
  deleteRfidTagList(device, module, body, element?, command?){
    // // this.url + '/user/device/' + device + '/module/modulator?' + params.toString(), { headers: this.getHeaders() }
    this._http.delete<any>(this.url + '/user/device/' + device + '/module/' + module + '/tagList', {headers: this.getHeaders()})
        .subscribe({
            next: data => {
                let status = 'RFID TagList with idTaglist: {idTaglist} was succesfully deleted';
            },
            error: error => {
                let errorMessage = error.message;
                console.error('There was an error!', error);
            }
        });
  }
  addIdTag(device, module, body, element?, command?){
    // // this.url + '/user/device/' + device + '/module/modulator?' + params.toString(), { headers: this.getHeaders() }
    this._http.post<any>(this.url + '/user/device/' + device + '/module/' + module + '/tagList', {headers: this.getHeaders()})
       //{ title: 'Add new RFID Card' })
       .subscribe({
        next: data => {
            let postId = data.id;
        },
        error: error => {
            let errorMessage = error.message;
            console.error('There was an error!', error);
        }
    })
  }
  updateIdTag(device, module){
    let putId: any;

    const body = { title: 'RFID Card idTag' };

    this._http.put<any>(this.url + '/user/device/' + device + '/module/' + module + '/tagList', {headers: this.getHeaders()})
      .subscribe(data => putId = data.id);
  }
  deleteIdTag(device, module, body, element?, command?){
    this._http.delete<any>(this.url + '/user/device/' + device + '/module/' + module + '/tagList', {headers: this.getHeaders()})
        .subscribe({
            next: data => {
                let status = 'RFID Card with idTagCard: {idTagCard} was succesfully deleted!';
            },
            error: error => {
                let errorMessage = error.message;
                console.error('There was an error!', error);
            }
        });
  }*/

  // Importar y exportar listado (fichero)
  importRFIDcardList(device, module){}
  exportRFIDcardList(device, module){}

  // END RFID CRUD functions ---------------------------------------------------------------------------------------------------------------------------------

  // TODO: Debe ser el chart de consumos, refactor por getConsumptions y buscar el getHistorics(tabla)
  getHistoricGraph(device, dateMax, dateMin?) {
    let params = new HttpParams().set('level', 'data').set('filter', 'day').set('dateMax', dateMax);
    if (dateMin)
      params = params.append('dateMin', dateMin);
    return this._http.get<any>(this.url + '/user/device/' + device + '/module/modulator?' + params.toString(), { headers: this.getHeaders() });
  }


  // TODO: Lo mismo pero con la view
  getHistoricGraphView(device, dateMax, dateMin?) {
    let params = new HttpParams().set('level', 'data').set('filter', 'day').set('dateMax', dateMax);
    let filter = "day";

    if(dateMax){
      dateMax = this._utils.getFormatedUTCDate(dateMax);
    }

    if (dateMin){
      dateMin = this._utils.getFormatedUTCDate(dateMin);
      params = params.append('dateMin', dateMin);
    }

    // let _url = this.url + '/user/device/' + device + '/view/consumption?' + (filter?'&filter='+filter:"") +
    let _url = this.url + '/user/device/' + device + '/view/consumption?' + (filter?'&filter='+filter:"") +
    (dateMin?'&dateMin='+dateMin:"") + (dateMax?'&dateMax='+dateMax:"");
      return this._http.get<any>(_url, { headers: this.getHeaders()});
  }

  getHeatmap(device, group?) {
    let params = new HttpParams().set('level', 'data').set('filter', 'heatmap');
    if (group)
      params = params.append('group', group);
    return this._http.get<any>(this.url + '/user/device/' + device + '/module/modulator?' + params.toString(), { headers: this.getHeaders() });
  }

  getHistorics(device, dateMax?, dateMin?) {
    let params = new HttpParams().set('level', 'historic');
    if (dateMax)
      params = params.append('dateMax', dateMax.getFullYear() + '-' + (dateMax.getMonth() + 1) + '-' + dateMax.getDate());
    else
      params = params.append('dateMax', new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate());
    if (dateMin)
      params = params.append('dateMin', dateMin.getFullYear() + '-' + (dateMin.getMonth() + 1) + '-' + dateMin.getDate());
    return this._http.get<any>(this.url + '/user/device/' + device + '/module/modulator?' + params.toString(), { headers: this.getHeaders() });
  }

  getLatestVersion(type, subtype) {
    return this._http.get<any>(this.url + '/user/lastVersion?type=' + type + '&subtype=' + subtype, { headers: this.getHeaders() });
  }

  getLatestVersionDevice(device) {
    return this._http.get<any>(this.url + '/user/device/' + device + '/lastVersion', { headers: this.getHeaders() });
  }

  // PUTTERS
  setModuleCfg(device, body, module, command?) {
    return this._http.put<any>(this.url + '/user/device/' + device + '/module/' + module + (command ? '?level=' + command : ""), JSON.stringify(body), { headers: this.getHeaders() });
  }

  setModuleElement(device, module, body, element?, command?){
    return this._http.put<any>(this.url + "/user/device/" + device + '/module/'
      + module + '/element' + (element ? "/" + element : "") + (command ? '?level=' + command : ""), JSON.stringify(body), { headers: this.getHeaders() });
  }

  setModuleCmd(device, body, module, command?) {
    return this._http.put<any>(this.url + '/user/device/' + device + '/module/' + module + (command ? '?command=' + command : ""), JSON.stringify(body), { headers: this.getHeaders() });
  }

  setModuleElemData(device, module, body, elemName?, command?) {
    return this._http.put<any>(this.url + "/user/device/" + device + '/module/'
      + module + '/element' + (elemName ? "/" + elemName : "") + (command ? '?level=' + command : ""), JSON.stringify(body), { headers: this.getHeaders() });
  }

  setRT(device, body) {
    return this._http.put<any>(this.url + "/user/device/" + device + '/module/modulator'
      + '?command=rt', JSON.stringify(body), { headers: this.getHeaders() });
  }

  updateFw(device, url) {
    return this._http.put<any>(this.url + '/user/device/' + device + '/fwupdate', JSON.stringify({ url }), { headers: this.getHeaders() });
  }

  setDeviceInfo(device, body) {
    return this._http.put<any>(this.url + '/user/' + this.user + '/device/' + device,  JSON.stringify(body), { headers: this.getHeaders() });
  }

  // POSTERS
  assignDevices(device, user, newRole) {
    return this._http.post<any>(this.url + '/user/device/' + device + '/addUser?new_user=' + user + '&new_role=' + newRole, null, { headers: this.getHeaders() });
  }

  updateModuleElement(device, module, body, element?, command?){
    return this._http.post<any>(this.url + "/user/device/" + device + '/module/'
      + module + '/element' + (element ? "/" + element : "") + (command ? '?level=' + command : ""), JSON.stringify(body), { headers: this.getHeaders() });
  }

  reset(device, type) {
    return this._http.post<any>(this.url + '/user/device/' + device + '/reset', JSON.stringify({type:type}), { headers: this.getHeaders() });
  }


  // DELETES
  deleteModule(device, module, body, command?){
    let options = {
      headers: this.getHeaders(),
      body
    }
    return this._http.delete<any>(this.url + "/user/device/" + device + '/module/'
      + module + (command ? '?level=' + command : ""), options);
  }

  deleteModuleElement(device, module, body, element?, command?){
    let options = {
      headers: this.getHeaders(),
      body
    }
    return this._http.delete<any>(this.url + "/user/device/" + device + '/module/'
      + module + '/element' + (element ? "/" + element : "") + (command ? '?level=' + command : ""), options);
  }
  resetHistoricsByDevice(device) {
    let _url = this.url + '/user/device/' + device + '/deleteAll';
    let _headers = new HttpHeaders({
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Authorization': this.headers.get('Authorization')
    })
    return this._http.delete<any>(_url, { headers: _headers, responseType: 'blob' as 'json', observe: 'response' });
  }
  checkChangesSettings(originalBody, actualBody) {
    if (JSON.stringify(originalBody) == JSON.stringify(actualBody)) {
      return false;
    } else {
      return true;
    }
  }
  unlinkUserFromCharger(userToDelete, device){
    return this._http.delete<any>(this.url + '/user/device/'+device+'/linkedUsers/' + userToDelete, {headers: this.getHeaders()})
  }

  // getModuleInfo(device, module) {
  //   return this._http.get<any>(this.url + '/user/' + 'device/' + device + '/module/' + module, { headers: this.getHeaders() });
  // }

  // se puede sustituir con getModuleElemData
  // getElementInfo(device, module, element, command?) {
  //   let commandArg = command ? '?level=' +  command : '';
  //   return this._http.get<any>(this.url + '/user/' + 'device/' + device + '/module/' + module + '/element/' + element + commandArg, { headers: this.getHeaders() });
  // }


  //quitar con setmoduleElemData
  // setElementModule(device, module, element, body) {
  //   return this._http.put<any>(this.url + '/user/' + 'device/' + device + '/module/' + module + '/element/' + element, JSON.stringify(body), { headers: this.getHeaders() });
  // }


  ///// GROUPS //////


   /// GETS

  getGroups( command? ){
    return this._http.get<any>(this.url + '/user/group' + (command ? '?onlyParents=true' : '') , { headers: this.getHeaders() })
  }

  getGroup( groupAlias ){
    return this._http.get<any>(this.url + '/user/group/' + groupAlias , { headers: this.getHeaders() })
  }

  getLinkedUsers(groupAlias){
    return this._http.get<any>(this.url + '/user/group/' + groupAlias + '/linkedUsers' , { headers: this.getHeaders() })
  }

  getLinkedDevices(groupAlias){
    return this._http.get<any>(this.url + '/user/group/' + groupAlias + '/linkedDevices' , { headers: this.getHeaders() })
  }


  getSubgroups(groupAlias){
    return this._http.get<any>(this.url + '/user/group/' + groupAlias + '/linkedSubgroups' , { headers: this.getHeaders() })
  }


  /// POST

  addSubGroups( groupAlias, body){
    return this._http.post<any>(this.url + '/user/group/' + groupAlias + '/addSubgroup' , JSON.stringify(body) ,   { headers: this.getHeaders() } )
  }

  createGroup(body){
    return this._http.post<any>(this.url + '/user/group', JSON.stringify(body),  { headers: this.getHeaders() } )
  }

  addDevices(groupAlias, body){
    return this._http.post<any>(this.url + '/user/group/' + groupAlias + '/addDevices' , JSON.stringify(body),  { headers: this.getHeaders() } )
  }

  addUser(groupAlias, body){
    return this._http.post<any>(this.url + '/user/group/' + groupAlias + '/addUser' , JSON.stringify(body),  { headers: this.getHeaders() } )
  }


  /// PUT

  updateGroup(groupAlias, body){
    return this._http.put<any>(this.url + '/user/group/' + groupAlias  , JSON.stringify(body),  { headers: this.getHeaders() } )
  }


/// DELETE

  deleteGroup(groupAlias){
    return this._http.delete<any>(this.url + '/user/group/' + groupAlias ,   { headers: this.getHeaders() } )
  }

  removeUser(groupAlias, userToRemove){
    return this._http.delete<any>(this.url + '/user/group/' + groupAlias + '/linkedUsers/' + userToRemove ,   { headers: this.getHeaders() } )
  }

  removeDevice (groupAlias, deviceToRemove){
    return this._http.delete<any>(this.url + '/user/group/' + groupAlias + '/linkedDevices/' + deviceToRemove ,   { headers: this.getHeaders() } )
  }

  removeSubgroup (groupAlias, subgroupToRemove){
    return this._http.delete<any>(this.url + '/user/group/' + groupAlias + '/linkedSubgroups/' + subgroupToRemove ,   { headers: this.getHeaders() } )
  }
}
