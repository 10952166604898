<div class="modal-header">
    <div class="d-flex w-100">

        <h2 class="text-primary modal-title" (click)="close()">
          <i class="fas fa-times"></i>
        </h2>
        <h2 class="modal-title text-primary text-center w-100">{{title}}</h2>
    </div>

</div>
<div class="modal-body m-3">
    <p class="mb-0">{{description}}</p>
</div>
<div class="modal-footer">
  <button type="button" [class]="customOkButtonClass"  data-dismiss="modal" (click)="close()">Ok</button>
  <button *ngIf="customFunction" [class]="customButtonClass" (click)="executeCustomFunction()">{{ customButtonText }}</button>
</div>

