import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterGroups',
  pure: true // Importante para mejorar rendimiento y evitar reejecuciones innecesarias
})
export class FilterGroupsPipe implements PipeTransform {

  transform(nodes: any[], filterText: string): any[] {
    if (!nodes) return [];

    // ✅ Si el filtro está vacío, devolvemos el mismo array original sin modificarlo
    if (!filterText || filterText.trim() === '') return nodes;

    const filterTextUpper = filterText.toUpperCase();

    return nodes.reduce((filteredNodes, node) => {
      const nodeMatches = node.name.toUpperCase().includes(filterTextUpper);
      const filteredSubgroups = node.subgroups?.filter(child =>
        child.name.toUpperCase().includes(filterTextUpper)
      ) || [];

      // ✅ Si el nodo o alguno de sus subgrupos coincide, lo añadimos sin crear un nuevo objeto
      if (nodeMatches || filteredSubgroups.length > 0) {
        filteredNodes.push({
          ...node,
          subgroups: filteredSubgroups.length > 0 ? filteredSubgroups : node.subgroups
        });
      }

      return filteredNodes;
    }, []);
  }
}