import { Cfg } from "./cfg"
import { Stat } from "./stat";

export class infoModulator{
    cfg: Cfg;
    stat: Stat;
    
    topic_name= "modulator"
    uid= null
    version= "1.0.0"

    constructor(values: Object = {}) {
        Object.assign(this, values);
        this.cfg=new Cfg();
        this.stat=new Stat();
    }

    update(values: Object = {}){
        Object.assign(this, values);
        // Object.entries(newObj).forEach(([key, value])=>{

        //     if(typeof value == 'object' && this[key] != undefined){
        //         this[key].update(value);
        //     }
        //     this[key] = value;
        // });
    }
}