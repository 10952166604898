<div class="w-100 p-3 card-historics-spec">
    <div class="card-body py-3">
        <div class="row">
            <div class="col-xl-8 d-flex justify-content-center">
                <div class="graph-historics-big">
                    <div class="chart chart-sm w-100 h-100">
                        <canvas id="chart-card-historics" class="w-100 h-100"></canvas>
                      </div>
                </div>

            </div>

            <div class="col-xl-4">
                <div class="card-actions mb-3">
                    <h4 class="text-light">Day</h4>
                    <div style="z-index: 2000;">
                        <input matInput [matDatepicker]="picker" name="date" [(ngModel)]="dateSearch" (dateChange)="changeDate(0)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </div>

                    <!-- <input class="form-control w-75" type="date"> -->
                  </div>
                <div>
                    <h4 class="text-light">Total of the day</h4>
                    <h2 class="text-right">{{(totalEvse+totalHome)/1000| number:'1.2-2'}} kWh</h2>
                </div>
                <div>
                    <h4 class="text-light">Total EVSE</h4>
                    <h2 class="text-right"> {{totalEvse/1000| number:'1.2-2'}} kWh </h2>
                </div>
                <div>
                    <h4 class="text-light">Total Home</h4>
                    <h2 class="text-right"> {{totalHome/1000| number:'1.2-2'}} kWh </h2>
                </div>
            </div>
        </div>
        <button type="button" class="btn ml-1 btn-secondary" (click)="changeDate(-1)">
          <i class="fas fa-arrow-left"></i>
        </button>
        <button type="button" class="btn ml-1 btn-secondary" (click)="changeDate(1)">
          <i class="fas fa-arrow-right"></i>
        </button>
    </div>
</div>
