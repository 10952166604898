import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private updateSidebar = new BehaviorSubject<number>(0);
  private breadCrumbs = new BehaviorSubject<{}[]>([{ name: 'home', alias: 'dashboard' }]);
  private viewDashboard = new BehaviorSubject<'groups' | 'devices'>('groups');
  private modoDashboard = new BehaviorSubject<'' | 'map' | 'list'>('');
  private location = new BehaviorSubject<{ lat: number, lng: number }>({ lat: 40.45987, lng: -3.691145 });
  private zoom = new BehaviorSubject<number>(6);

  constructor() {}

  // Gestión del modo de vista (mapa o lista)
  getViewDashboard() {
    return this.viewDashboard.asObservable();
  }

  setViewDashboard(view: 'groups' | 'devices') {
    this.viewDashboard.next(view);
  }

  getModoDashboard() {
    return this.modoDashboard.asObservable();
  }

  setModoDashboard(modo) {
    this.modoDashboard.next(modo);
  }

  getLocation() {
    return this.location.asObservable();
  }

  setLocation(location: { lat: number, lng: number }) {
    this.location.next(location);
  }

  getZoom() {
    return this.zoom.asObservable();
  }

  setZoom(zoom: number) {
    this.zoom.next(zoom);
  }

  // Gestión de los breadcrumbs
  getBreadCrumbs() {
    return this.breadCrumbs.asObservable();
  }

  /**
   * Actualiza los breadcrumbs, eliminando los elementos previos que ya no son relevantes.
   */
  setBreadCrumbs(crumb: { name: string, alias: string }) {
    const currentBreadCrumbs = this.breadCrumbs.getValue();
    const updatedBreadCrumbs = [...currentBreadCrumbs, crumb];
    this.breadCrumbs.next(updatedBreadCrumbs);
  }

  /**
   * Reemplaza completamente los breadcrumbs con un nuevo conjunto.
   */
  replaceBreadCrumbs(newCrumbs: { name: string, alias: string }[]) {
    this.breadCrumbs.next(newCrumbs);
  }

  // Reinicia los breadcrumbs a solo "home"
  resetBreadCrumbs() {
    this.breadCrumbs.next([{ name: 'home', alias: 'dashboard' }]);
  }

  removeLastBreadcrumb() {
    const currentBreadCrumbs = this.breadCrumbs.getValue();
    if (currentBreadCrumbs.length > 1) {
      currentBreadCrumbs.pop();
      this.breadCrumbs.next([...currentBreadCrumbs]);
    }
  }

  // Gestión del estado del sidebar
  getUpdateSidebar() {
    return this.updateSidebar.asObservable();
  }

  setUpdateSidebar() {
    this.updateSidebar.next(1);
  }

  resetUpdateSidebar() {
    this.updateSidebar.next(0);
  }
}
