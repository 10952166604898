import { Colors } from 'src/assets/js/colors';

/*SERVICES*/
import { AuthService } from './services/auth/auth.service';

/*MODULES*/
import { VerificationModalComponent } from './components/ui/verification-modal/verification-modal.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { SidebarModule } from './shared/sidebar/sidebar.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrModule } from 'ngx-toastr';
import { DateAdapter } from '@angular/material/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import { RouterModule } from '@angular/router';
import localeEs from '@angular/common/locales/es';
import { GoogleMapsModule } from '@angular/google-maps'


/*PIPES*/
import { UndefinedsPipe } from './services/pipes/undefineds/undefineds.pipe';
import { FilterLogsPipe } from './services/pipes/logsFilter/filter-logs.pipe';
import { AbsPipe } from './services/pipes/abs';
import { StringifyPipe } from './services/pipes/stringify';
import { CustomDateAdapter } from './custom-date.adapter';
import { CapitalizeFirstPipe } from './services/pipes/capitalizeFirst';
import { CommaToSlashPipe } from './services/pipes/commaToSlash/commaToSlash.pipe';

/*TRANSLATIONS*/
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/*ANGULAR MATERIAL*/
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';

import { MatTreeModule } from '@angular/material/tree';



/*COMPONENTS*/
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { DeviceComponent } from './pages/device/device.component';
import { SettingsComponent } from './pages/device/settings/settings.component';
import { DeviceDashboardComponent } from './pages/device/device-dashboard/device-dashboard.component';
import { ConnectivityComponent } from './pages/device/settings/connectivity/connectivity.component';
import { PasswordComponent } from './pages/device/settings/password/password.component';
import { WifiComponent } from './pages/device/settings/wifi/wifi.component';
import { PowerComponent } from './pages/device/settings/power/power.component';
import { OcppComponent } from './pages/device/settings/ocpp/ocpp.component';
import { ErrorModalComponent } from './components/ui/error-modal/error-modal.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { RegisterComponent } from './pages/register/register.component';
import { CardStateComponent } from './components/device-cards/card-state/card-state.component';
import { FooterComponent } from './shared/footer/footer.component';
import { CardHistoricComponent } from './components/card-historic/card-historic.component';
import { LoadingPopupComponent } from './components/ui/loading-popup/loading-popup.component';
import { AllSettingsComponent } from './pages/device/settings/all-settings/all-settings.component';
import { MapComponent } from './components/map/map.component';
import { AdminComponent } from './pages/admin/admin.component';
import { AdminSearchComponent } from './pages/admin/admin-search/admin-search.component';
import { RfidComponent } from './pages/device/settings/rfid/rfid.component';
import { LogsComponent } from './pages/admin/logs/logs.component';
import { FwupdateComponent } from './pages/device/settings/fwupdate/fwupdate.component';
import { RecoveryComponent } from './pages/recovery/recovery.component';
import { ForgotComponent } from './pages/recovery/forgot/forgot.component';
import { CustomModalComponent } from './components/ui/custom-modal/custom-modal.component';
import { ModalConfirmComponent } from './components/ui/modal-confirm/modal-confirm.component';
import { AddDeviceComponent } from './pages/admin/add-device/add-device.component';
import { ModalSelectSerialComponent } from './components/ui/modal-select-serial/modal-select-serial.component';
import { ChargeSchedulerComponent } from './pages/device/settings/charge-scheduler/charge-scheduler.component';
import { PowerSchedulerComponent } from './pages/device/settings/power-scheduler/power-scheduler.component';
import { ModalSchedulerTaskComponent } from './components/ui/modal-scheduler-task/modal-scheduler-task.component';
import { AlertsDiagnosisComponent } from './components/ui/alerts-diagnosis/alerts-diagnosis.component';
import { SolarPriorityModalComponent } from './components/ui/solar-priority-modal/solar-priority-modal.component';
import { EthernetComponent } from './pages/device/settings/ethernet/ethernet.component';
import { ModulesComponent } from './pages/device/modules/modules.component';
import { MqttApiComponent } from './pages/developers/documentation/mqtt-api/mqtt-api.component';
import { DeveloperLayoutComponent } from './layouts/developer-layout/developer-layout.component';
import { CardAnalyticsComponent } from './components/card-analytics/card-analytics.component';
import { ResetComponent } from './pages/device/settings/reset/reset.component';
import { MultipleCommandComponent } from './pages/admin/multiple-command/multiple-command.component';
import { ModalMainMeterComponent } from './components/ui/modal-mainMeter/modal-mainMeter.component';
import { ModalSecondaryMeterComponent } from './components/ui/modal-secondaryMeter/modal-secondaryMeter.component';
import { PowerNewComponent } from './pages/device/settings/power-new/power-new.component';
import { TestersComponent } from './pages/admin/testers/testers.component';
import { TestersSearchPipe } from './services/pipes/testers-search.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SharedModule } from './shared/shared.module';


//Firebase

import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import {provideAuth, getAuth} from '@angular/fire/auth';

import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFireAuthModule, PERSISTENCE } from '@angular/fire/compat/auth';

//import { environment } from '../environments/environment.dev';
import { environment } from '../environments/environment';
import { PatTokenComponent } from './pages/developers/settings/pat-token/pat-token.component';

//ReCaptchav2
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DeviceSettingsSplComponent } from './pages/device/device-settings-spl/device-settings-spl.component';
import { DiagnosisHomeComponent } from './pages/device/diagnosis-home/diagnosis-home.component';
import { LinkageComponent } from './pages/device/settings/linkage/linkage.component';
import { MatCardModule} from '@angular/material/card';
import { ModalCheckComponent } from './components/ui/modal-check/modal-check.component';
import { GeolocationComponent } from './pages/device/settings/geolocation/geolocation.component'
import { NameComponent } from './pages/device/settings/name/name.component';
import { MqttComponent } from './pages/device/settings/mqtt/mqtt.component';
import { JsonStringifyPipe } from './services/pipes/json-stringify.pipe';
import { NewGroupComponent } from './pages/group/new-group/new-group.component';
import { ViewGroupComponent } from './pages/group/view-group/view-group.component';
import { ModalAddGroupComponent } from './components/ui/modal-add-group/modal-add-group.component';
import { SplGroupComponent } from './pages/group/spl-group/spl-group.component';
import { ShowGroupComponent } from './pages/group/show-group/show-group.component';
import { SubscriptionsComponent } from './pages/device/subscriptions/subscriptions.component';
import { GroupComponent } from './pages/group/group.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeEs, 'es');
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminLayoutComponent,
    DeviceComponent,
    SettingsComponent,
    DeviceDashboardComponent,
    ConnectivityComponent,
    PasswordComponent,
    WifiComponent,
    PowerComponent,
    OcppComponent,
    ErrorModalComponent,
    ProfileComponent,
    RegisterComponent,
    CardStateComponent,
    FooterComponent,
    CardHistoricComponent,
    LoadingPopupComponent,
    AllSettingsComponent,
    MapComponent,
    AdminComponent,
    AdminSearchComponent,
    RfidComponent,
    LogsComponent,
    FwupdateComponent,
    RecoveryComponent,
    ForgotComponent,
    CustomModalComponent,
    ModalConfirmComponent,
    VerificationModalComponent,
    DiagnosisHomeComponent,
    AddDeviceComponent,
    ModalSelectSerialComponent,
    UndefinedsPipe,
    ChargeSchedulerComponent,
    PowerSchedulerComponent,
    ModalSchedulerTaskComponent,
    AlertsDiagnosisComponent,
    SolarPriorityModalComponent,
    FilterLogsPipe,
    EthernetComponent,
    ModulesComponent,
    MqttApiComponent,
    DeveloperLayoutComponent,
    CardAnalyticsComponent,
    AbsPipe,
    StringifyPipe,
    ResetComponent,
    MultipleCommandComponent,
    ModalMainMeterComponent,
    ModalSecondaryMeterComponent,
    PowerNewComponent,
    DashboardComponent,
    PatTokenComponent,
    TestersComponent,
    TestersSearchPipe,
    CapitalizeFirstPipe,
    DeviceSettingsSplComponent,
    LinkageComponent,
    ModalCheckComponent,
    GeolocationComponent,
    NameComponent,
    CommaToSlashPipe,
    MqttComponent,
    JsonStringifyPipe,
    SubscriptionsComponent,
    NewGroupComponent,
    ViewGroupComponent,
    ModalAddGroupComponent,
    SplGroupComponent,
    ShowGroupComponent,
    GroupComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    SidebarModule,
    NavbarModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatDatepickerModule,
    MatMomentDateModule,
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBIZOUS6mLugDM2ppq4q8QwA4ii45aDA_Q',
    }),
    UiSwitchModule.forRoot({
      size: 'small',
      color: Colors.primary,
      switchColor: 'white',
      defaultBgColor: 'grey',
      defaultBoColor: 'green',
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
    provideFirebaseApp(() => initializeApp({ ...environment.firebase })),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    AngularFireAuthModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatCardModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatTreeModule,
    SharedModule,
    GoogleMapsModule,
    RouterModule
  ],
  providers: [{
    provide: DateAdapter, useClass: CustomDateAdapter
  },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {provide: LOCALE_ID, useValue: "es"},
    {provide: PERSISTENCE, useValue: 'local'},
    {provide: FIREBASE_OPTIONS, useValue: environment.firebase},
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
   NgbActiveModal,
   AuthService
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('es');
  }
}
