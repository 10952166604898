<div class="wrapper minh-100" id="wraper-adminLayout">
    <div class="sidebar" data-color="white" data-active-color="danger">
        <sidebar-cmp></sidebar-cmp>
    </div>   

    <div class="main-panel w-100">
        <navbar-cmp></navbar-cmp>
        <div style="height: 100%;">
            <main style="height: calc(100% - 130px);">
                <router-outlet></router-outlet>
            </main>
        </div>
    </div>
</div>