<div class="prueba-sidebar">
  <nav id="sidebar" class="sidebar">
    <!-- <a class="sidebar-brand"  href="#/dashboard"  >
      <div class="img-logo d-flex align-items-center">
        <img src="/assets/img/orbis/orbisIcon.png" width="50" height="50" alt="">
        <h1 class="m-0 text-white text-logo-icon ml-3">RBIS</h1>
      </div>
    </a> -->
    <div (click)="home()">
      <a class="d-flex link-logo" href="#/dashboard" style="height: 64px;">
        <div class="img-logo d-flex align-items-center pl-2">
          <img src="/assets/img/orbis/logotipo-final-orbis.png" height="50" alt="">
          <!--h1 class="m-0 text-white text-logo-icon ml-3">ORBIS</h1-->
        </div>
      </a>
    </div>

    <div class="sidebar-content">
      <ul class="sidebar-nav">

        <div>
          <div class="accordion" id="accordionAdmin">
            <!--ADMINISTRATION-->
            <div class="accordion-item" *ngIf="userRole =='admin'">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed " type="button" data-bs-toggle="collapse" data-bs-target="#collapseAdmin"
                  aria-expanded="true" aria-controls="collcollapseAdminapseOne">
                  <i class="fas fa-file-signature mr-2 "></i>
                  <span class="align-middle ">{{"administration" | translate}}</span>
                </button>
              </h2>

              <div id="collapseAdmin" class="accordion-collapse collapse" data-bs-parent="#accordionAdmin">
                <div class="accordion-body">
                  <li class="sidebar-item">
                    <a class="sidebar-link" (click)="deleteUserDevice()"
                      routerLink="/dashboard/administration/search">{{"search" | translate}}</a>
                  </li>
                  <li class="sidebar-item">
                    <a class="sidebar-link" (click)="deleteUserDevice()"
                      routerLink="/dashboard/administration/addUser">{{"addUserToDevice" | translate}}</a>
                  </li>
                  <li class="sidebar-item">
                    <a class="sidebar-link" routerLink="/dashboard/administration/sendCommand">{{"sendCommands" |
                      translate}}</a>
                  </li>
                  <li class="sidebar-item">
                    <a class="sidebar-link" routerLink="/dashboard/administration/testers">{{"testers" | translate}}</a>
                  </li>
                  <li class="sidebar-item"><a class="sidebar-link"
                      (click)="clickDiagnosis();deleteUserDevice()">{{"diagnosis" | translate}}</a></li>
                  <li class="sidebar-item">
                    <a class="sidebar-link" (click)="deleteUserDevice()"
                      routerLink="/dashboard/administration/logs">{{"historicSidebar" | translate}}</a>
                  </li>
                </div>
              </div>
            </div>
            <!--DEVELOPER SETTINGS-->
            <div class="accordion-item" *ngIf="userRole=='admin' || userRole=='developer'">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDevSettings"aria-expanded="false" aria-controls="collcollapseAdminapseOne">
                  <i class="fas fa-code mr-2"></i> <span class="align-middle">{{"developerSettings" | translate}}</span>
                </button>
              </h2>
              <div id="collapseDevSettings" class="accordion-collapse collapse" data-bs-parent="#accordionAdmin">
                <div class="accordion-body">
                  <li class="sidebar-item"><a class="sidebar-link" routerLink="/developers/documentation/mqtt_api">{{"mqttApi" | translate}}</a></li>
                  <li class="sidebar-item"><a class="sidebar-link" routerLink="/developers/settings/pat-token">{{"patToken" | translate}}</a></li>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--DEVICES-->

        <div class="p-0 devicesGroupsBlock" *ngIf="userRole =='admin' || hasGroupSubscription()" >
          <!-- Sidebar -->

          <div class="d-flex w-100 m-auto button-group-container ">
            <div class=" w-50 text-center p-1 button-group" (click)="changeView('devices')"
              [ngClass]="{'bg-white text-black' : view === 'devices' }" data-cy="sidebarDevicesButton">
              {{"devices" | translate}}
            </div>
            <div class="w-50 text-center p-1 button-group" (click)="changeView('groups')"
              [ngClass]="{'bg-white text-black' : view === 'groups' }" data-cy="sidebarGroupsButton">
              {{"groups" | translate}}
            </div>
          </div>

          <div class="" id="sidebar-wrapper">

            <div class="text-muted listItemsGroups" *ngIf="view === 'groups'">
              <li class="sidebar-header">
                <form class="form-inline d-none d-sm-inline-block mt-2">
                  <input class="form-control" type="text" name="filteredGroup" [(ngModel)]="filteredGroup"
                    placeholder="{{'Buscar grupo' | translate}}" data-cy="sidebarSearchGroups">
                </form>
              </li>
              <div class="devices-sidebar pt-2">
                <div *ngFor="let node of data | filterGroups:filteredGroup; let i = index; trackBy: trackByFn" class="mb-2 ml-1" style="cursor: pointer;">
                  <!-- Nodo Padre -->
                  <div class="d-flex align-items-center pl-2" [ngClass]="{'selected-item bg-primary': node.alias === selectedNode}">
                    <span *ngIf="node.isExpanded" (click)="toggleNode(node, node.devices, i, false)"><i class="ion ion-ios-arrow-down"></i></span>
                    <span *ngIf="!node.isExpanded" (click)="toggleNode(node, node.devices, i, false)"><i class="ion ion-ios-arrow-forward"></i></span>
                    <div (click)="toggleNode(node, node.devices, i)" class="d-flex align-items-center">
                      <span class="ml-2"><i class="ion ion-ios-apps me-2"></i></span>
                      <span>{{ node.name }}</span>
                    </div>
                  </div>

                  <!-- Subgrupos del nodo padre al expandir -->
                  <div *ngIf="node.isExpanded">
                    <div *ngFor="let child of node.subgroups" class="ms-4 mt-1">
                      <!-- Subgrupo -->
                      <div class="d-flex align-items-center pl-2" [ngClass]="{
                        'selected-item bg-primary': child.alias === selectedNode
                      }">
                        <span *ngIf="child.isExpanded" (click)="clickGroup(node, child, false)"><i class="ion ion-ios-arrow-down"></i></span>
                        <span *ngIf="!child.isExpanded" (click)="clickGroup(node, child, false)"><i class="ion ion-ios-arrow-forward"></i></span>
                        <div (click)="clickGroup(node, child)" class="d-flex align-items-center">
                          <span class="ml-2"><i class="ion ion-ios-apps me-2"></i></span>
                          <span>{{ child.name }}</span>
                        </div>
                      </div>

                      <!-- Subgrupos y dispositivos del hijo -->
                      <div *ngIf="child.isExpanded">
                        <!-- Subgrupos del hijo -->
                        <div *ngFor="let grandchild of child.children" class="ms-4 mt-1">
                          <div class="d-flex align-items-center pl-2" [ngClass]="{
                            'selected-item bg-primary': grandchild.alias === selectedNode
                          }">
                            <span *ngIf="grandchild.isExpanded" (click)="clickGrandchild(node, child, grandchild, false)"><i class="ion ion-ios-arrow-down"></i></span>
                            <span *ngIf="!grandchild.isExpanded" (click)="clickGrandchild(node, child, grandchild, false)"><i class="ion ion-ios-arrow-forward"></i></span>
                            <div (click)="clickGrandchild(node, child, grandchild)" class="d-flex align-items-center">
                              <span class="ml-2"><i class="ion ion-ios-apps me-2"></i></span>
                              <span>{{ grandchild.name }}</span>
                            </div>
                          </div>

                          <!-- Dispositivos del nieto -->
                          <div *ngIf="grandchild.isExpanded" class="ms-4">
                            <div *ngFor="let device of grandchild.devices" (click)="clickDevice(device.serial)"
                              class="d-flex align-items-top mt-1 pl-2" style="cursor: pointer;"
                              [ngClass]="{'selected-item bg-primary': device.serial?.toLowerCase() === paramDevice?.toLowerCase()}">
                              <i class="fas fa-charging-station me-1 mt-1 text-success" *ngIf="device.alive == 1"></i>
                              <i class="fas fa-charging-station me-1 mt-1 text-danger" *ngIf="device.alive == 0"></i>
                              <span class="row">
                                <span *ngIf="!device.name">{{ device.serial }}</span>
                                <span *ngIf="device.name">{{ device.name }}</span>
                                <small *ngIf="device.name" class="text-secondary" style="width: fit-content;">
                                  {{device.serial}} </small>
                              </span>
                            </div>
                          </div>
                        </div>

                        <!-- Línea de separación -->
                        <hr class="m-1" *ngIf="child.children?.length > 0 && child.devices?.length > 0">

                        <!-- Dispositivos del hijo -->
                        <div *ngFor="let device of child.devices" (click)="clickDevice(device.serial)"
                          class="d-flex align-items-top mt-1 ms-4 pl-2" style="cursor: pointer;"
                          [ngClass]="{'selected-item bg-primary': device.serial?.toLowerCase() === paramDevice?.toLowerCase()}">
                          <i class="fas fa-charging-station me-1 mt-1 text-success" *ngIf="device.alive == 1"></i>
                          <i class="fas fa-charging-station me-1 mt-1 text-danger" *ngIf="device.alive == 0"></i>

                          <span class="row">
                            <span *ngIf="!device.name">{{ device.serial }}</span>
                            <span *ngIf="device.name">{{ device.name }}</span>
                            <small *ngIf="device.name" class="text-secondary" style="width: fit-content;">
                              {{device.serial}} </small>
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- Dispositivos del nodo padre -->
                    <hr class="m-1" *ngIf="node.subgroups?.length > 0 && node.devices?.length > 0">
                    <div *ngFor="let device of node.devices" (click)="clickDevice(device.serial)"
                      class="d-flex align-items-top mt-1 ms-3 pl-2" style="cursor: pointer;" 
                      [ngClass]="{'selected-item bg-primary': device.serial?.toLowerCase() === paramDevice?.toLowerCase()}">
                      <i class="fas fa-charging-station me-1 mt-1 text-success" *ngIf="device.alive == 1"></i>
                      <i class="fas fa-charging-station me-1 mt-1 text-danger" *ngIf="device.alive == 0"></i>
                      <span class="row">
                        <span *ngIf="!device.name" class="pe-0" style="width: 100%;">{{ device.serial }}</span>
                        <span *ngIf="device.name" class="pe-0" style="width: 100%;">{{ device.name }}</span>
                        <small *ngIf="device.name" class="text-secondary" style="width: fit-content;">
                          {{device.serial}} </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>




            <div *ngIf="view=='devices'" class="listItems">

              <li class="sidebar-header">
                <form class="form-inline d-none d-sm-inline-block mt-2">
                  <input class="form-control" type="text" name="filteredSerial" [(ngModel)]="filteredSerial"
                    placeholder="{{'searchDevices' | translate}}" data-cy="sidebarSearchDevices">
                </form>
              </li>
              <div class="devices-sidebar">
                <li class="sidebar-item" *ngFor="let device of devices | filterDevices:filteredSerial; let i = index">
                  <a class="sidebar-link collapsed" (click)="clickDevice(device.serial)">
                    <i class="fas fa-charging-station me-1 mt-1 text-success" *ngIf="device.alive == 1"></i>
                    <i class="fas fa-charging-station me-1 mt-1 text-danger" *ngIf="device.alive == 0"></i>
                    <span [attr.data-cy]="'sidebarDevice['+i+']'" class="align-middle">{{device.serial}}</span>&nbsp;
                    <!-- <i *ngIf="device.alive == 1" class="	fas fa-circle text-success"></i>
                                    <i *ngIf="device.alive == 0" class="	fas fa-circle text-danger"></i> -->
                  </a>
                </li>
              </div>
            </div>
          </div>
          <button *ngIf="view=='groups'" class="my-float bg-primary" (click)="addGroup()"><i
              class="fa fa-plus"></i></button>
        </div>


        <!--DEVICES-->
        <div *ngIf="userRole !='admin' && !hasGroupSubscription()">

          <li class="sidebar-header">
            {{"devices" | translate}}
            <form class="form-inline d-none d-sm-inline-block mt-2">
              <input class="form-control" type="text" name="filteredSerial" [(ngModel)]="filteredSerial"
              placeholder="{{'searchDevices' | translate}}">
            </form>
          </li>
          <div class="devices-sidebar">
            <li class="sidebar-item" *ngFor="let device of devices | filterDevices:filteredSerial">
              <a class="sidebar-link collapsed" (click)="clickDevice(device.serial)">
                <i class="fas fa-charging-station me-1 mt-1 text-success" *ngIf="device.alive == 1"></i>
                <i class="fas fa-charging-station me-1 mt-1 text-danger" *ngIf="device.alive == 0"></i>
                <span class="align-middle">{{device.serial}}</span>&nbsp;
              </a>
            </li>
          </div>
        </div>


      </ul>
    </div>
  </nav>
</div>
