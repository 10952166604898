<div class="header d-flex justify-content-between">
  <h1 class="header-title">
    {{"sendCommands" | translate}}
  </h1>
</div>
<div class="col-12 col-xl-12">
  <div class="card">
    <div class="card-header">
      <h5 class="card-title"></h5>
      <h6 class="card-subtitle text-muted"></h6>
    </div>
    <div class="card-body">
      <div class="ml-5">
      </div>
      <div class="row pl-5 pr-5">
        <div class="col-5">
          <h2>{{"devices" | translate}}</h2>

          <button class="btn mb-1 btn-pill ml-2 btn-tertiary"
            (click)="removeFilter('type')"
            *ngIf="filtersAdded.type!=''">{{this.filtersAdded.type}} <i class="fas fa-fw fa-close"></i>
          </button>
          <button class="btn mb-1 btn-pill ml-2 btn-info"
              (click)="removeFilter('version')"
              *ngIf="filtersAdded.version!=''">{{this.filtersAdded.version}} <i class="fas fa-fw fa-close"></i>
          </button>
          <div class="col-12">
            <div *ngIf="chargingToSend" class="d-flex justify-content-center">
              <div class="spinner-border text-primary" style="width: 3em; height:3em;"></div>
            </div>
            <table *ngIf="!chargingToSend" mat-table [dataSource]="dataSourceToSend" class="table table-striped my-0">

              <ng-container matColumnDef="serial">
                <th mat-header-cell *matHeaderCellDef> {{"serial" | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.serial}} </td>
              </ng-container>

              <ng-container matColumnDef="version">
                <th mat-header-cell *matHeaderCellDef> {{"version" | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.version}} </td>
              </ng-container>


              <ng-container matColumnDef="check">
                <th mat-header-cell *matHeaderCellDef> Checks </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngFor="let item of element.taskCheck; let i = index" >
                    <i *ngIf="element.taskCheck[i].check && getParcial(element.taskCheck[i].module, element.serial) == getTotal(element.taskCheck[i].module, element.serial) " class="fas fa-check-circle text-success i-s" (click)="openCheckModal(element,i)"  matTooltip="{{element.taskCheck[i].module}} {{getParcial(element.taskCheck[i].module, element.serial) }}/{{getTotal(element.taskCheck[i].module, element.serial) }}" ></i>
                    <i *ngIf="element.taskCheck[i].check && getParcial(element.taskCheck[i].module, element.serial) != getTotal(element.taskCheck[i].module, element.serial) " class="fas fa-exclamation-circle text-danger i-s" (click)="openCheckModal(element,i)"  matTooltip="{{element.taskCheck[i].module}} {{getParcial(element.taskCheck[i].module, element.serial) }}/{{getTotal(element.taskCheck[i].module, element.serial) }}" ></i>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="remove">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                  <button *ngIf="element.status==0" class="btn-error" (click)="removeDevice(element)">
                    <i class="fas fa-trash"></i>
                  </button>
                  <div *ngIf="element.status==1" class="spinner-border text-primary"></div>
                  <i *ngIf="element.status==2" class="fas fa-check-circle text-success i-s" (click)="removeDevice(element)"></i>
                  <i *ngIf="element.status==3" matTooltip={{element.error}} matTooltipPosition="right" class="fas fa-exclamation-circle text-danger i-s" (click)="removeDevice(element)"></i>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsToSend"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsToSend;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4"> {{"notDeviceSelected" | translate}} </td>
              </tr>
            </table>
            <mat-paginator #paginatorToSend="matPaginator" [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users"></mat-paginator>
          </div>
        </div>
        <div class="col-2 mt-3">
          <div class="justify-content-center d-flex">
            <div *ngIf="updateStatus==1" class="spinner-border text-primary"></div>
            <i *ngIf="updateStatus!=1" style="font-size: 40px;" class="fas fa-arrow-right"></i>
          </div>
          <div *ngIf="checkActiveButton()" class="mt-3 d-flex justify-content-center">
            <button *ngIf="updateStatus!=1" class="btn btn-primary btn-primary-hard col-10" [disabled]="buttonEnabled || this.dataSourceToSend.data.length==0" (click)="sendCommand()">{{"sendCommand" | translate}}</button>
          </div>
        </div>
        <div class="col-5">
          <h3>{{"command" | translate}}</h3>
          <select class="form-select" [(ngModel)]="command">
            <option value="0" selected disabled>{{"selectCommand" | translate}}</option>
            <option value="1">{{"reset" | translate}}</option>
            <option value="2">{{"update" | translate}}</option>
            <option value="3">Check</option>
          </select>
          <div *ngIf="command==1" class="mt-2">
            <select class="form-select" [(ngModel)]="resetType">
              <option value="hard">{{"hardResetButton" | translate}}</option>
              <option value="factoryresetsoft">{{"factoryResetButton" | translate}}</option>
              <option value="factoryresethard">{{"factoryResetHardButton" | translate}}</option>
            </select>
          </div>
          <div *ngIf="command==2" class="mt-2">
            <input type="text" class="form-control col-12" placeholder="{{'urlUpdate' | translate}}" name="urlUpdate" [(ngModel)]="urlUpdate">
            <div class="mt-2 d-flex justify-content-center">
              <button class="btn btn-tertiary btn-tertiary-hard col-8" (click)="getLastVersion()">{{"getLastVersion" | translate}}</button>
              <div class="col-4 pr-0">
                <select class="form-select" [(ngModel)]="roleUpdate">
                  <option value="admin" selected>admin</option>
                  <option value="developer">developer</option>
                  <option value="tester">tester</option>
                  <option value="user">user</option>
                </select>
              </div>
            </div>
          </div>
          <div *ngIf="command==3" class="mt-2">
            <div class="form-check"  *ngFor="let item of taskCheckOption ; let i = index">
              <input class="form-check-input" type="checkbox" value="" [(ngModel)]="item.check" (change)="changeTaskCheck(i)" >
              <label class="form-check-label" for="flexCheckDefault" >
                  {{item.module}}
              </label>
            </div>

          </div>
          <div class="mt-2 alert alert-success alert-dismissible" role="alert" *ngIf="updateStatus==2">
            <div class="alert-message">
                {{"sendCommandSuccess" | translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-header pl-5">
      <h5 class="card-title"></h5>
      <div class="d-flex align-items-baseline">
        <h5 class="card-subtitle text-muted">{{"devicesSelection" | translate}}</h5>
        <div class="d-flex button-group-container ml-5" *ngIf="userRole=='admin'">
          <div class="text-center p-2 button-group" style="width: 200px; border-right-width: 0;" (click)="changeAllDevicesMode(false)"
              [ngClass]="{'bg-primary text-white' : !getAllDevices}">
              {{"myDevices" | translate}}
          </div>
          <div class="text-center p-2 button-group" style="width: 200px; border-left-width: 0;" (click)="changeAllDevicesMode(true)"
              [ngClass]="{'bg-primary text-white' : getAllDevices}">
              {{"allDevices" | translate}}
          </div>
        </div>
      </div>
    </div>
    <div class="card-body pl-6 pr-6 justify-content-center">
      <div class="form-group col-xl-12">
        <label class="col-form-label col-sm-4">{{"filter_by" | translate}}</label>
        <div class="d-flex justify-content-between align-items-center">
          <div class="col-4 pl-3 pr-3">
            <select class="form-select col-12" [(ngModel)]="filter" (change)="changeFilter()">
              <option value="0" selected disabled>{{"selectFilter" | translate}}</option>
              <option value="1">{{"serial" | translate}}</option>
              <option value="2">{{"type" | translate}}</option>
              <option value="3">{{"version" | translate}}</option>
            </select>
          </div>
          <div *ngIf="filter==2" class="col-4 pl-3 pr-3">
            <select class="form-select col-12" [(ngModel)]="typeFilter">
              <option value="" selected disabled>{{"selectType" | translate}}</option>
              <option value="UNI">UNI</option>
              <option value="COMBI+">COMBI+</option>
              <option value="COMBI/CITY">COMBI/CITY</option>
              <option value="Contador">{{"contador" | translate}}</option>
            </select>
          </div>
          <div *ngIf="filter==3" class="col-4 pl-3 pr-3">
            <input type="text" class="form-control col-12" placeholder="{{'versionNumber' | translate}}"
              name="versionFilter" [(ngModel)]="versionFilter">
          </div>
          <div *ngIf="filter>1" class="col-4 pl-3 pr-3">
            <button class="btn btn-primary btn-primary-hard col-12" (click)="addFilter()">{{"addFilter" | translate}}</button>
          </div>
          <div *ngIf="filter==1" class="col-4 pl-3 pr-3">
            <input type="text" class="form-control col-12" placeholder="{{'searchBySerial' | translate}}"
              name="serialFilter" [(ngModel)]="serialFilter" (keyup)="applyFilter($event)">
          </div>
        </div>
      </div>
      <div class="results-search p-3" *ngIf="filter==1">
        <div *ngIf="charging" class="d-flex justify-content-center">
          <div class="spinner-border text-primary" style="width: 3em; height:3em;"></div>
        </div>
        <table *ngIf="!charging" mat-table [dataSource]="dataSource" matSort class="table table-striped my-0">
          <ng-container matColumnDef="selectedDev">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element"> <input type="checkbox" (click)="addDevice(element)" [(ngModel)]="element.selected" name="active"> </td>
          </ng-container>
          <ng-container matColumnDef="serial">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"serial" | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.serial}} </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"type" | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.type}} </td>
          </ng-container>
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"version" | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.version}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">{{"notDeviceFound" | translate}}</td>
          </tr>
        </table>
        <mat-paginator #paginatorAll="matPaginator" [pageSizeOptions]="[10, 25, 50]" aria-label="Select page of users"></mat-paginator>
      </div>
    </div>
  </div>
</div>
