<div class="w-100 p-3 card-historics-spec">
    <div class="card-body py-3">
        <div class="btn-group mb-2" role="group" aria-label="Basic example">
          <button *ngFor="let conn of connectors; index as i" type="button" class="btn ml-1"
           [class.btn-secondary]="selectedConnector==conn.name" (click)="selectedConnector=conn.name">
           {{conn.name.startsWith('mennekes') ? ("connector" | translate | uppercase  | titlecase) + (i+1)  : conn.name | titlecase}}

          </button>
        </div>
        <div class="btn-group mb-2 float-right" role="group" aria-label="Download historics">
            <button type="button" class="btn ml-1 btn-secondary" (click)="downloadFiles('csv')">{{"download" | translate}} .csv</button>
            <button type="button" class="btn ml-1 btn-secondary" (click)="downloadFiles('xls')">{{"download" | translate}} .xls</button>
        </div>

        <table class="table table-striped my-0">
          <thead>
            <tr>
              <th> {{"date" | translate}} </th>
              <th class="d-none d-xl-table-cell">{{"historicsStartTime" | translate}}</th>
              <th class="d-none d-xl-table-cell">{{"historicsEndTime" | translate}}</th>
              <th class="d-none d-md-table-cell">{{"historicsDuration" | translate}}</th>
              <th class="d-none d-md-table-cell">{{"historicsEnergy" | translate}}</th>
              <th class="d-none d-md-table-cell">{{"historicsStartSource" | translate}}</th>
              <th class="d-none d-md-table-cell">{{"historicsEndSource" | translate}}</th>
              <!-- <th class="d-none d-md-table-cell">{{"historicsUser" | translate}}</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let historic of historics[selectedConnector]">
              <td>{{historic.start_datetime | date:'dd/MM/yyyy'}}</td>
              <td class="d-none d-xl-table-cell">{{historic.start_datetime | date:'h:mm a'}}</td>
              <td class="d-none d-xl-table-cell">{{historic.end_datetime | date:'h:mm a'}}</td>
              <td class="d-none d-md-table-cell">{{subtractDates(historic.end_datetime, historic.start_datetime)}}</td>
              <td class="d-none d-md-table-cell">{{historic.end_energy-historic.start_energy | number: '1.0-0'}} Wh</td>
              <div *ngIf="historic.start_source; then thenHistSource else elseHistSource"></div>
              <ng-template #thenHistSource><td class="d-none d-md-table-cell">{{historic.start_source}}<small *ngIf="historic.user!=''"> ({{historic.user}})</small></td></ng-template>
              <ng-template #elseHistSource><td class="d-none d-md-table-cell">{{historic.user}}</td></ng-template>
              <td class="d-none d-md-table-cell">{{historic.end_source}}</td>
              <!-- <td class="d-none d-md-table-cell">{{historic.user}}</td> -->
            </tr>
          </tbody>
        </table>
      </div>

  </div>
