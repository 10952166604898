<div style="height: fit-content ; min-height: 850px; width: 100%; ">

  <div class="d-flex justify-content-center " *ngIf="loading">
    <div class="spinner-border  " role="status" style="height: 100px; width: 100px; margin: 100px;">
      <span class="visually-hidden"></span>
    </div>
  </div>

  <div class="card mt-4 ms-3 me-3">
    <div *ngIf="!loading" class="accordion accordion-flush  mt-4" >
      <div class="pl-4 pr-3 mb-2 mt-2 ">
        <div class="d-flex  align-items-center" *ngIf="data.subgroups != 0">
          <div style="flex: 1"> {{ "subgroups" | translate }}</div>
          <div class="divider ml-2"></div>
        </div>
        <div class="" *ngFor="let subgrupo of data.subgroups; let i = index">
          <h2 class="">
            <button class="item  d-flex  align-items-center ms-2 me-2"
              (click)="getLinkedDevices(subgrupo.alias)">
              <span style="width: 35%;" class="  d-flex  align-items-center ps-4 ">
                {{ subgrupo.name }}
              </span>


              <span style="width: 55%;" class="  d-flex  align-items-center">{{ subgrupo.description }}</span>
              <span style="width: 10%;" class=" d-flex  align-items-center">
                <button class="button-main-group text-primary" (click)="infoGroup(subgrupo)">
                  <i class="far fa-eye"></i>
                </button>
                <button class="button-main-group text-primary" (click)="detailGroup(subgrupo)">
                  <i class="fa fa-gear"></i>
                </button>
              </span>
            </button>
          </h2>
        </div>
      </div>
    </div>


    <div class="pl-4 pr-3 mb-2 mt-2 " *ngIf="!loading">
      <div class="d-flex  align-items-center">
        <div style="flex: 1"> {{ "devices" | translate }}</div>
        <div class="divider ml-2"></div>
      </div>
      <div class="pl-4 pr-4 pt-2 " style="width: 100%;">

        <div class="" style="width: 100%;" >
          <table mat-table [dataSource]="dataSource" matSort style="width: 100%;">
            <!-- Tipo -->
            <ng-container matColumnDef="icon">
              <th mat-header-cell *matHeaderCellDef (click)="sortTable('icon')" class="text-center">
                {{'type'|translate}}
                <span class="sort-icon">{{ sortField === 'icon' ? (sortDirection === 'asc' ? '▲' : '▼') : '' }}</span>
              </th>
              <td mat-cell *matCellDef="let device" (click)="goToDevice(device.serial)" style="cursor: pointer;" class="d-flex justify-content-center p-1">
                <img *ngIf="device.serial.includes('EVVC3')" src="./../../../../assets/img/orbis/uni.png" alt="" style="height: 45px;">
                <img *ngIf="device.serial.includes('EVVC4')" src="./../../../../assets/img/orbis/combiplus.png" alt="" style="height: 45px;">
                <img *ngIf="device.serial.includes('EVVC5')" src="./../../../../assets/img/orbis/city+2.png" alt="" style="height: 45px;">
                <img *ngIf="device.serial.includes('EVVC1')" src="./../../../../assets/img/orbis/combi.png" alt="" style="height: 45px;">
                <img *ngIf="device.serial.includes('EVVC6')" src="./../../../../assets/img/orbis/exo.png" alt="" style="height: 40px;">
              </td>
            </ng-container>

            <!-- Estado -->
            <ng-container matColumnDef="estado">
              <th mat-header-cell *matHeaderCellDef      (click)="sortTable('estado')">
                 {{'status'|translate}}
                 <span  class="sort-icon">{{ sortField === 'estado' ? (sortDirection === 'asc' ? '▲' : '▼') : '' }}</span>
              </th>


              <td mat-cell *matCellDef="let device" (click)="goToDevice(device.serial)" style="cursor: pointer;" >
                <span *ngIf="device.alive === 1" class="badge badge-success">
                  <i class="fas fa-wifi"></i>
                </span>
                <span *ngIf="device.alive === 0" class="badge badge-danger">
                  <i class="fas fa-wifi"></i>
                </span>
              </td>
            </ng-container>

            <!-- Nombre -->
            <ng-container matColumnDef="nombre">
              <th mat-header-cell *matHeaderCellDef     (click)="sortTable('name')">
                 {{'name'|translate}}
                 <span class="sort-icon">{{ sortField === 'name' ? (sortDirection === 'asc' ? '▲' : '▼') : '' }}</span>
              </th>
              <td mat-cell *matCellDef="let device; let i = index">
                <div class="row">
                  <input class="span-like-input fs-6 "  placeholder="{{device.serial}}" [(ngModel)]="device.name" (blur)="changeName( device.serial ,device.name)"  >
                  <small class="p-0 " style="color: #006272;" >
                    <i class="fas fa-charging-station pe-1" ></i>
                    {{device.serial}}
                  </small>
                </div>
              </td>
            </ng-container>

            <!-- Rol -->
            <ng-container matColumnDef="rol"  >
              <th mat-header-cell *matHeaderCellDef>  {{'role'|translate}}  </th>
              <td mat-cell *matCellDef="let device" (click)="goToSettings(device.serial, 'linkage')" style="cursor: pointer;" >
                <span *ngIf="device.role === 'all'"><i class="far fa-edit"></i></span>
                <span *ngIf="device.role === 'owner'"><i class="fas fa-user-shield"></i></span>
                <span *ngIf="device.role === 'write'"><i class="fas fa-pen"></i></span>
                <span *ngIf="device.role === 'read'"><i class="fas fa-book-open"></i></span>
              </td>
            </ng-container>

            <!-- Firmware -->
            <ng-container matColumnDef="firmware">
              <th mat-header-cell *matHeaderCellDef> Firmware </th>
              <td mat-cell *matCellDef="let device" (click)="goToSettings(device.serial, 'fwupdate')" style="cursor: pointer;" >
                <span class="badge badge-grey">{{ device.firmware_version_name }}</span>
              </td>
            </ng-container>
            <!-- Toma1 -->
            <ng-container matColumnDef="toma1">
              <th mat-header-cell *matHeaderCellDef> {{'out'|translate}} 1 </th>
              <td mat-cell *matCellDef="let device" (click)="goToDevice(device.serial)" style="cursor: pointer;" >
                <span>
                  <span *ngIf="device?.evsmData && device?.evsmData[0]?.type== 'mennekes_base'"> <img
                      src="./../../../../assets/img/orbis/baseT2.svg" alt="" style="height: 25px; width: 25px; "> </span>
                  <span *ngIf="device?.evsmData && device?.evsmData[0]?.type== 'mennekes'"> <img
                      src="./../../../../assets/img/orbis/mangueraT2.svg" alt="" style="height: 25px; width: 25px; ">
                  </span>
                  <span *ngIf="device?.evsmData && device?.evsmData[0]?.type== 'schuko'"> <img
                      src="./../../../../assets/img/orbis/schuko.svg" alt="" style="height: 25px; width: 25px;"> </span>
                  <span *ngIf="device?.evsmData"class="ms-2 pe-1"
                  [ngClass]="{
                    'badge badge-success': device?.evsmData[0]?.data?.stat?.state === 1,
                    'badge badge-primary': device?.evsmData[0]?.data?.stat?.state >= 3 && device?.evsmData[0]?.data?.stat?.state <= 8,
                    'badge badge-danger': device?.evsmData[0]?.data?.stat?.state === 31 || device?.evsmData[0]?.data?.stat?.state === 9
                  }"
                  >
                    {{device?.evsmData[0]?.data?.stat?.state}}-{{getTranslatedText(allStates[device?.evsmData[0]?.data?.stat?.state]?.text)}}
                  </span>
                </span>
              </td>
            </ng-container>


            <!-- Toma 2-->
            <ng-container matColumnDef="toma2">
              <th mat-header-cell *matHeaderCellDef> {{'out'|translate}} 2 </th>
              <td mat-cell *matCellDef="let device" (click)="goToDevice(device.serial)" style="cursor: pointer;" >
                <span>
                  <span *ngIf="device?.evsmData && device?.evsmData[1]?.type == 'mennekes'"> <img
                      src="./../../../../assets/img/orbis/mangueraT2.svg" alt="" style="height: 25px; width: 25px;"></span>
                  <span *ngIf="device?.evsmData && device?.evsmData[1]?.type == 'mennekes_base'"> <img
                      src="./../../../../assets/img/orbis/baseT2.svg" alt="" style="height: 25px; width: 25px;"> </span>
                  <span *ngIf="device?.evsmData && device?.evsmData[1]?.type == 'schuko'"> <img
                      src="./../../../../assets/img/orbis/schuko.svg" alt="" style="height: 25px; width: 25px;"></span>
                  <span *ngIf="device?.evsmData && device?.evsmData[1]" class="ms-2 pe-1"
                  [ngClass]="{
                    'badge badge-success': device?.evsmData[1]?.data?.stat?.state === 1,
                    'badge badge-primary': device?.evsmData[1]?.data?.stat?.state >= 3 && device?.evsmData[1]?.data?.stat?.state <= 8,
                    'badge badge-danger': device?.evsmData[1]?.data?.stat?.state === 31 || device?.evsmData[1]?.data?.stat?.state === 9
                  }"
                  >
                    {{device?.evsmData[1]?.data?.stat?.state}}-{{getTranslatedText(allStates[device?.evsmData[1]?.data?.stat?.state]?.text)}}
                  </span>
                </span>
              </td>
            </ng-container>

            <!-- Potencia -->
            <ng-container matColumnDef="potencia">
              <th mat-header-cell *matHeaderCellDef> {{ 'power' | translate }} </th>
              <td mat-cell *matCellDef="let device" (click)="goToSettings(device.serial, 'power')" style="cursor: pointer;">
                <span>
                  <i class="fas fa-bolt fs-5"></i>&nbsp;
                  <span
                    class="badge fs-6"
                    [ngClass]="{
                      'badge-primary': !(device?.spl?.cfg?.splMode !== 0 && device?.spl?.cfg?.splMode === 2),
                      'badge-light': device?.spl?.cfg?.splMode !== 0 && device?.spl?.cfg?.splMode === 2
                    }">
                    {{ device?.modulator?.cfg?.limitPower }} w
                  </span>
                </span>
              </td>
            </ng-container>


            <!-- SPL -->
            <ng-container matColumnDef="spl">
              <th mat-header-cell *matHeaderCellDef> SPL </th>
              <td mat-cell *matCellDef="let device">
                <span *ngIf="device?.spl?.cfg?.splMode != 0 && device?.spl?.cfg?.splMode == 1"  (click)="  goToSPL(device.serial)" style="cursor: pointer;"
                [matTooltip]="'master' | translate  " matTooltipPosition="above"> <img
                  src="./../../../../assets/img/orbis/Maestro.svg" alt=""> </span>
              <span *ngIf="device?.spl?.cfg?.splMode != 0 && device?.spl?.cfg?.splMode == 2"   (click)="  goToSPL(device.serial)" style="cursor: pointer;"
                [matTooltip]="'satellite' | translate  " matTooltipPosition="above"> <img
                  src="./../../../../assets/img/orbis/Satelite.svg" alt=""> </span>
              </td>
            </ng-container>

            <!-- Botón Configuración -->
            <ng-container matColumnDef="config">
              <th mat-header-cell *matHeaderCellDef> {{'configuration'|translate}} </th>
              <td mat-cell *matCellDef="let device"  (click)="goToSettings(device.serial, 'power')">
                <span class="fs-5" [ngClass]="device.solar?.cfg?.enabled ? 'activo' : 'desactivo' "
                [matTooltip]="'solar_cfg' | translate  " matTooltipPosition="above">
                <i class="fas fa-sun"></i>
              </span>

              <span (click)="goToSettings(device.serial, 'chargeScheduler')"  >
                <span class="m-1 fs-5"
                  [ngClass]="device.scheduler?.stat?.active ? 'activo' : 'desactivo' "
                  [matTooltip]="'scheduler' | translate  " matTooltipPosition="above">
                  <i class="fas fa-clock"></i> </span>
                <span class="m-1 fs-5" *ngIf="device.scheduler2?.stat"
                  [ngClass]="device.scheduler2?.stat?.active ? 'activo' : 'desactivo' "
                  [matTooltip]="'scheduler' | translate  " matTooltipPosition="above">
                  <i class="fas fa-clock"></i> </span>
                <span class="m-1 fs-5 hidden" *ngIf="!device.scheduler2?.stat"> <i class="fas fa-clock  "></i> </span>
              </span>
              <span class="m-1 fs-5" (click)="goToSettings(device.serial, 'rfid')"
                [ngClass]=" device.rfid?.cfg?.rfid &&  device.rfid?.cfg?.enabled ? 'activo' : 'desactivo' "
                [matTooltip]="'rfid' | translate  " matTooltipPosition="above">
                <i class="fas fa-address-card"></i></span>
              <span class="m-1 fs-5" (click)="goToSettings(device.serial, 'rfid')"
                [ngClass]="!device.rfid?.cfg?.rfid &&  device.rfid?.cfg?.enabled ? 'activo' : 'desactivo' "
                [matTooltip]="'touch' | translate  " matTooltipPosition="above">
                <i class="fas fa-hand-pointer"></i></span>
              <span class="m-1 fs-5" (click)="goToSettings(device.serial, 'powerScheduler')"
                [ngClass]="device.scheduler?.cfg?.tasks && (device.scheduler?.cfg?.tasks != '[]' )&&  checkTasks(device.scheduler?.cfg?.tasks) ? 'activo' : 'desactivo' "
                [matTooltip]="'power_scheduler' | translate  " matTooltipPosition="above">
                <i class="fas fa-tachometer-alt"></i> </span>
              <span class="m-1 fw-bold" [ngClass]="device.ocpp?.stat?.wsConnected == 1 ? 'activo' : 'desactivo' " (click)="goToSettings(device.serial, 'ocpp')"
                [matTooltip]="'connection' | translate  " matTooltipPosition="above"> ocpp </span>
              </td>
            </ng-container>

           <!-- Acciones -->
           <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef  >    </th>
            <td mat-cell *matCellDef="let device; let i = index">
              <span class="status-ok"    *ngIf="status[i]?.status ==1 && showStatus"> <i class="far fa-check-circle"></i>  </span>
              <span class="status-error" *ngIf="status[i]?.status ==2 && showStatus"> <i  class="far fa-times-circle"></i> </span>
            </td>
          </ng-container>

            <!-- Headers -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <!-- Filas -->
            <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToDevice(row.serial)"></tr> -->
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
          </table>

        </div>
        <br>
        <div> {{'devicesInGroup'|translate}}:  {{data.devices.length}}</div>
      </div>

    </div>
  </div>

</div>
