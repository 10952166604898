import { ThisReceiver } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  @Input()
  allSettings=false;

  body={
    apPasswd: '',
    apPassNew: '',
    apPassNew2: '',
    apEnabled : true
  }

  // originalData: any= {...this.body};
  originalData: any;
  changesMade=false;
  successMessage: boolean = false;
  error: string = '';
  modalLoad: NgbModalRef;
  device='';

  constructor(public _device: DeviceService, private _aRoute: ActivatedRoute, private _modal: ModalService) { }

  ngOnInit(): void {
    this._aRoute.parent?.parent?.params
    .subscribe((params: Params) => {this.device = params['serial'];

      this._device.getModuleInfo(this.device, 'network')
      .subscribe( data => {
        this.body.apEnabled = data.cfg.apEnabled;
        this.originalData = {...this.body};
      });
    });

  }

  save(){
    this.modalLoad=this._modal.open(LoadingPopupComponent);
    this._device.setModuleCfg(this.device, this.body, 'network')
      .subscribe(data=>{
        console.log(data);
        this.modalLoad.close();
        this.successMessage=true;
        setTimeout(() => {
          this.successMessage=false;
        }, 10000);
      }, err=>{
        console.error(err)
        this.error=err.error.message;
        setTimeout(() => {
          this.error='';
        }, 10000);
        this.modalLoad.close();
      })
  }

}
