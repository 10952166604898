<div tabindex="-1" role="dialog" aria-hidden="true">
    <div role="document">
        <div>
            <div class="modal-header">
                <h5 class="modal-title">{{"mainMeter" | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!-- Shelly -->
            <div *ngIf="type==3" class="modal-body m-3">
                <!-- ShellyMainIp -->
                <div class="form-group row">
                    <div class="col-12 d-flex align-items-center">
                        <label class="col-form-label">{{"IPShelly" | translate}} (P1):</label>
                    </div>
                    <div class="col-12 d-flex">
                        <input type="text" class="form-control" name="shellyMainIp" [(ngModel)]="shellyMainIp">
                    </div>
                </div>
                <!-- ShellyMainModel -->
                <div class="form-group row">
                    <div class="col-12 d-flex align-items-center">
                        <label class="col-form-label">{{"shellyModel" | translate}}:</label>
                    </div>
                    <div class="col-12 d-flex">
                        <select class="form-select form-select-group" id="shellyMainModel" name="shellyMainModel" [(ngModel)]="shellyMainModel">
                            <option *ngFor="let item of shellyMainModels" [ngValue]="item.value">{{item.label}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <!-- Solar Inverter -->
            <div *ngIf="type==4" class="modal-body m-3">
                <!-- InversorModel -->
                <div class="form-group row">
                    <div class="col-12 d-flex align-items-center">
                        <label class="col-form-label">{{"inversorModel" | translate}}:</label>
                        <i class="fas fa-info-circle pl-2" [matTooltip]="'inversorModelText' | translate"></i>
                    </div>
                    <div class="col-12 d-flex">
                        <select class="form-select form-select-group" id="inverterModel" name="inverterModel" [(ngModel)]="inverterModel" (change)="changeModel()">
                            <option *ngFor="let item of inverterModels" [value]="item.value">{{item.label}}</option>
                        </select>
                    </div>
                </div>
                <!-- InverterIp -->
                <div class="form-group row">
                    <div class="col-12 d-flex align-items-center">
                        <label class="col-form-label">{{"solarInverterIpAddress" | translate}}:</label>
                        <i class="fas fa-info-circle pl-2" [matTooltip]="'inversorIpText' | translate"></i>
                    </div>
                    <div class="col-12 d-flex">
                        <input type="text" class="form-control" name="inverterIp" [(ngModel)]="inverterIp">
                    </div>
                </div>
                <!-- InverterPort -->
                <div class="form-group row">
                    <div class="col-12 d-flex align-items-center">
                        <label class="col-form-label">{{"solarInverterPort" | translate}}:</label>
                        <i class="fas fa-info-circle pl-2" [matTooltip]="'inversorPortText' | translate"></i>
                    </div>
                    <div class="col-12 d-flex">
                        <input type="number" class="form-control" name="inverterPort" [(ngModel)]="inverterPort">
                    </div>
                </div>
                <!-- BatteryEnabled -->
                <div class="form-group row">
                    <div class="col-12 d-flex align-items-center">
                        <label class="col-form-label">{{"batteryInstalationDesc" | translate}}:</label>
                        <label class="switch ml-3">
                            <input type="checkbox" [checked]="inverterEnabledBatt" (click)="inverterEnabledBatt === 0 ? inverterEnabledBatt = 1 : inverterEnabledBatt = 0;" name="automaticSwitch">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>

                <div class="accordion " id="acoordionUnitIds" *ngIf="versionCompatible && inverterModel != 1  && inverterModel != 2">
                  <div class="accordion-item ">
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button collapsed text-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        {{"advanced" | translate}}
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#acoordionUnitIds">
                      <div class="accordion-body">
                        <h3  class="text-primary"> UnitIds</h3>
                        <div class="form-group row" *ngFor="let unitId of unitIds;let ind=index">
                          <div class="col-xl-5 col-md-6 col-sm-7 d-flex align-items-center">
                              <label class="col-form-label text-sm-right"  > {{ unitId.name | translate }}: </label>
                          </div>
                              <div class="col-xl-3 col-sm-3 d-flex"  >
                                  <input type="number" class="form-control form-control-group" name="extraUnitIds[0]" [placeholder]="unitId.placeHolder"
                                  [(ngModel)]="extraUnitIds[ind] "  min="0" max="247" >
                              </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
              <!-- P1 Wifi -->
            <div *ngIf="type==6" class="modal-body m-3">
                <!-- P1WifiMainIp -->
                <div class="form-group row">
                    <div class="col-12 d-flex align-items-center">
                        <label class="col-form-label">{{"IPP1" | translate}}:</label>
                    </div>
                    <div class="col-12 d-flex">
                        <input type="text" class="form-control" name="p1WifiMainIp" placeholder="0.0.0.0" [(ngModel)]="p1WifiMainIp " >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
