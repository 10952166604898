<div class="my-5 container" *ngIf="mode === 'imp'">
  <div class="row justify-content-center">
    <div class="col-8">
      <input class="form-control" type="file" (change)="onChange($event)" #input accept=".xls, .xlsx, .csv">
    </div>
    <div class="col-2">
      <button (click)="confirm()" [disabled]="noFile" class="btn btn-secondary">{{"upload" | translate}}</button>
    </div>
  </div>
  <div *ngIf="noFile" class="row justify-content-center mt-3">
    <div class="col-8">
      <h5 class="text-danger">{{"field_required" | translate}}</h5>
    </div>
  </div>
</div>


<div class="my-5 container" *ngIf="mode === 'exp'">
  <div class="row justify-content-center">
    <div class="col-4">
      <button type="button" class="btn btn-secondary" (click)="downloadFiles('xls')">
        {{"download" | translate}} .xls
      </button>
    </div>
    <div class="col-4">
      <button type="button" class="btn btn-secondary" (click)="downloadFiles('csv')">
       {{"download" | translate}} .csv
      </button>
    </div>
  </div>
</div>
