<div style="height: fit-content ; min-height: 850px; width: 100%; ">


  <div class="ps-5" style="background-color: #006272; min-height: 100px;">

    <nav aria-label="breadcrumb" class="ps-1">
      <ol class="breadcrumb" style="width: fit-content;">
        <li *ngFor="let item of breadCrumbs; let i = index" class="breadcrumb-item" style="padding: 10px;"
            [class.active]="i === breadCrumbs.length - 1">

          <!-- Si no es el último elemento en la ruta -->
          <div *ngIf="i !== breadCrumbs.length - 1" style="cursor: pointer;" (click)="updateBreadCrumbs(item.alias, i)">
            <!-- Si el nombre es 'home' muestra el ícono, de lo contrario muestra el nombre -->
            <ng-container *ngIf="item.name === 'home'; else normalBreadcrumb">
              <i class="fas fa-home text-white" (click)="home()"></i>
            </ng-container>
            <ng-template #normalBreadcrumb>
              <div class="text-white">{{ item.name }}</div>
            </ng-template>
          </div>

          <!-- Si es el último elemento en la ruta -->
          <span *ngIf="i === breadCrumbs.length - 1" class="text-white" (click)="item.name === 'home' ? home() : null">
            <i *ngIf="item.name === 'home'" class="fas fa-home"></i>
            <span *ngIf="item.name !== 'home'">{{ item.name }}</span>
          </span>
        </li>
      </ol>
    </nav>



    <div class="m-1  d-flex justify-content-between align-items-center " >
      <div class="text-white fs-4">
        {{data?.name}}&nbsp;

        <span *ngIf="data.role == 'all'"> <i class="far fa-edit"></i> </span>
        <span *ngIf="data.role == 'owner'"> <i class="fas fa-user-shield"></i> </span>
        <span *ngIf="data.role == 'write'"> <i class="fas fa-pen"></i> </span>
        <span *ngIf="data.role == 'read'"> <i class="fas fa-book-open"></i> </span>
      </div>
      <div class="d-flex justify-content-between align-items-center pe-3">
        <button class="button-main-group" (click)="viewGroup(data.alias)">
          <i class="far fa-chart-bar"></i>
        </button>
        <button class="button-main-group" (click)="goToSPL(data.alias)"><i>SPL</i></button>
        <button class="button-main-group " data-bs-toggle="modal" data-bs-target="#configmodal"
          (click)="openModal();">
          <i class="fas fa-sliders-h"></i>
        </button>
        <button class="button-main-group  " (click)="goTodetailGroup(data.alias)"> <i class="fa fa-gear"></i>
        </button>
      </div>
    </div>

  </div>




  <div class="d-flex justify-content-center " *ngIf="loading">
    <div class="spinner-border  " role="status" style="height: 100px; width: 100px; margin: 100px;">
      <span class="visually-hidden"></span>
    </div>
  </div>

  <div class="card mt-4 ms-3 me-3">
    <div *ngIf="!loading" class="accordion accordion-flush  mt-4" id="accordion ">
      <div class="pl-4 pr-3 mb-2 mt-2 ">
        <div class="d-flex  align-items-center" *ngIf="data.subgroups != 0">
          <div style="flex: 1"> {{ "subgroups" | translate }}</div>
          <div class="divider ml-2"></div>
        </div>
        <div class="" *ngFor="let subgrupo of data.subgroups; let i = index">
          <h2 class="">
            <button class="item  d-flex  align-items-center ms-2 me-2"

              type="button" data-bs-toggle="collapse"
              [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i"
              (click)="getLinkedDevices(subgrupo.alias)">
              <span style="width: 35%;" class="  d-flex  align-items-center ps-4 ">
                {{ subgrupo.name }}
              </span>


              <span style="width: 55%;" class="  d-flex  align-items-center">{{ subgrupo.description }}</span>
              <span style="width: 10%;" class=" d-flex  align-items-center">
                <button class="button-main-group text-primary" (click)="infoGroup(subgrupo)">
                  <i class="far fa-eye"></i>
                </button>
                <button class="button-main-group text-primary" (click)="detailGroup(subgrupo)">
                  <i class="fa fa-gear"></i>
                </button>
              </span>
            </button>
          </h2>

          <div [id]="'collapse' + i" class="accordion-collapse collapse m-4 ps-3 pe-3" data-bs-parent="#accordion">
            <div class="accordion-body   devices">
              <h5 *ngIf="linkedDevices.length === 0" style="text-align: center;"> {{"noSubgroups" | translate}}</h5>
              <div *ngFor="let device of linkedDevices; let x = index">
                <div *ngIf="linkedDevices.length > 0"

                  class="item ">
                  {{ device.serial }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="pl-4 pr-3 mb-2 mt-2 " *ngIf="!loading">
      <div class="d-flex  align-items-center">
        <div style="flex: 1"> {{ "devices" | translate }}</div>
        <div class="divider ml-2"></div>
      </div>
      <div class="pl-4 pr-4 pt-2 ">
        <table class="tabla text-muted fst-italic">
          <tbody>
            <tr *ngFor="let device of  data.devices; let x =index" (click)="goToDevice(device.serial)">
              <!-- <td [ngClass]="{'slave': device.spl.cfg.splMode != 0 && device.spl.cfg.splMode == 2}" > -->
              <td>
                <img src="./../../../assets/img/orbis/uni.png" style="height: 45px;" alt="" srcset=""
                  *ngIf="device.serial.includes('EVVC3')">
                <img src="./../../../assets/img/orbis/combiplus.png" style="height: 45px;" alt="" srcset=""
                  *ngIf="device.serial.includes('EVVC4')">
                <img src="./../../../assets/img/orbis/city+2.png" style="height: 45px; padding-left: 13px;" alt=""
                  srcset="" *ngIf="device.serial.includes('EVVC5')">
                <img src="./../../../assets/img/orbis/combi.png" style="height: 45px;" alt="" srcset=""
                  *ngIf="device.serial.includes('EVVC1')">
                <img src="./../../../assets/img/orbis/exo.png" style="height: 40px;  padding-left: 4px;" alt=""
                  srcset="" *ngIf="device.serial.includes('EVVC6')">

              </td>
              <td>
                <span class="badge badge-success" *ngIf="device?.deviceInfo?.alive ==1"> <i class="fas fa-wifi"></i>
                </span>
                <span class="badge badge-danger" *ngIf="device?.deviceInfo?.alive ==0"> <i class="fas fa-wifi"></i>
                </span>
                <!-- <td> {{device.potencia}}w</td> -->
              </td>
              <td> <span class="fw-bold">
                  {{device.serial}}

                </span>
              </td>
              <td> {{device.deviceInfo.name}}</td>
              <td>
                <span *ngIf="device?.role == 'all'"> <i class="far fa-edit"></i> </span>
                <span *ngIf="device?.role == 'owner'"> <i class="fas fa-user-shield"></i> </span>
                <span *ngIf="device?.role == 'write'"> <i class="fas fa-pen"></i> </span>
                <span *ngIf="device?.role == 'read'"> <i class="fas fa-book-open"></i> </span>
              </td>
              <td> fw <span class=" badge badge-grey">{{device?.deviceInfo?.firmware_version_name}} </span></td>

              <td>
                <span>
                  <span *ngIf="device?.element && device?.element[0]?.data?.type== 'mennekes_base'"> <img
                      src="./../../../assets/img/orbis/baseT2.svg" alt="" style="height: 25px; width: 25px; "> </span>
                  <span *ngIf="device?.element && device?.element[0]?.data?.type== 'mennekes'"> <img
                      src="./../../../assets/img/orbis/mangueraT2.svg" alt="" style="height: 25px; width: 25px; ">
                  </span>
                  <span *ngIf="device?.element && device?.element[0]?.data?.type== 'schuko'"> <img
                      src="./../../../assets/img/orbis/schuko.svg" alt="" style="height: 25px; width: 25px;"> </span>
                  <span *ngIf="device?.evsmData" class="ms-2 badge badge-success">
                    {{device?.evsmData[0]?.data?.stat?.state}}-{{getTranslatedText(allStates[device?.evsmData[0]?.data?.stat?.state]?.text)}}
                  </span>
                </span>
              </td>
              <td>
                <span>
                  <span *ngIf="device?.element && device?.element[1]?.data?.type == 'mennekes'"> <img
                      src="./../../../assets/img/orbis/mangueraT2.svg" alt="" style="height: 25px; width: 25px;"></span>
                  <span *ngIf="device?.element && device?.element[1]?.data?.type == 'mennekes_base'"> <img
                      src="./../../../assets/img/orbis/baseT2.svg" alt="" style="height: 25px; width: 25px;"> </span>
                  <span *ngIf="device?.element && device?.element[1]?.data?.type == 'schuko'"> <img
                      src="./../../../assets/img/orbis/schuko.svg" alt="" style="height: 25px; width: 25px;"></span>
                  <span *ngIf="device?.element[1]" class="ms-2 badge badge-success">
                    {{device?.evsmData[1]?.data?.stat?.state}}-{{getTranslatedText(allStates[device?.evsmData[1]?.data?.stat?.state]?.text)}}
                  </span>
                </span>
              </td>
              <td class="m-0 p-0">
                <span> <i class="fas fa-bolt fs-5"></i>&nbsp; <span class="badge badge-primary fs-6"> {{
                    device?.modulator?.cfg?.limitPower }} w</span></span>
              </td>
              <td>
                <span *ngIf="device.spl.cfg.splMode != 0 && device.spl.cfg.splMode == 1"
                  [matTooltip]="'master' | translate  " matTooltipPosition="above"> <img
                    src="./../../../assets/img/orbis/Maestro.svg" alt=""> </span>
                <span *ngIf="device.spl.cfg.splMode != 0 && device.spl.cfg.splMode == 2"
                  [matTooltip]="'satellite' | translate  " matTooltipPosition="above"> <img
                    src="./../../../assets/img/orbis/Satelite.svg" alt=""> </span>
              </td>
              <td class="m-0 p-0">
                <!-- <span class=" fs-5 "     [ngClass]="device.solar.cfg.enabled ? 'solar' : 'no-solar' "  matTooltip="Solar" matTooltipPosition="above" >  <i class="fas fa-sun"></i> </span> -->

                <span class="fs-5" [ngClass]="device.solar.cfg.enabled ? 'activo' : 'desactivo' "
                  [matTooltip]="'solar_cfg' | translate  " matTooltipPosition="above">
                  <i class="fas fa-sun"></i>
                </span>

                <span>
                  <span class="m-1 fs-5" [ngClass]="device.scheduler.stat.active ? 'activo' : 'desactivo' "
                    [matTooltip]="'scheduler' | translate  " matTooltipPosition="above">
                    <i class="fas fa-clock"></i> </span>
                  <span class="m-1 fs-5" *ngIf="device.scheduler2.stat"
                    [ngClass]="device.scheduler2.stat.active ? 'activo' : 'desactivo' "
                    [matTooltip]="'scheduler' | translate  " matTooltipPosition="above">
                    <i class="fas fa-clock"></i> </span>
                  <span class="m-1 fs-5 hidden" *ngIf="!device.scheduler2.stat"> <i class="fas fa-clock  "></i> </span>
                </span>
                <span class="m-1 fs-5"
                  [ngClass]=" device.rfid.cfg.rfid &&  device.rfid.cfg.enabled ? 'activo' : 'desactivo' "
                  [matTooltip]="'rfid' | translate  " matTooltipPosition="above">
                  <i class="fas fa-address-card"></i></span>
                <span class="m-1 fs-5"
                  [ngClass]="!device.rfid.cfg.rfid &&  device.rfid.cfg.enabled ? 'activo' : 'desactivo' "
                  [matTooltip]="'touch' | translate  " matTooltipPosition="above">
                  <i class="fas fa-hand-pointer"></i></span>
                <span class="m-1 fs-5"
                  [ngClass]="device.scheduler.cfg.tasks && device.scheduler.cfg.tasks != '[]'? 'activo' : 'desactivo' "
                  [matTooltip]="'power_scheduler' | translate  " matTooltipPosition="above">
                  <i class="fas fa-tachometer-alt"></i> </span>
                <span class="m-1 fw-bold" [ngClass]="device.ocpp?.stat?.wsConnected == 1 ? 'activo' : 'desactivo' "
                  [matTooltip]="'connection' | translate  " matTooltipPosition="above"> ocpp </span>
              </td>
              <td>
                <span class="status-ok" *ngIf="status[x]?.status ==1 && showStatus"> <i class="far fa-check-circle"></i>
                </span>
                <span class="status-error" *ngIf="status[x]?.status ==2 && showStatus"> <i
                    class="far fa-times-circle"></i> </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>

  <!-- MODAL PARA PROGRAMAR  TODOS LOS EQUIPOS DEL GRUPO -->
  <div class="modal fade " id="configmodal" tabindex="-1" aria-labelledby="powerModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content" style="min-width: 800px;">
        <div class="modal-header">
          <h1 class="modal-title fs-5 text-primary" id="powerModalLabel">{{ "settings" | translate}}</h1>
          <div class="d-flex justify-content-center text-center" style="width: 100%;">
            <h3 class="text-muted"> {{chooseCfg |uppercase }}</h3>
          </div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="  justify-content-center">
          <ul class="nav nav-tabs text-center ">
            <li class="nav-item" (click)="chooseCfg ='power'; cancel()">
              <a [ngClass]="chooseCfg == 'power' ? 'nav-link active' : 'nav-link'" style="width: 70px;"><i
                  class="fas fa-bolt tex"></i></a>
            </li>
            <li class="nav-item" (click)="chooseCfg ='reset'; cancel() ">
              <a [ngClass]="chooseCfg == 'reset' ? 'nav-link active' : 'nav-link'" style="width: 70px;"><i
                  class="fas fa-sync-alt"></i></a>
            </li>
            <li class="nav-item" (click)="chooseCfg ='ocpp'; cancel() ">
              <a [ngClass]="chooseCfg == 'ocpp' ? 'nav-link active' : 'nav-link'" style="width: 70px;"><i>OCPP</i></a>
            </li>
            <li class="nav-item" (click)="chooseCfg ='rfid'; cancel() ">
              <a [ngClass]="chooseCfg == 'rfid' ? 'nav-link active' : 'nav-link'"
                style="width: 70px; padding: 5px 16px;">
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="touch_app"></mat-icon>
              </a>
            </li>
            <li class="nav-item" (click)="chooseCfg ='cards'; cancel()">
              <a [ngClass]="chooseCfg == 'cards' ? 'nav-link active' : 'nav-link'" style="width: 70px;"><i
                  class="fas fa-id-card"></i></a>
            </li>
            <li class="nav-item" (click)="chooseCfg ='wifi'; cancel()">
              <a [ngClass]="chooseCfg == 'wifi' ? 'nav-link active' : 'nav-link'" style="width: 70px;"><i
                  class="fas fa-broadcast-tower"></i></a>
            </li>
            <li class="nav-item" (click)="chooseCfg ='password' ; cancel()">
              <a [ngClass]="chooseCfg == 'password' ? 'nav-link active' : 'nav-link'" style="width: 70px;"><i
                  class="fas fa-key"></i></a>
            </li>
          </ul>
        </div>

        <div class="modal-body">
          <div class="row mb-4">
            <div class="col d-flex justify-content-center">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" [(ngModel)]="selectAll" (change)="toggleAllDevices()"
                  id="checkAll">
                <label class="form-check-label text-primary fw-bold" for="checkAll"> {{"all" | translate | uppercase}}
                </label>
                <div *ngFor="let device of selectedDevices; let i = index">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="device.check" id="check{{i}}">
                  <label class="form-check-label" for="check{{i}}">
                    <span> {{ device.serial }}</span>
                  </label>
                </div>
              </div>
            </div>

            <!-- Instalación -->

            <div class="col-6 d-flex justify-content-center align-items-center" *ngIf="chooseCfg =='power'">
              <form class="d-flex justify-content-center">
                <div class="row">
                  <!-- <div class="form-group row" >
                          <div class="col-xl-4 col-md-6 col-sm-7 d-flex align-items-center">
                            <label class="col-form-label">{{"hiredPower" | translate}}:</label>
                          </div>
                          <div class="col-xl-8 col-sm-6">
                            <div class="d-flex">
                              <input type="number" class="form-control form-control-group" name="limitPowerAll"
                              [(ngModel)]="power">
                              <div class="input_right_data ">W</div>
                            </div>
                          </div>
                        </div> -->


                  <div class="form-group row">
                    <div class="col-12 d-flex align-items-center">
                      <label class="col-form-label">{{"limitPowerCharger" | translate}}:</label>
                      <i class="fas fa-info-circle pl-2" [matTooltip]="'limitPowerChargerText' | translate"></i>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-12 d-flex ">
                      <div class="d-flex">
                        <input type="number" class="form-control form-control-group" name="ampacity"
                          placeholder="0-32/64 A" [(ngModel)]="ampacity">
                        <div class="input_right_data">A</div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <!-- OCPP -->
            <div class="col-6 d-flex justify-content-center align-items-center" *ngIf="chooseCfg =='ocpp'">
              <form class="d-flex justify-content-center">
                <div class="row">
                  <div class="form-group row">
                    <label class="col-form-label col-sm-4 text-sm-right">Url</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" placeholder="Url" name="serverUrl" [(ngModel)]="url">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-sm-4 text-sm-right">{{"port" | translate}}</label>
                    <div class="col-sm-8">
                      <input type="number" class="form-control" [placeholder]="'port' | translate" name="port"
                        [(ngModel)]="port">
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <!--  RFID  -->
            <div class="col-6 d-flex justify-content-center align-items-center" *ngIf="chooseCfg =='rfid'">
              <form class="d-flex justify-content-center">
                <div class="row">
                  <div class="text-center ">
                    {{"rfidMode"|translate}}
                  </div>
                  <div class="d-flex w-80 m-auto button-group-container ">
                    <div class="col-4 text-center p-1 button-group" (click)="changeMode('touch')"
                      [ngClass]="{'bg-primary text-white' : rfidEnabled == 1 && rfidMode == 0 }">
                      {{"touch" | translate}}
                    </div>
                    <div class="col-4 text-center p-1 button-group" (click)="changeMode('rfid')"
                      [ngClass]="{'bg-primary text-white' : rfidEnabled == 1 && rfidMode == 1  }">
                      {{"rfid" | translate}}
                    </div>
                    <div class="col-4 text-center p-1 button-group" (click)="changeMode('off')"
                      [ngClass]="{'bg-primary text-white' : rfidEnabled == 0 }">
                      OFF
                    </div>
                  </div>
                  <div *ngIf="rfidEnabled===1  && rfidMode === 1" class=" mt-3 mb-2">
                    <h5 class="text-dark">{{"funcMode" | translate}}</h5>
                    <div class="form-group col-10 m-auto">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="familiar" (change)="eocMode='any' "
                          [(ngModel)]="eocMode" value="any">
                        <label class="form-check-label text-dark">
                          <b>{{"familiarMode" | translate}}</b>
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="enterprise" (change)="eocMode='own' "
                          [(ngModel)]="eocMode" value="own">
                        <label class="form-check-label text-dark">
                          <b>{{"corporativeMode" | translate}}</b>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <!-- CARDS RFID   -->
            <div class="col-6 d-flex justify-content-center align-items-center" *ngIf="chooseCfg =='cards'">
              <form class="d-flex justify-content-center">
                <div class="row">
                  <!-- <div class="row "> -->
                  <div class="d-flex justify-content-around mb-3">
                    <div class="btn-group mb-2 float-left" role="group" aria-label=""
                      (click)=" messageDelCards = true; cardsMode = 'delete'; confirm.cards = true">
                      <div class=" text-danger fs-5">
                        <i class="fas fa-trash-alt"></i> {{ "deleteAll" | translate}}
                      </div>
                    </div>
                  </div>
                  <div class="text-danger mt-2" *ngIf="messageDelCards"><i class="fas fa-exclamation-triangle"></i>
                    {{"deleteRfidAll" | translate }}
                  </div>
                  <div class="d-flex justify-content-center mt-2" *ngIf="!messageDelCards">
                    <input class="form-control form-control-sm" id="formFileSm" type="file"  #input accept=".xls, .xlsx, .csv"  (change)="onFileSelected($event)">
                  </div>

                  <!-- </div> -->
                </div>
              </form>
            </div>

            <!-- CONECTIVIDAD -->
            <div class="col-6 d-flex justify-content-center align-items-center" *ngIf="chooseCfg =='wifi'">
              <form class="d-flex justify-content-center">
                <div class="row">
                  <div class="form-group row">
                    <label class="col-form-label col-sm-4 text-sm-right">SSID</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" placeholder="SSID" name="ssid" [(ngModel)]="ssid">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-sm-4 text-sm-right">{{"password" | translate}}</label>
                    <div class="col-sm-8">
                      <input type="password" class="form-control" [placeholder]="'password' | translate" name="password"
                        [(ngModel)]="wifiPass">
                    </div>
                  </div>
                </div>
              </form>
            </div>


            <!-- PASSWORD -->
            <div class="col-6 d-flex justify-content-center align-items-center" *ngIf="chooseCfg =='password'">
              <form class="d-flex justify-content-center">
                <div class="row">
                  <div class="d-flex w-80 m-auto button-group-container  justify-content-center">
                    <div class="col-4 text-center p-1 button-group" (click)="accessPoint=1"
                      [ngClass]="{'bg-primary text-white' : accessPoint == 1 }">
                      {{"activated" | translate}}
                    </div>
                    <div class="col-4 text-center p-1 button-group" (click)="accessPoint=0"
                      [ngClass]="{'bg-primary text-white' : accessPoint == 0  }">
                      {{"disactivated" | translate}}
                    </div>
                  </div>
                  <div class="form-group row mt-5">
                    <label class="col-form-label col-sm-4 text-sm-right">{{"password_new" | translate}}</label>
                    <div class="col-sm-8">
                      <input type="password" class="form-control" name="apPassNew" [(ngModel)]="newPass"
                        [placeholder]="'password_new' | translate">
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <form class="d-flex justify-content-center" *ngIf="checkAnyFieldTrue() && cardsMode !='delete'">
              <div class="form-group row d-flex">
                <div class=" d-flex align-items-center">
                  <label class="col-form-label text-warning">{{ messageConfirm() | translate}}:</label>
                </div>
              </div>
            </form>

          </div>
          <div class="modal-footer">
            <button *ngIf="chooseCfg =='power' && !confirm.power" type="button" class="btn btn-primary"
              (click)="confirm.power= true">{{"send" | translate}}</button>
            <button *ngIf="chooseCfg =='reset' && !confirm.reset" type="button" class="btn btn-primary"
              (click)="confirm.reset= true">{{"send" | translate}}</button>
            <button *ngIf="chooseCfg =='ocpp'  && !confirm.ocpp" type="button" class="btn btn-primary"
              (click)="confirm.ocpp= true">{{"send" | translate}}</button>
            <button *ngIf="chooseCfg =='rfid'  && !confirm.rfid" type="button" class="btn btn-primary"
              (click)="confirm.rfid= true">{{"send" | translate}}</button>
            <button *ngIf="chooseCfg =='wifi'  && !confirm.wifi" type="button" class="btn btn-primary"
              (click)="confirm.wifi= true">{{"send" | translate}}</button>
            <button *ngIf="chooseCfg =='password' &&  !confirm.password" type="button" class="btn btn-primary"
              (click)="confirm.password= true">{{"send" | translate}}</button>
            <button *ngIf="chooseCfg =='cards' &&  !confirm.cards && cardsMode == 'upload' " type="button"
              class="btn btn-primary" (click)="confirm.cards= true">{{"send" | translate}}</button>
            <button *ngIf="confirm.power" type="button" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="setLimitPower()">{{"accept" | translate}}</button>
            <button *ngIf="confirm.reset" type="button" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="reset()">{{"accept" | translate}}</button>
            <button *ngIf="confirm.ocpp" type="button" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="ocpp()">{{"accept" | translate}}</button>
            <button *ngIf="confirm.rfid" type="button" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="setRFID()">{{"accept" | translate}}</button>
            <button *ngIf="confirm.wifi" type="button" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="setWifi()">{{"accept" | translate}}</button>
            <button *ngIf="confirm.password" type="button" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="setPassword()">{{"accept" | translate}}</button>
            <button *ngIf="confirm.cards " type="button" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="setCards(cardsMode)">{{"accept" | translate}}</button>
            <button *ngIf="checkAnyFieldTrue()" type="button" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="cancel()">{{"cancel" | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
