import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringify'
})
export class StringifyPipe implements PipeTransform {

  transform(value: any): any {
    console.log(typeof value)
    if(typeof value === 'object')
        return JSON.stringify(value)
    return value;
  }

}