import { Component, OnChanges, OnInit } from '@angular/core';
import { DeviceService } from 'src/app/services/devices/device.service';
import { UserService } from 'src/app/services/user/user.service';
import { Router } from '@angular/router';
import { ConnectorState } from 'src/assets/js/constants';
import { TranslateService } from '@ngx-translate/core';


import { DashboardService } from 'src/app/services/dashboard/dashboard.service';

import { forkJoin, of } from 'rxjs';
import { mergeMap, map, concatMap, catchError } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import calculoZoom from './calculoDistancias';




@Component({
  selector: 'dashboard-cmp',
  //moduleId: module.id,
  templateUrl: 'dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']


})

export class DashboardComponent implements OnInit {

  showDevices = false
  linkedDevices :any[] = []
  data: any[]=[];
  myZoom : number = 6
  view : 'groups' | 'devices';
  breadCrumb : string[] = ['home'];

  userName: string = '';
  userRole: string = '';
  userEmail: string = '';
  devices: any[] = [];
  selectedSetting: 'list' | 'map' | '';
  locations: any[] = [];
  position = {lat: 40.45987, lng: -3.691145  }
  showList: boolean = true;
  info: boolean = false;

  dataSource: any[] = [];
  search = '';
  selectDevice : 'viaris' | 'xeo' | 'others' | 'all' = 'viaris';
  // selectView : 'list' | 'map' = 'map';

  owners : any[] = [];

  map: any;
  infoWindow: any
  serial: string = ''
  indice: number;

  totalPower = 0;
  homePower = 0;
  fvPower = 0;
  evsePower = 0;
  battEnergy = 0;
  battPercent = 0;
  solarInfo: any;

  statesPermission = [5, 6, 7];
  permission: any[] = [];
  allStates = ConnectorState;
  connectorsState: any[] = [];
  connectors: any;
  type: any;
  elementInfo: any;

  loadingInfo: boolean = true;
  loading: boolean = true;
  animacion = 1;
  batteryCharging = false;

  divIcono = document.createElement('h5');
  icono ='<div class="text-center m-2 "><svg xmlns="http://www.w3.org/2000/svg" height="30px"  width="30px" fill="currentColor" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M96 0C60.7 0 32 28.7 32 64V448c-17.7 0-32 14.3-32 32s14.3 32 32 32H320c17.7 0 32-14.3 32-32s-14.3-32-32-32V304h16c22.1 0 40 17.9 40 40v32c0 39.8 32.2 72 72 72s72-32.2 72-72V252.3c32.5-10.2 56-40.5 56-76.3V144c0-8.8-7.2-16-16-16H544V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H480V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H432c-8.8 0-16 7.2-16 16v32c0 35.8 23.5 66.1 56 76.3V376c0 13.3-10.7 24-24 24s-24-10.7-24-24V344c0-48.6-39.4-88-88-88H320V64c0-35.3-28.7-64-64-64H96zM216.9 82.7c6 4 8.5 11.5 6.3 18.3l-25 74.9H256c6.7 0 12.7 4.2 15 10.4s.5 13.3-4.6 17.7l-112 96c-5.5 4.7-13.4 5.1-19.3 1.1s-8.5-11.5-6.3-18.3l25-74.9H96c-6.7 0-12.7-4.2-15-10.4s-.5-13.3 4.6-17.7l112-96c5.5-4.7 13.4-5.1 19.3-1.1z"/></svg></div> '
  divLoading = '<div class="text-center mb-1">  <div id="loading"  class="spinner-border text-primary spinner" role="status" ><span class="visually-hidden"></span></div> </div> '
  divTitle =document.createElement('h5')


  positionObservable

  constructor(  private _devices: DeviceService,
                private _user: UserService,
                private _router: Router,
                private _translate: TranslateService,
                private _dashboardService : DashboardService) {

                  _dashboardService.getLocation()
                    .subscribe (()=>{
                      if(this.selectedSetting === 'map' ){
                        this.initMap()
                      }
                    })
                 }

  ngOnInit() {

    this._dashboardService.resetBreadCrumbs();
    this._dashboardService.getModoDashboard(). subscribe ( data => { this.selectedSetting = data})

    console.log('valor inicial de selectedSetting', this.selectedSetting)

    if ( this.selectedSetting == ''){
      localStorage.getItem('dashboardOpt') === 'lista' ? (this.showList = true , this.selectedSetting = 'list' ):( this.showList = false , this.selectedSetting = 'map');
      this._dashboardService.setModoDashboard(this.selectedSetting);
    }

    if (this.selectedSetting == 'list'){
      this.showList = true;
    }

    if (this.selectedSetting == 'map'){
      this.showList = false;
    }


    this._dashboardService.getViewDashboard().subscribe( data => {this.view = data })

    this._user.getUserInfo().subscribe((userInfo) => {
      this.userEmail = userInfo.email || '';
      this.userName = userInfo.name || '';
      this.userRole = userInfo.role || '';


      this._devices.getGroups('onlyParents').pipe(
        // Encadenar las peticiones de los grupos padres
        concatMap(groupsParents => {
          const groupRequests = groupsParents.map(grupoPadre =>
            this._devices.getGroup(grupoPadre.alias).pipe(
              // Encadenar para obtener la información de cada dispositivo en el grupo padre
              concatMap(dataGrupoPadre => {
                if (dataGrupoPadre.devices.length > 0) {
                  const deviceRequests = dataGrupoPadre.devices.map(device =>
                    // Ejecutar ambas peticiones para cada dispositivo en paralelo
                    forkJoin({
                      deviceInfo: this._devices.getDeviceInfo(device.serial).pipe(  catchError(error => of({ error, message: 'Error fetching deviceInfo data' })) ),
                      evsmData: this._devices.getElementModule(device.serial, 'evsm').pipe(  catchError(error => of({ error, message: 'Error fetching evsmData data' })) ),
                      element: this._devices.getElemDevice(device.serial).pipe(  catchError(error => of({ error, message: 'Error fetching element data' })) )
                    }).pipe(
                      // Combinar los datos del dispositivo con los resultados de las peticiones
                      map(({ deviceInfo, evsmData, element }) => ({
                        ...device,           // Información original del dispositivo
                        deviceInfo,          // Información adicional del dispositivo
                        evsmData,            // Datos del módulo EVSM
                        element              // Datos de element
                      }))
                    )
                  );

                  // Esperar a que todas las llamadas a dispositivos se resuelvan antes de continuar
                  return forkJoin(deviceRequests).pipe(
                    map(updatedDevices => ({
                      ...dataGrupoPadre,
                      devices: updatedDevices // Sobrescribir los dispositivos con la nueva info
                    }))
                  );
                } else {
                  // Si no hay dispositivos, devolver el grupo padre tal cual
                  return of(dataGrupoPadre);
                }
              })
            )
          );
          // Esperar a que todas las peticiones de grupos padres terminen
          return forkJoin(groupRequests);
        })
      ).subscribe(
        updatedGroups => {
          this.data = updatedGroups;  // Guardar la información actualizada
          this.dataSource = this.data;
          this.dataSource.sort((a, b) => new Date(a.date_build).getTime() - new Date(b.date_build).getTime());
        },
        error => {
          console.error('Error fetching data:', error);
        }
      );
      this.getAllDevices()
    });
    setTimeout(() => {
      this.loading = !this.loading;
      if (this.showList == false)
        try {
          this.initMap();
        } catch (error) {
          alert('Error');
          this.showList = true;
        }
    }, 1900);
  }

  getAllDevices(){
    this._devices?.getDevicesByUser().subscribe((devices) => {
      if (devices) {
        this.devices = devices;
        if (this.devices.length == 1) this.goToDevice(this.devices[0].serial);
        this.geolocation()
        for (let index = 0; index < this.devices.length; index++) {
          this._devices.getModuleInfo(this.devices[index].serial, 'clock', "cfg").subscribe((data) => {
            data.cfg.geoloc = this.parseData(data.cfg.geoloc);
            this.locations.push({
              position: {
                lat: parseFloat(data.cfg.geoloc.coords[0]),
                lng: parseFloat(data.cfg.geoloc.coords[1])
              },
              title: this.devices[index].serial,
              alive: devices[index].alive,
              sub_type: devices[index].sub_type,
              firmware_version_name: devices[index].firmware_version_name
            })
          })
        }

        for (let index = 0; index < devices.length; index++) {
          this._devices.getDeviceInfo(devices[index].serial).subscribe((data) => {
            this.owners.push(data)
          })
        }
      }
    });
  }

  clickDevice(device) {
    this._devices?.setUserDevice(this.userEmail);
    setTimeout(() => {
      this._router.navigate(['/dashboard/device', device]);
    }, 200);
  }

  async initMap(): Promise<void> {
    const { Map, InfoWindow } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    const Places = await google.maps.importLibrary("places") as google.maps.PlacesLibrary;

    this.infoWindow = new InfoWindow();

    const input = document.getElementById("pac-input") as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);
    const button = document.getElementById("list") as HTMLInputElement;
    const buttonHome = document.getElementById('home') as HTMLInputElement;


 this._dashboardService.getLocation( ).subscribe( data => {
  this.positionObservable = data
    })
  this._dashboardService.getZoom( ).subscribe ( data => this.myZoom=data)

    const mapOptions = {
      // zoom: calculoZoom(this.locations),
      zoom:this.myZoom,
      // center: this.position,
      // center:  {lat: this.locations[0].position.lat , lng:  this.locations[0].position.lng },
      center:  {lat: this.positionObservable.lat , lng:  this.positionObservable.lng },
      mapId: 'mapsCargadoresDashboard',
      disableDefaultUI: true,
    };

    this.map = new Map(
      document.getElementById("map") as HTMLElement,
      mapOptions
    );

    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input); // Añade el input del Autocomplete al mapa
    this.map.addListener("bounds_changed", () => {
      searchBox.setBounds(this.map.getBounds() as google.maps.LatLngBounds);
    });

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (places?.length == 0) {
        return;
      }
      const bounds = new google.maps.LatLngBounds();
      places?.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      })
      this.map.fitBounds(bounds);
    })

    // botón para volver al listado
    // const button = document.getElementById("list") as HTMLInputElement;
    // button.addEventListener( 'click', ()=> {this.selectedSetting='list';  this.changeOption()} );
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(button);
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(buttonHome);
    // button.addEventListener( 'click', ()=> {this.selectedSetting='list';  this.changeOption()} );

    this.locations.forEach(({ position, title }, i) => {
      let glyphColor = "#FFFFFF"
      let background: string;
      this.locations[i].alive == 0 ? background = '#D90000' : background = '#39b690';

      const icon = document.createElement('div');
      icon.innerHTML = '<i class="fas fa-charging-station" style="font-size: 20px;"></i>';

      const pin = new PinElement({
        scale: 1.3,
        glyph : icon,
        glyphColor,
        background,
        borderColor: '#000000'
      });

      const marker = new AdvancedMarkerElement({
        position,
        map: this.map,
        title: `${title}`,
        content: pin.element
      });
      marker.addListener('click', () => {
        this.info = false;
        this.loadingInfo = true;
        this.divIcono.style.color = '#00778B'
        this.divIcono.innerHTML = this.divLoading
        this.infoWindow.close();
        this.infoWindow.setContent( this.divIcono);
        this.divTitle.innerHTML ='<div class="me-2" >' + title + '</div>'
        this.infoWindow.setHeaderContent(this.divTitle);
        this.infoWindow.open(marker.map, marker);
        this.infoWindow.open(marker);
        this.serial = title;
        this.indice = i;
        this.getData(this.serial);
      });
    });
  }

  parseData(data) {
    if (typeof data === "string") {
      return JSON.parse(data);
    } else { return data; }
  }


  // changeOption(button: string) {
    changeOption() {
      this._dashboardService.setModoDashboard(this.selectedSetting)
      this._dashboardService.setUpdateSidebar()

    if ( this.selectedSetting == 'map'  ){
      localStorage.setItem("viewCurrent",  `${this.selectedSetting}`);
      this.loading=true
      this.showList = !this.showList;
      setTimeout(() => {
        this.loading = !this.loading;
        this.initMap();
      }, 1500);
    }else{
      localStorage.setItem("viewCurrent",  `${this.selectedSetting}`);
      this.info = false;
      this.loading = !this.loading;
      setTimeout(() => {
      this.loading = !this.loading;
        this.showList = true
      }, 1000);
    }
  }

  getData(device) {
    this._devices.getModuleInfo(device, 'modulator')
      .subscribe(data => {
        this.totalPower = data.stat.totalPower;
        this.homePower = data.stat.homePower;
        this.evsePower = data.stat.evsePower;
        this.fvPower = data.stat.fvPower;
        if (data.stat.battEnergy < 0 ){
          this.battEnergy = -1 * data.stat.battEnergy;
          console.log(data.stat.battEnergy, this.battEnergy);
          this.batteryCharging = true;
        }else {
          this.battEnergy =  data.stat.battEnergy;
          this.batteryCharging = false;
        }
        this.battPercent = data.stat.battPercent;
      });

    this._devices.getModuleInfo(device, "solar")
      .subscribe(data => {
        this.solarInfo = data;
        if (typeof this.solarInfo?.cfg?.inversor === "string")
          this.solarInfo.cfg.inversor = JSON.parse(this.solarInfo.cfg.inversor);
      });

    this._devices.getElemDevice(device)
      .subscribe(data => {
        this.connectors = data;
        console.log('conectors ', this.connectors);
        this._devices.getElementModule(device, "evsm")
          .subscribe(data => {
            console.log(data);
            data.forEach((conn, i) => {
              this.connectorsState[i] = conn.data.stat.state;
              if (this.statesPermission.includes(this.connectorsState[i]))
                this.permission[i] = true;
              else this.permission[i] = false;
            }
            );
          })
      });

    setTimeout(() => {
      this.divIcono.innerHTML =  this.icono
      this.infoWindow.setContent( this.divIcono);
      this.loadingInfo = false;
      this.info = true
    }, 1500);
  }

  getTranslatedText(translationKey: string) {
    if (translationKey)
      return this._translate.instant(translationKey);
    else
      return null;
  }

  goToDevice(serial) {
    this._router.navigateByUrl(`/dashboard/device/${serial}`)
  }
  goToSettings(serial) {
    this._router.navigateByUrl(`/dashboard/device/${serial}/settings`)
  }

  goToDiagnosis(serial){
    this._router.navigateByUrl(`/dashboard/device/${serial}/diagnosis`)
  }

  geolocation(){
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Permiso habilitado
          this.position.lat = position.coords.latitude,
          this.position.lng = position.coords.longitude
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            // Permiso deshabilitado
            const idioma = navigator.language
            switch (idioma) {
              case 'it-IT':
                this.position.lat = 41.889099,
                this.position.lng = 12.490894
                break;
              case 'da':
                this.position.lat = 55.663301,
                this.position.lng = 12.579402
                break;
              case 'nl':
                  this.position.lat = 52.371933,
                  this.position.lng = 4.892886
                  break;
              default:
                this.position = {lat: 40.45987, lng: -3.691145  }
                break;
          }}
        }
      );
    } else {
      console.log('El navegador no soporta la API de Geolocation');
    }
  }

  changeAnimation(){
    this.animacion = 2
    setTimeout(() => {
      this.info = false;
      this.animacion = 1;
    }, 1000);
  }


  infoGroup(group){
    this._dashboardService.setBreadCrumbs({
      name: group.name,
      alias: group.alias
    });
    this._router.navigate(['/dashboard/group', group.alias]);  }

  getLinkedDevices(subgrupoAlias){
    !this.showDevices
    this._devices.getLinkedDevices(subgrupoAlias)
      .subscribe( data => {
          this.linkedDevices = data
      })
  }

  detailGroup(group) {
    this._dashboardService.setBreadCrumbs({
      name: group.name,
      alias: group.alias
    });
    this._router.navigate(['/dashboard/newgroup', group.alias]);
  }
  viewGroup(group ){
    this._dashboardService.setBreadCrumbs({
      name: group.name,
      alias: group.alias
    });
    this._router.navigate(['/dashboard/viewgroup', group.alias]);
  }


  goToSPL(group ){
    this._dashboardService.setBreadCrumbs({
      name: group.name,
      alias: group.alias
    });
    this._router.navigate(['/dashboard/splconfig', group.alias]);
  }

  applyFilter(event: Event) {
    const filterValue = ((event.target as HTMLInputElement).value).trim().toLowerCase();

    this.data = this.dataSource.filter(item => {
        // Comprueba si el nombre del grupo principal incluye el valor del filtro
        const matchesMainGroup = item.name.toLowerCase().includes(filterValue);

        // Comprueba si alguno de los subgrupos incluye el valor del filtro
        const matchesSubGroup = item.subgroups?.some(subgroup =>
            subgroup.name.toLowerCase().includes(filterValue)
        );

        // Comprueba si alguno de los dispositivos incluye el valor del filtro por el campo serial (ignora mayúsculas/minúsculas)
        const matchesDevice = item.devices?.some(device =>
            device.serial.toLowerCase().includes(filterValue)
        );

        // Devuelve true si hay coincidencia en el grupo principal, en cualquier subgrupo o en cualquier dispositivo
        return matchesMainGroup || matchesSubGroup || matchesDevice;
    });
}

  home(){

    this._dashboardService.setUpdateSidebar()
    // this.getAllDevices();
    this._dashboardService.setLocation(this.locations[0].position);
    this._dashboardService.setZoom(calculoZoom(this.locations));
  }
}
