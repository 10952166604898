import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, LOCALE_ID, OnInit, Inject, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Toast } from 'ngx-toastr';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { zoomIn } from 'ng-animate';
import * as XLSX from 'xlsx';
import { formatDate } from '@angular/common';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';


@Component({
  selector: 'app-view-group',
  templateUrl: './view-group.component.html',
  styleUrls: ['./view-group.component.scss'],
  animations: [
    trigger('zoomIn', [transition('* => *', useAnimation(zoomIn))])
  ]
})
export class ViewGroupComponent implements OnInit, OnChanges {


  @Input() data : any;
  params : any;
  total = 0;
  @Input() parentLoading = false;
  loading = false;
  showModalHistorics = false;
  showModalGraphics = false;
  showModalAllHistorics = false;
  dataHistoric : any;
  connectors : any
  zoomIn : any
  historicStart = new Date();
  historicEnd = new Date();
  dataAnalytics : any;
  device : any;
  selectAll = false;
  selectedDevices: any[] = [];
  formato : 'csv' | 'xls' = 'csv'


  search = '';
  selectDevice : 'viaris' | 'xeo' | 'others' | 'all' = 'viaris';
  selectedSetting : 'list' | 'map' = 'map';

  devices: any[] = [];
  lastChargers : any[] = [];
  lastFiveCharges : any
  view : string

  confirm = {
    power : false,
    reset : false,
    ocpp: false,
    rfid: false,
    wifi : false,
    password : false,
    cards : false
  }
  chooseCfg : string = 'power';
  breadCrumbs : any = [];



  constructor(private _router: Router,
    private _modal: ModalService,
    private _devices: DeviceService,
    private route: ActivatedRoute,
    private _translate: TranslateService,
    private _utils: UtilsService,
    private _dashboard : DashboardService,
    @Inject(LOCALE_ID) public locale: string
  ) {       this.view = 'consumptions';
  }


  ngOnInit(): void {}

async ngOnChanges(){
  if(!this.parentLoading){
    await this.getHistoric();
    await this.sortArrayByName (this.data.devices, 'info.name');

    setTimeout(() => {
      this.loading = false;
      this._dashboard.replaceBreadCrumbs(this.breadCrumbs);
    }, 1000);
  }
}

sortArrayByName(array, key) {
  return array.sort((a, b) => {
      const valorA = key.split('.').reduce((o, k) => (o?.[k] ?? ""), a).toString().toLowerCase();
      const valorB = key.split('.').reduce((o, k) => (o?.[k] ?? ""), b).toString().toLowerCase();
      return valorA.localeCompare(valorB);
  });
}

getHistoric() {
  return new Promise((resolve) => {
      const historicPromises = this.data.devices.map(device => {
          return new Promise<void>((resolveDevice) => {
              // Hacer dos llamadas: obtener históricos y obtener información del dispositivo
              Promise.all([
                  this._devices.getHistorics(device.serial).toPromise(),
              ]).then(([historicDataResponse]) => {

                  const historicData = historicDataResponse.historic || [];
                  let total = historicData.reduce((acc, element) => acc + element.active, 0);

                  this.lastChargers.push({ serial: device.serial, historics: historicData });

                  const deviceIndex = this.data.devices.findIndex(d => d.serial === device.serial);
                  if (deviceIndex !== -1) {
                      this.data.devices[deviceIndex] = {
                          ...device,
                          historic: historicData,
                          total: total
                      };
                  }

                  this.total += total;
                  resolveDevice();
              }).catch(error => {
                  console.error('Error obteniendo históricos o información del dispositivo:', error);
                  resolveDevice(); // Continuar con los demás dispositivos aunque haya fallos
              });
          });
      });
      Promise.all(historicPromises).then(() => {
          resolve(this.data);
          this.data.devices.forEach(device => {
              this.selectedDevices.push({ check: false, serial: device.serial });
          });
          this.lastFiveCharges = this.getLastFiveCharges();
      });
  });
}


getLastFiveCharges() {
  var allHistorics: any[] = [];
  this.lastChargers.forEach(charger => {
    charger.historics.forEach((historic: any) => {
      allHistorics.push({
        serial: charger.serial,
        name: charger.name,
        ...historic
      });
    });
  });
  allHistorics.sort((a: any, b: any) =>
    new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  );
  return allHistorics.slice(0, 10);
}


// getLastFiveCharges() {
//   const allHistorics:any[] = [];

//   console.log('lastChargers ', this.lastChargers);

//   this.lastChargers.forEach(charger => {
//     charger.historics.forEach((historic: any) => {
//       allHistorics.push({
//         serial: charger.serial,
//         ...historic
//       });
//     });
//   });

//   // Ordenar todos los historics por el campo timestamp en orden descendente
//   allHistorics.sort((a: any, b: any) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

//   // Para obtener las 10 últimas recargas
//   return allHistorics.slice(0, 10);
// }

  getHistoricsDevice(device){
    this._devices.getHistorics(device).subscribe(data => {
      this.dataHistoric = data.historic;
      this.getConnectors(device);
    })
  }

  getHistoricGraph(device){
    this.device = device;
    this.getConnectors(device);
    this._devices.getHistoricGraphView(device,
      this._utils.getFormatedLocalDate(this.historicEnd, 'YYYY-MM-DD'),
      this._utils.getFormatedLocalDate(this.historicStart, 'YYYY-MM-DD'))
          .subscribe(data => {

            let filledData: any[] = [];
            for(let i=0; i<24; i++){

              let findHour = data.data.find(d=>Number.parseInt(this._utils.getFormatedLocalDate(d.hour, 'HH'))==i)
              findHour?filledData.push(findHour):filledData.push({});
            }
            this.dataAnalytics = filledData;
            this.showModalGraphics = true;

            console.log("%cHistorics: ", 'background: blue; color: white', this.dataAnalytics)
            setTimeout(() => {
              try {
              } catch (error: any) {
                console.error("%cLoad Graph Historics: ", 'background: black; color: white;', error.message);
              }
            }, 300);
          }, err => {

          });

  }

  getConnectors(device){
    this._devices.getElemDevice(device)
    .subscribe(data => {
      this.connectors = data;
      this.showModalHistorics = true;
    })
  }

  goToDevice(device){
    this._router.navigate(['/dashboard/device',device]);
  }

  goToInfoGroup(alias){
    this._router.navigate(['/dashboard/group',alias]);
  }

  goToDetailGroup(alias){
    this._router.navigate(['/dashboard/newgroup',alias]);
  }

  goToSPL(group : string){
    this._router.navigate(['/dashboard/splconfig', group]);
  }

  detailGroup(group){
    this._dashboard.setBreadCrumbs({
      name: group.name,
      alias: group.alias
    });
    this._router.navigate(['/dashboard/group', group.alias]);
  }

  infoGroup(group){
    this._dashboard.setBreadCrumbs({
      name: group.name,
      alias: group.alias
    });
    this._router.navigate(['/dashboard/newgroup', group.alias]);
  }

  subtractDates(date1, date2) {
    var difference_ms = new Date(date1).getTime() - new Date(date2).getTime();
    //take out milliseconds
    difference_ms = difference_ms / 1000;
    var seconds = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    var minutes = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    var hours = Math.floor(difference_ms % 24);
    if (hours != 0)
      return hours + 'h ' + minutes.toString() + 'm ' + seconds.toString() + 's';
    else return minutes.toString() + 'm ' + seconds.toString() + 's';
  }

  resetModals() {
    this.showModalHistorics = false;
    this.showModalGraphics = false;
  }

  donwloadHistorics(selection  ,extension: string = 'xls') {
    const filteredDevices = this.data.devices.filter(device =>
      selection.some(selectedDevice =>
        selectedDevice.serial === device.serial && selectedDevice.check === true
      )
    );
    // var objToPrint = [];
    var objToPrint = filteredDevices;
    objToPrint = this.data.devices.flatMap(device =>
      device.historic.map(record => ({
        Serial: device.serial,
        Date: formatDate(record.start_datetime, 'dd-MM-yyyy', this.locale),
        Element: record.element,
        'Start time': formatDate(record.start_datetime, 'h:mm a', this.locale),
        'End time': formatDate(record.end_datetime, 'h:mm a', this.locale),
        Duration: this.subtractDates(record.end_datetime, record.start_datetime),
        Energy: record.end_energy - record.start_energy + ' Wh',
        IdTag: record.user,
        'End source': record.end_source
      }))
    );

    const worksheet = XLSX.utils.json_to_sheet(objToPrint);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Historics');
    XLSX.utils.sheet_add_aoa(worksheet, [['Serial', 'Date', 'Element', 'Start time', 'End time', 'Duration', 'Energy', 'Start source', 'IdTag', 'End source']], { origin: 'A1' });
    XLSX.writeFile(workbook, `historic_data_all_devices.${extension}`, { compression: true });
  }

  toggleAllDevices(){
    this.selectedDevices.forEach(device => {
      device.check = this.selectAll;
    });
  }

  viewGroup(group){
    this._dashboard.setBreadCrumbs({
      name: group.name,
      alias: group.alias
    });
    this._router.navigate(['/dashboard/viewgroup', group.alias]);
  }



  cancel(){
    this.confirm = {
      power : false,
      reset : false,
      ocpp: false,
      rfid: false,
      wifi : false,
      password : false,
      cards : false
    };
  }

  home(){
    this._dashboard.setUpdateSidebar();
  }

  updateBreadCrums(alias: string, index: number): void {
    const currentBreadCrumbs = this.breadCrumbs;
    const updatedBreadCrumbs = currentBreadCrumbs.slice(0, index +1);
    this._dashboard.replaceBreadCrumbs(updatedBreadCrumbs )
    if (index === 0) {
      this._dashboard.setModoDashboard('list')
      this._router.navigate(['dashboard']);
    } else {
      this._router.navigate(['dashboard/viewgroup', alias]);
    }
  }


}




