<div class="container-fluid">
  <div class="row mb-3">
    <div class="col-xl-12">
      <button class="btn mb-1 btn-pill ml-2" [ngClass]="selectedSetting=='power'?'btn-primary':'btn-light'"
        (click)="selectedSetting!='power'?selectedSetting='power':selectedSetting=''"
        *ngIf="modules.includes('modulator')"><i class="fas fa-fw fa-bolt"></i>
        {{"installation" | translate}}</button>
      <button class="btn mb-1 btn-pill ml-2" [ngClass]="selectedSetting=='connectivity'?'btn-primary':'btn-light'"
        (click)="selectedSetting!='connectivity'?selectedSetting='connectivity':selectedSetting=''"
        *ngIf="modules.includes('network')">
        <i class="fas fa-fw fa-broadcast-tower"></i> {{"connectivity" | translate}}
      </button>
      <button class="btn mb-1 btn-pill ml-2" [ngClass]="selectedSetting=='password'?'btn-primary':'btn-light'"
        (click)="selectedSetting!='password'?selectedSetting='password':selectedSetting=''"
        *ngIf="modules.includes('network')"><i class="fas fa-fw fa-key"></i> {{"password" | translate}}</button>
      <button class="btn mb-1 btn-pill ml-2" [ngClass]="selectedSetting=='wifi'?'btn-primary':'btn-light'"
        (click)="selectedSetting!='wifi'?selectedSetting='wifi':selectedSetting=''"
        *ngIf="modules.includes('network')"><i class="fas fa-fw fa-wifi"></i>
        {{"wifi" | translate}}</button>
      <button class="btn mb-1 btn-pill ml-2" [ngClass]="selectedSetting=='ethernet'?'btn-primary':'btn-light'"
        (click)="selectedSetting!='ethernet'?selectedSetting='ethernet':selectedSetting=''"
        *ngIf="modules.includes('network')"><i class="fas fa-fw fa-ethernet"></i>
        {{"ethernetTitle" | translate}}</button>
      <button class="btn mb-1 btn-pill ml-2" [ngClass]="selectedSetting=='ocpp'?'btn-primary':'btn-light'"
        (click)="selectedSetting!='ocpp'?selectedSetting='ocpp':selectedSetting=''" *ngIf="modules.includes('ocpp')"><i
          class="fas fa-fw fa-cogs"></i>
        {{"OCPP" | translate}}</button>
        <button class="btn mb-1 btn-pill ml-2" [ngClass]="selectedSetting=='mqtt_user'?'btn-primary':'btn-light'"
          (click)="selectedSetting!='mqtt_user'?selectedSetting='mqtt_user':selectedSetting=''" *ngIf="modules.includes('mqtt_user')">
          <i class="fas fa-rss-square"></i> {{"MQTT" | translate}}
      </button>
      <button class="btn mb-1 btn-pill ml-2" [ngClass]="selectedSetting=='scheduler'?'btn-primary':'btn-light'"
        (click)="selectedSetting!='scheduler'?selectedSetting='scheduler':selectedSetting=''"
        *ngIf="modules.includes('schedman')"><i class="fas fa-clock pr-1"></i>{{"scheduler" |
        translate}}</button>
      <button class="btn mb-1 btn-pill ml-2" [ngClass]="selectedSetting=='power-scheduler'?'btn-primary':'btn-light'"
        (click)="selectedSetting!='power-scheduler'?selectedSetting='power-scheduler':selectedSetting=''"
        *ngIf="modules.includes('schedman')"><i class="fas fa-tachometer-alt pr-1"></i> {{"powerScheduler" |
        translate}}</button>
      <button class="btn mb-1 btn-pill ml-2" [ngClass]="selectedSetting=='rfid'?'btn-primary':'btn-light'"
        (click)="selectedSetting!='rfid'?selectedSetting='rfid':selectedSetting=''" *ngIf="modules.includes('rfid')"><i
          class="fas fa-fw fa-hand-pointer"></i> {{"RFID-mode" | translate}}</button>

      <button class="btn mb-1 btn-pill ml-2" [ngClass]="selectedSetting=='geolocation'?'btn-primary':'btn-light'"
        (click)="selectedSetting!='geolocation'?selectedSetting='geolocation':selectedSetting=''">
        <i class="fas fa-map-marked-alt"></i> {{"geoLocationTabTitle" | translate}}
      </button>
      <button class="btn mb-1 btn-pill ml-2" [ngClass]="selectedSetting=='fwupdate'?'btn-primary':'btn-light'"
        (click)="selectedSetting!='fwupdate'?selectedSetting='fwupdate':selectedSetting=''"
        *ngIf="modules.includes('fwupd')">
        <i class="fas fa-cloud-download-alt"></i> {{"FWUpdate" | translate}}</button>
        <button class="btn mb-1 btn-pill ml-2" [ngClass]="selectedSetting=='name'?'btn-primary':'btn-light'"
        (click)="selectedSetting!='name'?selectedSetting='name':selectedSetting=''"
       > <i class="fa fa-address-card"></i> {{ 'nameCharger' |translate}}</button>
      <button class="btn mb-1 btn-pill ml-2" [ngClass]="selectedSetting=='reset'?'btn-primary':'btn-light'"
        (click)="selectedSetting!='reset'?selectedSetting='reset':selectedSetting=''">
        <i class="fas fa-power-off"></i> {{"reset" | translate}}
      </button>
      <button *ngIf="isOwner || isAdmin" class="btn mb-1 btn-pill ml-2" [ngClass]="selectedSetting=='chargVinc'?'btn-primary':'btn-light'"
        (click)="selectedSetting!='chargVinc'?selectedSetting='chargVinc':selectedSetting=''" >
        <i class="fas fa-link"></i> {{"chgVincTab" | translate}}</button>
      <button class="btn mb-1 btn-pill ml-2" (click)="selectedSetting=''">{{"all" | translate}}</button>
    </div>
  </div>

  <div *ngIf="loading" class="row d-flex align-items-center justify-content-center vh-50">
    <div class="col-xl-2 d-flex justify-content-center">
      <div  class="spinner-border" style="width: 8rem; height: 8rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12" *ngIf="!loading">
      <app-power  class="pl-3 pr-3" [ngClass]="(selectedSetting == '' || selectedSetting == 'power') ? '' : 'd-none'"
        *ngIf="!newInstallSectionCompatible && modules.includes('modulator')" [connectors]="connectors"
        [allSettings]="true">
      </app-power>
      <app-power-new class="pl-3 pr-3" [ngClass]="(selectedSetting == '' || selectedSetting == 'power') ? '' : 'd-none'"
        *ngIf="newInstallSectionCompatible && modules.includes('modulator')" [connectors]="connectors"
        [allSettings]="true" [solarActive]="modules.includes('solar')">
      </app-power-new>
      <div class="row pl-3 pr-3" [ngClass]="{'w-xl-100' : selectedSetting == '' || ['all'].includes(selectedSetting)}">
        <app-connectivity class="col-xl-6" [ngClass]="(selectedSetting == '' || selectedSetting == 'connectivity') ? '' : 'd-none'" *ngIf="modules.includes('network')" [allSettings]="true">
        </app-connectivity>
        <app-password class="col-xl-6" [ngClass]="(selectedSetting == '' || selectedSetting == 'password') ? '' : 'd-none'" *ngIf="modules.includes('network')" [allSettings]="true">
        </app-password>
        <app-wifi class="col-xl-6" [ngClass]="(selectedSetting == '' || selectedSetting == 'wifi') ? '' : 'd-none'" *ngIf="modules.includes('network')" [allSettings]="true">
        </app-wifi>
        <app-ethernet class="col-xl-6" [ngClass]="(selectedSetting == '' || selectedSetting == 'ethernet') ? '' : 'd-none'" *ngIf="modules.includes('network')" [allSettings]="true">
        </app-ethernet>
        <app-ocpp class="col-xl-6" [ngClass]="(selectedSetting == '' || selectedSetting == 'ocpp') ? '' : 'd-none'" *ngIf="modules.includes('ocpp')" [allSettings]="true">
        </app-ocpp>
        <app-mqtt class="col-xl-6" [ngClass]="(selectedSetting == '' || selectedSetting == 'mqtt_user') ? '' : 'd-none'" *ngIf="modules.includes('mqtt_user')" [allSettings]="true">
        </app-mqtt>
        <app-charge-scheduler class="col-xl-6" [ngClass]="(selectedSetting == '' || selectedSetting == 'scheduler') ? '' : 'd-none'" *ngIf="modules.includes('schedman')" [connectors]="connectors" [allSettings]="true">
        </app-charge-scheduler>
        <app-power-scheduler class="col-xl-6" [ngClass]="(selectedSetting == '' || selectedSetting == 'power-scheduler') ? '' : 'd-none'" *ngIf="modules.includes('schedman')" [allSettings]="true" [connectors]="connectors">
        </app-power-scheduler>
        <app-rfid class="col-xl-6" [ngClass]="(selectedSetting == '' || selectedSetting == 'rfid') ? '' : 'd-none'" *ngIf="modules.includes('rfid')" [allSettings]="true">
        </app-rfid>
        <app-geolocation class="col-xl-6" [ngClass]="(selectedSetting == '' || selectedSetting == 'geolocation') ? '' : 'd-none'" [allSettings]="true">
        </app-geolocation>
        <app-fwupdate class="col-xl-6" [ngClass]="(selectedSetting == '' || selectedSetting == 'fwupdate') ? '' : 'd-none'" *ngIf="modules.includes('fwupd')" [allSettings]="true">
        </app-fwupdate>
        <app-name class="col-xl-6"  [ngClass]="(selectedSetting == '' || selectedSetting == 'name') ? '' : 'd-none'"  [allSettings]="true"></app-name>
        <app-reset class="col-xl-6" [ngClass]="(selectedSetting == '' || selectedSetting == 'reset') ? '' : 'd-none'" [allSettings]="true">
        </app-reset>
      </div>
      <app-linkage class="col-xl-8" [ngClass]="(selectedSetting == '' || selectedSetting == 'chargVinc') ? '' : 'd-none'"  [allSettings]="true"></app-linkage>
    </div>
  </div>
</div>
