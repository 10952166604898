<div class="card flex-fill w-100">
    <div class="card-header">

      <h5 class="card-title mb-0">Map</h5>
    </div>
    <div class="card-body p-0 h_300">
      <div class="image-body-map-card image-body-map-card-overlay bg-primary position-absolute w-100 h_300" style="mix-blend-mode: hue"></div>
      <div class="image-body-map-card">
        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="15">
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
          </agm-map>
      </div>
    </div>
  </div>