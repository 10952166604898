<div [ngClass]="{'col-6 col-xl-6' : !allSettings}">
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">{{"touchRfid" | translate}}</h5>
      <h6 class="card-subtitle text-muted">{{"touchText" | translate}}</h6>
    </div>
    <div class="card-body">
      <form [formGroup]="rfidForm">
        <div class="d-flex w-80 m-auto button-group-container">
          <div class="col-4 text-center p-1 button-group" (click)="changeMode('touch')"
            [ngClass]="{'bg-primary text-white' : body?.rfid === 0 && body.enabled === 1}">
            {{"touch" | translate}}
          </div>
          <div class="col-4 text-center p-1 button-group" (click)="changeMode('rfid')"
            [ngClass]="{'bg-primary text-white' : body?.rfid === 1 && body.enabled === 1}">
            {{"rfid" | translate}}
          </div>
          <div class="col-4 text-center p-1 button-group" (click)="changeMode('off')"
            [ngClass]="{'bg-primary text-white' :  body?.enabled === 0}">
            OFF
          </div>
        </div>
        <!-- RFID -->
        <div *ngIf="body.rfid===1  && body.enabled === 1" class="mt-3 mb-2">
          <h5 class="text-dark">{{"funcMode" | translate}}</h5>
          <div class="form-group col-10 m-auto">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="eocMode"
                (change)="changesMade=this._device.checkChangesSettings(originalData, body)" [(ngModel)]="body.eocMode"
                value="any">
              <label class="form-check-label text-dark">
                <b>{{"familiarMode" | translate}}</b>
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="eocMode"
                (change)="changesMade=this._device.checkChangesSettings(originalData, body)" [(ngModel)]="body.eocMode"
                value="own">
              <label class="form-check-label text-dark">
                <b>{{"corporativeMode" | translate}}</b>
              </label>
            </div>
          </div>
        </div>

        <div *ngIf="body?.rfid === 1  && body.enabled === 1" class="mt-3 mb-2 text-center">

          <div class="d-flex my-5">
            <div class="btn-group mb-2 float-left" role="group" aria-label="">
              <button class="btn btn-outline-primary mr-1" (click)="openCrudModal('add')" style = "visibility: hidden;">
                <i class="fas fa-plus"></i>
                Add Card
              </button>

              <button class="btn btn-outline-danger mr-auto" (click)="openCrudModal('deleteAll')" [ngClass]="{'disabled' : cardUserList.length < 2}">
                <i class="fas fa-trash-alt"></i>
                Delete All
              </button>
            </div>

            <h3 class="text-dark mx-auto">{{"authorizedCard" | translate}}</h3>


            <div class="btn-group mb-2 float-right" role="group" aria-label="">
              <button type="button" class="btn ml-1 btn-secondary" (click)="displayFileModal('imp')">
                <i class="fas fa-arrow-up"></i>
                {{"import" | translate}}
              </button>
              <button type="button" class="btn ml-1 btn-secondary" (click)="displayFileModal('exp')">
                <i class="fas fa-arrow-down"></i>
                 {{"export" | translate}}
              </button>
            </div>
          </div>

          <app-file-upload-export [data]="data" *ngIf="displayModalFile" (event)="getFile($event)" [ngClass]="{'fadeTop': displayModalFile}"></app-file-upload-export>



          <div *ngIf="loading" class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>

          <div *ngIf="!loading">
            <p *ngIf="cardUserList.length === 0">{{"unexistantRfidCards" | translate}}
            </p>
            <table *ngIf="cardUserList.length > 0" class="table col-10 m-auto">
              <tr class="border-top-0">
                <th class="border-top-0 vertical-middle">{{"Card" | translate}}</th>
                <th class="border-top-0 vertical-middle">{{"expiryDate" | translate}}</th>
                <th class="border-top-0 vertical-middle">{{"group" | translate}}</th>
                <th class="border-top-0 vertical-middle">{{"status" | translate}}</th>
                <th class="border-top-0 vertical-middle">{{"actions" | translate}}</th>
              </tr>
              <tr *ngFor="let card of cardUserList let i = index">
                <td>
                  {{ card.idTag}}
                </td>
                <td *ngIf="card.idTagInfo.expiryDate != ''">
                  {{ card.idTagInfo.expiryDate | date:'dd-MM-YYYY HH:mm'}}
                </td>
                <td *ngIf="card.idTagInfo.expiryDate === ''">
                  <span class="text-danger">Not configured</span>
                </td>
                <td>
                  {{ card.idTagInfo.parentIdTag }}
                </td>
                <td>
                  {{card.idTagInfo.status | translate}}
                </td>
                <td class="row">
                  <div class="col-6">
                    <i class="fas fa-pencil-alt" (click)="openCrudModal('edit', card.idTag)"></i>
                  </div>
                  <div class="col-6">
                    <i class="fas fa-trash-alt" (click)="openCrudModal('delete', card.idTag)"></i>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- TOUCH -->
        <div *ngIf="body?.rfid === 0 && body.enabled === 1" class="mt-3 mb-2 text-center">
          {{"touchMode" | translate}}
        </div>

        <!-- OFF -->
        <div *ngIf="body?.rfid === 0 && body?.enabled === 0" class="mt-3 mb-2 text-center">
          {{"touchRfidDisabled" | translate}}
        </div>

        <div class="form-group row mt-3">
          <div class="col-sm-10 ml-sm-auto">
            <button type="submit cursor-pointer" class="btn btn-primary" (click)="save()"
              [disabled]="!changesMade">{{"overwrite" | translate}}</button>
            <button type="submit cursor-pointer" class="btn btn-light ml-2" [disabled]="!changesMade">{{"restore" | translate}}</button>
          </div>

        </div>
        <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error!=''">
          <div class="alert-message">
            {{ error }}
          </div>
        </div>
        <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage">
          <div class="alert-message">
            {{"updatedSuccesfully" | translate}}
          </div>
        </div>
      </form>
    </div>
