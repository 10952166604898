<div class="container-fluid">
  <div class="header d-flex">
    <div class="w-75">
        <h1 class="header-title">
          {{"patTokenTitle" | translate}}
        </h1>
          <h5 class="text-white">{{"patTokenSubtitle" | translate}}</h5>
    </div>
    <div class="image-profile">
        <i class="fas fa-code text-white"></i>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-xl-11 m-auto">
        <div class="card  text-center">
            <div class="card-header">
                <div class="d-flex justify-content-between">
                    <h4 class="card-title">{{"patTokenManage" | translate}}</h4>
                </div>
            </div>
            <div class="card-body">
                  <div class=" row">
                      <div class="col-sm-12 ml-sm-auto">
                        <div class="row">
                            <div class="alert alert-warning disclaimer-alert" role="alert">
                              <b style="font-weight: bolder !important;">{{"patTokenDisclaimerTitle" | translate}} &nbsp;</b>
                              {{"patTokenAgreementAccept" | translate}}
                            </div>
                          <label class="col-sm-6 text-sm-right">{{"patTokenUniqueUserId" | translate}}</label>
                          <div class="col-sm-6 text-sm-left">
                            <mark>{{patToken.uuid}}</mark>
                          </div>
                        </div>
                        <div class="row">
                          <label class="col-sm-6 text-sm-right">{{"token" | translate}}</label>
                          <div class="col-sm-6 text-sm-left">
                            <mark  *ngIf="patToken.token !== ''">{{patToken.token}}</mark>
                          </div>
                        </div>
                        <a class="btn btn-lg btn-warning" *ngIf="patToken.token !== '' && patToken.loaded" (click)="deleteToken()">{{"patTokenDelete" | translate}}</a>&nbsp;
                        <a class="btn btn-lg btn-danger" *ngIf="patToken.token === '' && patToken.loaded" (click)="createToken()">{{"patTokenCreate" | translate}}</a>
                      </div>
                  </div>
            </div>
        </div>
    </div>
  </div>
</div>
