import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { isIPv4 } from 'is-ip';
import * as _ from 'lodash';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { compare } from 'compare-versions';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import { ModalMainMeterComponent } from 'src/app/components/ui/modal-mainMeter/modal-mainMeter.component';
import { ModalSecondaryMeterComponent } from 'src/app/components/ui/modal-secondaryMeter/modal-secondaryMeter.component';

@Component({
  selector: 'app-power-new',
  templateUrl: './power-new.component.html',
  styleUrls: ['./power-new.component.scss']
})
export class PowerNewComponent implements OnInit {

  device = '';

  loading = false;

  showAdvanced: boolean = false;

  @Input()
  allSettings = false;

  @Input() solarActive = true;

  body = {
    limitPower: 0,
    limitPowerByPhase: [0, 0, 0],
    switchEnabled: -1,
    unswitchCurrent: 0,
    safetyOnRetries: 3
  }

  bodyMinP = {
    minPower: 0
  }

  ampacity;
  originalAmpacity;

  secondaryMeterPos;
  selectSplInterface;

  changes = {
    solar: false,
    spl: false,
    limitP: false,
    minP: false,
    ampacity: false,
    ampacityConn: false,
    rotationPhases: false,
    switchEnabled: false,
    shelly: false,
    unswitchCurrent : false,
    safetyOnRetries : false,
  }

  successMessage = {
    spl: false,
    solar: false,
    limitP: false,
    minP: false,
    ampacity: false,
    rotationPhases: false
  };

  successAllMessage = false;

  error = {
    spl : '',
    solar: '',
    limitP: '',
    minP: '',
    ampacity: '',
    shelly: '',
    unswitchCurrent: ''
  };

  collapsibles = {
    vehicle: false,
    chargerLimits: false,
    phaseRotation: false,
    phaseSwitching: false,
    chargersLine: false,
    safetyOnRetries: false
  }

  originalData: any = {};
  changesMade = false;

  splEnabled=false;
  splCfg : any;
  originalSPL:any;
  originalSolar:any;
  solarCfg: any;
  inversorParsed: any;
  deviceInfo: any;
  versionInversorCompatible: boolean = false;
  versionInversorExtraUnitIdsCompatible: boolean = false;
  versionSPLInterfaceCompatible: boolean = false;
  versionDisableModulatorCompatible: boolean = false;
  versionChain2gateCompatible: boolean = false;
  version230BetweenPhases : boolean = false;
  solarCfgTriphasic = false;
  solarCfgInjected = false;

  disableModulator = false

  originalShelly: any;
  shellyMainCfg: any;
  shellyAuxCfg: any;


  modulatorActiv = false;

  num_phases;

  focus='';

  @Input()
  connectors: any;

  connectorsMennekes: any[] = [];
  originalConnectorsMennekes: any[] = [];
  rotationPhases: any[] = [
    { name: 'Default (RST)', value: 0 },
    { name: 'TRS', value: 1 },
    { name: 'STR', value: 2 }
  ];
  inverters = [
    {label: 'INGETEAM - INGECON SUN STORAGE 1PLAY TLM (general profile)', value: 1},
    {label: 'INGETEAM - INGECON SUN STORAGE 1PLAY TLM (EV profile)', value: 2},
    {label: 'HUAWEI - SUN2000-(2KTL-6KTL)-L1', value: 3},
  ]


  mainMeters = [
    {label: 'selectMeter', value: 255},
    {label: 'Contax 0643 BUS', value: 0},
    {label: 'TMC100', value: 1},
    {label: 'solarContax', value: 2},
    {label: 'Shelly', value: 3},
    {label: 'solarInverterTitle', value: 4},
    {label: 'P1', value: 5},
    {label: 'P1 Wifi', value: 6},
    // {label: 'Chain2Gate  P1/P2', value: 8},
    // {label: 'Chain2Gate P4', value: 9}
  ]

  secondaryMeters = [
    {label: 'selectMeter', value: 255},
    {label: 'TMC100', value: 1},
    {label: 'Shelly', value: 3}
  ]

  socket;
  infoModulator: {
    stat: any
  };
  intervalRT: any;

  modalMain: NgbModalRef;

  origninalUnswitchCurrent: number;
  unswitchCurrent : number;
  safetyOnRetries: boolean;

  constructor(public _dService: DeviceService, private _aRoute: ActivatedRoute, private _modal: ModalService, private _ws: WebsocketService) { }

  ngOnInit(): void {
    this._aRoute.parent?.parent?.params
      .subscribe((params: Params) => this.device = params['serial']);

    this.splCfg={};
    this.getData();

    this.infoModulator={stat:{}};
    this.connectWS();

    this.startRT(this);
    var context = this;
    this.intervalRT = setInterval(() => {
      this.startRT(context);
    }, 120000)
  }

  getData(){
    this._dService.getModuleInfo(this.device, 'modulator')
      .subscribe(data => {
        this.writeModulatorCfg(data.cfg);
      })

    this._dService.getDeviceInfo(this.device)
      .subscribe(data => {
        this.deviceInfo = data;
        this.versionChain2gateCompatible = compare(this.deviceInfo.firmware_version_name, '7.3.0', '>=');
        if ( this.versionChain2gateCompatible){
          this.mainMeters.push(
                {label: 'Chain2Gate  P1/P2', value: 8},
                {label: 'Chain2Gate P4', value: 9}
          )
        }
        this.versionInversorExtraUnitIdsCompatible = compare(this.deviceInfo.firmware_version_name, '7.3.0', '>=');
        this.versionInversorCompatible = compare(this.deviceInfo.firmware_version_name, '6.2.23', '>=');
        this.versionSPLInterfaceCompatible = compare(this.deviceInfo.firmware_version_name, '6.2.31', '>=');
        this.versionDisableModulatorCompatible = compare(this.deviceInfo.firmware_version_name, '7.2.56', '>=')
        this.version230BetweenPhases = compare(this.deviceInfo.firmware_version_name, '7.3.35', '>=')
      })

    this._dService.getModuleInfo(this.device, 'spl')
      .subscribe(data => {
        this.writeSplCfg(data.cfg);
      })

    this._dService.getModuleInfo(this.device, 'solar')
      .subscribe(data => {
        this.writeSolarCfg(data.cfg);
      })

    this._dService.getModuleInfo(this.device, "sys")
      .subscribe(data => {
        this.writeSys(data);
      })

    this._dService.getModuleInfo(this.device, "shelly")
      .subscribe(data => {
        this.writeShelly(data.cfg);
      })

    setTimeout(() => {
      this._dService.getElementModule(this.device, 'mennekes')
      .subscribe(data => {
        this.writeElements(data);
      })
    }, 500);
  }

  writeModulatorCfg(cfg){
    this.body.limitPower = cfg?.limitPower;
    this.origninalUnswitchCurrent = Math.floor(cfg.unswitchCurrent/1000*230);
    this.unswitchCurrent=  this.origninalUnswitchCurrent;
    if(cfg?.limitPowerByPhase){
      this.body.limitPowerByPhase = JSON.parse(cfg?.limitPowerByPhase);
      this.body.switchEnabled = cfg?.switchEnabled;
      this.body.unswitchCurrent = cfg?.unswitchCurrent;
    }
    this.originalData.limitP = _.cloneDeep(this.body)
    this.changes.limitP = false;
    this.changes.switchEnabled = false;
  }

  writeSplCfg(cfg){
    if(typeof cfg.splLimitPowerByPhase === 'string')
      cfg.splLimitPowerByPhase=JSON.parse(cfg.splLimitPowerByPhase);
    this.splCfg = cfg;
    if(this.splCfg?.splInterface > 0)
      this.selectSplInterface = this.splCfg.splInterface;
    else
      this.selectSplInterface =1;
    this.originalSPL = _.cloneDeep(cfg);
    if(cfg.splMeter == 255)
      this.modulatorActiv = false;
    else
      this.modulatorActiv = true;

    if(this.splCfg.splMeterAux != 1 && this.splCfg.splMeterAux != 3){
      this.splCfg.splMeterAux = 1;
    }

    this.changes.spl = false;
  }

  writeSolarCfg(cfg){
    if(cfg.injected==1 || cfg.injected==true)
      cfg.injected = true;
    else
      cfg.injected = false;
    this.solarCfg = cfg;
    this.inversorParsed = this.parseInversorData(cfg.inversor);
    this.originalSolar = {...cfg};
    if(this.solarCfg.type >= 30)
      this.solarCfgTriphasic = true;
    else
      this.solarCfgTriphasic = false;
    if(!this.solarCfg.type){
      this.solarCfg.type=this.solarCfgTriphasic?32:12;
    }
    this.secondaryMeterPos = (this.solarCfg.type==32||this.solarCfg.type==12)?"solar":((this.solarCfg.type==31||this.solarCfg.type==11)?"home":"total");

    this.changes.solar = false;
  }

  writeSys(data){
    this.ampacity=data.cfg.ampacity;
    this.originalAmpacity=data.cfg.ampacity;
    this.num_phases = data.stat.fwv_pot.startsWith('3') ? data.cfg.phase!=1 ? 3 : 1: 1;

    this.changes.ampacity = false;
  }

  writeElements(data){
    this.connectorsMennekes = [];
    data.forEach(element => {
      if(element.name.startsWith('mennekes')){
        element.data.cfg.limitPower = Math.floor(element.data.cfg.limitPower / (23 * this.num_phases))/10;
        this.connectorsMennekes.push(element);
        this.originalConnectorsMennekes.push(_.cloneDeep(element));
        this.bodyMinP.minPower = Math.floor(element.data.cfg.minPower / (23 * this.num_phases))/10;
        this.originalData.minP = { ...this.bodyMinP }
      }
    });

    this.body.safetyOnRetries = data[0].data.cfg.safetyOnRetries;
    this.originalData.safetyOnRetries = this.body.safetyOnRetries;
    this.safetyOnRetries = this.body.safetyOnRetries == 3 ? false : true;

    this.changes.ampacityConn = false;
    this.changes.rotationPhases = false;
    this.changes.minP = false;
  }

  writeShelly(cfg){
    if(typeof cfg.elements === 'string')
      cfg.elements=JSON.parse(cfg.elements);
    this.originalShelly = _.cloneDeep(cfg);
    if(cfg.elements?.length>0){
      this.shellyMainCfg = _.cloneDeep(cfg.elements[0]);
      if(cfg.elements?.length>1){
        this.shellyAuxCfg = _.cloneDeep(cfg.elements[1]);
      }
      else{
        this.shellyAuxCfg = _.cloneDeep(cfg.elements[0]);
      }
    }
    else{
      this.shellyMainCfg = {};
      this.shellyAuxCfg = {};
    }

    this.changes.shelly = false;
  }

  save(){
    if(this.changes.spl && this.splCfg.splMeter == 1){
      this.splCfg.splMeterAux = 255;
      this.solarCfg.enabled=0;
      this.checkChangesSolar();
    }
    if(this.changes.solar){
      this.saveSolar();
    }
    if(this.changes.spl){
      this.saveSPL();
    }
    if(this.changes.limitP || this.changes.switchEnabled || this.changes.unswitchCurrent) {
      this.savePower();
    }
    if(this.changes.minP){
      this.saveMinP();
    }
    if(this.changes.ampacity){
      this.saveAmpacity();
    }
    if(this.changes.ampacityConn || this.changes.rotationPhases || this.changes.safetyOnRetries){
      this.saveElement();
    }
    if(this.changes.shelly){
      this.saveShelly();
    }
  }

  restore(){
    this.getData();
  }

  savePower() {
    var modalLoad = this._modal.open(LoadingPopupComponent);
    this._dService.setModuleCfg(this.device, this.body, 'modulator')
      .subscribe(data => {
        this.writeModulatorCfg(data.cfg);
        modalLoad.close();
        this.successMessage.limitP = true;
        this.successAllMessage = true;
        setTimeout(() => {
          this.successMessage.limitP = false;
          this.successAllMessage = false;
        }, 5000);
      }, err => {
        console.error(err)
        this.error.limitP = err.error.message;
        setTimeout(() => {
          this.error.limitP = '';
        }, 10000);
        modalLoad.close();
      })
  }

  saveMinP() {
    var modalLoad = this._modal.open(LoadingPopupComponent);
    let minPower = this.bodyMinP.minPower*230*this.num_phases;

    this._dService.setModuleElemData(this.device, 'mennekes', {minPower: minPower})
      .subscribe(data => {
        this.writeElements(data);
        modalLoad.close();
        this.successMessage.minP = true;
        this.successAllMessage = true;
        setTimeout(() => {
          this.successMessage.minP = false;
          this.successAllMessage = false;
        }, 5000);
      }, err => {
        console.error(err)
        this.error.minP = err.error.message;
        setTimeout(() => {
          this.error.minP = '';
        }, 10000);
        modalLoad.close();
      })

  }

  saveElement() {
    if(!this.changes.ampacityConn && !this.changes.rotationPhases && !this.changes.safetyOnRetries)
      return;

    var modalLoad = this._modal.open(LoadingPopupComponent);

    this.connectors.forEach((conn, i) => {
      if(!conn.name.startsWith('mennekes')) return;

      setTimeout(() => {
        var data={};

        if(this.changes.ampacityConn){
          data["limitPower"] = Math.floor(this.connectorsMennekes.find(c=>c.name==conn.name).data.cfg.limitPower*230*this.num_phases);
        }
        if(this.changes.rotationPhases){
          data["phaseRotation"] = parseInt(this.connectorsMennekes.find(c=>c.name==conn.name).data.cfg.phaseRotation);
        }
        if(this.changes.safetyOnRetries){
          data["safetyOnRetries"] = this.body.safetyOnRetries;
        }

        this._dService.setModuleElemData(this.device, 'mennekes', data, conn.name)
          .subscribe(data => {
            // this.writeElements(data);
            modalLoad.close();
            if(this.changes.ampacityConn)
              this.successMessage.ampacity = true;
            if(this.changes.rotationPhases)
              this.successMessage.rotationPhases = true;
            this.successAllMessage = true;
            setTimeout(() => {
              if(this.changes.ampacityConn)
                this.successMessage.ampacity = false;
              if(this.changes.rotationPhases)
                this.successMessage.rotationPhases = false;
              this.successAllMessage = false;
            }, 5000);
          }, err => {
            console.error(err)
            this.error.ampacity = err.error.message;
            setTimeout(() => {
              this.error.ampacity = '';
            }, 10000);
            modalLoad.close();
          })
      }, 500*i);
    });
  }

  saveSPL() {
    var modalLoad = this._modal.open(LoadingPopupComponent);
    console.log(this.selectSplInterface)
    if(this.splCfg.splMode > 0)
      this.splCfg.splInterface=parseInt(this.selectSplInterface);
    else
      this.splCfg.splInterface=0;
    if(this.splCfg.splMeter == 4)
      this.splCfg.splMeterAux=4;
    this._dService.setModuleCfg(this.device, this.splCfg, 'spl')
      .subscribe(data => {
        this.writeSplCfg(data.cfg);
        modalLoad.close();
        this.successMessage.spl = true;
        this.successAllMessage = true;
        setTimeout(() => {
          this.successMessage.spl = false;
          this.successAllMessage = false;
        }, 5000);
      }, err => {
        console.error(err)
        this.error.spl = err.error.message;
        setTimeout(() => {
          this.error.spl = '';
        }, 10000);
        modalLoad.close();
      })
  }

  saveSolar() {
    var modalLoad = this._modal.open(LoadingPopupComponent);
    if(this.inversorParsed.enabled)
      this.solarCfg.type=this.solarCfgTriphasic?32:12;

    this._dService.setModuleCfg(this.device, this.solarCfg, 'solar')
      .subscribe(data => {
        this.writeSolarCfg(data.cfg);
        modalLoad.close();
        this.successMessage.solar = true;
        this.successAllMessage = true;
        setTimeout(() => {
          this.successMessage.solar = false;
          this.successAllMessage = false;
        }, 5000);
      }, err => {
        console.error(err)
        this.error.solar = err.error.message;
        setTimeout(() => {
          this.error.solar = '';
        }, 10000);
        modalLoad.close();
      })
  }

  saveAmpacity() {
    var modalLoad = this._modal.open(LoadingPopupComponent);
    this._dService.setModuleCfg(this.device, {ampacity:this.ampacity}, 'sys')
      .subscribe(data => {
        this.writeSys(data);
        modalLoad.close();
        this.successMessage.ampacity = true;
        this.successAllMessage = true;
        setTimeout(() => {
          this.successMessage.ampacity = false;
          this.successAllMessage = false;
        }, 5000);
      }, err => {
        console.error(err)
        this.error.ampacity = err.error.message;
        setTimeout(() => {
          this.error.ampacity = '';
        }, 10000);
        modalLoad.close();
      });
  }

  saveShelly() {
    var modalLoad = this._modal.open(LoadingPopupComponent);

    this._dService.setModuleCfg(this.device, this.getNewShellyCfg(), 'shelly')
      .subscribe(data => {
        this.writeShelly(data.cfg);
        modalLoad.close();
        this.successAllMessage = true;
        setTimeout(() => {
          this.successAllMessage = false;
        }, 5000);
      }, err => {
        console.error(err)
        this.error.shelly = err.error.message;
        setTimeout(() => {
          this.error.shelly = '';
        }, 10000);
        modalLoad.close();
      })
  }


  checkChangesLimit(){
    this.changes.limitP = this._dService.checkChangesSettings(this.originalData.limitP, this.body);
    //If has changes, three values has to be the same
    if(this.changes.limitP) {
      for (let i = 0; i < this.body.limitPowerByPhase.length; i++) {
        this.body.limitPowerByPhase[i] = this.body.limitPowerByPhase[0];
      }
    }
    this.changes.limitP = this._dService.checkChangesSettings(this.originalData.limitP, this.body);
  }
  checkChangesMin(){
    this.changes.minP = this._dService.checkChangesSettings(this.originalData.minP.minPower, this.bodyMinP.minPower);
  }

  checkChangesSolar(){
    // Si la batería está habilitada, evitas que se pueda introducir un valor superior a 95%
    if (this.inversorParsed.batterySaving > 95 ) this.inversorParsed.batterySaving = 100;
    if (this.inversorParsed.initialBattPower){
      if (this.inversorParsed?.initialBattPower[0] < 500) this.inversorParsed.initialBattPower[0] = 500;
      if (this.inversorParsed?.initialBattPower[0] > 10000) this.inversorParsed.initialBattPower[0] = 1000;
    }
    let solarChanges = this._dService.checkChangesSettings(this.originalSolar, this.solarCfg);
    let inversorChanges = this._dService.checkChangesSettings(this.inversorParsed, this.solarCfg.inversor);
    this.inversorParsed.model = parseInt(this.inversorParsed.model);
    if(inversorChanges){
      this.inversorParsed.model = parseInt(this.inversorParsed.model);
      if(isIPv4(this.inversorParsed.inversorIP)){
        this.error.solar = '';
        this.solarCfg.inversor = this.inversorParsed;
      }else {
        this.error.solar = "Dirección IP inválida";
        setTimeout(() => {
          this.error.solar = '';
        }, 5000);
        return;
      }
    }
    this.changes.solar = solarChanges || inversorChanges;
  }

  checkChangesSPL(){
    var tmpSplInterface = this.splCfg.splInterface==0?1:this.splCfg.splInterface;
    this.changes.spl = (this._dService.checkChangesSettings(this.originalSPL, this.splCfg) || this.selectSplInterface != tmpSplInterface);
  }

  checkChangesAmpacity(){
    this.changes.ampacity = this.originalAmpacity!=this.ampacity;
  }

  checkChangesAmpacityConn(){
    this.changes.ampacityConn = false;
    this.connectorsMennekes.forEach(c=>{
      if(!this.changes.ampacityConn)
        this.changes.ampacityConn = c.data.cfg.limitPower!=this.originalConnectorsMennekes.find(co=>co.name==c.name).data.cfg.limitPower;
    })
  }

  checkChangesRotation(){
    this.changes.rotationPhases = false;
    this.connectorsMennekes.forEach(c=>{
      if(!this.changes.rotationPhases)
      this.changes.rotationPhases = c.data.cfg.phaseRotation!=this.originalConnectorsMennekes.find(co=>co.name==c.name).data.cfg.phaseRotation;
    })
  }

  checkSwitchEnabled(){
    this.changes.switchEnabled = this.originalData.limitP.switchEnabled != this.body.switchEnabled;
  }

  checkChangesShelly(){
    var newShellyCfg = this.getNewShellyCfg();
    this.changes.shelly = this._dService.checkChangesSettings(this.originalShelly.elements,newShellyCfg.elements);
  }

  getNewShellyCfg(){
    var newShellyCfg:any = [];
    if(this.splCfg.splMeter == 3 || this.splCfg.splMeter == 6){
      newShellyCfg.push(this.shellyMainCfg);
    }
    if((this.splCfg.splMeterAux == 3 || this.splCfg.splMeter == 6) && this.shellyMainCfg.ip != this.shellyAuxCfg.ip){
      newShellyCfg.push(this.shellyAuxCfg);
    }
    return {elements:newShellyCfg};
  }

  setFocus(foc){
    this.focus=foc;
  }

  changeSolarOption(){
    if(this.secondaryMeterPos == "solar" || this.splCfg.splMeter == 4)
      this.solarCfg.type=this.solarCfgTriphasic?32:12;
    else if(this.secondaryMeterPos == "home")
      this.solarCfg.type=this.solarCfgTriphasic?31:11;
    else
      this.solarCfg.type=this.solarCfgTriphasic?30:10;

    this.checkChangesSolar();
  }

  setAdvanced(){
    this.showAdvanced=!this.showAdvanced;
  }

  activeModulator(){
    this.modulatorActiv=!this.modulatorActiv;
    if(!this.modulatorActiv && this.versionDisableModulatorCompatible){
      this.splCfg.splMeter = 255;
      this.splCfg.splMeterAux = 255;
      this.solarCfg.enabled = 0;
      this.checkChangesSolar()

    }
    if(!this.modulatorActiv && !this.versionDisableModulatorCompatible){
      this.splCfg.splMeter = this.originalSPL.splMeter;
      this.disableModulator = true;
      setTimeout(() => {
        this.disableModulator = false;
        this.modulatorActiv = true;
        this.changes.spl=false
      }, 5000);

    }

    this.checkChangesMainMeter();
  }

  parseInversorData(data){
    if(data){
        if(typeof data === "string"){
            return JSON.parse(data);
        }else{
            return data;
        }
    }
  }

  checkChangesMainMeter(){
    // Obliga al usuario a configurar el inversor o los MainMeters
    if (this.splCfg.splMeter == 3 || this.splCfg.splMeter == 4 || this.splCfg.splMeter == 6 )
      this.openMainMeterCfg();
    // Si pasamos de una instalación con inversor a una de otro tipo --> deshabilita el inversor
    if (this.splCfg.splMeter != 4 && this.inversorParsed.enabled == true){
      this.inversorParsed.enabled = false;
      this.solarCfg.battery = false;
      this.checkChangesSolar();
    }
    this.changes.spl = this._dService.checkChangesSettings(this.originalSPL, this.splCfg);
    this.checkChangesShelly();
  }

  checkChangesAuxMeter(){
    // Obliga al usaurio a configurar el auxMeter si es un Shelly
    if (this.splCfg.splMeterAux == 3)
      this.openSecondaryMeterCfg();

    this.changes.spl = this._dService.checkChangesSettings(this.originalSPL, this.splCfg);
    this.checkChangesShelly();
  }

  connectWS() {
    this.socket = this._ws.connect(this.device)
      .subscribe(
        msg => {
          let message = msg;
          switch (message.module) {
            case "modulator":
              if (!message.element) {
                if(message.data.stat)
                 this.infoModulator.stat = message.data.stat;
              }

              break;
          }

        },
        err => {
          console.log(err);
        }
      )
  }

  startRT(context) {
    context._dService.setRT(this.device, { period: 1, timeout: 120, status: true })
      .subscribe(data => {
      }, (err) => {
      })
  }

  ngOnDestroy() {
    this._dService.setRT(this.device, { period: 1, timeout: 120, status: false })
      .subscribe(data => {
      })

    clearInterval(this.intervalRT);

    this._ws.close();
  }

  openMainMeterCfg(){
    this.modalMain=this._modal.open(ModalMainMeterComponent);
    this.modalMain.componentInstance.type=this.splCfg.splMeter;
    switch (this.splCfg.splMeter) {
      case 3:
        this.modalMain.componentInstance.shellyMainIp = this.shellyMainCfg.ip;
        this.modalMain.componentInstance.shellyMainModel = this.shellyMainCfg.model;
        break;

      case 4:
        this.modalMain.componentInstance.inverterModel = this.inversorParsed.model;
        this.modalMain.componentInstance.inverterIp = this.inversorParsed.inversorIP;
        this.modalMain.componentInstance.inverterPort = this.inversorParsed.inversorPort;
        this.modalMain.componentInstance.inverterEnabledBatt = this.solarCfg.battery;
        if (!this.inversorParsed.extraUnitIds) {
          this.inversorParsed.extraUnitIds = []
        }
        this.modalMain.componentInstance.extraUnitIds = this.inversorParsed.extraUnitIds;
        this.modalMain.componentInstance.versionCompatible = this.versionInversorExtraUnitIdsCompatible
        break;

      case 6:
        this.modalMain.componentInstance.p1WifiMainIp = this.shellyMainCfg.ip;
        break;
      default:
        break;
    }
    this.modalMain.result.then((comp) => {
      if(this.splCfg.splMeter == 3 || this.splCfg.splMeter == 6){
          this.shellyMainCfg = comp;
          this.checkChangesShelly();
      }
      else if(this.splCfg.splMeter == 4){
          this.inversorParsed.enabled = true  // Para habilitar el inversor
          this.inversorParsed.model = comp.model;
          this.inversorParsed.inversorIP = comp.inversorIP;
          this.inversorParsed.inversorPort = comp.inversorPort;
          this.solarCfg.battery = comp.battery;
          this.checkChangesSolar();
      }
    });
  }

  openSecondaryMeterCfg(){
    this.modalMain=this._modal.open(ModalSecondaryMeterComponent);
    this.modalMain.componentInstance.splMeter=this.splCfg.splMeter;
    switch (this.splCfg.splMeterAux) {
      case 3:
        this.modalMain.componentInstance.shellyMainIp = this.shellyMainCfg.ip;
        this.modalMain.componentInstance.shellyMainModel = this.shellyMainCfg.model;
        this.modalMain.componentInstance.shellySecondaryIp = this.shellyAuxCfg.ip;
        this.modalMain.componentInstance.shellySecondaryModel = this.shellyAuxCfg.model;
        break;
      case 6:
        this.modalMain.componentInstance.p1WifiMainIp = this.shellyMainCfg.ip;
        break;
      default:
        break;
    }
    this.modalMain.result.then((comp) => {
      this.shellyAuxCfg = comp;
      this.checkChangesShelly();
    });
  }

  hasChanges(){
    return Object.values(this.changes).every(item => item === false);
  }

  checkChangesUnswitchCurrent(){
    if (this.unswitchCurrent > 7360 ) this.unswitchCurrent = 7360;
    if (this.unswitchCurrent < 4140 ) this.unswitchCurrent = 4140;

    if (this.origninalUnswitchCurrent != this.unswitchCurrent){
      this.changes.unswitchCurrent = true;
      this.body.unswitchCurrent =  Math.floor(this.unswitchCurrent / 230 * 1000 ) ;
    }else{
      this.changes.unswitchCurrent = false
    }
  }

  changeSafetyOnRetries(){
    this.safetyOnRetries = !this.safetyOnRetries
    this.body.safetyOnRetries == 3 ? this.body.safetyOnRetries = 0 : this.body.safetyOnRetries = 3;
    console.log( this.body.safetyOnRetries )
    if (this.body.safetyOnRetries === this.originalData.safetyOnRetries  ){
      this.changes.safetyOnRetries = false
    }else{
      this.changes.safetyOnRetries = true

    }

  }
}
