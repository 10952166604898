import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DeviceService } from 'src/app/services/devices/device.service';
import { compare } from 'compare-versions';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-all-settings',
  templateUrl: './all-settings.component.html',
  styleUrls: ['./all-settings.component.scss']
})
export class AllSettingsComponent implements OnInit {

  // 0: "astcal"
  // 1: "sys"
  // 2: "fwupd"
  // 3: "socket"
  // 4: "mqtt"
  // 5: "modulator"
  // 6: "spl"
  // 7: "reqman"
  // 8: "evsm"
  // 9: "network"
  // 10: "rfid"
  // 11: "schedman"
  // 12: "mennekes"
  // 13: "schuko"

  selectedSetting = '';
  loading = false;

  serial: string = '';
  modules: any[] = [];

  connectors: any;

  newInstallSectionCompatible: boolean = false;
  isAdmin = false;
  isOwner = false;

  constructor(private _device: DeviceService, private _aRoute: ActivatedRoute, private _user: UserService) { }

  ngOnInit(): void {

    this._aRoute.parent?.parent?.params.subscribe((params: Params) => {
        this.serial = params['serial']
        var deviceInfo = this._device.getActiveDeviceInfo();
        if(deviceInfo)
          this.newInstallSectionCompatible = compare(deviceInfo.firmware_version_name, '7.2.0', '>=');
        else{
          this._device.getDeviceInfo(this.serial).subscribe(data => {
            this._device.setActiveDeviceInfo(data);
            this.newInstallSectionCompatible = compare(data.firmware_version_name, '7.2.0', '>=');
          });
        }
    });

    this.loading = true


    this._device.getModulesByDevice(this.serial).subscribe(data => {
        console.log(data);
        data.forEach(module => {
          this.modules.push(module.topic_name);
        });
        this.loading = false
        console.log(this.modules)
      })

      this._device.getElemDevice(this.serial).subscribe(data => {
          this.connectors=data;
          console.log(data)
        });
    this.checkChargerRole(this.serial);
  }

  checkChargerRole(serial){
    this._user.getUserInfo().subscribe((user) => {
      this.isAdmin = user.role === 'admin';
      this._device.getChargerOwner(serial).subscribe((co) => {
        this.isOwner = user.id === co.owner_user_id;
      });
    });
  }
}
