<div tabindex="-1" role="dialog" aria-hidden="true">
    <a href="#close" class="modal-overlay" aria-label="Close" (click)="close()"></a>
    <div class="modal-container">
      <div class="modal-header d-flex">
        <div class="col-9">

          <div class="modal-title h5">{{"solarMode" | translate}}</div>
        </div>

        <a href="#close" class="btn btn-clear float-right" aria-label="Close" (click)="close()"></a>
      </div>
      <div class="modal-body">
        <div class="content">
            <p class="text-grey">{{"solarPrioritySelectMode" | translate}}</p>
            <!-- TODO: Refactor "green" por Solar-->
          <div class="card_mode_little green_card" [ngClass]="{'card_closed': modeSelected!='green','border-primary': modeSelected=='green'}" id="card_green_0" (click)="selectModeCard('green')">
            <div class="overflow-hidden h-100">
                <h4 class="text-center">{{"solar" | translate}}</h4>
                <p class="text-grey mb-2">{{"smartEco_green" | translate}}</p>
                <div class="col-6 m-auto">
                  <ul class="text-grey">
                    <li style="width: 100%" class="d-flex" data-translate="string_100pcent">
                      <svg style="width:18px;height:18px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M17,8C8,10 5.9,16.17 3.82,21.34L5.71,22L6.66,19.7C7.14,19.87 7.64,20 8,20C19,20 22,3 22,3C21,5 14,5.25 9,6.25C4,7.25 2,11.5 2,13.5C2,15.5 3.75,17.25 3.75,17.25C7,8 17,8 17,8Z" />
                      </svg>
                      &nbsp;{{"smartEco_green_1" | translate}}
                    </li>
                    <li style="width: 100%" class="d-flex" data-translate="string_doesntGuar">
                      <svg style="width:18px;height:18px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M20.84 22.73L15.31 17.2L14.5 18V21H9.5V18L6 14.5V9C6 8.7 6.1 8.41 6.25 8.14L1.11 3L2.39 1.73L22.11 21.46L20.84 22.73M18 14.5V9C18 8 17 7 16 7V3H14V7H10.2L17.85 14.65L18 14.5M10 3H8V4.8L10 6.8V3Z" />
                       </svg>
                       &nbsp;{{"noLoadGuarantee" | translate}}</li>
                  </ul>
                </div>
            </div>
          </div>
          <div class="card_mode_little hybrid_card" [ngClass]="{'card_closed': modeSelected!='hybrid','border-primary': modeSelected=='hybrid'}" id="card_hybrid_0" (click)="selectModeCard('hybrid')">
            <div class="overflow-hidden h-100">
                <h4 class="text-center">{{"smartEco_hybrid_title" | translate}}</h4>
                <p class="text-grey m-0">{{"hybridInfoMessage" | translate}}</p>
                <div class="col-6 m-auto">
                  <ul class="text-grey">
                    <li style="width: 100%" class="d-flex">
                      <svg style="width:18px;height:18px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z" />
                    </svg>
                    &nbsp;{{"hybridYouDecideSpend" | translate}}</li>
                    <li style="width: 100%" class="d-flex">
                      <svg style="width:18px;height:18px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M20.84 22.73L15.31 17.2L14.5 18V21H9.5V18L6 14.5V9C6 8.7 6.1 8.41 6.25 8.14L1.11 3L2.39 1.73L22.11 21.46L20.84 22.73M18 14.5V9C18 8 17 7 16 7V3H14V7H10.2L17.85 14.65L18 14.5M10 3H8V4.8L10 6.8V3Z" />
                       </svg>&nbsp;{{"noLoadGuarantee" | translate}}</li>
                  </ul>
                </div>
            </div>
          </div>
          <div class="card_mode_little ac_card" [ngClass]="{'card_closed': modeSelected!='ac','border-primary': modeSelected=='ac'}" id="card_ac_0" (click)="selectModeCard('ac')">
            <div class="overflow-hidden h-100">
                <h4 class="text-center">{{"solarAndNet" | translate}}</h4>
                <p class="text-grey m-0">{{"ecoInfoMessage" | translate}}</p>
                <div class="col-6 m-auto">
                  <ul class="text-grey">
                    <li style="width: 100%" class="d-flex">
                      <svg style="width:18px;height:18px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12 20H4V6H12M12.67 4H11V2H5V4H3.33C2.6 4 2 4.6 2 5.33V20.67C2 21.4 2.6 22 3.33 22H12.67C13.41 22 14 21.41 14 20.67V5.33C14 4.6 13.4 4 12.67 4M11 16H5V19H11V16M11 7H5V10H11V7M11 11.5H5V14.5H11V11.5M23 10H20V3L15 13H18V21" />
                    </svg>
                    &nbsp;{{"maxLoadVelocity" | translate}}</li>
                    <li style="width: 100%" class="d-flex">
                      <svg style="width:18px;height:18px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M17,8C8,10 5.9,16.17 3.82,21.34L5.71,22L6.66,19.7C7.14,19.87 7.64,20 8,20C19,20 22,3 22,3C21,5 14,5.25 9,6.25C4,7.25 2,11.5 2,13.5C2,15.5 3.75,17.25 3.75,17.25C7,8 17,8 17,8Z" />
                      </svg>
                      &nbsp;{{"yesLoadGuarantee" | translate}}</li>
                  </ul>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn-primary btn" (click)="close()">{{"ok" | translate}}</button>
      </div>
    </div>
</div>
