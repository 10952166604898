export class Stat{
    evsePower= 0;
    homeEnergy= 0;
    homeEnergyByPhase;
    homePower= 0;
    limitPower= 0;
    fvPower= 0;
    battEnergy = 0;
    battPercent = 0;
    instPower=0;
    limitPowerByPhase;
    instPowerByPhase;
    mbusDetected= 0;
    relOverload= 0;
    relOverloadByPhase=[0,0,0];
    selectorPower= 0;
    splDetected= 0;
    status= 0;
    timestamp = new Date();
    totalCurrent;
    totalEnergy= 0;
    totalEnergyByPhase = [];
    totalPower= 0;
    totalPowerByPhase;
    splEvsePower=0;
    splEvsePowerByPhase=[0,0,0]

    constructor(values: Object = {}) {
        Object.assign(this, values);
        this.timestamp = new Date();
        this.instPowerByPhase = [-1,-1,-1];
    }

    update(newObj){
        Object.entries(newObj).forEach(([key, value])=>{
            this[key] = value;
        });
    }
}
