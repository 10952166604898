<div [ngClass]="{'col-12 col-xl-6' : !allSettings}">
    <div class="card">
        <div class="card-header">
            <div class="float-right fit-content h-100 d-flex align-items-center mt-2">

                <label class="col-7 text-right text-bold">{{showIpManualWifi ? 'Manual' : 'DHCP'}}</label>

                <div class="col-sm-4 text-right">

                <label class="switch">
                    <input type="checkbox"  (click)="setIpType()" name="wifiDhcpen" [(ngModel)]="body.wifiDhcpen"
                        (change)="changesMade=_dService.checkChangesSettings(originalData,body)">
                    <span class="slider round"></span>
                </label>
            </div>
            </div>
            <h5 class="card-title">{{"wifi" | translate}}</h5>
            <h6 class="card-subtitle text-muted">{{"wifi_text" | translate}}</h6>
        </div>

        <div class="card-body">
            <form>
                <div class="form-group row">
                    <label class="col-form-label col-sm-4 text-sm-right">{{"ipAddress" | translate}}</label>
                    <div class="col-sm-8">
                        <input [disabled]="body.wifiDhcpen" type="text" class="form-control"
                            placeholder="{{'ipAddress' | translate}}" name="ipstatic" [(ngModel)]="body.wifiipstatic"
                            (keyup)="changesMade=_dService.checkChangesSettings(originalData,body)">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-sm-4 text-sm-right">{{"subnetMask" |
                        translate}}</label>
                    <div class="col-sm-8">
                        <input [disabled]="body.wifiDhcpen" type="text" class="form-control"
                            placeholder="{{'subnetMask' | translate}}" name="wifimask" [(ngModel)]="body.wifimask"
                            (keyup)="changesMade=_dService.checkChangesSettings(originalData,body)">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-sm-4 text-sm-right">{{"gateway" | translate}}</label>
                    <div class="col-sm-8">
                        <input [disabled]="body.wifiDhcpen" type="text" class="form-control"
                            placeholder="{{'gateway' | translate}}" name="wifigw" [(ngModel)]="body.wifigw"
                            (keyup)="changesMade=_dService.checkChangesSettings(originalData,body)">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-sm-4 text-sm-right">{{"mac_address" |
                        translate}}</label>
                    <div class="col-sm-8">
                        <input type="text" readonly class="form-control" placeholder="{{'mac_address' | translate}}"
                            name="macaddress" value="{{deviceObj?.mac}}">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-sm-4 text-sm-right">{{"dnsServer" |
                        translate}}</label>
                    <div class="col-sm-8">
                        <input [disabled]="body.wifiDhcpen" type="text" class="form-control"
                            placeholder="{{'dnsServer' | translate}}" name="wifidns" [(ngModel)]="body.wifidns"
                            (keyup)="changesMade=_dService.checkChangesSettings(originalData,body)">
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10 ml-sm-auto">
                        <button type="submit" class="btn btn-primary" (click)="save()"
                            [disabled]="!changesMade">{{"overwrite" | translate}}</button>
                        <button type="submit" class="btn btn-light ml-2" [disabled]="!changesMade">{{"restore"
                            | translate}}</button>
                    </div>

                </div>
                <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="error!=''">
                    <div class="alert-message">
                        {{ error }}
                    </div>
                </div>
                <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successMessage">
                    <div class="alert-message">
                        {{"updatedSuccesfully" | translate}}
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
