<div tabindex="-1" role="dialog" aria-hidden="true">
    <div role="document">
        <div>
            <div class="modal-header">
                <h5 class="modal-title">{{"diagnosis" | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body m-3">
                <p class="mb-0">{{"diagnosisInspectMessage" | translate}}</p>
                <div class="form-group col-xl-12 justify-content-center d-flex">
                        <div class="col-6">
                            <label for="">{{"yourDevices" | translate}}:</label>
                            <select type="text" class="form-control" [(ngModel)]="yourDevice" name="yourDevice">
                                <option [value]="d.serial" *ngFor="let d of devices">{{d.serial}}</option>
                            </select>
                        </div>
                    <div class="col-6">
                        <label for="">{{"others" | translate}}</label>
                        <input type="text" class="form-control" [placeholder]="'name' | translate" [(ngModel)]="otherDevice" name="deviceName" (keyup.enter)="passBack()">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="passBack()">{{"inspect" | translate}}</button>
            </div>
        </div>
    </div>
</div>
