import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Auth, verifyPasswordResetCode, confirmPasswordReset, applyActionCode } from '@angular/fire/auth';
import { CustomModalComponent } from 'src/app/components/ui/custom-modal/custom-modal.component';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {

  userInfo = {
    password: ''
  }

  email: string = '';
  modalLoad: NgbModalRef;
  modalOk: NgbModalRef;
  errorModal: NgbModalRef;
  modalConfirm: NgbModalRef;

  rePass = '';
  error = false;
  errorPasswords = false;

  oobCode: string = '';
  mode: string = '';

  constructor(private _modal: ModalService, private _translate: TranslateService, private _aRoute: ActivatedRoute, private _auth: Auth, private _router: Router) { }

  ngOnInit(): void {
    this._aRoute.queryParams.subscribe(params => {
      this.oobCode = params['oobCode'];
      this.mode = params['mode'];

      if (this.mode === 'verifyEmail') {
        this.verifyEmail();
      } else if (this.mode === 'resetPassword') {
        this.verifyPasswordResetCode();
      }
    });
  }

  verifyPasswordResetCode() {
    verifyPasswordResetCode(this._auth, this.oobCode).then((email: string) => {
      this.email = email;
    }).catch((error: any) => {
      this.error = true;
      this.modalOk = this._modal.open(CustomModalComponent);
      this.modalOk.componentInstance.title = this._translate.instant('error');
      this.modalOk.componentInstance.description = this._translate.instant('linkNotValid');
    });
  }

  verifyEmail() {
    applyActionCode(this._auth, this.oobCode)
      .then(() => {
        this.modalOk = this._modal.open(CustomModalComponent);
        this.modalOk.componentInstance.title = this._translate.instant('accountVerification');
        this.modalOk.componentInstance.description = this._translate.instant('accountVerificationOk');

        setTimeout(() => this._router.navigate(['/login']), 1000);
      })
      .catch((error: any) => {
        this.errorModal = this._modal.open(CustomModalComponent);
        this.errorModal.componentInstance.title = this._translate.instant('accountVerification');
        this.errorModal.componentInstance.description = this._translate.instant('accountVerificationError');
      });
  }

  recover() {
    this.error=false;
    this.errorPasswords=false;

    if (this.rePass != this.userInfo.password) {
      this.errorPasswords = true;
      return;
    }

    this.modalLoad = this._modal.open(LoadingPopupComponent);
    confirmPasswordReset(this._auth, this.oobCode, this.userInfo.password)
    .then(() => {
      this.modalLoad.dismiss();

      this.modalOk = this._modal.open(CustomModalComponent);
      this.modalOk.componentInstance.title = this._translate.instant('recoverRestablished');
      this.modalOk.componentInstance.description = this._translate.instant('recoverRestablishedSub');

      setTimeout(() => this._router.navigate(['/login']), 1000);
    })
    .catch((error: any) => {
      this.modalLoad.dismiss();

      this.errorModal = this._modal.open(CustomModalComponent);
      this.errorModal.componentInstance.title = this._translate.instant('error');
      this.errorModal.componentInstance.description = this._translate.instant('recoverPassError');
    });
  }


}
