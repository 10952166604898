<!--ADD-->
<div role="modal" *ngIf="data.option === 'add'">
  <form #form="ngForm">
    <div class="modal-header">
      <h5 class="modal-title">{{"addCard" | translate}}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body m-3">
      <div class="form-group col-xl-12 justify-content-center d-flex align-items-center">
        <div class="col-6">
          <label for="">{{"expiryDate" | translate}}</label>
        </div>
        <div class="col-6">
          <div class="d-flex align-items-center">
            <input class="form-control" [(ngModel)]="body.expiryDate" type="datetime-local" id="datetime"
              name="datetime" value="{{body.expiryDate}}" required />
          </div>
        </div>
      </div>
      <div class="form-group col-xl-12 justify-content-center d-flex">
        <div class="col-6">
          <label for="">{{"group" | translate}}</label>
        </div>
        <div class="col-6">
          <input class="form-control" type="text" id="group" name="group" [(ngModel)]="body.parentIdTag"
            (ngModelChange)="controlInputs()">
        </div>
      </div>
      <div class="form-group col-xl-12 justify-content-center d-flex">
        <div class="col-6">
          <label for="">{{"status" | translate}}</label>
        </div>
        <div class="col-6">
          <select type="text" class="form-select" placeholder="Select option" [(ngModel)]="body.status" id="select"
            name="select" (ngModelChange)="controlInputs()">
            <option value="" disabled selected>Select card status</option>
            <option [value]="stt.value" *ngFor="let stt of statusOpt">{{stt.viewValue}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="close()">{{"cancel" | translate}}</button>
      <button type="button" class="btn btn-primary" [disabled]="buttonDis" (click)="sendData()">{{"save" | translate}}</button>
    </div>
  </form>
</div>

<!--MODIFY-->
<div role="modal" *ngIf="data.option === 'edit'">
  <form #form="ngForm">
    <div class="modal-header">
      <h5 class="modal-title">{{"editCard" | translate}}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body m-3">
      <div class="form-group col-xl-12 justify-content-center d-flex align-items-center">
        <div class="col-6">
          <label for="">{{"expiryDate" | translate}}</label>
        </div>
        <div class="col-6">
          <div class="d-flex align-items-center">
            <input class="form-control" [(ngModel)]="body.expiryDate" type="datetime-local" id="datetime"
              name="datetime" value="{{body.expiryDate}}" [min]="actualDate" (ngModelChange)="controlInputs()" />
          </div>
        </div>
      </div>
      <div class="form-group col-xl-12 justify-content-center d-flex">
        <div class="col-6">
          <label for="">{{"group" | translate}}</label>
        </div>
        <div class="col-6">
          <input class="form-control" type="text" id="group" name="group" [(ngModel)]="body.parentIdTag"
            (ngModelChange)="controlInputs()">
        </div>
      </div>
      <div class="form-group col-xl-12 justify-content-center d-flex">
        <div class="col-6">
          <label for="">{{"status" | translate}}</label>
        </div>
        <div class="col-6">
          <select type="text" class="form-select" placeholder="Select option" [(ngModel)]="body.status" id="select"
            name="select" (ngModelChange)="controlInputs()">
            <option value="" disabled selected>Select card status</option>
            <option [value]="stt.viewValue" *ngFor="let stt of statusOpt">{{stt.viewValue}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="close()">{{"cancel" | translate}}</button>
      <button type="button" class="btn btn-primary" [disabled]="buttonDis" (click)="sendData()">{{"save" | translate}}</button>
    </div>
  </form>
</div>

<!--DELETE-->
<div role="modal" *ngIf="data.option === 'delete'">
  <div class="modal-header">
    <h5 class="modal-title">{{"deleteCard" | translate}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body m-3">
    <div class="col-xl-12">
      <h4 class="text-center">{{"alertDeleteCard" | translate}}</h4>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="close()">{{"cancel" | translate}}</button>
    <button type="button" class="btn btn-primary" (click)="sendData()">{{"editProfileDeleteAccountConfirmationTitle" | translate}}</button>
  </div>
</div>

<!--DELETE All-->
<div role="modal" *ngIf="data.option === 'deleteAll'">
  <div class="modal-header">
    <h5 class="modal-title">{{"deleteAllRfidCardsTit" | translate}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body m-3">
    <div class="col-xl-12">
      <h4 class="text-center">{{"deleteAllRfidCardsBody" | translate}}</h4>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="close()">{{"cancel" | translate}}</button>
    <button type="button" class="btn btn-primary" (click)="sendData()">{{"editProfileDeleteAccountConfirmationTitle" | translate}}</button>
  </div>
</div>

