<div tabindex="-1" role="dialog" aria-hidden="true">
    <div role="document">
        <div>
            <div class="modal-header">
                <h5 class="modal-title">{{"meterAux" | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!-- Shelly -->
            <div class="modal-body m-3">
                <!-- SameMainMeter -->
                <div class="d-flex mb-3 align-items-center" *ngIf="splMeter==3 && shellyMainModel==0">
                    <label class="mb-0 mr-2">{{"shellySameMainMeter" | translate}}:</label>
                    <label class="switch">
                        <input type="checkbox" [checked]="sameMainMeter" (click)="changeSameMeter()" name="sameMainMeter">
                        <span class="slider round"></span>
                    </label>
                </div>
                <!-- ShellySecondaryIp -->
                <div class="form-group row">
                    <div class="col-12 d-flex align-items-center">
                        <label class="col-form-label">{{"IPShelly" | translate}} (P2):</label>
                    </div>
                    <div class="col-12 d-flex">
                        <input type="text" [disabled]="sameMainMeter" class="form-control" name="shellySecondaryIp" [(ngModel)]="shellySecondaryIp">
                    </div>
                </div>
                <!-- ShellySecondaryModel -->
                <div class="form-group row">
                    <div class="col-12 d-flex align-items-center">
                        <label class="col-form-label">{{"shellyModel" | translate}}:</label>
                    </div>
                    <div class="col-12 d-flex">
                        <select class="form-select form-select-group" [disabled]="sameMainMeter" id="shellySecondaryModel" name="shellySecondaryModel" [(ngModel)]="shellySecondaryModel">
                            <option *ngFor="let item of shellyMainModels" [ngValue]="item.value">{{item.label}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
