import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mqtt-api',
  templateUrl: './mqtt-api.component.html',
  styleUrls: ['./mqtt-api.component.scss']
})
export class MqttApiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
