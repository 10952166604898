import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonStringify'
})
export class JsonStringifyPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): string {
    // Convert the object to a JSON string with indentation
    let jsonString = JSON.stringify(value, null, 2);

    // Replace commas with commas followed by a newline
    jsonString = jsonString.replace(/,/g, ',\n');

    // Replace curly braces with spaces
    jsonString = jsonString.replace(/{|}/g, ' ');

    // Remove quotes around keys
    jsonString = jsonString.replace(/"([^"]+)":/g, '$1:');

    // Remove quotes around values
    jsonString = jsonString.replace(/"([^"]+)"/g, '$1');

    return jsonString;
  }
}
