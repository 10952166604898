import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { ModalSchedulerTaskComponent } from 'src/app/components/ui/modal-scheduler-task/modal-scheduler-task.component';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-charge-scheduler',
  templateUrl: './charge-scheduler.component.html',
  styleUrls: ['./charge-scheduler.component.scss']
})
export class ChargeSchedulerComponent implements OnInit {

  device: string;
  tasks: any = { timeTasks: {}, powerTasks: { weekday: [], weekend: [] }};
  originalTasks: any = {};
  powerTasks: any = { weekday: [], weekend: [] };

  modalAdd: NgbModalRef;
  newTaskId: number;

  @Input()
  allSettings = false;

  @Input()
  connectors: any;

  modalLoad: NgbModalRef;

  successMessage = {};
  error = {};

  constructor(public _dService: DeviceService, private _aRoute: ActivatedRoute, private _modal: ModalService, public _utils: UtilsService) { }

  ngOnInit(): void {
    this.powerTasks = { weekday: [], weekend: [] };

    this._aRoute.parent?.parent?.params
      .subscribe((params: Params) => this.device = params['serial']);

      setTimeout(() => {
        this.connectors.forEach(conn => {
          this._dService.getElementModule(this.device, 'schedman', conn.name)
            .subscribe(data => {
              let t = JSON.parse(data.cfg.tasks);
              console.log(this.tasks);

              t = t.filter(task=>task.type==0);
              t = t.sort((t1, t2)=>t1.initTime.timeList[0].hourMin-t2.initTime.timeList[0].hourMin);
              this.tasks['timeTasks'][conn.name] = t;

              t = JSON.parse(data.cfg.tasks);
              t = t.filter(task=>task.type==1);
              t = t.sort((t1, t2)=>t1.initTime.timeList[0].hourMin-t2.initTime.timeList[0].hourMin);
              
              this.tasks['powerTasks'][conn.name]={};
              this.tasks['powerTasks'][conn.name]['weekday'] = t.filter(ta=>ta.initTime.weekday==62)||[];
              this.powerTasks['weekday']=this.powerTasks['weekday'].concat(t.filter(ta=>ta.initTime.weekday==62));
              this.tasks['powerTasks'][conn.name]['weekend'] = t.filter(ta=>ta.initTime.weekday==65)||[];
              this.powerTasks['weekend']=this.powerTasks['weekend'].concat(this.tasks['powerTasks'][conn.name]['weekend']);

              this.newTaskId=this.getFreeIdFromTasks();

              console.log(this.tasks)

              this.successMessage[conn.name]=false;
              this.error[conn.name]='';
            })
        });
      }, 500);
 
  }

  openAddTask(cName){
    this.modalAdd=this._modal.open(ModalSchedulerTaskComponent);
    this.modalAdd.componentInstance.id=this.newTaskId;
    this.modalAdd.componentInstance.type=0;
    this.modalAdd.componentInstance.weekday=0;

    this.modalAdd.result.then((task) => {
      this.tasks['timeTasks'][cName].push(task);
      console.log(this.tasks['timeTasks']);
      this.newTaskId=this.getFreeIdFromTasks();
     
    }, (reason) => {
      // on dismiss
    });
  }

  getFreeIdFromTasks(){
    let idTaskDisp = 1;
    let found = false;
  
    let ids: any[] = [];

    this.powerTasks['weekday'].forEach(t => {
      ids.push(t.id);
    });
        
    this.powerTasks['weekend'].forEach(t => {
      ids.push(t.id);
    });

    this.connectors.forEach(element => {
      if(this.tasks['timeTasks'][element.name])
        this.tasks['timeTasks'][element.name].forEach(t => {
          ids.push(t.id);
        });
    });
    

    console.log(ids);

    while(!found){
      if(ids.includes(idTaskDisp)) idTaskDisp++;
      else found=true;
    }
    

    console.log("NEW ID: " + idTaskDisp);
    return idTaskDisp;
  }

  save(element){
    let t = this.tasks['timeTasks'][element];
    this.modalLoad = this._modal.open(LoadingPopupComponent);

    this.saveConfig(element, t);
  }

  saveConfig(element, tasks){
    this._dService.setModuleElement(this.device, 'schedman', { tasks }, element)
        .subscribe(data=>{
          this.successMessage[element] = true;
          this.modalLoad .close();
          setTimeout(() => {
            this.successMessage[element] = false;
          }, 10000);
        }, err => {
          console.error(err)
          this.error[element] = err.error.message;
          this.modalLoad .close();
          setTimeout(() => {
            this.error[element] = '';
          }, 10000);
        })
  }

  deleteTask(id, element){
    var data = {
      tasks: [{id, type:0}]
    }

    this._dService.deleteModuleElement(this.device, 'schedman', data, element)
      .subscribe(data=>{
        this.successMessage[element] = true;
        this.tasks['timeTasks'][element]=this.tasks['timeTasks'][element].filter(t=>t.id!=id);

        setTimeout(() => {
          this.successMessage[element] = false;
        }, 10000);
      }, err => {
        console.error(err)
        this.error[element] = err.error.message;
        setTimeout(() => {
          this.error[element] = '';
        }, 10000);
      })
  }

  openEditTask(task){
    let t = {...task};
    this.modalAdd=this._modal.open(ModalSchedulerTaskComponent);
    this.modalAdd.componentInstance.type=0;
    this.modalAdd.componentInstance.task=task;

    this.modalAdd.result.then((task) => {
      console.log(task)
    }, (reason) => {
      task = t;
    });
  }


}
