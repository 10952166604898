import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ErrorModalComponent } from 'src/app/components/ui/error-modal/error-modal.component';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DeviceService } from 'src/app/services/devices/device.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username = '';
  password = '';

  loginErrorText='';

  errorModal: NgbModalRef;
  modalLoad: NgbModalRef;

  showLogin: boolean = false;

  prod = environment.production;

  constructor(private _auth: AuthService, private _router: Router, private _translate: TranslateService, private _modal: NgbModal, private _devices: DeviceService) {
    this.token = undefined;

   }

   params = new URLSearchParams(window.location.search);
   token: string|undefined;

   captchaRef = undefined;

  ngOnInit(): void {

    // Para detectar el idioma del navegador y aplicarlo a la app
    // console.log(  'Idioma del navegador  '+ this._translate.getBrowserLang() );
    this._translate.use( this._translate.getBrowserLang() );


    this._auth.user.subscribe(data => {
      if(data){
         this.modalLoad=this._modal.open(LoadingPopupComponent, {centered: true});
         this.userAuthenticated();
       }else{
         this.showLogin=true;
       }
     });

  }

  async checkRecaptcha(event){
    this.token = event;
    if(this.token){
      this.login();
    }
  }

  login(){
    this.modalLoad=this._modal.open(LoadingPopupComponent, {centered: true});
    this.loginErrorText=this._translate.instant('invalidCredentials');
    this.showLogin=false;

    this._auth.login(this.username, this.password)
      .then(data=>{
        data.user?.getIdToken().then(token=>{
          this.modalLoad.close();
          this.userAuthenticated();
        });
      }, err=>{
        console.error(err);
        this.modalLoad.close();
        this.errorModal=this._modal.open(ErrorModalComponent);
        this.errorModal.componentInstance.description=this._translate.instant("invalidCredentials");
        this.showLogin=true;
      })
  }

  userAuthenticated(){
    let role = sessionStorage.getItem('role');
    let _url = localStorage.getItem('url_before_redirect');
    this._devices.getDevicesByUser().subscribe((devices) => {
      this.modalLoad.close();
      if(_url && _url!='/' && _url != '/register' && _url != '/forgot'){
        this._router.navigateByUrl(_url);
        localStorage.removeItem('url_before_redirect');
      }
      else if(role=='admin'){
        this._router.navigate(['/dashboard/administration/search']);
      }else if(devices.length==1){
        //Si sólo tiene un cargador, redirigirle directamente a él.
        this._router.navigate(['/dashboard/device',devices[0].serial]);
      }else{
        //Mandamos al dashboard para que seleccione el que quiera.
        this._router.navigate(['/dashboard']);
      }
    });


  }



}
