import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Chart from 'chart.js';
import { infoModulator } from 'src/app/models/Modulator/infoModulator';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';

@Component({
  selector: 'app-device-settings-spl',
  templateUrl: './device-settings-spl.component.html',
  styleUrls: ['./device-settings-spl.component.scss'],
})
export class DeviceSettingsSplComponent implements OnInit {
  infoModulator: infoModulator;
  infoSpl: any;
  charts: any = [];
  charts2: any = [];
  serial: string;
  device: any;
  userRole;
  progressValue: number = 65;
  canvas: any;
  ctx: any;
  canvas2: any;
  ctx2: any;
  dataObject: any;
  lastReport = Date.now();
  intervalRT: any;
  deviceConnected = false;
  loading = false;
  splData: any;
  rangeOverall: any;
  splMode:number;
  splOperativeMode: number;
  splElements: any = [];
  splDataObject: any = {
    relOverload: 0,
    limitPower: 0,
    totalPower: 0,
    valueD: [0, 0],
    gauge: [
      {
        id: 'donutIns0',
        limitPowerByPhase: 0,
        totalPowerByPhase: 0,
        relOverload: 0,
      },
      {
        id: 'donutIns1',
        limitPowerByPhase: 0,
        totalPowerByPhase: 0,
        relOverload: 0,
      },
      {
        id: 'donutIns2',
        limitPowerByPhase: 0,
        totalPowerByPhase: 0,
        relOverload: 0,
      },
    ],
  };
  splDataObject2: any = {
    relChgLineOverload: 0,
    limitPower: 0,
    splEvsePower: 0,
    valueD: [0, 0],
    gauge: [
      {
        id: 'donutChg0',
        splLimitPowerByPhase: 0,
        splEvsePowerByPhase: 0,
        relChgLineOverload: 0,
      },
      {
        id: 'donutChg1',
        splLimitPowerByPhase: 0,
        splEvsePowerByPhase: 0,
        relChgLineOverload: 0,
      },
      {
        id: 'donutChg2',
        splLimitPowerByPhase: 0,
        splEvsePowerByPhase: 0,
        relChgLineOverload: 0,
      },
    ],
  };
  splLimitPower: number;
  splLimitPowerByPhase: number;
  toolTipPbp: any;

  constructor(
    private _aRoute: ActivatedRoute,
    public _router: Router,
    private _ws: WebsocketService,
    private _device: DeviceService,
    private _modal: ModalService,
    private _translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initComponents();
  }

  initComponents() {
    this.loading = true;
    this.toolTipPbp = this._translate.instant('powerByPhase'),

    this.userRole = sessionStorage.getItem('role') || '';
    this.serial = this._aRoute.snapshot.params.serial;
    /*this._router.events.subscribe((val) => {
      if (
        val instanceof NavigationEnd &&
        this.serial != this._aRoute.snapshot.params.serial &&
        this._router.url.includes('spl')
      ) {
        this.completeRT();
        this.infoModulator = new infoModulator();
        this.deviceConnected = false;
        this.loadData();
        this.connectWS();
      }
    });*/
    this._aRoute.parent?.params.subscribe((params: Params) => {
      this.serial = params['serial'];
    });
    this._device.getDeviceInfo(this.serial).subscribe((data) => {
      this.device = data;
      this._device.setActiveDeviceInfo(data);
    });
    this._device.getModuleInfo(this.serial, "spl").subscribe((data) => {
      console.log(data);
      if(data.cfg){
        this.splMode = data.cfg.splMode
        this.splOperativeMode = data.cfg.splFactory;
        this.splLimitPower = data.cfg.splLimitPower
        this.splLimitPowerByPhase = JSON.parse(data.cfg.splLimitPowerByPhase)
      }
      if (data.stat) {
        let info = JSON.parse(data.stat.splElements);
        info.forEach((x) => {
          this.splElements.push({
            serial: this.getSerial(x.mac),
            pbp: x.power,
            chargeEnergy: x.chargeEnergy,
            connectorId: x.connectorId + 1,
            current: x.current,
            disconnected: x.disconnected,
            flags: x.flags,
            index: x.index,
            isMaster: x.isMaster,
            mac: x.mac,
            phase: x.phase,
            phaseCount: x.phaseCount,
            power: x.phase,
            pwm: this.calcPWM(x.pwm),
            totalEnergy: x.totalEnergy,
          });
        });
      }
      console.log(this.splElements);
    });

    var time = this._device.isFirstLoad ? 1000 : 500;
    this.infoModulator = new infoModulator();

    setTimeout(() => {
      this.loadData();
    }, time);
    this.connectWS();
  }

  connectWS() {

    this._ws.connect(this.serial).subscribe((data) => {

        this.lastReport = Date.now();
        if (data) {
          switch (data.module) {
            case 'modulator':
              if (!data.element && data.data) {
                this.infoModulator = data.data;
                if (this.charts.length>0) {
                  this.updatePies(this.infoModulator.stat.status);
                } else {
                  this.loadData();
                }
                break;
              } else {
                break;
              }

            case 'spl':
              this.infoSpl = data.data.stat
              this.updateTable(this.infoSpl);
              break;
          }
        }
      },
      (err) => {
        console.log(err);
      },
      () => console.log('complete')
    );
  }
  startRT(context) {
    context._device
      .setRT(this.serial, { period: 1, timeout: 120, status: true })
      .subscribe(
        (data) => {
          if (data) {
            if (!this.deviceConnected) {
              this._modal.showSuccess(
                this._translate.instant('deviceConnectedToServer'),
                ''
              );
              this.deviceConnected = true;
            }
          }
        },
        (err) => {
          if (this.deviceConnected || this.deviceConnected == undefined)
            this._modal.showWarn(
              this._translate.instant('deviceDisconnectedFromServer'),
              this._translate.instant('connectionCouldNotBeStablished')
            );
        }
      );
  }
  completeRT() {
    this._device
      .setRT(this.device, { period: 1, timeout: 120, status: false })
      .subscribe((data) => {
        console.log(data);
      });

    clearInterval(this.intervalRT);
    this._ws.close();
  }
  loadData() {
    this.startRT(this);
    let context = this;
    this.intervalRT = setInterval(() => {
      this.startRT(context);
    }, 120000); //120000
    this._device.getModuleInfo(this.serial, 'modulator').subscribe(
      (data) => {
        if (data) {
          if (this.infoModulator) {
            this.loading = false;
            setTimeout(() => {
              try {
                this.loadGraphPie();
              } catch (error) {
                console.error(error);
              }
            }, 300);
          }
        }
      },
      (err) => {}
    );
  }
  loadGraphPie() {
    for (let index = 0; index < 3; index++) {
      //create installation line donuts
      let labelId = 'donutIns' + index;
      this.canvas = document.getElementById(labelId);
      let ctx = this.canvas.getContext('2d');
      this.charts.push(this.createDoughnutChart(ctx, labelId));
      //create charger line donuts
      let labelId2 = 'donutChg' + index;
      this.canvas2 = document.getElementById(labelId2);
      let ctx2 = this.canvas2.getContext('2d');
      this.charts2.push(this.createDoughnutChart(ctx2, labelId2));
    }
  }
  updatePies(status) {
    //installation line
    let totalPowerByPhase = this.infoModulator.stat.totalPowerByPhase != undefined? this.extract(this.infoModulator.stat.totalPowerByPhase): [0, 0, 0];
    let limitPowerByPhase = this.infoModulator.stat.limitPowerByPhase != undefined? this.extract(this.infoModulator.stat.limitPowerByPhase): [0, 0, 0];
    let relOverload = (this.infoModulator.stat.totalPower / this.infoModulator.stat.limitPower).toFixed(2);
    //charger line
    let splEvsePower = this.infoModulator.stat.splEvsePower ? this.infoModulator.stat.splEvsePower : 0;
    let relChgLineOverload = splEvsePower / this.splLimitPower
    let splEvsePowerByPhase = this.infoModulator.stat.splEvsePowerByPhase ? this.infoModulator.stat.splEvsePowerByPhase : [0 ,0 ,0]
    let donutsChgLineData = this.calcArr(splEvsePowerByPhase,  this.splLimitPowerByPhase)
    //installation line
    this.splDataObject.status = this.infoModulator.stat.status;
    this.splDataObject.totalPowerByPhase = totalPowerByPhase;
    this.splDataObject.relOverload = relOverload;
    this.splDataObject.totalPower = this.infoModulator.stat.totalPower;
    this.splDataObject.limitPower = this.infoModulator.stat.limitPower;
    //charger line
    this.splDataObject2.status = this.infoModulator.stat.status;
    this.splDataObject2.relChgLineOverload = relChgLineOverload.toFixed(2);
    this.splDataObject2.splEvsePower = this.infoModulator.stat.splEvsePower ? this.infoModulator.stat.splEvsePower : 0;
    this.splDataObject2.splLimitPower = this.splLimitPower;

    var color = status == 0 ? '#00778b' : status == 1 ? '#cc0000' : '#f1c232';

    for (let index = 0; index < 3; index++) {
      //installation line
      this.splDataObject.gauge[index].limitPowerByPhase = limitPowerByPhase[index];
      this.splDataObject.gauge[index].totalPowerByPhase = totalPowerByPhase[index];
      this.splDataObject.gauge[index].relOverload = (totalPowerByPhase[index] / limitPowerByPhase[index]).toFixed(2)
      this.charts[index].data.datasets[0].backgroundColor = [color];
      this.charts[index].data.datasets[0].data = [(totalPowerByPhase[index] / limitPowerByPhase[index]).toFixed(2), (1 - (totalPowerByPhase[index] / limitPowerByPhase[index])).toFixed(2)];
      this.charts[index].update();
      //charger line
      this.splDataObject2.gauge[index].splLimitPowerByPhase = this.splLimitPowerByPhase[index];
      this.splDataObject2.gauge[index].splEvsePowerByPhase = splEvsePowerByPhase[index];
      this.splDataObject2.gauge[index].relChgLineOverload = donutsChgLineData[index].toFixed(2)
      this.charts2[index].data.datasets[0].backgroundColor = [color];
      this.charts2[index].data.datasets[0].data = [(splEvsePowerByPhase[index] / this.splLimitPowerByPhase[index]).toFixed(2), (1 - (splEvsePowerByPhase[index] / this.splLimitPowerByPhase[index])).toFixed(2)];
      this.charts2[index].update();
    }
  }
  updateTable(info) {

    let newValue = info.splElements;

    for (let i = 0; i < this.splElements.length; i++) {
      this.splElements[i].serial =  newValue[i].mac ? this.getSerial(newValue[i].mac || 0) : '';
      this.splElements[i].pbp = newValue[i].power;
      this.splElements[i].chargeEnergy =  newValue[i].chargeEnergy;
      this.splElements[i].connectorId =  newValue[i].connectorId;
      this.splElements[i].current =  newValue[i].current;
      this.splElements[i].disconnected =  newValue[i].disconnected;
      this.splElements[i].flags =  newValue[i].flags;
      this.splElements[i].index =  newValue[i].index;
      this.splElements[i].isMaster =  newValue[i].isMaster;
      this.splElements[i].mac =  newValue[i].mac;
      this.splElements[i].phase =  newValue[i].phase;
      this.splElements[i].phaseCount =  newValue[i].phaseCount;
      this.splElements[i].power =  newValue[i].phase;
      this.splElements[i].pwm =  this.calcPWM(newValue[i].pwm);
      this.splElements[i].totalEnergy =  newValue[i].totalEnerg;
    }
    return this.splElements
  }

  calculateRange(limitP, totalC) {
    if (limitP != 0 || totalC != 0) {
      let percent = (totalC / limitP) * 100;
      if (percent < 0 || percent == -0 || isNaN(percent)) {
        return 0;
      } else {
        return percent;
      }
    }
    return 0;
  }
  goToLink(serial: string) {
    if (serial[4] === '4') {
      window.open(
        `https://dev.orbis.com.es/chronograf/sources/1/dashboards/18?tempVars%5Bserial%5D=${serial}`,
        '_blank'
      );
    } else {
      window.open(
        `https://dev.orbis.com.es/chronograf/sources/1/dashboards/19?tempVars%5Bserial%5D=${serial}`,
        '_blank'
      );
    }
  }
  extract(string) {
    let arrayifyData: number[] = [];
    if (string != undefined) {
      string.forEach((x) => {
        arrayifyData.push(parseFloat(x));
      });
      return arrayifyData;
    }
    return arrayifyData;
  }
  getSerial(string) {
    let mac = string.split(':').join('').toUpperCase();
    return 'EVVC_' + mac;
  }
  getSlaveCons(array) {
    if (array.length === 0) {
      return 0;
    }
    const sum = array.reduce((total, num) => total + num, 0).toFixed(2);
    return sum / array.length;
  }
  calcArr(arr1: any[], arr2: any): any[] {

    const result: any[] = [];
    for (let i = 0; i < arr1.length; i++) {
        result.push((arr1[i] / arr2[i]));
    }
    return result;
}
  calcRelChgLineOverload(relOverload:any, relOverloadByPhase: any[]){

    relOverloadByPhase.push(relOverload);
    relOverloadByPhase.sort((a, b) => b - a);
    return relOverloadByPhase[0];
  }
  createDoughnutChart(context, labelId) {
    return new Chart(context, {
      type: 'doughnut',
      data: {
        labels: ['Range'],
        datasets: [
          {
            label: labelId,
            data: [0, 1],
            backgroundColor: ['#00778b'],
          },
        ],
      },
      options: {
        tooltips: {
          enabled: false,
        },
      },
    });
  }
  calcPWM(numbers: number[]): number {
    const nonZeroNumbers = numbers.filter(num => num !== 0);
    if (nonZeroNumbers.length === 0) {
        return 0;
    }
    const minNonZero = Math.min(...nonZeroNumbers);
    return minNonZero / 1000
  }
}




