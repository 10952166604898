<nav class="navbar navbar-expand navbar-theme d-flex  justify-content-md-around"  style="  background-color: '#006272';">

  <!-- <a class="d-flex link-logo" href="#/dashboard"  style="height: 64px;  background-color: '#006272'">
    <div class="img-logo d-flex align-items-center">
      <img src="/assets/img/orbis/orbisIcon.png" width="40" height="40" alt=""  >
      <h1 class="m-0 text-white text-logo-icon ml-3 " >RBIS</h1>
    </div>
  </a> -->

  <div class="container-fluid justify-content-center justify-content-lg-start" (click)="home()">
    <a class="d-flex link-logo" href="#/dashboard" style="height: 64px; background-color: '#006272'">
      <div class="img-logo d-flex align-items-center">
        <img src="/assets/img/orbis/orbisIcon.png" width="40" height="40" alt="">
        <h1 class="m-0 text-white text-logo-icon ml-3">RBIS</h1>
      </div>
    </a>
  </div>

  <div class="navbar-collapse collapse " style="width: fit-content;">
    <ul class="navbar-nav ml-auto ">
      <li class="nav-item dropdown ml-lg-2">
        <a class="nav-link dropdown-toggle position-relative">
          <i class="align-middle fas fa-palette text-white cursor-pointer" (click)="toggleTheme()"></i>
        </a>
      </li>
      <!--LANGUAGE-->
      <li class="nav-item dropdown ml-lg-2">
        <div class="dropdown">
          <div class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="align-middle fas fa-globe-europe text-white cursor-pointer"></i>
          </div>
          <ul class="dropdown-menu dropdown-menu-right">
            <li (click)="changeLang('en')"><a class="dropdown-item"> EN</a></li>
            <li (click)="changeLang('es')"><a class="dropdown-item"> ES</a></li>
            <li (click)="changeLang('it')"><a class="dropdown-item"> IT</a></li>
            <li (click)="changeLang('it')"><a class="dropdown-item"> SK</a></li>
            <li (click)="changeLang('da')"><a class="dropdown-item"> DA</a></li>
            <li (click)="changeLang('de')"><a class="dropdown-item"> DE</a></li>
            <li (click)="changeLang('el')"><a class="dropdown-item"> EL</a></li>
            <li (click)="changeLang('fr')"><a class="dropdown-item"> FR</a></li>
            <li (click)="changeLang('nl')"><a class="dropdown-item"> NL</a></li>
            <li (click)="changeLang('cz')"><a class="dropdown-item"> CZ</a></li>
            <li (click)="changeLang('ru')"><a class="dropdown-item"> RU</a></li>
            <li (click)="changeLang('iw')"><a class="dropdown-item"> IW</a></li>
            <li (click)="changeLang('tr')"><a class="dropdown-item"> TR</a></li>
          </ul>
        </div>
      </li>

      <!--PROFILE-->
      <li class="nav-item dropdown ml-lg-2">
        <div class="dropdown">
          <div class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="align-middle fas fa-user text-white cursor-pointer"></i>
          </div>
          <ul class="dropdown-menu dropdown-menu-right">
            <li><a class="dropdown-item" routerLink="/dashboard/profile"><i
                  class="align-middle mr-1 fas fa-fw fa-user"></i> {{"viewProfile" | translate}}</a></li>
            <li>
              <a class="dropdown-item" (click)="logout()">
                <i class="align-middle mr-1 fas fa-fw fa-arrow-alt-circle-right"></i>
                {{"logOut" | translate}}</a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
  <a  class="sidebar-toggle d-flex ms-4 mr-2 d-lg-none">
    <i class="hamburger align-self-center"></i>
  </a>
</nav>

