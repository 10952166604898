import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { ModalConfirmComponent } from 'src/app/components/ui/modal-confirm/modal-confirm.component';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss'],
})
export class AddDeviceComponent implements OnInit {
  user: string = '';
  disabledBtn = true;
  response = {
    success: false,
    error: false,
  };
  error404 = false
  error500 = false

  body = {
    serial: '',
    name: '',
  };

  buttonOpenConfirm: any;
  buttonCloseConfirm: any;
  loadingModal: NgbModalRef;
  modalSuccess: NgbModalRef;

  constructor(private _device: DeviceService, private _modal: ModalService, private _translate: TranslateService) {}

  ngOnInit(): void {
    this.buttonOpenConfirm = document.getElementById('toggle-name-modal');
    this.buttonCloseConfirm = document.getElementById('dismiss-confirm-modal');
  }

  save() {
    this.buttonOpenConfirm?.click();
  }

  assign() {

    this.buttonCloseConfirm?.click();
    this.loadingModal = this._modal.open(LoadingPopupComponent);

    this.error404 = false
    this.error500 = false;

    this._device.getDeviceInfo(this.body.serial).subscribe( (data) => {
        this._device.assignDevices(this.body.serial, this.user, 'all').subscribe( (data) => {
          console.log(data);

            this.loadingModal.close();
            this.modalSuccess=this._modal.open(ModalConfirmComponent);
            this.modalSuccess.componentInstance.title = this._translate.instant("device_assigned_successfully");

          },
          (err) => {
            this.response.error = true;
            if (err.status === 404) {
              this.loadingModal.close();
              this.error404 = true
              this.resetForm()

            }else if(err.status === 500){
                this.loadingModal.close();
                this.error500 = true
                this.resetForm()
            }
          }
        );
      },
      (err) => {
        this.response.error = true;
        if (err.status === 404) {
          this.error404 = true
          this.loadingModal.close();
          this.resetForm()
        }
      }
    );
  }



  resetForm(){
    this.user = '';
    this.body.serial = '';

  }
}
