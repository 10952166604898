import { CrudModalComponent } from 'src/app/shared/components/modals/crud-modal/crud-modal.component';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { LocalAuthorizationList, idTagInfo } from 'src/app/shared/models/components.interface';
import { Component, Input, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import { ModalConfirmComponent } from 'src/app/components/ui/modal-confirm/modal-confirm.component';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
  selector: 'app-rfid',
  templateUrl: './rfid.component.html',
  styleUrls: ['./rfid.component.scss'],
})

export class RfidComponent implements OnInit {
  @Input() allSettings = false;
  modal: NgbModalRef;
  modalError: NgbModalRef;
  displayModalFile: boolean = false;
  loading: boolean = true;
  mode: any = '';
  cardUserList: any[] = [];
  historics: any = {};
  objToPrint: any = {
    idTag:'',
    idTagInfo: [{
      expiryDate: '',
      parentIdTag: '',
      status: ''
    }]
  };
  data: any[] = [];
  body: any = {
    rfid: 0,
    eocMode: 'own',
    enabled: 0,
    childSync: 0,
  };

  successMessage: boolean = false;
  error: string = '';
  modalLoad: NgbModalRef;
  device: string = '';

  originalData: any;
  originalMode;
  changesMade = false;

  constructor(public _device: DeviceService, private _modal: ModalService, private _aRoute: ActivatedRoute, @Inject(LOCALE_ID) public locale: string, private _translate: TranslateService) {}

  ngOnInit(): void {
    this._aRoute.parent?.parent?.params.subscribe((params: Params) => (this.device = params['serial']));
    this.getModuleInfo(this.device);
    this.getRFIDCards();
  }

  save() {
    this.modalLoad = this._modal.open(LoadingPopupComponent);

    this.body.childSync = this.body.rfid == 1 ? 0 : 1;
    this._device.setModuleCfg(this.device, this.body, 'rfid').subscribe((data) => {
        this.modalLoad.close();
        this.successMessage = true;
        setTimeout(() => {
          this.successMessage = false;
        }, 10000);
      },
      (err) => {
        console.error(err);
        this.error = err.error.message;
        setTimeout(() => {
          this.error = '';
        }, 10000);
        this.modalLoad.close();
      }
    );
  }
  changeMode(mode) {
    if (mode == 'rfid') {
      this.body.rfid = 1;
      this.body.enabled = 1;
    } else if (mode == 'touch') {
      this.body.rfid = 0;
      this.body.enabled = 1;
    } else {
      this.body.rfid = 0;
      this.body.enabled = 0;
    }

    this.changesMade = this._device.checkChangesSettings(
      this.originalData,
      this.body
    );
  }
  /*CRUD*/
  getModuleInfo(device) {
    this._device.getModuleInfo(device, 'rfid').subscribe((data) => {
      this.body = data.cfg ? data.cfg : this.body;
      this.originalData = { ...data.cfg };
      this.originalMode = data.cfg?.rfid;
    });
  }
  getRFIDCards() {
    const serial = this.device;
    this._device.getRfidTagList(serial).subscribe((tag) => {

      if(tag && tag.localAuthorizationList.length != 0){
        this.cardUserList = tag.localAuthorizationList;
      }else{
        this.cardUserList = []
      }
      this.loading = false;
    });
  }
  deleteCard(serial, idTag) {
    this.loading = true
    this._device.deleteRfidCard(serial, idTag).subscribe((data) => {
      this.getRFIDCards();
        this.successMessage = true;
        setTimeout(() => {
          this.successMessage = false;
        }, 10000);
      },
      (err) => {
        console.error(err);
        this.notifyError(err)
        this.error['element'] = err.error.message;
        setTimeout(() => {
          this.error['element'] = '';
        }, 10000);
      }
    );
  }
  deleteAllCards(serial) {
    this.loading = true
    this._device.deleteRfidListCard(serial).subscribe((data) => {
      this.getRFIDCards();
        this.successMessage = true;
        setTimeout(() => {
          this.successMessage = false;
        }, 10000);
      },
      (err) => {
        console.error(err);
        this.notifyError(err)
        this.error['element'] = err.error.message;
        setTimeout(() => {
          this.error['element'] = '';
        }, 10000);
      }
    );
  }
  updateCard(serial, idTag, body){
    this.loading = true
    this._device.updateRfidCard(serial, idTag, body).subscribe( (data) => {

      this.getRFIDCards();
      this.successMessage = true;
      setTimeout(() => {
        this.successMessage = false;
      }, 10000);
    },
    (err) => {
      console.error(err);
      this.notifyError(err)
      this.error['element'] = err.error.message;
      setTimeout(() => {
        this.error['element'] = '';
      }, 10000);
    }
    )
  }
  updateAllCard(serial, body){
    this.loading = true
    this._device.modifyRfidListCards(serial, body).subscribe( (data) => {
      this.getRFIDCards();
      this.successMessage = true;
      setTimeout(() => {
        this.successMessage = false;
      }, 10000);
    },
    (err) => {
      console.error(err);
      this.notifyError(err)
      this.error['element'] = err.error.message;
      setTimeout(() => {
        this.error['element'] = '';
      }, 10000);
    }
    )
  }
  addCard(serial, idTag, body){
    this._device.addRfidCard(serial, idTag, body).subscribe( (data) => {
      this.getRFIDCards();
        this.successMessage = true;
      setTimeout(() => {
        this.successMessage = false;
      }, 10000);
    },
    (err) => {
      console.error(err);
      this.error['element'] = err.error.message;
      setTimeout(() => {
        this.error['element'] = '';
      }, 10000);
    }
    )
  }
  /*FILES*/
  openCrudModal(option: string, id?: string) {
    /*SEND DATA TO MODAL*/
    let card = this.cardUserList.filter((item) => item.idTag === id);
    let dataToSend = {
      option: option,
      serial: this.device,
      idTag: id,
      data: {
        listVersion: '1',
        localAuthorizationList: card,
      },
    };
    this.modal = this._modal.open(CrudModalComponent);
    this.modal.componentInstance.data = dataToSend;

    /*RECIEVE DATA FROM MODAL*/
    this.modal.componentInstance.dataToSend.subscribe((incomingData) => {
      let serial;
      let idTag
      let body

      switch (incomingData.mode) {
        case 'add': {
          serial = this.device;
          /*idTag = incomingData.body.localAuthorizationList[0].idTag*/
          idTag = this.generateTimeStamps()
          body = incomingData.body
          this.addCard(serial, idTag, body)
          break;
        }
        case 'edit': {
          serial = this.device;
          idTag = incomingData.idTag
          body = incomingData.body
          this.updateCard(serial, idTag, body)
          break;
        }
        case 'delete': {
          serial = incomingData.body.serial;
          idTag = incomingData.body.idTag
          this.deleteCard(serial, idTag);
          break;
        }
        case 'deleteAll': {
          serial = incomingData.body.serial;
          this.deleteAllCards(serial)
          break;
        }
        default: {
          break;
        }
      }
    });
  }
  displayFileModal(value: string) {
    this.displayModalFile = !this.displayModalFile;
    this.data = [{ mode: value, data: this.cardUserList }];
    this.data = this.data;
  }
  exportFiles() {
    let hist = this.cardUserList;
    this.objToPrint = [];
    this.objToPrint = hist.forEach((element) => ({
      idTag: element.idTag,
      expiryDate: element.idTagInfo.expiryDate,
      parentIdTag: element.idTagInfo.parentIdTag,
      status: element.idTagInfo.status,
    }));
    const worksheet = XLSX.utils.json_to_sheet(this.objToPrint);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Historics');
    XLSX.utils.sheet_add_aoa(worksheet,[['id', 'Expiry Date', 'Name', 'Status']], { origin: 'A1' });
    XLSX.writeFile(workbook, `rfid_card_list.xls`, { compression: true });
  }
  getFile(data) {
    this.loading = data.loading;
    let arrayFile = data.raw_data;
    const xlsCardsArray: LocalAuthorizationList[] = [];


    for (let i = 1; i < arrayFile.length; i++) {
      if(arrayFile[i].length > 1){
        const item = arrayFile[i];
        const idTag = item[0];
        const expiryDate = item[1] ? moment(item[1]).format('YYYY-MM-DDTHH:mm') : '';
        const parentIdTag = item[2];
        const status = item[3] ? item[3] : 'Accepted';
        const tagInfo: idTagInfo = { parentIdTag, expiryDate, status };
        const lal: LocalAuthorizationList = { idTag: idTag, idTagInfo: tagInfo };
        xlsCardsArray.push(lal);
      }
    }
    this.cardUserList = xlsCardsArray;
    let csvObject = this.formatJson(this.cardUserList)


    if(csvObject.file){
      this.updateAllCard(this.device, csvObject)
    }

    setTimeout(() => {
      this.loading = false;
      this.displayModalFile = false;
    }, 1000);
  }
  /*PROVISIONAL*/
  generateTimeStamps(){
    return Math.floor(Date.now() / 1000).toString()
  }
  formatJson(jsonData: any[]): { file: string } {
    const csvData = jsonData.reduce((acc, obj, i) => {
      const idTag = obj.idTag || '';
      const expiryDate = obj.idTagInfo?.expiryDate || '';
      const parentIdTag = obj.idTagInfo?.parentIdTag || '';
      const status = obj.idTagInfo.status ? this.getCodeFromStatus(obj.idTagInfo.status) : 0;
      const statusCode = status;
      const lineSeparator = i === 0 ? '\n' : '';
      return `${acc}${lineSeparator}${idTag};${expiryDate};${parentIdTag};${statusCode}\r\n`;
    }, '2\r');

    return { file: csvData };
  }
  formatDate(value: any): string {

    const MS_PER_DAY: number = 24 * 60 * 60 * 1000;
    const EPOCH_OFFSET: number = 25569;

    const daysSinceEpoch: number = value - EPOCH_OFFSET;
    const unixTime: number = daysSinceEpoch * MS_PER_DAY;
    const date: Date = new Date(unixTime);

    const year: number = date.getFullYear();
    const month: string = ('0' + (date.getMonth() + 1)).slice(-2);
    const day: string = ('0' + date.getDate()).slice(-2);
    const hours: string = ('0' + date.getUTCHours()).slice(-2);
    const minutes: string = ('0' + date.getUTCMinutes()).slice(-2);
    const seconds: string = ('0' + date.getUTCSeconds()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }
  getCodeFromStatus(status: string): number | string {
    const statusMap: { [key: string]: number | string } = {
      'Accepted': 0,
      'Rejected': 1,
      'Expired': 2,
      'Invalid': 3,
      'In transaction': 4,
      'No credit': 5,
      //'Blocked': 6,
      //'ConcurrentTx': 7,
      //'NotDefined': 8
    };

    const code = statusMap[status];
    return code !== undefined ? code : '';
  }
  notifyError(error): any {
    if(error.status == 404){
      this.modalError = this._modal.open(ModalConfirmComponent);
      this.modalError.componentInstance.title = this._translate.instant('something_failed');
      this.modalError.componentInstance.icon = 'fas fa-exclamation-triangle';
      this.modalError.componentInstance.description = this._translate.instant('deviceNotConnected');
      this.loading = false;
    }
  }
}
