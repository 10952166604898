<div [ngClass]="{'col-12 col-xl-6' : !allSettings}">
  <div class="card">
      <div class="card-header">
          <h5 class="card-title">{{ "MQTT" | translate }}</h5>
          <h6 class="card-subtitle text-muted">{{ "mqttSubTitle" |translate }}</h6>
      </div>
      <div class="card-body">
          <form>
              <div class="form-group row">
                  <label class="col-form-label col-sm-4 text-sm-right">Url</label>
                  <div class="col-sm-8">
                      <input type="text" class="form-control" placeholder="Url" [(ngModel)]="mqtt.mqttUrl" name="mqttUrl" (keyup)="changesMade=_device.checkChangesSettings(originalData,mqtt)">
                  </div>
              </div>
              <div class="form-group row">
                  <label class="col-form-label col-sm-4 text-sm-right">{{"port" | translate}}</label>
                  <div class="col-sm-8">
                      <input type="number" class="form-control" [placeholder]="'port' | translate" name="mqttPort" [(ngModel)]="mqtt.mqttPort" (keyup)="changesMade=_device.checkChangesSettings(originalData,mqtt)" (change)="changesMade=_device.checkChangesSettings(originalData,mqtt)">
                  </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-4 text-sm-right">{{"user" | translate}}</label>
                <div class="col-sm-8">
                    <!-- <input type="number" class="form-control" [placeholder]="'user' | translate" name="userMqtt" [(ngModel)]="mqtt.mqttUser" (keyup)="changesMade=_device.checkChangesSettings(originalData,mqtt)"> -->
                    <input type="text" class="form-control" [placeholder]="'user' | translate" name="mqttUser" [(ngModel)]="mqtt.mqttUser"  (keyup)="changesMade=_device.checkChangesSettings(originalData,mqtt)">
                </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-sm-4 text-sm-right">{{"Password" | translate}}</label>
              <div class="col-sm-8">
                  <!-- <input type="number" class="form-control" [placeholder]="'Password' | translate" name="Password" [(ngModel)]="mqtt.mqttPass" (keyup)="changesMade=_device.checkChangesSettings(originalData,mqtt)"> -->
                  <input type="text" class="form-control" [placeholder]="'Password' | translate" name="mqttPassword"  [(ngModel)]="mqtt.mqttPass"   (keyup)="changesMade=_device.checkChangesSettings(originalData,mqtt)">
              </div>
          </div>


          <div class="accordion" id="acoordionMqtt">
            <div class="accordion-item ">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed text-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                  {{"advanced" | translate}}
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#acoordionMqtt">
                <div class="accordion-body">

                  <div class="form-group row">
                    <label class="col-form-label col-sm-4 text-sm-right">{{"ClientId" | translate}}</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" [placeholder]="'ClientId' | translate" name="ClientId" [(ngModel)]="mqtt.mqttClientId" (keyup)="changesMade=_device.checkChangesSettings(originalData,mqtt)" (change)="changesMade=_device.checkChangesSettings(originalData,mqtt)">
                    </div>
                </div>

                <div class="form-group row">
                  <label class="col-form-label col-sm-4 text-sm-right">{{"QoS" | translate}}</label>
                  <div class="col-sm-8">
                      <input type="number" class="form-control" [placeholder]="'QoS' | translate" name="QoS" [(ngModel)]="mqtt.qos" (keyup)="changesMade=_device.checkChangesSettings(originalData,mqtt)" (change)="changesMade=_device.checkChangesSettings(originalData,mqtt)">
                  </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-sm-4 text-sm-right">{{"pingInterv" | translate }} {{ " (s)"}}</label>
                <div class="col-sm-8">
                    <input type="number" class="form-control" [placeholder]="'pingInterv' | translate" name="PingInterval(s)" [(ngModel)]="mqtt.pingInterval" (keyup)="changesMade=_device.checkChangesSettings(originalData,mqtt)" (change)="changesMade=_device.checkChangesSettings(originalData,mqtt)">
                </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-sm-4 text-sm-right">{{"keepAlive" | translate}} {{ " (s)"}}</label>
              <div class="col-sm-8">
                  <input type="number" class="form-control" [placeholder]="'keepAlive' | translate " name="KeepAlive(s)" [(ngModel)]="mqtt.keepAlive" (keyup)="change()" (change)="change">
              </div>
          </div>

              </div>
            </div>
          </div>
        </div>

              <div class="form-group row mt-4" >
                  <div class="col-sm-10 ml-sm-auto">
                      <button type="submit" class="btn btn-primary" (click)="save()" [disabled]="!changesMade">{{"overwrite" | translate}}</button>
                      <button type="submit" class="btn btn-light ml-2" (click)="restore()" [disabled]="!changesMade">{{"restore" | translate}}</button>
                  </div>

              </div>
          </form>
          <div class="alert alert-danger alert-dismissible mt-2" role="alert" *ngIf="error!=''">
            <div class="alert-message">
                {{ error }}
            </div>
        </div>
        <div class="alert alert-success alert-dismissible mt-2" role="alert" *ngIf="successMessage">
            <div class="alert-message">
                {{"updatedSuccesfully" | translate}}
            </div>
        </div>
      </div>
  </div>
</div>

