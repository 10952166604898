

<main class="main h-100 w-100">
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                <div class="d-table-cell align-middle">

                    <div class="text-center mt-4">
                        <h1 class="h2">{{"reestablish" | translate}}</h1>
                        <p class="lead">
                            <!-- Sign in to your account to continue -->
                        </p>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="m-sm-4">
                                <div class="text-center">
                                    <img src="/assets/img/orbis/orbis-logo.jpg" alt="Orbis logo" class="img-fluid" width="132" height="132" />
                                </div>
                                <form class="mt-4">
                                    <p>{{"enterEmailToRestablishPass" | translate}}</p>
                                    <div class="form-group">
                                        <input class="form-control form-control-lg" [(ngModel)]="email" type="email" name="email" [placeholder]="'email' | translate" />
                                    </div>
                                    <re-captcha #captchaRef="reCaptcha"
                                    siteKey="6LdmZkckAAAAAM8ZmRJ-Gt8CfhPX3GTKPc_HkDen"
                                    size="invisible"
                                    (resolved)="$event && checkRecaptcha($event)"></re-captcha>
                                    <div class="text-center mt-3">
                                        <a class="btn btn-lg btn-primary" (click)="captchaRef.execute();">{{"send-email" | translate}}</a>
                                        <!-- <button type="submit" class="btn btn-lg btn-primary">Sign in</button> -->
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
