import { Component, ElementRef, EventEmitter, Input } from '@angular/core';
import { Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectInpI, idTagInfo } from '../../../models/components.interface';
import moment from 'moment';
import { DatePipe, formatDate } from '@angular/common';

@Component({
  selector: 'app-crud-modal',
  templateUrl: './crud-modal.component.html',
  styleUrls: ['./crud-modal.component.scss'],
  providers: [DatePipe],
})
export class CrudModalComponent {
  @Output() dataToSend: EventEmitter<any> = new EventEmitter();
  @Input() public data;
  actualDate: any;

  body: idTagInfo = { expiryDate: '', parentIdTag: '', status: '' };
  statusOpt: SelectInpI[] = [
    { value: '0', viewValue: 'Accepted' },
    { value: '1', viewValue: 'Rejected' },
    { value: '2', viewValue: 'Expired' },
    { value: '3', viewValue: 'Invalid' },
    { value: '4', viewValue: 'In transaction' },
    { value: '5', viewValue: 'No credit' },
    //{ value: '6', viewValue: 'Blocked' },
    //{ value: '7', viewValue: 'ConcurrentTx' },
    //{ value: '8', viewValue: 'NotDefined' },

  ];
  successMessage = {};
  error = {};
  buttonDis: boolean = true;
  startDate: any;
  date: any;
  serial: string;
  currentDate: string;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.actualDate = formatDate(new Date(), 'YYYY-MM-ddThh:mm', 'es');
    this.initForm();
    const now = new Date();
    now.setDate(now.getDate() + 1);
    now.setHours(0, 0, 0, 0);
    this.currentDate = now.toISOString().slice(0, 16);
  }

  initForm() {
    switch (this.data.option) {
      case 'add': {
        this.body.expiryDate = formatDate(new Date(), 'YYYY-MM-ddThh:mm', 'es');
        break;
      }
      case 'edit': {
        this.body.expiryDate = this.data.data.localAuthorizationList[0].idTagInfo.expiryDate;
        this.body.parentIdTag = this.data.data.localAuthorizationList[0].idTagInfo.parentIdTag;
        this.statusOpt.forEach((x) => {
          if (x.viewValue === this.data.data.localAuthorizationList[0].idTagInfo.status) {
            this.body.status = x.viewValue;
          }
        });
        break;
      }
      case 'delete': {
        break;
      }

      default: {
        break;
      }
    }
  }
  sendData() {
    let objToSend;
    var date: any = moment(this.body.expiryDate).format('YYYY-MM-DDTHH:mm');
    if(date == 'Invalid date')
      this.body.expiryDate = "";
    else
      this.body.expiryDate = date.toLocaleString();
    switch (this.data.option) {
      case 'add': {
        objToSend = {
          listVersion: 1,
          localAuthorizationList: [
            {
              idTag: '',
              idTagInfo: {
                expiryDate: this.body.expiryDate,
                parentIdTag: this.body.parentIdTag,
                status: Number(this.body.status),
              },
            },
          ],
        };
        this.dataToSend.emit({ body: objToSend, mode: 'add' });
        break;
      }

      case 'edit': {
        objToSend = {
          expiryDate: this.body.expiryDate,
          parentIdTag: this.body.parentIdTag,
          status: this.body.status, //se envia status en string
          //status: Number(this.body.status), //se envia status en number
        };
        this.dataToSend.emit({ body: objToSend, mode: 'edit', idTag: this.data.idTag });
        break;
      }

      case 'delete': {
        let body = {
          serial: this.data.serial,
          idTag: this.data.idTag
        };
        this.dataToSend.emit({ body: body, mode: 'delete' });
        break;
      }

      case 'deleteAll': {
        let body = {
          serial: this.data.serial,
        };
        this.dataToSend.emit({ body: body, mode: 'deleteAll' });
        break;
      }
      default: {
        break;
      }
    }
    this.close();
  }

  /*PRIVATE*/
  close() {
    this.activeModal.close();
  }
  toJson(element) {
    return JSON.stringify(Object.assign({}, element));
  }
  controlInputs() {
    if(this.body.expiryDate != this.data.data.localAuthorizationList[0].idTagInfo.expiryDate ||
      this.body.parentIdTag != this.data.data.localAuthorizationList[0].idTagInfo.parentIdTag ||
      this.body.status != this.data.data.localAuthorizationList[0].idTagInfo.status){
        this.buttonDis = false
    }else{
      this.buttonDis = true
    }
  }
}
