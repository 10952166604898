import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterDevices'
})
export class FilterDevicesPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if(!value){
      return value;
    }
    return value.filter(item => String(item.serial).toUpperCase().indexOf(args[0].toUpperCase()) !== -1);
  }

}
