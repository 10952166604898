import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Router } from '@angular/router';
//Firebase
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private _router: Router, public auth:  AngularFireAuth) {

    this.user = this.auth.authState;

    this.isLoggedIn = false;

    this.refreshAuthenticationStatus();

   }

  public user: Observable<firebase.User | null >;

  public isLoggedIn: boolean;

  public userToken: String;


  login(username, password): Promise< firebase.auth.UserCredential> {
    return this.auth.signInWithEmailAndPassword(username, password)
  }

  register(userInfo) {
     return this.auth.createUserWithEmailAndPassword(userInfo.email,userInfo.password);
  }

  // Obtener el estado de autenticación
  get authenticated():boolean {

    return this.isLoggedIn; // True ó False
  }

  // Obtener el observador del usuario actual
  get currentUser(): Observable<firebase.User | null> {
    return this.user;
  }

  get getToken(): Observable<firebase.auth.IdTokenResult> | null {
    this.auth.idTokenResult.subscribe((token) => {
      //sessionStorage.setItem('token', token!.token);
      this.userToken = token!.token;
      return token;
    })
    return null;
  }

  refreshAuthenticationStatus(){
     return this.user.subscribe((user) => {
      if (user) {
          //Set globally user data
          this.isLoggedIn = true;
          user.getIdTokenResult().then((token) => {
            this.userToken = token!.token;
          })
      }else{
        this.isLoggedIn = false;
      }
    })
  }

  // Verificar correo
  verifyEmail() {
    return this.currentUser.subscribe({
      next: (user) => {
        user!.sendEmailVerification();
      },
      error: (err) => {
        console.log("verifyEmail:",err);
      }
    });
  }

  // Actualizar perfil firebase authentication
  updateProfile(name?) {
    return this.currentUser.subscribe((user) => {
      user?.updateProfile({
      displayName:
      (name) ? name : user?.displayName,
      });
    })
  }

  public logout(): void {
    sessionStorage.clear();
    Cookie.deleteAll();

    this.auth.signOut().then(() => {
      this.isLoggedIn = false;
      this._router.navigate(['/login']);
    });
  }

  // Recuperar contraseña
  resetPassword(email: string) {
    return this.auth.sendPasswordResetEmail(email);
  }
}
