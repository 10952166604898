// import { InfoWindow } from '@agm/core/services/google-maps-types';
import { Component,  Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';



@Component({
  selector: 'app-geolocation',
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.scss']
})
export class GeolocationComponent {

  @Input() allSettings = false;
  device: string = '';
  latitud: number = 0;
  longitud: number = 0;
  orignalLat: number = 0;
  orignalLng: number = 0;
  change = true;
  modalLoad: NgbModalRef;

  error: string = '';
  successMessage: boolean = false;

  map: google.maps.Map;
  address : string = '';
  mark: any ;

  constructor(public _device: DeviceService, private _aRoute: ActivatedRoute, private _modal: ModalService) { }

  ngOnInit(): void {
    this._aRoute.parent?.parent?.params.subscribe((params: Params) => this.device = params['serial']);
    this._device.getModuleInfo(this.device, 'clock', "cfg").subscribe((data) => {
      data.cfg.geoloc = JSON.parse(data.cfg.geoloc);
      this.latitud = parseFloat(data.cfg.geoloc.coords[0])
      this.longitud = parseFloat(data.cfg.geoloc.coords[1])
      this.orignalLat = this.latitud;
      this.orignalLng = this.longitud;


      this.initMap();
    })
  }


  async initMap(): Promise<void> {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    const Places = await google.maps.importLibrary("places") as google.maps.PlacesLibrary;

    var geocoder = new google.maps.Geocoder();

    this.mark = new AdvancedMarkerElement({
      position: { lat: this.latitud, lng: this.longitud },
      title: 'cargador'
    });

    const mapOptions = {
      zoom: 17,
      center: { lat: this.latitud, lng: this.longitud },
      mapId: 'mapaCargadores',  //Campo obligatorio
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false
      // disableDefaultUI: true,
    };


    geocoder.geocode( { 'location' : {lat : this.latitud, lng: this.longitud}}, (results) =>{
      console.log(results![0].formatted_address);
      this.address = results![0].formatted_address;
    } )

    this.map = new Map(document.getElementById('map') as HTMLElement, mapOptions);
    this.mark.setMap(this.map);

    this.map.addListener('click', (location) => {
      this.latitud = location.latLng.lat()
      this.longitud = location.latLng.lng()

      this.mark.map = null; // Borra la anterior marca

      this.mark = new AdvancedMarkerElement({
        position: { lat: this.latitud, lng: this.longitud },
        map: this.map
      })
      this.changeData(this.latitud, this.longitud);
    })

    const input = document.getElementById("pac-input") as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);
    // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input); // Añade el input del Autocomplete al mapa
    this.map.addListener("bounds_changed", () => {
      searchBox.setBounds(this.map.getBounds() as google.maps.LatLngBounds);
    });

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (places?.length == 0) {
        return;
      }
      // Por cada 'Place', get icono, nombre y localización.
      const bounds = new google.maps.LatLngBounds();
      places?.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }
        this.latitud = place.geometry.location.lat();
        this.longitud = place.geometry.location.lng();

        this.mark.setMap(null);
        this.mark = new AdvancedMarkerElement({
          position: place.geometry.location,
          map: this.map,
          title: place.name
        })

        this.changeData(this.latitud, this.longitud);

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.map.fitBounds(bounds);
    })

  }

  save() {
    this.modalLoad = this._modal.open(LoadingPopupComponent);
    let body = {
      "geoloc": {
        "coords": [this.latitud, this.longitud]
      }
    }
    this._device.setModuleCfg(this.device, body, 'clock').subscribe((data) => {
      console.log('data', data);
      this.modalLoad.close();
      this.successMessage = true;
      setTimeout(() => {
        this.successMessage = false;
      }, 10000);
    }, err => {
      console.error(err)
      this.error = err.error.message;
      setTimeout(() => {
        this.error = '';
      }, 10000);
      this.modalLoad.close();
    })
  }

  changeData(lat : number, lng : number) {
    if (this.orignalLat != lat || this.orignalLng != lng) {
      this.change = false;
    }
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({location: {lat: lat, lng: lng}}, (results, status) =>{
      if (status === 'OK'){
        if (results![0] ){
          console.log(results![0].formatted_address);
          this.address = results![0].formatted_address;
        }
      }
    })
  }

  async restoreData() {
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

    this.change = true;

    this.mark.setMap(null); // Borra la anterior marca
    this.mark = new AdvancedMarkerElement({
      position: { lat: this.orignalLat, lng: this.orignalLng },
      map: this.map
    });
    this.changeData(this.orignalLat, this.orignalLng );
  }
}
