<div class="header d-flex justify-content-between">
  <h1 class="header-title">
    {{"device_assignment" | translate}}
  </h1>
</div>
<div class="col-12 col-xl-12">
  <div class="card">
    <div class="card-header">
      <h5 class="card-title"></h5>
      <h6 class="card-subtitle text-muted"></h6>
    </div>
    <div class="card-body">
      <form class="row align-items-center">
        <div class="form-group col-xl-5 justify-content-center d-flex">
          <div>
            <label class="col-form-label col-sm-4">{{"user" | translate}}</label>
            <div class="col-12">
              <input type="email" class="form-control" placeholder="User" [(ngModel)]="user" name="user">
            </div>
          </div>
        </div>
        <div class="col-2 justify-content-center d-flex">
          <i style="font-size: 40px;" class="fas fa-link"></i>
        </div>
        <div class="form-group col-xl-5 justify-content-center d-flex">
          <div>
            <label class="col-form-label col-sm-12">{{"serial" | translate}}</label>
            <div class="col-12">
              <input type="text" class="form-control" placeholder="Serial" [(ngModel)]="body.serial" name="serial">
            </div>
          </div>
        </div>

        <div class="form-group row justify-content-center d-flex">
          <div class="justify-content-center d-flex">
            <button type="submit" class="btn btn-primary" [disabled]="!user || !body.serial" (click)="save()">{{"link" | translate}}</button>
          </div>
        </div>
      </form>

      <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="response.error && error404">
        <div class="alert-message">
          {{ "error404" | translate }}
        </div>
      </div>

      <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="response.error && error500">
        <div class="alert-message">
          {{ "error500" | translate }}
        </div>
      </div>

      <div class="alert alert-success alert-dismissible" role="alert" *ngIf="response.success">
        <div class="alert-message">
          {{"settings.successMessage" | translate}}
        </div>
      </div>
    </div>
  </div>
</div>

<button type="button" id="toggle-name-modal" class="btn btn-primary d-none" data-toggle="modal"
  data-target="#centeredModalPrimary">
  Primary
</button>
<div class="modal fade" id="centeredModalPrimary" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{"confirmAssgnmt" | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body m-3 text-center">
        <p class="mb-0">¿{{"assignDev" | translate}} {{body.serial}} {{"toUser" | translate}} {{user}}?</p>
        <p>{{"youWantToContinue" | translate}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" id="dismiss-confirm-modal" class="btn btn-secondary" data-dismiss="modal">{{"cancel" | translate}}</button>
        <button type="button" class="btn btn-primary" (click)="assign()">{{"assign" | translate}}</button>
      </div>
    </div>
  </div>
</div>
