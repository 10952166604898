<div class="modal-header" data-cy="errorModal" >
    <div class="d-flex w-100">

        <h2 class="text-danger modal-title"><i class="fas fa-times"></i></h2>
        <h2 class="modal-title text-danger text-center w-100">ERROR</h2>
    </div>

</div>
<div class="modal-body m-3">
    <p class="mb-0">{{description}}</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="close()" data-cy="errorModalOkButton" >Ok</button>
</div>
