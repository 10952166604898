<div class="flex-fill w-100" id="card-state">
  <div class="card-header">
    <!--BODY PAGE-->
    <div class="">
      <h1 *ngIf="splOperativeMode === 2">{{"splDtitle" | translate}}</h1>
      <h1 *ngIf="splOperativeMode === 1">{{"multiDTitle" | translate}}</h1>
      <div class="card-body mh-300" *ngIf="loading">
        <div class="d-flex justify-content-center align-items-center h-100 mh-300">
          <div class="spinner-grow text-primary" style="width: 4rem; height: 4rem;" role="status">
            <span class="sr-only">{{"loading" | translate}}...</span>
          </div>
        </div>
      </div>

      <div *ngIf="!loading">
        <div class="row">
          <!-- Installation -->
          <div class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6">
            <div class="card p-5">
              <div class="row">
                <div class="col-12 col-md-12 col-xl-12">
                  <h3>{{"installation" | translate}}</h3>
                </div>
              </div>
              <!-- BAR -->
              <div class="row align-items-center pb-5">
                <div class="col-8 col-sm-7 col-md-7 col-xl-7 mt-3">
                  <ngb-progressbar class="progressBar" [value]="splDataObject.relOverload" [max]="1" [striped]="true" [ngClass]="{'progressRange': splDataObject.status == 0,'progressOverload': splDataObject.status == 1, 'progressRecover': splDataObject.status == 2}"
                    [animated]="true">
                    <span class="">{{splDataObject.relOverload}}</span>
                  </ngb-progressbar>
                </div>
                <!-- DATA POWER -->
                <div class="col-4 col-sm-2 col-md-2 col-xl-2 mt-3">
                  <h4 class="row justify-content-center px-2" [ngClass]="{'text-success': splDataObject.status == 0,'textOverload': splDataObject.status == 1, 'textRecover': splDataObject.status == 2}">{{splDataObject.totalPower}}W</h4>
                  <h4 class="row justify-content-center px-2">{{splDataObject.limitPower}}W</h4>
                </div>
                <!-- STATUS -->
                <div class="col-12 col-sm-3 col-md-3 col-xl-3 mt-3">
                  <div class="row justify-content-center">
                    <span class="circleRange" [ngClass]="{'circleRange': splDataObject.status == 0,'circleOverload': splDataObject.status == 1, 'circleRecover': splDataObject.status == 2}">
                      <p class="m-0" *ngIf="splDataObject.status == 0">{{"splStatusRange" | translate}}</p>
                      <p class="m-0" *ngIf="splDataObject.status == 1">{{"splStatusOverload" | translate}}</p>
                      <p class="m-0" *ngIf="splDataObject.status == 2">{{"splStatusRecover" | translate}}</p>
                    </span>
                  </div>
                </div>
              </div>
              <!-- DONUTS -->
              <div class="row justify-space-between align-items-center">
                <div class="col-4 col-sm-4 col-md-4 col-xl-4 text-center p-0" *ngFor="let gauge of splDataObject.gauge">
                  <div class="chart chart-xs">
                    <p>
                      <strong class="text-success" style="font-size: 20px;" [ngClass]="{'textOverload': gauge.totalCurrent*0.23 > gauge.limitPowerByPhase}">{{gauge.totalPowerByPhase}} W</strong>
                      <span class="textRange"> / {{gauge.limitPowerByPhase}} W</span>
                    </p>
                    <div style="width: 130px;margin: 0 auto">
                      <canvas class="ring-outside" height="130" width="130" id={{gauge.id}}></canvas>
                    </div>
                    <p>{{"Range" | translate}}: <span class="textRange" [ngClass]="{'textOverload': gauge.totalCurrent*0.23 > gauge.limitPowerByPhase}">{{(gauge.relOverload)}}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Charger lineup -->
          <div class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6">
            <div class="card p-5">
              <div class="row">
                <div class="col-12 col-md-12 col-xl-12">
                  <h3>{{"chargerLine" | translate}}</h3>
                </div>
              </div>
              <!-- BAR -->
              <div class="row align-items-center pb-5">
                <div class="col-8 col-sm-7 col-md-7 col-xl-7 mt-3">
                  <ngb-progressbar class="progressBar" [value]="splDataObject2.relChgLineOverload" [max]="1" [striped]="true" [ngClass]="{'progressRange': splDataObject2.status == 0,'progressOverload': splDataObject2.status == 1, 'progressRecover': splDataObject2.status == 2}"
                    [animated]="true">
                    <span class="">{{splDataObject2.relChgLineOverload}}</span>
                  </ngb-progressbar>
                </div>
                <!-- DATA POWER -->
                <div class="col-4 col-sm-2 col-md-2 col-xl-2 mt-3">
                  <h4 class="row justify-content-center px-2" [ngClass]="{'text-success': splDataObject2.status == 0,'textOverload': splDataObject2.status == 1, 'textRecover': splDataObject2.status == 2}">{{splDataObject2.splEvsePower}}W</h4>
                  <h4 class="row justify-content-center px-2">{{splDataObject2.splLimitPower}}W</h4>
                </div>
                <!-- STATUS -->
                <div class="col-12 col-sm-3 col-md-3 col-xl-3 mt-3">
                  <div class="row justify-content-center">
                    <span class="circleRange" [ngClass]="{'circleRange': splDataObject2.status == 0,'circleOverload': splDataObject2.status == 1, 'circleRecover': splDataObject2.status == 2}">
                      <p class="m-0" *ngIf="splDataObject2.status == 0">{{"splStatusRange" | translate}}</p>
                      <p class="m-0" *ngIf="splDataObject2.status == 1">{{"splStatusOverload" | translate}}</p>
                      <p class="m-0" *ngIf="splDataObject2.status == 2">{{"splStatusRecover" | translate}}</p>
                    </span>
                  </div>
                </div>
              </div>
              <!-- DONUTS -->
              <div class="row justify-space-between align-items-center">
                <div class="col-4 col-sm-4 col-md-4 col-xl-4 text-center p-0" *ngFor="let gauge of splDataObject2.gauge">
                  <div class="chart chart-xs">
                    <p>
                      <strong class="text-success" style="font-size: 20px;" [ngClass]="{'textOverload': gauge.splEvsePowerByPhase > gauge.splLimitPowerByPhase}">{{gauge.splEvsePowerByPhase}} W</strong>
                      <span class="textRange"> / {{gauge.splLimitPowerByPhase}} W</span>
                    </p>
                    <div style="width: 130px;margin: 0 auto">
                      <canvas class="ring-outside" height="130" width="130" id={{gauge.id}}></canvas>
                    </div>
                    <p>{{"Range" | translate}}: <span class="textRange" [ngClass]="{'textOverload': gauge.splEvsePowerByPhase > gauge.splLimitPowerByPhase}">{{gauge.relChgLineOverload}}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--TABLE-->
        <div class="table-responsive">
          <table class="table table-striped my-5">
            <thead>
              <tr>
                <th class="">{{"splTableMac" | translate}}</th>
                <th class="">{{"splTableSerial" | translate}}</th>
                <th class="">{{"splTableConn" | translate}}</th>
                <th class="">{{"splTableIndex" | translate}}</th>
                <th class="">{{"connection" | translate}}</th>
                <th class="">{{"status" | translate}}</th>
                <th class="">PWM</th>
                <th class="" matTooltipPosition="above" matTooltip="{{toolTipPbp | translate}}">{{"pbpAcronim" | translate}}</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of splElements">
                <td class="">{{data.mac }}</td>
                <td class="">{{data.serial}}</td>
                <td class="">{{data.connectorId}}</td>
                <td class="">{{data.index}}</td>

                <td class="text-danger" *ngIf="data.disconnected">{{"disconnected" | translate}}</td>
                <td class="text-success" *ngIf="!data.disconnected">{{"connected" | translate}}</td>

                <td class="text-danger" *ngIf="data.flags == 0">{{"splStatus0" | translate}}</td>
                <td class="text-warning" *ngIf="data.flags == 1">{{"splStatus1" | translate}}</td>
                <td class="text-success" *ngIf="data.flags == 2">{{"splStatus2" | translate}}</td>

                <td class="">{{data.pwm.toFixed(2) }} A</td>
                <td class="">{{data.pbp | commaToSlash}}</td>
              </tr>
            </tbody>
          </table>
          <h4 class="text-right">{{"splTableSlaves" | translate}}: {{splElements.length}}</h4>
        </div>

      </div>
    </div>
  </div>
</div>
