import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-secondaryMeter',
  templateUrl: './modal-secondaryMeter.component.html',
  styleUrls: ['./modal-secondaryMeter.component.scss']
})
export class ModalSecondaryMeterComponent implements OnInit {

    @Input()
    shellyMainIp: string;
    @Input()
    shellyMainModel: number;
    @Input()
    shellySecondaryIp: string;
    @Input()
    shellySecondaryModel: number;
    @Input()
    splMeter: number;
    @Input()
    splMeterAux: number;

    sameMainMeter=false;

    shellyMainModels = [
        {label: 'ShellyEM', value: 0},
        {label: 'Shelly3EM', value: 1}
    ]

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit(): void {
      if (this.shellySecondaryIp === undefined) this.shellySecondaryIp = '0.0.0.0';
      if (this.shellySecondaryModel === undefined ) this.shellySecondaryModel = 0;
      
      if(this.shellyMainIp == this.shellySecondaryIp && this.shellyMainModel == this.shellySecondaryModel && this.splMeter == 3)
        this.sameMainMeter = true;

    }

    close(){
        var result:any = {};
        if(this.sameMainMeter){
          result.model = this.shellyMainModel;
          result.ip = this.shellyMainIp;
        }
        else{
          result.model = this.shellySecondaryModel;
          result.ip = this.shellySecondaryIp;
        }
        result.zone = "secondary";

        this.activeModal.close(result);
      }

    changeSameMeter(){
      if(this.sameMainMeter)
        this.sameMainMeter = false;
      else{
        this.sameMainMeter = true;
        this.shellySecondaryModel = this.shellyMainModel;
        this.shellySecondaryIp = this.shellyMainIp;
      }
    }
}
