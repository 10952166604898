import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  Inject,
  LOCALE_ID,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as XLSX from 'xlsx';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-card-historic',
  templateUrl: './card-historic.component.html',
  styleUrls: ['./card-historic.component.scss'],
  // animations: [
  //   trigger('fadeInLeft', [transition('* => *', useAnimation(fadeInRight, {
  //     // Set the duration to 5seconds and delay to 2seconds
  //     params: { timing: 0.2, delay: 0 }
  //   }))])
  // ]
})
export class CardHistoricComponent implements OnInit {
  @Input() dataHistoric: any;
  @Input() connectors: any;

  device: string = '';
  view: string;
  selectedConnector: any;
  historics: any = {};


  constructor( private _aRoute: ActivatedRoute, @Inject(LOCALE_ID) public locale: string  ) {
    this.view = 'consumptions';
  }

  ngOnInit(): void {
    this.initComponents();
  }

  initComponents(){
    this.device = this._aRoute.snapshot.params.serial;

    // Para ordenar la array de los conectores por si se hubieran programado en el orden equivocado
    this.connectors.sort((a, b) => a.data.id - b.data.id);

    this.connectors.forEach((conn) => {
      this.historics[conn.name] = this.dataHistoric.filter( (historic) => historic.element == conn.name );
    });
    this.selectedConnector = this.connectors[0].name;
  }
  downloadFiles(extension: string){

    let hist = this.historics[this.selectedConnector];
    let objToPrint = [];


    objToPrint = hist.map((element) => ({
      date: formatDate(element.start_datetime, 'dd-MM-yyyy', this.locale),
      startTime:  formatDate(element.start_datetime, 'h:mm a', this.locale),
      endtime: formatDate(element.end_datetime, 'h:mm a', this.locale),
      duration: this.subtractDates( element.end_datetime, element.start_datetime ),
      energy: element.end_energy - element.start_energy + ' Wh',
      startSource: element.start_source,
      idTag: element.user,
      endSource: element.end_source,
    }));

    const worksheet = XLSX.utils.json_to_sheet(objToPrint);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Historics');
    XLSX.utils.sheet_add_aoa( worksheet, [['Date', 'Start time', 'End time', 'Duration', 'Energy', 'Start source', 'IdTag', 'End source']], { origin: 'A1' } );
    XLSX.writeFile(workbook, `historic_data_${this.selectedConnector}.${extension}`, { compression: true });

  }

  //PRIVATE
  subtractDates(date1, date2) {
    var difference_ms = new Date(date1).getTime() - new Date(date2).getTime();
    //take out milliseconds
    difference_ms = difference_ms / 1000;
    var seconds = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    var minutes = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    var hours = Math.floor(difference_ms % 24);
    if (hours != 0)
      return (
        hours + 'h ' + minutes.toString() + 'm ' + seconds.toString() + 's'
      );
    else return minutes.toString() + 'm ' + seconds.toString() + 's';
  }


}
